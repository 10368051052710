import React, { useEffect, useState } from 'react';

import { useMutation, useReactiveVar } from '@apollo/client';
import { DndContext, DragOverlay, MouseSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { useTheme } from '@emotion/react';
import { Text } from '@mantine/core';
import { Grow, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { get, split, orderBy } from 'lodash';
import { Waypoint } from 'react-waypoint';

import FeatureCard from './FeatureCard';
import { styles } from './styles';
import TeamSprint from '../../../containers/Delivery/components/DetailedView/TeamSprint';
import { canScroll, dragFeatureEnd, sortTeams } from '../../../containers/Delivery/utils';
import { UPDATE_FEATURE_STAGE } from '../../../containers/Product/Features/graphql';
import { stickyHeaderVar } from '../../../reactiveVariables';
import { getSprintMilestones } from '../../Common/BigAgileTable/helper';
import Milestone from '../../Common/Milestone';

const useStyles = makeStyles(styles);

const FeatureBoard = ({
  teams,
  pi,
  milestones,
  features,
  objectives,
  selectedMilestone,
  handleMilestoneClick,
  handleMouseEnter,
  handleMouseLeave,
  anchorMilestone,
  isHoverActive,
  showObjectives,
  showCurrentState,
  progressBy,
  includes,
  deferredFeatures,
}) => {
  const [updateFeature] = useMutation(UPDATE_FEATURE_STAGE);

  const theme = useTheme();
  const stickyHeader = useReactiveVar(stickyHeaderVar);
  const classes = useStyles({ stickyHeader: stickyHeader });
  const [activeId, setActiveId] = useState(null);

  useEffect(() => () => stickyHeaderVar(false), []);

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: { distance: 5 },
  });
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 5 },
  });

  const sensors = useSensors(pointerSensor, mouseSensor);

  const handleDragStart = (event) => setActiveId(event.active?.data?.current);
  const handleDragEnd = (event) => {
    dragFeatureEnd(event, updateFeature);
    setActiveId(null);
  };

  const sprintField =
    pi.status !== 'planning'
      ? showCurrentState
        ? 'committedSprint'
        : 'originalState.committedSprint'
      : 'committedSprint';

  const renderSprintForTeam = (team, pi, selectedMilestone) => {
    const teamField = pi.status !== 'planning' ? (showCurrentState ? 'teamId' : 'originalState.teamId') : 'teamId';

    const teamFeatures = features.filter(
      (feature) => get(feature, teamField) === team.id && feature.programIncrement === pi.id,
    );
    const teamDeferredFeatures = deferredFeatures.filter((feature) => get(feature, 'teamId', null) === team.id);

    const teamObjectives = objectives.filter((objective) => objective.teamId === team.id);
    const plannedItems = pi?.sprints?.map((sprint) => (
      <TeamSprint
        key={sprint.id}
        team={team}
        sprint={sprint}
        teamFeatures={teamFeatures}
        deferredFeatures={teamDeferredFeatures}
        sprintField={sprintField}
        teamObjectives={teamObjectives}
        progressBy={progressBy}
        includes={includes}
        enableDrag={pi.status === 'planning'}
        selectedMilestone={selectedMilestone}
        showObjectives={showObjectives}
        pi={pi}
      />
    ));

    return (
      <TableRow key={team.id} className={classes.row}>
        <TableCell className={classes.tableHeadCol}>
          <span className={classes.teamName}>{team.name}</span>
        </TableCell>
        {plannedItems}
      </TableRow>
    );
  };

  const onLeave = ({ currentPosition, previousPosition }) => {
    const isAbove = currentPosition === Waypoint.above;
    const wasInside = previousPosition === Waypoint.inside;

    if (isAbove && wasInside) {
      stickyHeaderVar(true);
    }
  };

  const onEnter = ({ currentPosition, previousPosition }) => {
    stickyHeaderVar(false);
  };

  const isVisibleToolTip = ({ id }) =>
    (isSelectedMilestone(id) && isHoverActive) || (isSelectedMilestone(id) && anchorMilestone);

  const isSelectedMilestone = (id) => id === selectedMilestone;

  const renderDragOverlay = (feature) => (
    <FeatureCard noTooltip={true} selectedMilestone={selectedMilestone} feature={feature} progressBy={progressBy} />
  );

  return (
    <Grow in={true}>
      <div>
        <Paper elevation={0} className={classes.scroll}>
          <DndContext
            autoScroll={{ canScroll }}
            sensors={sensors}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}>
            <Table stickyHeader={stickyHeader} className={classes.table}>
              <TableHead>
                <Waypoint onLeave={onLeave} onEnter={onEnter} />
                <TableRow className={classes.rowMilestones}>
                  <TableCell align="right" className={classes.tableCellMilestones} key="team" />
                  {pi?.sprints?.map((sprint, index) => {
                    const sprintMilestones = getSprintMilestones(pi, milestones, sprint, index);
                    return (
                      <TableCell
                        align="center"
                        key={sprint.id}
                        classes={{ root: classes.tableHeadCellRoot }}
                        className={classes.tableCellNoBorder}>
                        {sprintMilestones.map((milestone) => (
                          <Milestone
                            key={milestone.id}
                            milestone={milestone}
                            selectedMilestone={selectedMilestone}
                            isVisibleTooltip={isVisibleToolTip(milestone)}
                            handleMouseLeave={handleMouseLeave}
                            handleMouseEnter={() => handleMouseEnter(milestone.id)}
                            handleMilestoneClick={() => handleMilestoneClick(milestone.id)}
                          />
                        ))}
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell key="header" className={classes.tableHeadRow}>
                    <Text size="sm" c={theme.palette.text.secondary}>
                      Teams ({teams.length})
                    </Text>
                  </TableCell>
                  {pi.sprints &&
                    pi.sprints.map((sprint) => {
                      const startDate = split(sprint.startDate, '-');
                      const endDate = split(sprint.endDate, '-');

                      return (
                        <TableCell
                          classes={{ root: classes.tableHeadCellRoot }}
                          key={sprint.startDate}
                          className={classes.tableHeadRow}>
                          <div
                            className={
                              classes.sprintDates
                            }>{`${startDate[2]}/${startDate[1]} - ${endDate[2]}/${endDate[1]}`}</div>
                          <div className={classes.sprintItems}>{sprint.name.replace('Sprint', '').trim()}</div>
                        </TableCell>
                      );
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {orderBy([...teams], [sortTeams, 'name'], 'desc').map((team) =>
                  renderSprintForTeam(team, pi, selectedMilestone),
                )}
              </TableBody>
              <DragOverlay>{activeId ? renderDragOverlay(activeId) : null}</DragOverlay>
            </Table>
          </DndContext>
        </Paper>
      </div>
    </Grow>
  );
};

export default FeatureBoard;
