import * as React from 'react';

function SvgSidebar(props) {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.9165 1.44461H14.2082C15.3588 1.44461 16.2915 2.43953 16.2915 3.66683V10.3335C16.2915 11.5608 15.3588 12.5557 14.2082 12.5557H6.9165V1.44461ZM5.87484 1.44461H3.7915C2.64091 1.44461 1.70817 2.43953 1.70817 3.66683V10.3335C1.70817 11.5608 2.64091 12.5557 3.7915 12.5557H5.87484V1.44461ZM0.666504 3.66683C0.666504 1.82588 2.06561 0.333496 3.7915 0.333496H14.2082C15.9341 0.333496 17.3332 1.82588 17.3332 3.66683V10.3335C17.3332 12.1744 15.9341 13.6668 14.2082 13.6668H3.7915C2.06561 13.6668 0.666504 12.1744 0.666504 10.3335V3.66683Z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgSidebar;
