import * as React from 'react';

function InitiativeSvg(props) {
  return (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.07478 0.780368C9.18814 1.1747 10.8925 2.8835 11.2333 4.9209C11.5742 6.56397 11.0288 8.20704 9.8017 9.39005C9.32449 9.85011 9.0518 10.3759 8.91545 10.9674L8.71093 11.8218C8.50641 12.6762 7.68834 13.3334 6.80209 13.3334H5.16593C4.21151 13.3334 3.4616 12.6762 3.25708 11.8218L3.12074 10.9674C2.98439 10.3759 2.7117 9.85011 2.23449 9.39005C1.21189 8.40421 0.666504 7.15548 0.666504 5.7753C0.666504 4.26367 1.34824 2.81777 2.64352 1.83193C3.87064 0.846091 5.5068 0.451755 7.07478 0.780368ZM7.55199 10.7045C7.75651 9.91583 8.16555 9.12716 8.84728 8.46993C9.73353 7.61553 10.1426 6.3668 9.86988 5.11807C9.59718 3.60645 8.37007 2.35771 6.80209 2.0291C6.52939 1.96338 6.2567 1.96338 5.98401 1.96338C5.09776 1.96338 4.21151 2.29199 3.4616 2.81777C2.57535 3.54072 2.02997 4.65801 2.02997 5.7753C2.02997 6.82686 2.43901 7.74698 3.12074 8.46993C3.80247 9.12716 4.27968 9.85011 4.41603 10.7045L4.62055 11.5589C4.68872 11.8218 4.89324 12.019 5.16593 12.019H6.80209C7.07478 12.019 7.34747 11.8218 7.34747 11.5589L7.55199 10.7045ZM4.04428 14.0001H7.88428C8.31095 14.0001 8.6665 14.2667 8.6665 14.6667C8.6665 15.0667 8.38206 15.3334 7.95539 15.3334H4.04428C3.61761 15.3334 3.33317 15.0667 3.33317 14.6667C3.33317 14.2667 3.61761 14.0001 4.04428 14.0001Z"
        fill="#242424"
      />
    </svg>
  );
}

export default InitiativeSvg;
