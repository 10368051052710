export const headCells = [
  {
    id: 'id',
    field: 'id',
    label: 'ID',
    disablePadding: false,
    filtering: false,
    defaultSort: 'asc',
    numeric: false,
    align: 'left',
    width: 3,
  },
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    filtering: false,
    width: 9,
    cellStyle: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  {
    id: 'progress',
    field: 'progress',
    label: 'Progress',
    width: 5,
  },
  {
    id: 'date',
    field: 'date',
    label: 'Event Date',
    width: 3,
  },
];
