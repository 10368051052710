import React, { memo } from 'react';

import { useTheme } from '@emotion/react';
import { Text } from '@mantine/core';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Card, CardActionArea, CardContent, Grid, Typography, Zoom, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { styles } from './styles';
import { openDrawer } from '../../../reactiveVariables';
import { getFeatureBackgroundColor } from '../../../utils/piColors';

const useStyles = makeStyles(styles);

const ObjectiveCard = ({ objective }) => {
  const { id } = objective;
  const theme = useTheme();
  const backgroundColor = getFeatureBackgroundColor(objective, theme);
  const classes = useStyles();

  const handleClick = () => {
    openDrawer(objective, 'objective');
  };

  return (
    <Zoom in={true}>
      <Card className={classes.card} onClick={handleClick} style={{ borderLeftColor: backgroundColor }}>
        <CardActionArea>
          <CardContent className={classes.cardContent}>
            <Grid container spacing={3}>
              <Grid container item xs={12} sx={{ marginBottom: '2px', lineHeight: 0 }} justifyContent={'space-between'}>
                <Text span={true} mt={8} mb={10} fw={550} sx={{ lineHeight: 1, padding: 0 }}>
                  {objective.commitment === 'Committed' ? (
                    <Tooltip withArrow placement="top" title={'Committed'}>
                      <TaskAltIcon style={{ width: '16px', position: 'relative', top: '-1px' }} />
                    </Tooltip>
                  ) : (
                    <Tooltip withArrow placement="top" title={'Uncommitted'}>
                      <RadioButtonUncheckedIcon style={{ width: '16px', position: 'relative', top: '-1px' }} />
                    </Tooltip>
                  )}{' '}
                  O-{id}
                </Text>
                <Text span={true} mt={8} mb={10} fw={550} sx={{ lineHeight: 1, padding: 0 }}>
                  PV {objective.plannedValue}
                </Text>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.typo} variant="caption" display="block" align="left" color="textSecondary">
                {objective.name}
              </Typography>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Zoom>
  );
};

export default memo(ObjectiveCard);
