import React from 'react';

import { useQuery } from '@apollo/client';
import { useTheme } from '@emotion/react';
import { Divider, Group, Text } from '@mantine/core';

import SvgFeatures from '../../../assets/images/Features';
import { ClickableCard, DisplayID, IDWrapper } from '../../../components/Common/Elements';
import { openDrawer } from '../../../reactiveVariables';
import { GET_FEATURES } from '../../Product/Features/graphql';
import { GET_SPRINTS } from '../../Settings/ProgramIncrement/graphql';

const FeatureList = ({ initiative, setLoading }) => {
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES);
  const { data: { sprints = [] } = {} } = useQuery(GET_SPRINTS);

  const theme = useTheme();
  const initiativeFeatures = features.filter((feature) => feature.initiativeId === initiative.id);

  return (
    <>
      {initiativeFeatures.map((feature) => (
        <ClickableCard key={feature.id} onClick={() => openDrawer(feature, 'feature')}>
          <IDWrapper>
            <SvgFeatures color={theme.palette.color.buttonIcon} />
            <DisplayID span={true} size="xs">
              {feature.enabler ? 'E' : 'F'}-{feature.id}
            </DisplayID>
          </IDWrapper>
          <div style={{ padding: '10px' }}>
            <Text lineClamp={1} fw={450} size="sm">
              {feature.name}
            </Text>
            <Group>
              <Text span={true} size="xs">
                Progress: {feature.metrics?.percentageDone || 0}%
              </Text>
              <Divider orientation="vertical" style={{ marginTop: '3px', marginBottom: '3px' }} />
              <Text span={true} size="xs">
                Estimated Sprint: {sprints.find((sprint) => sprint.id === feature.estimatedSprint)?.name}
              </Text>
            </Group>
          </div>
        </ClickableCard>
      ))}
    </>
  );
};

export default FeatureList;

FeatureList.propTypes = {};
