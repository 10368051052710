import React, { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { useTheme } from '@emotion/react';
import { Box, Divider, Grid, Text } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem, Avatar, Tooltip } from '@mui/material';
import { sortBy } from 'lodash';
import { DateTime } from 'luxon';

import ConfirmationDialog from '../../../components/Common/ConfirmationDialog';
import {
  Field,
  renderInput,
  renderDate,
  renderInputTextArea,
  renderCombobox,
} from '../../../components/Common/FormFieldsHooks/MantineField';
import ModalContainer from '../../../components/Modal';
import {
  GET_PROJECT_MILESTONES,
  INSERT_PROJECT_MILESTONES,
  UPDATE_PROJECT_MILESTONES,
  DELETE_PROJECT_MILESTONE,
} from '../../../containers/Organisation/Projects/graphql';
import { Display2 } from '../../../containers/ValueStream/InitiativesDashboard/InitiativeDashboard.style';
import { getInitiativeBgColor } from '../../../containers/ValueStream/utils';
import { color, lightColors } from '../../../shared/styles/color';
import { RagStatus } from '../RagStatus';
import { ContentInner, CircleIcon } from '../style';

export const WhenTab = ({ selectedItem, onTargetDateChange, usersList }) => {
  const theme = useTheme();

  const [showMilestoneModal, setShowMilestoneModal] = useState(false);
  const [selectedMilestoneForEdit, setSelectedMilestoneForEdit] = useState(null);

  const [ragAnchorEl, setRagAnchorEl] = useState(null);
  const openRag = Boolean(ragAnchorEl);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const [targetDate, setTargetDate] = useState(selectedItem?.planned_start_date || '');
  const [endDate, setEndDate] = useState(selectedItem?.planned_end_date || '');

  const { data: { project_milestones = [] } = {}, refetch: refetchProjectMilestones } =
    useQuery(GET_PROJECT_MILESTONES);

  const selectedItemsMilestones = project_milestones.filter((milestone) => milestone.project === selectedItem.id);

  const [ragStatus, setRagStatus] = useState('');

  const [InsertProjectMilestone] = useMutation(INSERT_PROJECT_MILESTONES);
  const [UpdateProjectMilestone] = useMutation(UPDATE_PROJECT_MILESTONES);
  const [deleteProjectMilestone] = useMutation(DELETE_PROJECT_MILESTONE);

  const addNewMilestone = async (event) => {
    try {
      await InsertProjectMilestone({
        variables: {
          milestone: {
            name: event.name,
            description: event.description,
            agreed_date: event.agreedDate,
            forecasted_date: event.forecastedDate,
            project: selectedItem.id,
            rag_status: ragStatus,
          },
        },
      });

      setShowMilestoneModal(false);
      setSelectedMilestoneForEdit(null);
      refetchProjectMilestones();
    } catch (error) {
      console.error('Error inserting new theme:', error);
    }
  };

  const updateMilestone = async (event) => {
    try {
      await UpdateProjectMilestone({
        variables: {
          milestoneId: selectedMilestoneForEdit.id,
          milestone: {
            name: event.name,
            description: event.description,
            agreed_date: event.agreedDate,
            forecasted_date: event.forecastedDate,
            project: selectedItem.id,
            rag_status: ragStatus,
            owner: event.owner,
          },
        },
      });

      await refetchProjectMilestones();
      setSelectedMilestoneForEdit(null);
      setShowMilestoneModal(false);
    } catch (error) {
      console.error('Error updating milestone:', error);
    }
  };

  const selectedMilestone = (milestone) => {
    setSelectedMilestoneForEdit(milestone);
  };

  const handleRagMenuClose = () => {
    setRagAnchorEl(null);
  };

  const handleRagSelection = (color) => {
    setRagStatus(color);
    handleRagMenuClose();
  };

  const getRagStatusColor = (color) => {
    switch (color) {
      case 'Red':
        return '#FF0000';
      case 'Amber':
        return '#F6C544';
      case 'Green':
        return '#50A771';
      default:
        return '#808080';
    }
  };

  const truncateText = (text, maxLength) => (text.length > maxLength ? `${text.slice(0, maxLength)}...` : text);

  const handleDeleteLink = () => {
    setOpenConfirmation(true);
    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDeleteConfirm = () => {
    deleteProjectMilestone({ variables: { id: selectedMilestoneForEdit.id } }).then(() => {
      refetchProjectMilestones();
      showNotification({
        title: `${selectedMilestoneForEdit.name} has been deleted`,
      });
    });

    setOpenConfirmation(false);
  };

  const handleDateChange = (e, type) => {
    const newDate = e.target.value;

    if (type === 'planned_start_date') {
      setTargetDate(newDate);
    } else {
      setEndDate(newDate);
    }
    onTargetDateChange(newDate, type);
  };

  const inputStyles = {
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    width: '100%',
    cursor: 'pointer',
  };

  return (
    <div>
      <ModalContainer
        open={showMilestoneModal}
        title={selectedMilestoneForEdit ? 'Edit Milestone' : 'Add a milestone'}
        primaryButtonText={selectedMilestoneForEdit ? 'Update milestone' : 'Add milestone'}
        onSubmit={selectedMilestoneForEdit ? updateMilestone : addNewMilestone}
        initialValues={selectedMilestoneForEdit || {}}
        close={() => {
          setShowMilestoneModal(false);
          setSelectedMilestoneForEdit(null);
        }}>
        <Grid>
          <Field
            name="name"
            data-autofocus
            required
            renderField={renderInput}
            label="Milestone title"
            defaultValue={selectedMilestoneForEdit?.name || ''}
          />
          <Field
            name="description"
            renderField={renderInputTextArea}
            label="Add description"
            defaultValue={selectedMilestoneForEdit?.description || ''}
          />
        </Grid>
        <Divider mt={16} mb={12} />
        <Grid>
          <Field
            name="agreedDate"
            required
            renderField={renderDate}
            label="Agreed date"
            size={6}
            defaultValue={selectedMilestoneForEdit?.agreed_date || ''}
          />
          <Field
            name="forecastedDate"
            required
            renderField={renderDate}
            label="Forecasted date"
            size={6}
            defaultValue={selectedMilestoneForEdit?.forecasted_date || ''}
          />
        </Grid>
        <Divider mt={16} mb={0} />

        <Grid>
          <Field
            name="owner"
            renderField={renderCombobox}
            label="Owner"
            mt="md"
            data={usersList.map((user) => ({ value: user.id, label: `${user.name}` }))}
            styles={{
              wrapper: { width: '30%', minWidth: '200px', marginTop: '0px' },
            }}
          />
        </Grid>

        <Box
          style={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
          }}>
          <Box
            style={{
              border: `1px solid ${lightColors.tileBorder}`,
              display: 'flex',
              alignItems: 'center',
              width: '65px',
              borderRadius: '8px',
              fontSize: '12px',
              marginTop: '10px',
              height: '28px',
              cursor: 'pointer',
            }}
            onClick={(e) => {
              if (!openRag) {
                setRagAnchorEl(e.currentTarget);
              }
            }}>
            <CircleIcon style={{ margin: '0px 5px' }} color={getRagStatusColor(ragStatus)} />
            <RagStatus
              handleRagSelection={handleRagSelection}
              open={Boolean(openRag)}
              anchorEl={ragAnchorEl}
              handleRagMenuClose={handleRagMenuClose}
            />
            RAG
          </Box>
        </Box>
      </ModalContainer>

      <ContentInner style={{ margin: '18px 18px' }}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginBottom: '10px',
          }}>
          <Text fw={550}>Targeted Dates</Text>
          <AddIcon style={{ width: '20px', cursor: 'pointer' }} onClick={() => setShowMilestoneModal(true)} />
        </Box>

        <Box style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', width: '100%' }}>
          <div style={{ width: '48%' }}>
            <label htmlFor="planned-start-date" style={{ marginBottom: '8px', fontSize: '14px' }}>
              Planned start date
            </label>
            <input
              id="planned-start-date"
              type="date"
              value={targetDate}
              onChange={(e) => handleDateChange(e, 'planned_start_date')}
              onFocus={(e) => e.target.showPicker()}
              style={inputStyles}
            />
          </div>

          <div style={{ width: '48%' }}>
            <label htmlFor="planned-end-date" style={{ marginBottom: '8px', fontSize: '14px' }}>
              Planned end date
            </label>
            <input
              id="planned-end-date"
              type="date"
              value={endDate}
              onChange={(e) => handleDateChange(e, 'planned_end_date')}
              onFocus={(e) => e.target.showPicker()}
              style={inputStyles}
            />
          </div>
        </Box>
      </ContentInner>

      <ContentInner style={{ margin: '18px 18px' }}>
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <Text fw={550}>Milestones ({selectedItemsMilestones.length})</Text>
          <AddIcon
            style={{ width: '20px', cursor: 'pointer' }}
            onClick={() => {
              setShowMilestoneModal(true);
              setSelectedMilestoneForEdit(null);
            }}
          />
        </Box>
      </ContentInner>

      {selectedItemsMilestones.length > 0 && (
        <div>
          <div style={{ overflow: 'hidden' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '8px 16px',
                backgroundColor: theme.palette.mode === 'dark' ? '#242424' : color.grey,
                fontSize: '14px',
              }}>
              <div style={{ width: 'calc(40% - 60px)' }}>Milestone</div>
              <div style={{ width: '7%', fontSize: '12px', marginRight: '10px' }}>Owner</div>
              <div style={{ width: '18%', fontSize: '12px', marginRight: '14px' }}>Agreed Date</div>
              <div style={{ width: '18%', fontSize: '12px', marginRight: '12px' }}>Forecasted Date</div>
              <div style={{ width: '17%', fontSize: '12px' }}>RAG Status</div>
            </div>
          </div>

          {sortBy(selectedItemsMilestones, 'name').map((milestone) => {
            const owner = usersList.find((user) => user.id === milestone.owner);
            return (
              <div
                key={milestone.id}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '8px 16px',
                  backgroundColor: theme.palette.mode === 'dark' ? '#191B1F' : '#fff',
                }}>
                <div
                  style={{
                    fontSize: '14px',
                    width: 'calc(40% - 50px)',
                  }}>
                  {truncateText(milestone.name, 30)}
                </div>

                <div style={{ width: '7%', fontSize: '12px', color: color.secondary, paddingLeft: '7px' }}>
                  {owner && (
                    <Tooltip withArrow placement="top" title={owner.name}>
                      <Avatar
                        sx={{
                          width: 20,
                          height: 20,
                          fontSize: 10,
                          fontWeight: 'bold',
                          backgroundColor: '#d156a1',
                        }}>
                        {owner.name
                          .split(' ')
                          .map((n) => n[0])
                          .join('')}
                      </Avatar>
                    </Tooltip>
                  )}
                </div>
                <div style={{ width: '20%', fontSize: '12px', color: color.secondary, paddingLeft: '7px' }}>
                  {DateTime.fromISO(milestone?.agreed_date).toFormat('MMMM dd, yyyy')}
                </div>

                <div style={{ fontSize: '12px', color: color.secondary, width: '20%', paddingLeft: '12px' }}>
                  {DateTime.fromISO(milestone?.forecasted_date).toFormat('MMMM dd, yyyy')}
                </div>
                <div style={{ fontSize: '12px', color: color.secondary, width: '20%', paddingLeft: '16px' }}>
                  {milestone.rag_status && (
                    <Display2
                      data-value={milestone.rag_status}
                      color={milestone.rag_status}
                      bgColor={getRagStatusColor(milestone.rag_status)}
                      variant="light"
                      style={{ width: '65px' }}>
                      {milestone?.rag_status?.toUpperCase() || ''}
                    </Display2>
                  )}
                </div>
                <MoreVertIcon
                  fontSize="small"
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                    selectedMilestone(milestone);
                  }}
                />

                <div>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                    styles={{ backgroundColor: 'white' }}
                    slotProps={{
                      paper: {
                        sx: {
                          width: '100px',
                          borderRadius: '15px',
                          marginTop: '10px',
                        },
                      },
                    }}>
                    <MenuItem
                      sx={{ fontSize: 14 }}
                      onClick={() => {
                        setShowMilestoneModal(true);
                        handleCloseMenu();
                      }}>
                      Edit
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      sx={{ fontSize: 14, color: 'red' }}
                      onClick={() => {
                        handleDeleteLink();
                        handleCloseMenu();
                      }}>
                      Delete
                    </MenuItem>
                  </Menu>
                </div>

                <ConfirmationDialog
                  open={openConfirmation}
                  title={`Delete milestone`}
                  text={`Are you sure you want to delete -  ${selectedMilestoneForEdit?.name}`}
                  handleOk={handleDeleteConfirm}
                  handleCancel={() => setOpenConfirmation(false)}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
