import React, { useState, useEffect, useRef, useMemo } from 'react';

import { useQuery, useReactiveVar, useMutation } from '@apollo/client';
import { Box, Loader, Select, Badge, Tooltip, Textarea } from '@mantine/core';
import { DataTableToolbar } from 'components/Common/DataTableToolbar';
import { DateTime } from 'luxon';
import { DataTable } from 'mantine-datatable';
import { useNavigate } from 'react-router-dom';

import { selectedOwnerVar, selectedValueStreamVar } from '../../../reactiveVariables';
import { color } from '../../../shared/styles/color';
import { GET_FEATURES } from '../../Product/Features/graphql';
import { GET_USERS } from '../../Settings/GraphQL/users';
import LinearWithValueLabel from '../../ValueStream/components/OKRTable/LinearWithValueLabel';
import { GET_ALL_INITIATIVES } from '../../ValueStream/Initiatives/graphql';
import { Display2 } from '../../ValueStream/InitiativesDashboard/InitiativeDashboard.style';
import { GET_PROJECTS, UPDATE_PROJECT } from '../Projects/graphql';

import '@mantine/core/styles.css';
import 'mantine-datatable/styles.css';

const PAGE_SIZE = 60;

export const Reporting = () => {
  const [projects, setProjects] = useState([]);
  const [allProjects, setAllProjects] = useState([]);
  const [page, setPage] = useState(0);
  const [sortStatus, setSortStatus] = useState({ columnAccessor: 'name', direction: 'asc' });
  const [visibleColumns, setVisibleColumns] = useState({});
  const [tempVisibleColumns, setTempVisibleColumns] = useState({});
  const [tableHeight, setTableHeight] = useState('100vh');
  const [searchQuery, setSearchQuery] = useState('');

  const navigate = useNavigate();

  const { data: { projects: strategic_themes = [], loading } = {} } = useQuery(GET_PROJECTS);
  const { data: { initiatives = [] } = {} } = useQuery(GET_ALL_INITIATIVES);
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES);

  const { data: { users = [] } = {} } = useQuery(GET_USERS);

  const [updateSelectedProject] = useMutation(UPDATE_PROJECT);

  const scrollViewportRef = useRef(null);

  const selectedOwners = useReactiveVar(selectedOwnerVar);
  const selectedValueSteams = useReactiveVar(selectedValueStreamVar);

  useEffect(() => {
    const updateTableHeight = () => {
      const vh = window.innerHeight;
      setTableHeight(vh * 0.8);
    };

    updateTableHeight();
    window.addEventListener('resize', updateTableHeight);

    return () => {
      window.removeEventListener('resize', updateTableHeight);
    };
  }, []);

  useEffect(() => {
    if (strategic_themes.length) {
      const filteredProjects = strategic_themes.filter((project) => project.isActive === true);

      setProjects(filteredProjects.slice(0, PAGE_SIZE));
      setAllProjects(filteredProjects);
    }
  }, [strategic_themes]);

  const loadMoreData = () => {
    if (loading || !allProjects.length) return;

    const nextPage = page + 1;
    const newProjects = allProjects.slice(0, (nextPage + 1) * PAGE_SIZE);

    if (newProjects.length > projects.length) {
      setProjects(newProjects);
      setPage(nextPage);
    }
  };

  const toggleTempColumn = (accessor) => {
    setTempVisibleColumns((prev) => ({ ...prev, [accessor]: !prev[accessor] }));
  };

  const handleSort = (status) => {
    setSortStatus(status);
    const { columnAccessor, direction } = status;

    const sortedData = [...allProjects].sort((a, b) => {
      // Default sorting for other columns
      const aValue = a[columnAccessor] ?? '';
      const bValue = b[columnAccessor] ?? '';
      return direction === 'asc'
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    });

    setAllProjects(sortedData);
    setProjects(sortedData.slice(0, PAGE_SIZE));
  };

  const RagStatusCell = ({ record, updateProject, field, defaultValue = 'Green' }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [selectedRag, setSelectedRag] = useState(record[field] || defaultValue);
    const selectRef = useRef(null);

    useEffect(() => {
      if (isEditing && selectRef.current) {
        selectRef.current.focus();
      }
    }, [isEditing]);

    const handleRagChange = (value) => {
      setSelectedRag(value);
      setIsEditing(false);
      updateProject(record.id, field, value);
    };

    return isEditing ? (
      <Select
        ref={selectRef}
        data={['Red', 'Amber', 'Green']}
        value={selectedRag}
        onChange={handleRagChange}
        onBlur={() => setIsEditing(false)}
        autoFocus
        dropdownOpened
        styles={{ dropdown: { width: '100px' } }}
      />
    ) : (
      <Display2
        data-value={selectedRag}
        color={selectedRag === 'Red' ? color.red : selectedRag === 'Green' ? color.green : color.amber}
        bgColor={selectedRag === 'Red' ? color.red : selectedRag === 'Green' ? color.green : color.amber}
        variant="light"
        style={{ width: '65px', cursor: 'pointer' }}
        onClick={() => setIsEditing(true)}>
        {selectedRag?.toUpperCase()}
      </Display2>
    );
  };

  const EditableTextArea = ({ record, field, updateProject }) => {
    const [value, setValue] = useState(record[field] || '');
    const [isSaving, setIsSaving] = useState(false);

    const handleBlur = async () => {
      if (value !== record[field]) {
        setIsSaving(true);
        await updateProject(record.id, field, value);
        setIsSaving(false);
      }
    };

    return (
      <Textarea
        value={value}
        onChange={(event) => setValue(event.currentTarget.value)}
        onBlur={handleBlur}
        autosize
        minRows={4}
        disabled={isSaving}
      />
    );
  };

  const updateProject = async (id, field, value) => {
    await updateSelectedProject({
      variables: {
        themeId: id,
        theme: {
          [field]: value,
        },
      },
    });

    // Update only the modified project in `allProjects`
    setAllProjects((prevProjects) =>
      prevProjects.map((project) => (project.id === id ? { ...project, [field]: value } : project)),
    );

    // Update only the modified project in `projects` (current visible page)
    setProjects((prevProjects) =>
      prevProjects.map((project) => (project.id === id ? { ...project, [field]: value } : project)),
    );
  };

  const staticColumns = useMemo(
    () => [
      {
        accessor: 'name',
        title: 'Project Name',
        sortable: true,
        width: 300,
        render: (record) => {
          return (
            record?.name && (
              <Tooltip data-value={record?.name} transition="scale" label={record?.name} zIndex={1000}>
                <Badge
                  color={color.purple}
                  size="sm"
                  radius="4px"
                  onClick={() => navigate(`/organisation/projects/${record.id}`)}
                  styles={{
                    padding: '4px',
                    color: 'white',
                    root: {
                      cursor: 'pointer',
                    },
                  }}>
                  {record?.name}
                </Badge>
              </Tooltip>
            )
          );
        },
      },
      {
        accessor: 'owner',
        title: 'Owner',
        sortable: true,
        width: 150,
        render: (record) => {
          const user = users.find((user) => user.id === record.owner);
          return user ? user.firstName + ' ' + user.lastName : '';
        },
      },
      {
        accessor: 'previous_rag_status',
        title: 'Previous RAG',
        sortable: true,
        width: 130,
        render: (record) => (
          <RagStatusCell
            record={record}
            updateProject={updateProject}
            field="previous_rag_status"
            defaultValue="Green"
          />
        ),
      },
      {
        accessor: 'rag_status',
        title: 'Current RAG',
        sortable: true,
        width: 130,
        render: (record) => <RagStatusCell record={record} updateProject={updateProject} field="rag_status" />,
      },
      {
        accessor: 'updated_at',
        title: 'Last Update',
        sortable: true,
        width: 130,
        render: (record) => {
          if (record.updated_at) {
            const date = DateTime.fromISO(record.updated_at);
            return date.isValid && date.toFormat('dd/MM/yyyy');
          }
          return '';
        },
      },
      {
        accessor: 'rag_justification',
        title: 'RAG Justification',
        sortable: true,
        width: 300,
        render: (record) => (
          <EditableTextArea record={record} field="rag_justification" updateProject={updateProject} />
        ),
      },
      {
        accessor: 'path_to_green',
        title: 'Path to Green',
        sortable: true,
        width: 300,
        render: (record) => <EditableTextArea record={record} field="path_to_green" updateProject={updateProject} />,
      },
      {
        accessor: 'progress',
        title: 'Progress',
        sortable: true,
        width: 300,
        render: (record) => {
          const selectedItemsInitiatives = initiatives.filter((init) => init.strategic_theme === record.id);
          const selectedItemsFeatures = features.filter((feat) => feat.project === record.id);

          // Calculate the average percentage for initiatives
          const initiativeValues = selectedItemsInitiatives
            .map((init) => init?.features?.aggregate?.avg?.percentageDone)
            .filter((val) => typeof val === 'number');

          // Calculate the average percentage for features
          const featureValues = selectedItemsFeatures
            .map((feat) => feat?.percentageDone)
            .filter((val) => typeof val === 'number');

          const totalValues = [...initiativeValues, ...featureValues];
          const overallAverage =
            totalValues.length > 0 ? totalValues.reduce((sum, val) => sum + val, 0) / totalValues.length : 0;

          return <LinearWithValueLabel data-value={overallAverage} value={overallAverage} />;
        },
      },
    ],
    [features, initiatives, updateProject, users],
  );

  // Filter columns based on visibility
  const filteredColumns = staticColumns.filter((col) => visibleColumns[col.accessor]);

  useEffect(() => {
    if (Object.keys(visibleColumns).length === 0) {
      const initialVisibility = staticColumns.reduce((acc, col) => {
        acc[col.accessor] = true;
        return acc;
      }, {});
      setVisibleColumns(initialVisibility);
      setTempVisibleColumns(initialVisibility);
    }
  }, [staticColumns, visibleColumns]);

  const filteredProjects = useMemo(() => {
    return allProjects.filter(
      (project) =>
        (selectedOwners.length === 0 || selectedOwners.includes(project.owner)) &&
        (selectedValueSteams === null || (project.value_streams?.includes(selectedValueSteams) ?? false)) &&
        (searchQuery.trim() === '' ||
          Object.values(project).some((value) => value?.toString().toLowerCase().includes(searchQuery.toLowerCase()))),
    );
  }, [allProjects, selectedOwners, selectedValueSteams, searchQuery]);

  return (
    <Box sx={{ height: '100%', overflowY: 'auto' }}>
      <DataTableToolbar
        setSearchQuery={setSearchQuery}
        staticColumns={staticColumns}
        tempVisibleColumns={tempVisibleColumns}
        toggleTempColumn={toggleTempColumn}
        setVisibleColumns={setVisibleColumns}
      />

      <DataTable
        columns={filteredColumns}
        records={filteredProjects}
        sortStatus={sortStatus}
        onSortStatusChange={handleSort}
        highlightOnHover
        withColumnBorders
        fetching={loading}
        enableRowVirtualization
        onScrollToBottom={loadMoreData}
        height={tableHeight}
        emptyState={<div style={{ textAlign: 'center', padding: '20px' }}></div>}
        stickyHeader
        scrollViewportRef={scrollViewportRef}
        styles={{
          root: { backgroundColor: 'transparent' },
          table: { backgroundColor: 'transparent' },
          header: { backgroundColor: '#f7f7f8' },
          row: { backgroundColor: 'transparent' },
        }}
      />
      {loading && <Loader sx={{ margin: '10px auto', display: 'block' }} />}
    </Box>
  );
};
