export const OBJECTIVES = 'objectives';

export const headCells = [
  {
    id: 'id',
    field: 'id',
    label: 'ID',
    disablePadding: false,
    filtering: false,
    defaultSort: 'asc',
    numeric: false,
    align: 'left',
    width: '3%',
  },
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    disablePadding: false,
    filtering: false,
    numeric: false,
    align: 'left',
    width: '30%',
  },
  {
    id: 'team',
    field: 'team',
    label: 'Team',
    disablePadding: false,
    numeric: false,
    align: 'left',
  },
  {
    id: 'sprint',
    field: 'sprint',
    label: 'Sprint',
    disablePadding: false,
    numeric: true,
    align: 'left',
    width: '8%',
  },
  {
    id: 'status',
    field: 'status',
    label: 'Status',
    disablePadding: false,
    numeric: false,
    align: 'left',
  },
  {
    id: 'plannedValue',
    field: 'plannedValue',
    label: 'Planned Value',
    disablePadding: false,
    numeric: true,
    align: 'left',
  },
  {
    id: 'deliveredValue',
    field: 'deliveredValue',
    label: 'Delivered Value',
    disablePadding: false,
    numeric: true,
    align: 'left',
  },
  {
    id: 'ragStatus',
    field: 'ragStatus',
    label: 'RAG Status',
    disablePadding: false,
    numeric: false,
    align: 'left',
    width: '12%',
  },
  {
    id: 'uncommitted',
    field: 'uncommitted',
    label: 'Commitment',
    disablePadding: false,
    numeric: false,
    align: 'left',
    width: '9%',
  },
];
