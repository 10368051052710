import { gql } from '@apollo/client';

export const FEATURE_FIELDS = gql`
  fragment FeatureFields on features {
    acceptanceCriterias
    assignedTo
    backlogId
    businessOwner
    businessValue
    committedSprint
    completedIncrements
    confluenceLink
    costOfDelay
    description
    epicId
    errors
    estimatedSprint
    hasUnassignedStories
    enabler
    risk_flagged
    hlDependencies
    id
    isDescoped
    metrics
    name
    notes
    okrs
    originalState
    plannedReleaseDate
    plannedStartDate
    priority
    project
    programIncrement
    program_increment {
      name
      status
    }
    ragStatus
    releaseDate
    releaseNumber
    initiativeId
    riskReduction
    size
    stage
    startingSprint
    strategic_theme
    status
    storyPointsPerSprint
    teamId
    team {
      type
      boardName
      connectorId
      projectId
      name
    }
    timeCriticality
    wsjf
    missingFields
    milestones {
      id: milestoneId
    }
    dependencies: features_dependencies {
      dependencyId
    }
    type
    createdAt
    cycleTime
    programmeId
    programme {
      name
    }
    productThemes
    valueStreamStepId
    valueStreamSteps
    percentageDone
    storyPointCount
    statusChangelog
    subtasks
    risk_flagged
  }
`;

export const INSERT_FEATURE = gql`
  ${FEATURE_FIELDS}
  mutation InsertFeature($feature: [features_insert_input!] = {}) {
    feature: insert_features(objects: $feature) {
      returning {
        ...FeatureFields
      }
    }
  }
`;

export const UPDATE_FEATURE = gql`
  ${FEATURE_FIELDS}
  mutation UpdateFeature(
    $featureId: Int!
    $feature: features_set_input = {}
    $milestones: [features_milestones_insert_input!] = []
  ) {
    delete_features_milestones(where: { featureId: { _eq: $featureId } }) {
      returning {
        milestoneId
      }
    }
    insert_features_milestones(objects: $milestones) {
      returning {
        milestoneId
      }
    }
    update_features_by_pk(pk_columns: { id: $featureId }, _set: $feature) {
      ...FeatureFields
    }
  }
`;

export const UPDATE_FEATURE_WITHOUT_MILESTONES = gql`
  ${FEATURE_FIELDS}
  mutation UpdateFeature($featureId: Int!, $feature: features_set_input = {}) {
    update_features_by_pk(pk_columns: { id: $featureId }, _set: $feature) {
      ...FeatureFields
    }
  }
`;

export const UPDATE_FEATURE_STAGE = gql`
  ${FEATURE_FIELDS}
  mutation UpdateFeature($featureId: Int!, $feature: features_set_input = {}) {
    feature: update_features_by_pk(pk_columns: { id: $featureId }, _set: $feature) {
      ...FeatureFields
    }
  }
`;

export const DELETE_FEATURE = gql`
  mutation DeleteFeature($id: Int!) {
    feature: delete_features_by_pk(id: $id) {
      id
    }
  }
`;

export const GET_FEATURES = gql`
  ${FEATURE_FIELDS}
  query GetFeatures {
    features: features(order_by: { id: asc }) {
      ...FeatureFields
    }
  }
`;

export const GET_FEATURES_FOR_PI = gql`
  ${FEATURE_FIELDS}
  query GetFeaturesForPI($pi: Int) {
    selectedPI @client @export(as: "pi")
    features: features(where: { programIncrement: { _eq: $pi } }) {
      ...FeatureFields
    }
  }
`;

export const GET_FEATURES_FOR_PROGRAMME = gql`
  ${FEATURE_FIELDS}
  query GetFeaturesForProgramme($programme: Int) {
    selectedProgramme @client @export(as: "programme")
    features: features(where: { programmeId: { _eq: $programme } }) {
      ...FeatureFields
    }
  }
`;

export const GET_FEATURES_FOR_VS = gql`
  ${FEATURE_FIELDS}
  query GetFeatureForVSs($valueStream: Int) {
    selectedValueStream @client @export(as: "valueStream")
    features: features(where: { programme: { valueStream: { _eq: $valueStream } } }) {
      ...FeatureFields
    }
  }
`;

export const SUBSCRIBE_FEATURES = gql`
  subscription GetFeatures {
    features(limit: 1, order_by: { updatedAt: desc }) {
      updatedAt
      id
    }
  }
`;

export const GET_STORIES_FOR_FEATURE = gql`
  query GetStories($connectorId: String!, $epicId: String!, $project: String) {
    stories(connectorId: $connectorId, epicId: $epicId, project: $project)
      @rest(type: "Story", path: "/api/jira/{args.connectorId}/{args.epicId}/issues?project={args.project}") {
      id
      key
      fields
      sprint
      status
      statusCategory
      name
      storyPoints
    }
  }
`;

export const GET_IN_PROGRESS_STORIES_FOR_FEATURE = gql`
  query GetStories($connectorId: String!, $epicId: String!, $project: String) {
    stories(connectorId: $connectorId, epicId: $epicId, project: $project)
      @rest(
        type: "Story"
        path: "/api/jira/{args.connectorId}/{args.epicId}/issues?project={args.project}&jql=statusCategory=In%20Progress"
      ) {
      id
      key
      fields
      status
      statusCategory
      name
      storyPoints
    }
  }
`;

export const GET_EPICS = gql`
  query GetEpics($connectorId: String!, $query: String!) {
    epics(connectorId: $connectorId, query: $query)
      @rest(type: "Epic", path: "/api/jira/{args.connectorId}/epic/search/{args.query}") {
      id
      key
      summary
      summaryText
    }
  }
`;

export const GET_TAGS = gql`
  query GetTags($connectorId: String!, $projectId: String) {
    tags(connectorId: $connectorId, projectId: $projectId)
      @rest(type: "Tag", path: "/api/features/{args.connectorId}/tags?project={args.projectId}") {
      tag
    }
  }
`;

export const GET_FEATURES_FOR_TAGS = gql`
  query GetFeaturesForTags($connectorId: String!, $projectId: String, $tags: String!) {
    toolingFeatures(connectorId: $connectorId, projectId: $projectId, tags: $tags)
      @rest(
        type: "ToolingFeatures"
        path: "/api/features/{args.connectorId}/features/tags?tags={args.tags}&project={args.projectId}"
      ) {
      id
      name
      description
    }
  }
`;

export const GET_EPIC = gql`
  query GetEpic($connectorId: String!, $epicId: String!) {
    epic(connectorId: $connectorId, epicId: $epicId)
      @rest(type: "Epic", path: "/api/jira/{args.connectorId}/epic/{args.epicId}") {
      id
      key
      fields
    }
  }
`;

export const CREATE_TOOLING_FEATURE = gql`
  fragment FeatureInput on REST {
    name: String
    description: String
    areaPath: String
    id: String
  }

  mutation CreateToolingFeature($connectorId: String!, $projectId: String, $input: FeatureInput!) {
    toolingFeature(connectorId: $connectorId, projectId: $projectId, input: $input)
      @rest(method: "POST", type: "ToolingFeatures", path: "/api/features/{args.connectorId}/{args.projectId}/epic") {
      epicId
    }
  }
`;

export const GET_FEATURE_BY_ID = gql`
  ${FEATURE_FIELDS}
  query GetFeatureById($id: Int!) {
    features_by_pk(id: $id) {
      ...FeatureFields
    }
  }
`;

export const GET_FEATURES_WITHOUT_INITIATIVE = gql`
  ${FEATURE_FIELDS}
  query GetFeaturesWithoutInitiative {
    features(where: { initiativeId: { _is_null: true } }, order_by: { id: asc }) {
      ...FeatureFields
    }
  }
`;

export const UPDATE_FEATURE_MANY = gql`
  mutation MyMutation($updates: [features_updates!] = { where: {} }) {
    update_features_many(updates: $updates) {
      affected_rows
    }
  }
`;
