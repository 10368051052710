export const headCells = [
  {
    id: 'id',
    label: 'ID',
    width: 1,
  },
  {
    id: 'name',
    label: 'Name',
    width: 5,
  },
  {
    id: 'owner',
    label: 'Owner',
    width: 3,
  },
  {
    id: 'value_streams',
    label: 'Value Streams',
    width: 3,
  },
  {
    id: 'planned_start_date',
    label: 'Planned Start Date',
    width: 3.5,
  },
  {
    id: 'planned_end_date',
    label: 'Planned End Date',
    width: 3,
  },
];
