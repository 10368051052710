import React, { useMemo, useState } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import { Text } from '@mantine/core';
import { SegmentedControl, Group } from '@mantine/core';
import { sortBy } from 'lodash';

import TimeLineDashboard from './TimeLineDashboard';
import { getColumnDates, getPredictabilityForPeriod, getPreviousPeriod } from './utils';
import ValueStreamMetrics from './ValueStreamMetrics';
import Switch from '../../../components/Common/Switch';
import Select from '../../../components/Common/Select';
import {
  selectedProductThemeVar,
  selectedTeamVar,
  selectedOwnerVar,
  selectedValueStreamVar,
  selectedProjectVar,
} from '../../../reactiveVariables';
import { filterProductTheme } from '../../../utils/helpers';
import { useProjectedFeatures } from '../../../utils/hooks';
import { GET_FEATURES_FOR_VS } from '../../Product/Features/graphql';
import { GET_PROGRAM_INCREMENTS, GET_SPRINTS } from '../../Settings/ProgramIncrement/graphql';
import { GET_INITIATIVES } from '../Initiatives/graphql';
import { GET_MILESTONES_WITH_FEATURES } from '../Milestones/graphql';
import { initiativeWithDates } from '../utils';
import { GET_INITIATIVE_STATE_CONFIG } from '../../Settings/GraphQL/configuration';
import { FormControlLabel, Checkbox } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const InitiativesDashboard = () => {
  const { data: { initiatives = [] } = {} } = useQuery(GET_INITIATIVES);
  const { data: { milestones = [] } = {} } = useQuery(GET_MILESTONES_WITH_FEATURES);
  const { data: { sprints = [] } = {}, loading } = useQuery(GET_SPRINTS);
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES_FOR_VS);
  const { data: { programIncrements = [] } = {} } = useQuery(GET_PROGRAM_INCREMENTS);
  const { data: { initiativeStates = [] } = {} } = useQuery(GET_INITIATIVE_STATE_CONFIG);

  const [dateType, setDateType] = useState('quarter');
  const [columDates, setColumnDates] = useState(getColumnDates(dateType));
  const [stages, setStages] = useState([]);
  const [excludeDoneFeatures, setExcludeDoneFeatures] = useState(false);
  const projectedFeatures = useProjectedFeatures();

  const selectedProductTheme = useReactiveVar(selectedProductThemeVar);
  const selectedTeams = useReactiveVar(selectedTeamVar);
  const selectedOwners = useReactiveVar(selectedOwnerVar);
  const selectedValueStream = useReactiveVar(selectedValueStreamVar);
  const selectedProject = useReactiveVar(selectedProjectVar);

  // Get active initiative states for the current value stream
  const activeInitiativeStates = useMemo(
    () =>
      initiativeStates
        .filter((state) => state.value_stream === selectedValueStream && state.inUse === true)
        .map((state) => ({
          value: state.name,
          label: state.name,
        })),
    [initiativeStates, selectedValueStream],
  );

  const predictability = useMemo(
    () => getPredictabilityForPeriod(features, columDates, programIncrements),
    [features, columDates, programIncrements],
  );
  const previousPredictability = useMemo(
    () => getPredictabilityForPeriod(features, getPreviousPeriod(dateType, columDates), programIncrements),
    [features, columDates, programIncrements],
  );

  const onDateTypeChange = (value) => {
    setColumnDates(getColumnDates(value));
    setDateType(value);
  };

  // First filter by product theme and add dates
  const initiativesWithDates = useMemo(
    () =>
      sortBy(
        initiatives
          .filter((initiative) => filterProductTheme(selectedProductTheme, initiative))
          .map((initiative) => initiativeWithDates(initiative, sprints, projectedFeatures)),
        'rank',
      ),
    [selectedProductTheme, initiatives, sprints, projectedFeatures],
  );

  const filterInitiativesByProject = (initiatives, selectedProject) => {
    if (!selectedProject) {
      return initiatives;
    }

    return initiatives.filter((initiative) => {
      return initiative.strategic_theme === parseInt(selectedProject);
    });
  };

  const filterInitiativesByTeams = (initiatives, selectedTeams) => {
    if (selectedTeams.length === 0) {
      return initiatives;
    }

    return initiatives
      .map((initiative) => {
        const hasMatchingTeam =
          Array.isArray(initiative.teams) &&
          initiative.teams.some((team) => {
            return selectedTeams.includes(team.id);
          });

        if (!hasMatchingTeam) {
          return null;
        }

        const filteredFeatures = initiative.features.nodes.filter((feature) => selectedTeams.includes(feature.teamId));

        return {
          ...initiative,
          features: {
            ...initiative.features,
            nodes: filteredFeatures,
          },
        };
      })
      .filter((initiative) => initiative !== null);
  };

  const filterInitiativesByOwners = (initiatives, selectedOwners) => {
    if (selectedOwners.length === 0) {
      return initiatives;
    }

    return initiatives
      .map((initiative) => {
        const hasMatchingOwner = selectedOwners.includes(initiative.owner);

        const filteredFeatures = initiative.features.nodes.filter((feature) => {
          return selectedOwners.includes(feature.assignedTo);
        });

        if (hasMatchingOwner || filteredFeatures.length > 0) {
          return {
            ...initiative,
            features: {
              ...initiative.features,
              nodes: filteredFeatures,
            },
          };
        }

        return null;
      })
      .filter((initiative) => initiative !== null);
  };

  const filterInitiativesByStages = (initiatives, stages) => {
    if (stages.length === 0) {
      return initiatives;
    }

    // First check if all states are selected
    const allStates = activeInitiativeStates.map((state) => state.value);
    const areAllStatesSelected = allStates.every((state) => stages.includes(state));

    // If all states are selected, return all initiatives
    if (areAllStatesSelected) {
      return initiatives;
    }

    return initiatives
      .map((initiative) => {
        // Check if any features match the selected stages
        const hasMatchingFeatures = initiative.features.nodes.some((feature) => stages.includes(feature.stage));

        // Check completion status
        const progressDone = initiative.features?.aggregate?.avg?.percentageDone === 100;

        // Determine if the initiative should be shown based on its states and the selected stages
        const hasMatchingState =
          stages.includes(initiative.initiative_state) ||
          // Show in 'Done' if:
          // 1. It has initiative_state of 'Done', OR
          // 2. It's 100% complete AND has portfolio_state of 'Done'
          (stages.includes('Done') &&
            (initiative.initiative_state === 'Done' || (progressDone && initiative.portfolio_state === 'Done')));

        // Return initiative if it has matching features or matching state
        if (hasMatchingFeatures || hasMatchingState) {
          return initiative;
        }

        return null;
      })
      .filter((initiative) => initiative !== null);
  };

  // Apply filters in sequence
  const filteredByProject = filterInitiativesByProject(initiativesWithDates, selectedProject);
  const filteredTeamInitiatives = filterInitiativesByTeams(filteredByProject, selectedTeams);
  const filteredOwnerInitiatives = filterInitiativesByOwners(filteredTeamInitiatives, selectedOwners);
  const filteredStageInitiatives = filterInitiativesByStages(filteredOwnerInitiatives, stages);

  // Filter out done features if excludeDoneFeatures is true
  const initiativesWithFilteredFeatures = useMemo(() => {
    if (!excludeDoneFeatures) {
      return filteredStageInitiatives;
    }

    return filteredStageInitiatives.map((initiative) => ({
      ...initiative,
      features: {
        ...initiative.features,
        nodes: initiative.features.nodes.filter((feature) => feature.stage !== 'Done' && feature.status !== 'Done'),
      },
    }));
  }, [filteredStageInitiatives, excludeDoneFeatures]);

  const theme = useTheme();

  return (
    <div>
      <ValueStreamMetrics
        previousPredictability={previousPredictability}
        predictability={predictability}
        milestones={milestones}
        initiatives={initiativesWithDates}
        dateType={dateType}
        setDateType={onDateTypeChange}
      />
      <Group position="apart" align="center" mb={16}>
        <Text size="lg" fw={700}>
          Initiatives Roadmap
        </Text>
        <Group>
          <Select
            title="Value Stream States"
            onChange={(event, values) => setStages(values)}
            placeholder="Select..."
            defaultValue={activeInitiativeStates.map((state) => state.value)}
            multiple={true}
            options={activeInitiativeStates}
            border={false}
          />
          <div style={{ marginLeft: '12px' }}>
            <FormControlLabel
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: '12px',
                  color: theme.palette.text.primary,
                },
              }}
              control={
                <Checkbox
                  checked={excludeDoneFeatures}
                  onChange={(e) => setExcludeDoneFeatures(e.target.checked)}
                  name="checkbox-exclude-done"
                />
              }
              label="Exclude Done Features"
            />
          </div>
        </Group>
        <Switch
          value={dateType}
          onChange={onDateTypeChange}
          data={[
            { label: 'Month', value: 'month' },
            { label: 'Quarter', value: 'quarter' },
            { label: 'Year', value: 'year' },
          ]}
          sx={{ marginBottom: 0, marginLeft: 'auto' }}
        />
      </Group>
      <TimeLineDashboard
        initiatives={initiativesWithFilteredFeatures}
        projectedFeatures={projectedFeatures}
        columDates={columDates}
        loading={loading}
        setColumnDates={setColumnDates}
        dateType={dateType}
        selectedValueStream={selectedValueStream}
      />
    </div>
  );
};

export default InitiativesDashboard;
