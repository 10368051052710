import React, { memo, useContext, useEffect, useState } from 'react';

import { NumberInput, Select, Text, TextInput, useMantineTheme } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/lab';
import LuxonUtils from '@mui/lab/AdapterLuxon';
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { isEmpty, isEqual } from 'lodash';
import { Controller } from 'react-hook-form';
import { DATE_FORMAT } from 'utils/timeUtils';

import RTE from '../RichTextEditor';

export function Form({ methods, children, onSubmit }) {
  const { handleSubmit } = methods;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {React.Children.map(children, (child) => {
        return child.props.name
          ? React.createElement(child.type, {
              ...{
                ...child.props,
                register: methods.register,
                key: child.props.name,
              },
            })
          : child;
      })}
    </form>
  );
}

export const FormContext = React.createContext({});

// eslint-disable-next-line react/display-name
export const Field = memo(
  ({ name, size, defaultValue = '', renderField, sx, noSize, title, required = false, dataTestId = '', ...props }) => {
    const { control } = useContext(FormContext);
    const rules = required ? { required: `${props.label || title} is required`, ...props.rules } : props.rules;

    return (
      <Grid style={{ marginTop: 0, ...sx }} key={name} xs {...{ xs: noSize ? undefined : size || 6 }} item>
        <Controller
          {...props}
          render={(renderProps) =>
            renderField({
              error: !!renderProps.fieldState.error,
              helperText: renderProps.fieldState.error?.message,
              dataTestId: dataTestId,
              ...renderProps.field,
              ...props,
            })
          }
          name={name}
          defaultValue={defaultValue}
          control={control}
          rules={rules}
          required={required}
        />
      </Grid>
    );
  },
);

export const renderDatePicker = ({ helperText, error, onBlur, onChange, margin = 'dense', ...other }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [open, setOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={LuxonUtils}>
      <DatePicker
        {...other}
        clearable
        open={open}
        onChange={(value) => onChange(value && value.toISODate())}
        onClose={() => setOpen(false)}
        renderInput={(props) => (
          <TextField
            {...props}
            onClick={() => setOpen(true)}
            onBlur={onBlur}
            fullWidth
            error={error}
            margin={margin}
            variant="outlined"
            helperText={helperText}
            readOnly
          />
        )}
        inputFormat={DATE_FORMAT.date}
        ampm={false}
      />
    </LocalizationProvider>
  );
};

export const renderTextField = ({
  label,
  fullWidth,
  handleChange,
  onChange,
  focused,
  margin = 'dense',
  placeHolder,
  dataTestId = '',
  ...custom
}) => (
  <TextField
    label={label}
    placeholder={placeHolder || label}
    margin={margin}
    fullWidth={typeof fullWidth == 'undefined' ? true : fullWidth}
    variant="outlined"
    autoComplete="off"
    onChange={handleChange || onChange}
    error={custom.error}
    focused={focused}
    inputProps={{
      'data-testid': dataTestId,
    }}
    {...custom}
  />
);

export const renderRTE = ({
  label,
  fullWidth,
  handleChange,
  onChange,
  focused,
  margin = 'dense',
  placeHolder,
  ...custom
}) => <RTE onChange={handleChange || onChange} id={label} {...custom} />;

export const renderCheckbox = ({ label, onChange, value, ...custom }) => (
  <div>
    <FormControlLabel
      control={<Checkbox onChange={(e) => onChange(e.target.checked)} checked={!!value} {...custom} />}
      label={label}
    />
  </div>
);

export const renderSelectField = ({
  input,
  label,
  children,
  renderValue,
  defaultValue,
  handleChange,
  onChange,
  margin = 'dense',
  ...custom
}) => (
  <TextField
    select
    label={label}
    placeholder={label}
    margin={margin}
    variant="outlined"
    onChange={handleChange || onChange}
    fullWidth
    {...input}
    {...custom}
    SelectProps={{
      renderValue: renderValue,
      defaultValue: defaultValue,
    }}>
    {children}
  </TextField>
);

const InputText = ({ label, placeholder, ...other }) => {
  const theme = useMantineTheme();

  return (
    <TextInput
      {...other}
      styles={{
        wrapper: { boxShadow: '0px 2px 2px 0px #0000000D' },
        label: { color: theme.other.colours.secondary },
      }}
      label={label}
      placeholder={placeholder}
    />
  );
};

const InputNumber = ({ label, placeholder, ...other }) => {
  const theme = useMantineTheme();

  return (
    <NumberInput
      {...other}
      styles={{
        wrapper: { boxShadow: '0px 2px 2px 0px #0000000D' },
        label: { color: theme.other.colours.secondary },
      }}
      label={label}
      placeholder={placeholder}
    />
  );
};

const Combobox = ({ width, maxWidth, ...props }) => {
  const theme = useMantineTheme();

  return (
    <Select
      {...props}
      styles={{
        wrapper: { boxShadow: '0px 2px 2px 0px #0000000D', width, maxWidth },
        label: { color: theme.other.colours.secondary },
      }}
    />
  );
};

const DateField = ({ width, maxWidth, ...props }) => {
  const theme = useMantineTheme();

  return (
    <DateInput
      {...props}
      styles={{
        wrapper: { boxShadow: '0px 2px 2px 0px #0000000D', width, maxWidth },
        label: { color: theme.other.colours.secondary },
      }}
    />
  );
};

export const renderInput = ({
  input,
  label,
  children,
  renderValue,
  defaultValue,
  handleChange,
  onChange,
  margin = 'dense',
  ...custom
}) => (
  <InputText
    label={label}
    placeholder={label}
    margin={margin}
    onChange={handleChange || onChange}
    fullWidth
    {...input}
    {...custom}
    SelectProps={{
      renderValue: renderValue,
      defaultValue: defaultValue,
    }}>
    {children}
  </InputText>
);

export const renderCombobox = ({
  input,
  label,
  children,
  renderValue,
  defaultValue,
  handleChange,
  onChange,
  ...custom
}) => (
  <Combobox
    label={label}
    placeholder={label}
    comboboxProps={{ zIndex: 5000 }}
    onOptionSubmit={handleChange || onChange}
    {...custom}
  />
);

export const renderDate = ({ input, label, renderValue, defaultValue, handleChange, onChange, value, ...custom }) => (
  <DateField
    label={label}
    placeholder={label}
    value={value && new Date(value)}
    onChange={handleChange || onChange}
    popoverProps={{ zIndex: 5000 }}
    {...custom}
  />
);

const defaultChange = (data) => data?.map((option) => (option.name ? option.id : option));

export function AutoCompleteWrapper({
  control,
  options,
  multiple,
  label,
  idField,
  disabled,
  name,
  size,
  freeSolo = false,
  changeFunc = defaultChange,
  changeSingle,
  margin = 'dense',
  getOptionLabel,
  nameField = 'name',
  displayIcon = false,
  required = false,
}) {
  const defaultGetOptionLabel = (option) => {
    return (
      option?.[nameField] ||
      (options.find((opt) => opt?.id === option || opt?.id === option?.id) || {})[nameField] ||
      option?.id ||
      option
    );
  };

  return (
    <Grid key={name} xs={size || 6} item>
      <Controller
        name={name}
        control={control}
        rules={{
          required: required ? `${label} is required` : false,
        }}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            value={multiple ? field.value || [] : field.value}
            multiple={multiple}
            id={name}
            autoComplete={!multiple}
            disableCloseOnSelect={multiple}
            options={options}
            freeSolo={freeSolo}
            selectOnFocus
            limitTags={disabled ? 100 : 5}
            disabled={disabled}
            isOptionEqualToValue={(option, value) => {
              return value?.[idField] ? option[idField] === value[idField] : option[idField] === value;
            }}
            onChange={
              multiple
                ? (_e, data) => field.onChange(changeFunc(data))
                : (_e, option) => field.onChange(changeSingle ? changeSingle(option) : option || null)
            }
            getOptionLabel={getOptionLabel || defaultGetOptionLabel}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                required={required}
                margin={margin}
                variant="outlined"
                placeholder={label}
                error={!!error}
                helperText={error ? error.message : null}
                fullWidth
              />
            )}
            renderOption={
              displayIcon
                ? (props, option) => (
                    <ListItem {...props}>
                      {option.icon && <ListItemIcon style={{ minWidth: '30px' }}>{option.icon}</ListItemIcon>}
                      <ListItemText primary={option.name} marginleft={0} />
                    </ListItem>
                  )
                : undefined
            }
          />
        )}
      />
    </Grid>
  );
}

export const renderSearchSelectField = ({ handleChange, label, options, placeHolder, onChange, title, ...custom }) => (
  <Select
    data={options}
    iconWidth={70}
    searchable
    clearable
    comboboxProps={{ zIndex: 5000 }}
    placeholder={placeHolder || label}
    styles={{ wrapper: { height: 45.5, margin: '8px 0 8px 0' }, input: { height: 45.5 } }}
    icon={<Text style={{ paddingLeft: '12px', marginRight: '8px' }}>{label}: </Text>}
    onChange={handleChange || onChange}
    {...custom}
  />
);

export function AsyncAutoCompleteWrapper({
  control,
  multiple,
  label,
  errors,
  idField,
  disabled,
  name,
  size,
  freeSolo = false,
  changeFunc = defaultChange,
  fetchDataFunction,
}) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading || !isEmpty(options)) {
      return undefined;
    }

    (async () => {
      const newOptions = await fetchDataFunction(); // For demo purposes.

      if (active) {
        setOptions([...newOptions]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  return (
    <Grid key={name} xs={size || 6} item>
      <Controller
        name={name}
        render={(props) => (
          <Autocomplete
            {...props}
            multiple={multiple}
            id={name}
            autoComplete={!multiple}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            disableCloseOnSelect={multiple}
            value={props.field.value}
            options={options}
            freeSolo={freeSolo}
            selectOnFocus
            limitTags={disabled ? 100 : 5}
            disabled={disabled}
            isOptionEqualToValue={(option, value) => {
              return value?.name ? option[idField] === value[idField] : isEqual(option[idField], value);
            }}
            onChange={
              multiple
                ? (_e, data) => props.field.onChange(changeFunc(data))
                : (_e, option) => props.field.onChange(option?.name || option?.[idField] || option)
            }
            getOptionLabel={(option) => {
              return option?.name || option;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                margin="normal"
                variant="outlined"
                placeholder={label}
                error={!!errors[name]}
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )}
        control={control}
      />
    </Grid>
  );
}
