import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';

import { useQuery, useMutation, useReactiveVar } from '@apollo/client';
import { Flex } from '@mantine/core';
import Download from '@mui/icons-material/GetApp';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import TableSortLabel from '@mui/material/TableSortLabel';
import { useTheme } from '@mui/styles';
import { uniq } from 'lodash';
import { Waypoint } from 'react-waypoint';

import { csvColumns } from './constants';
import Feature from './feature';
import Search from '../../../components/Common/BigAgileTable/TableToolBar/Search';
import ButtonIcon from '../../../components/Common/Button/ButtonIcon';
import Select from '../../../components/Common/Select';
import { selectedProductThemeVar, selectedTeamVar, selectedEnablerVar } from '../../../reactiveVariables';
import { exportCSVData, getFieldValue } from '../../../utils/export';
import { STAGE_SELECTS, STAGE_VALUE } from '../../../utils/formConstants';
import { filterProductTheme } from '../../../utils/helpers';
import { UPDATE_FEATURE_WITHOUT_MILESTONES } from '../../Product/Features/graphql';
import { GET_BACKLOGS } from '../../Settings/GraphQL/backlogs';
import { GET_FEATURES_FOR_PROGRAMME } from '../Features/graphql';

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const Prioritisation = ({ user }) => {
  const { data: { backlogs = [] } = {} } = useQuery(GET_BACKLOGS);
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES_FOR_PROGRAMME);

  const [updateFeature] = useMutation(UPDATE_FEATURE_WITHOUT_MILESTONES);

  const [backlog, setBacklog] = useState(0);
  const [stages, setStages] = useState([STAGE_VALUE.BACKLOG, STAGE_VALUE.ANALYSIS, STAGE_VALUE.CANDIDATE]);
  const [assignedTo, setAssignedTo] = useState([]);
  const [lockedFeatureId, setLockedFeatureId] = useState(null);
  const [isSticky, setIsSticky] = useState(false);
  const lockedRowRef = useRef(null);

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('wsjf');
  const [searchQuery, setSearchQuery] = useState('');
  const [noteToBeAdded, setNoteToBeAdded] = useState('');
  const [openNotesId, setOpenNotesId] = useState('');

  const handleSearch = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  const handleLockFeature = (featureId) => {
    setLockedFeatureId((prevLockedId) => (prevLockedId === featureId ? null : featureId));
    setIsSticky(false);
  };

  const ownedByList = useMemo(
    () =>
      uniq(features.filter((feature) => !!feature.assignedTo).map((feature) => feature.assignedTo)).map((owner) => ({
        label: owner,
        value: owner,
      })),
    [features],
  );

  useEffect(() => {
    if (lockedFeatureId && lockedRowRef.current) {
      const rect = lockedRowRef.current.getBoundingClientRect();
      const isAboveViewport = rect.top < 0;
      const isInViewport = rect.top >= 0 && rect.bottom <= window.innerHeight;

      if (!isInViewport && isAboveViewport) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }
  }, [lockedFeatureId]);

  const handleWaypointEnter = () => setIsSticky(false);
  const handleWaypointLeave = () => setIsSticky(true);

  const theme = useTheme();
  const selectedTeams = useReactiveVar(selectedTeamVar);
  const selectedProductTheme = useReactiveVar(selectedProductThemeVar);
  const selectedEnabler = useReactiveVar(selectedEnablerVar);

  const filteredFeatures = useMemo(
    () =>
      backlog ||
      stages.length ||
      selectedTeams ||
      assignedTo?.length ||
      searchQuery ||
      selectedProductTheme ||
      selectedEnabler
        ? features.filter(
            (feature) =>
              filterProductTheme(selectedProductTheme, feature) &&
              (!backlog || feature.backlogId === backlog) &&
              (!stages.length || stages.includes(feature.stage)) &&
              (!selectedTeams.length || selectedTeams.includes(feature.teamId)) &&
              (!assignedTo?.length || assignedTo.includes(feature.assignedTo)) &&
              (!searchQuery ||
                Object.values(feature).some((value) => value?.toString().toLowerCase().includes(searchQuery))) &&
              (selectedEnabler === 'enablers'
                ? feature.enabler === true
                : selectedEnabler === 'businessFeatures'
                ? feature.enabler === false
                : true),
          )
        : features,
    [backlog, stages, selectedTeams, assignedTo, searchQuery, features, selectedProductTheme, selectedEnabler],
  );

  const sortedFeatures = useMemo(() => {
    // Move the locked feature to the top if it exists
    const lockedFeature = filteredFeatures.find((feature) => feature.id === lockedFeatureId);
    const otherFeatures = filteredFeatures.filter((feature) => feature.id !== lockedFeatureId);

    return lockedFeature
      ? [lockedFeature, ...otherFeatures.sort(getComparator(order, orderBy))]
      : otherFeatures.sort(getComparator(order, orderBy));
  }, [lockedFeatureId, filteredFeatures, order, orderBy]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const getTableCell = (name, title, size, sx) => {
    return (
      <TableCell sx={sx} key={name} sortDirection={orderBy === name ? order : false} width={size}>
        <TableSortLabel
          active={orderBy === name}
          direction={orderBy === name ? order : 'asc'}
          onClick={createSortHandler(name)}>
          {title}
        </TableSortLabel>
      </TableCell>
    );
  };

  const exportFeatures = useCallback(() => {
    const data = [...filteredFeatures]
      .sort(getComparator(order, orderBy))
      .map((feature) => {
        return {
          id: feature.id,
          name: feature.name,
          assignedTo: feature.assignedTo,
          businessValue: feature.businessValue,
          riskReduction: feature.riskReduction,
          timeCriticality: feature.timeCriticality,
          costOfDelay: feature.costOfDelay,
          size: feature.size,
          wsjf: feature.wsjf,
        };
      })
      .map((rowData) => csvColumns.map((columnDef) => getFieldValue(rowData, columnDef)));

    exportCSVData(csvColumns, data, 'Features');
  }, [features]);

  const onNotesCancelClick = () => {
    setOpenNotesId('');
    setNoteToBeAdded('');
  };

  const onNotesTextChange = (e) => {
    setNoteToBeAdded(e.target.value);
  };

  const onNotesAddClick = () => {
    const feature = features.find((feature) => feature.id === openNotesId);

    const notesData = {
      note: noteToBeAdded,
      created_at: Date.now(),
      user: `${user.firstName} ${user.lastName}`,
    };

    if (openNotesId) {
      updateFeature({
        variables: {
          featureId: openNotesId,
          feature: {
            notes: feature.notes?.length ? [...feature.notes, notesData] : [notesData],
          },
        },
      });
    }

    setOpenNotesId('');
    setNoteToBeAdded('');
  };

  return (
    <div
      style={{
        height: '100%',
        paddingBottom: '20px',
        backgroundColor: theme.palette.color.background,
      }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <Select
            title="Stage"
            placeholder="Select a stage..."
            onChange={(event, values) => {
              setLockedFeatureId(null);
              setStages(values);
            }}
            defaultValue={[STAGE_VALUE.BACKLOG, STAGE_VALUE.ANALYSIS, STAGE_VALUE.CANDIDATE]}
            multiple={true}
            options={STAGE_SELECTS}
          />
          <Select
            title="Product"
            placeholder="All"
            onChange={(event, value) => {
              setLockedFeatureId(null);
              setBacklog(value);
            }}
            options={[
              { label: 'All', value: 0 },
              ...backlogs.map((backlog) => ({ label: backlog.name, value: backlog.id })),
            ]}
          />
          <Select
            title="Owner"
            placeholder="All"
            onChange={(event, value) => {
              setLockedFeatureId(null);
              setAssignedTo(value);
            }}
            multiple={true}
            options={ownedByList}
          />
        </div>
        <Flex align={'center'} gap={35}>
          <Search handleChange={handleSearch} />
          <ButtonIcon icon={Download} handleClick={exportFeatures} />
        </Flex>
      </div>
      <Table sx={{ borderTopRightRadius: 10, borderTopLeftRadius: 10 }} style={{ tableLayout: 'fixed', width: '100%' }}>
        <TableHead>
          <TableRow sx={{ backgroundColor: 'color.background', borderBottom: '2px solid color.darkPaper' }}>
            {getTableCell('id', 'ID', '8%', { borderTopLeftRadius: 10 })}
            {getTableCell('name', 'Feature', '15%')}
            {getTableCell('assignedTo', 'Owner', '8%')}
            {getTableCell('businessValue', 'Business Value', '8%')}
            <TableCell align={'center'} padding={'none'} width="5px" />
            {getTableCell('riskReduction', 'Risk Reduction', '8%')}
            <TableCell align={'center'} padding={'none'} width="5px" />
            {getTableCell('timeCriticality', 'Time Criticality', '8%')}
            <TableCell align={'center'} padding={'none'} width="5px" />
            {getTableCell('costOfDelay', 'COD', '8%')}
            <TableCell align={'center'} padding={'none'} width="5px" />
            {getTableCell('size', 'Job Size', '8%')}
            <TableCell align={'center'} padding={'none'} width="5px" />
            {getTableCell('wsjf', 'WSJF', '10%', { borderTopRightRadius: 10 })}
          </TableRow>
        </TableHead>
        <TableBody>
          {lockedFeatureId && sortedFeatures.some((feature) => feature.id === lockedFeatureId) && (
            <>
              <Waypoint onEnter={handleWaypointEnter} onLeave={handleWaypointLeave} />
              <Feature
                key={lockedFeatureId}
                feature={sortedFeatures.find((feature) => feature.id === lockedFeatureId)}
                isSticky={isSticky}
                onLockFeature={handleLockFeature}
                lockedFeatureId={lockedFeatureId}
                ref={lockedRowRef}
                onNotesCancelClick={onNotesCancelClick}
                onNotesTextChange={onNotesTextChange}
                onNotesAddClick={onNotesAddClick}
                openNotesId={openNotesId}
                setOpenNotesId={setOpenNotesId}
                disabledNotesSubmit={noteToBeAdded.length === 0}
              />
            </>
          )}
          {sortedFeatures
            .filter((feature) => feature.id !== lockedFeatureId)
            .map((feature) => (
              <Feature
                key={feature.id}
                feature={feature}
                onLockFeature={handleLockFeature}
                onNotesCancelClick={onNotesCancelClick}
                onNotesTextChange={onNotesTextChange}
                onNotesAddClick={onNotesAddClick}
                openNotesId={openNotesId}
                setOpenNotesId={setOpenNotesId}
                disabledNotesSubmit={noteToBeAdded.length === 0}
              />
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default Prioritisation;
