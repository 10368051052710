import React, { useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import { Tooltip, Modal } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Download from '@mui/icons-material/GetApp';
import { IconButton, Toolbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Search from 'components/Common/BigAgileTable/TableToolBar/Search';
import ButtonIcon from 'components/Common/Button/ButtonIcon';
import config from 'Config';
import { TOOLING_NAME } from 'containers/App/constants';
import PropTypes from 'prop-types';

import { styles } from './styles';
import Import from '../../../../assets/images/Import';
import FeatureImportModal from '../../../../containers/Delivery/components/shared/FeatureImportModal';
import { loggedInUserVar } from '../../../../reactiveVariables';

const useStyles = makeStyles(styles);
const envConfig = config[window.location.hostname];

const TableToolBar = (props) => {
  const {
    onHandleAdd,
    handleDownloadClick,
    handleSearch,
    title,
    disableAddButton,
    tooltipMessage,
    hideToolBar = false,
    showFeatureImport = false,
  } = props;
  const classes = useStyles(props);
  const [openImport, setOpenImport] = useState(false);

  const user = useReactiveVar(loggedInUserVar);

  const { roles = [] } = user;
  const canImport = roles.includes(`cru-product`);

  const matches = useMediaQuery('(min-width: 960px)');

  const getAddButton = () => {
    return (
      <IconButton
        key="add"
        aria-label="Add"
        color="inherit"
        disabled={disableAddButton}
        onClick={onHandleAdd}
        size="small"
        style={{ marginLeft: '6px' }}>
        <AddBoxIcon />
      </IconButton>
    );
  };

  return (
    <>
      <Toolbar className={classes.appBar} disableGutters>
        <div className={classes.toolbarLeft}>
          <span style={{ fontWeight: 600, fontSize: '20px' }}>{title}</span>
          {tooltipMessage ? (
            <Tooltip withArrow transition="scale" position="top" transitionDuration={200} label={tooltipMessage}>
              <div>{getAddButton()}</div>
            </Tooltip>
          ) : (
            getAddButton()
          )}

          {canImport && showFeatureImport && (
            <Tooltip
              transition={'scale'}
              withArrow
              zIndex={1000}
              label={`Bulk import features from ${TOOLING_NAME[envConfig.tooling]}`}>
              <IconButton
                key="import"
                aria-label="Import"
                color="inherit"
                size="small"
                onClick={() => setOpenImport(true)}>
                <Import />
              </IconButton>
            </Tooltip>
          )}
        </div>
        {!hideToolBar && (
          <div className={classes.toolbarRight}>
            <Search handleChange={handleSearch} />
            <ButtonIcon icon={Download} handleClick={handleDownloadClick} />
          </div>
        )}
      </Toolbar>

      <Modal
        zIndex={10000}
        opened={openImport}
        overflow="inside"
        centered
        size="50vw"
        fullScreen={!matches}
        onClose={() => setOpenImport(false)}
        title="Import Features">
        <FeatureImportModal onClose={setOpenImport} />
      </Modal>
    </>
  );
};

TableToolBar.propTypes = {
  onHandleAdd: PropTypes.func.isRequired,
  handleDownloadClick: PropTypes.func.isRequired,
  handleSearch: PropTypes.func,
  isRisksTab: PropTypes.bool,
};

export default TableToolBar;
