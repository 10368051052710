type PortfolioState = {
    name: string;
    rank: number;
    states: {
        name: string;
        valueStream: string;
    }[];
};

export const defaultPortfolioStateList: PortfolioState[] = [
    {
        name: 'Unmapped status',
        rank: 1,
        states: [],
    },
    {
        name: 'Funnel',
        rank: 2,
        states: [],
    },
    {
        name: 'Inactive',
        rank: 3,
        states: [],
    },
    {
        name: 'Analysis',
        rank: 4,
        states: [],
    },
    {
        name: 'Review',
        rank: 5,
        states: [],
    },
    {
        name: 'Backlog',
        rank: 6,
        states: [],
    },
    {
        name: 'Rollout',
        rank: 7,
        states: [],
    },
    {
        name: 'Measure',
        rank: 8,
        states: [],
    },
    {
        name: 'Done',
        rank: 9,
        states: [],
    },
];