import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { showNotification } from '@mantine/notifications';
import { Card, CardContent } from '@mui/material';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import { mapValues, omitBy } from 'lodash';
import { sortBy } from 'lodash';
import { useForm } from 'react-hook-form';

import { GET_PROJECTS } from '../../../containers/Organisation/Projects/graphql';
import {
  DELETE_STRATEGIC_THEME,
  INSERT_STRATEGIC_THEME,
  UPDATE_STRATEGIC_THEME,
  GET_STRATEGIC_THEMES,
} from '../../../containers/Organisation/StrategicThemes/graphql';
import { resetDrawerDetails } from '../../../reactiveVariables';
import { Field, FormContext, renderTextField, AutoCompleteWrapper } from '../../Common/FormFieldsHooks';

const StrategicThemeDetails = (props, ref) => {
  const { data: { projects = [] } = {} } = useQuery(GET_PROJECTS);

  const [insertStrategicTheme] = useMutation(INSERT_STRATEGIC_THEME);
  const [updateStrategicTheme] = useMutation(UPDATE_STRATEGIC_THEME);
  const [deleteStrategicTheme] = useMutation(DELETE_STRATEGIC_THEME);

  const [openConfirmation, setOpenConfirmation] = useState(false);

  const activeProjects = projects.filter((project) => project.isActive);

  const {
    handleSubmit,
    watch,
    control,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      ...props.element,
    },
  });

  const theme = watch();

  useImperativeHandle(ref, () => ({
    handleSave: () => {
      handleSubmit(onSubmit)();
    },
    handleDelete: () => {
      if (props.element && props.element.id) setOpenConfirmation(true);
    },
    handleClose: () => resetDrawerDetails(),
  }));

  const handleDeleteConfirm = () => {
    deleteStrategicTheme({
      variables: { id: theme.id },
      refetchQueries: [GET_STRATEGIC_THEMES, 'GetStrategicThemes'],
    }).then(() => resetDrawerDetails());
  };

  const onSubmit = (values) => {
    const themeToSave = mapValues(
      omitBy(values, (property, key) => key === 'id'),
      (property) => (property === '' ? null : property),
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    values.id
      ? updateStrategicTheme({
          variables: { theme: themeToSave, themeId: values.id },
        }).then(() => {
          showNotification({
            title: 'Strategic Theme Updated',
            message: `Strategic Theme ${values?.id} was successfully updated`,
          });

          resetDrawerDetails();
        })
      : insertStrategicTheme({
          refetchQueries: [GET_STRATEGIC_THEMES, 'GetStrategicThemes'],
          variables: { theme: themeToSave },
        }).then(() => {
          showNotification({
            title: 'Strategic Theme Created',
            message: `Strategic Theme was successfully created`,
          });
          resetDrawerDetails();
        });
  };

  return (
    <Card elevation={0}>
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContext.Provider value={{ control, errors }}>
            <Field name="name" renderField={renderTextField} label="Name" required />
            <Field
              name="description"
              multiline
              maxRows="100"
              minRows="3"
              renderField={renderTextField}
              label="Description"
            />
            <Field name="investment" renderField={renderTextField} label="Investment" />
            <AutoCompleteWrapper
              errors={errors}
              label="Projects"
              name="projects"
              multiple={true}
              control={control}
              changeFunc={(data) => data?.map((option) => ({ id: option.id }))}
              options={sortBy(activeProjects, 'name').filter((stream) => stream.name)}
              size={12}
            />
          </FormContext.Provider>
        </form>
      </CardContent>
      <ConfirmationDialog
        open={openConfirmation}
        title={`Delete Strategic Theme: ${theme.name}`}
        text="Are you sure you want to delete this Strategic Theme?"
        handleOk={handleDeleteConfirm}
        handleCancel={() => setOpenConfirmation(false)}
      />
    </Card>
  );
};

export default forwardRef(StrategicThemeDetails);
