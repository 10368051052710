import React from 'react';

import { useTheme } from '@emotion/react';

import { selectedProjectVar } from '../../../reactiveVariables';
import { BigAgileSelect } from '../Select/CustomSelect';

const ProjectSelector = ({ projects, value, handleChange, allOption }) => {
  const theme = useTheme();

  if (allOption) {
    projects = [
      {
        id: null,
        name: 'All Projects',
      },
      ...projects,
    ];
  }

  if (!value && projects[0]) {
    selectedProjectVar(projects[0]?.id);
    window.localStorage.setItem('selectedProject', projects[0]?.id);
  }

  const truncateText = (text, maxLength) => {
    if (!text) return '';
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  const renderValue = (option) => (
    <span style={{ color: theme.palette.text.secondary }}>
      Project:{' '}
      <span style={{ color: theme.palette.text.primary }}>{truncateText(option?.label, 15) || 'All Projects'}</span>
    </span>
  );

  return (
    <BigAgileSelect
      options={projects}
      value={value}
      onChange={(event, newValue) => {
        if (newValue === null) {
          handleChange(event, null);
        } else {
          handleChange(event, newValue);
        }
      }}
      renderValue={renderValue}
    />
  );
};

export default ProjectSelector;
