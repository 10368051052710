import React, { Fragment, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { useTheme } from '@emotion/react';
import { Button, Flex, Grid, Paper, Text } from '@mantine/core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { last } from 'lodash';
import { useNavigate } from 'react-router-dom';

import FeatureLine from './FeatureLine';
import SvgLinkArrow from '../../../assets/images/LinkArrow';
import Tooltip from '../../../components/Common/Tooltip';
import { openDrawer, selectedPiVar, selectedProgrammeVar, selectedValueStreamVar } from '../../../reactiveVariables';
import { hasJiraBE } from '../../../utils/helpers';
import { truncate } from '../../../utils/stringUtils';
import { getFeatureBackgroundColor } from '../../../utils/piColors';
import { GET_FEATURES } from '../../Product/Features/graphql';
import { GET_PROGRAMMES } from '../../Settings/GraphQL/programmes';
import { GET_SPRINTS } from '../../Settings/ProgramIncrement/graphql';

export const getStartDate = (feature, sprints, projectedFeatures) => {
  const startDate = feature.startingSprint?.startDate;
  if (feature.pi?.id && startDate) return startDate;
  if (feature.plannedStartDate) return feature.plannedStartDate;
  const piStartSprint = sprints.filter((sprint) => sprint.programIncrement === feature.pi?.id)?.[0];
  if (piStartSprint) return piStartSprint.startDate;
  return projectedFeatures[feature.id]?.startDate;
};

export const getEndDate = (feature, sprints, projectedFeatures) => {
  if (feature.releaseDate) return feature.releaseDate;
  if (feature.plannedReleaseDate) return feature.plannedReleaseDate;
  const endDate = sprints.find((sprint) => sprint.id === (feature.estimatedSprint ?? feature.committedSprint))?.endDate;
  if (endDate) return endDate;
  const piEndSprint = last(sprints.filter((sprint) => sprint.programIncrement === feature.pi?.id));
  if (piEndSprint) return piEndSprint.endDate;
  return projectedFeatures[feature.id]?.endDate;
};

const FeatureRow = ({
  feature,
  features,
  lastChild,
  width,
  height,
  columnDates,
  projectedFeatures = {},
  sprints,
  programmes,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const color = getFeatureBackgroundColor(feature, theme);
  const startDate = getStartDate(feature, sprints, projectedFeatures);
  const endDate = getEndDate(feature, sprints, projectedFeatures);
  const programme = programmes.find((programme) => programme.id === feature.programmeId);

  const isProjected = !!projectedFeatures[feature.id];
  const fullFeature = useMemo(() => features.find((fullFeature) => fullFeature.id === feature.id), [features]);

  const onProgramRedirect = () => {
    window.localStorage.setItem('selectedValueStream', programme?.valueStream?.id);
    window.localStorage.setItem('selectedProgramme', programme.id);
    window.localStorage.setItem('selectedPI', feature.pi?.id);
    selectedPiVar(feature.pi?.id);
    selectedProgrammeVar(programme.id);
    selectedValueStreamVar(programme?.valueStream?.id);

    navigate('/delivery/summary');
  };

  const onOpenFeature = () => {
    openDrawer(fullFeature, 'feature');
  };

  return (
    <Grid gutter={0} m={0} p={0}>
      <Grid.Col
        span={4}
        p={0}
        style={{
          alignItems: 'center',
          borderRight: `1px solid ${theme.palette.color.tableColumn}`,
          backgroundColor: theme.palette.color.backgroundColor,
        }}>
        <div style={{ display: 'flex', height: '60px', alignItems: 'center' }}>
          {feature.status === 'Done' ? (
            <CheckCircleIcon htmlColor="#2D9CDB" />
          ) : (
            <RadioButtonUncheckedIcon htmlColor={color} />
          )}
          <Tooltip
            key={fullFeature?.id}
            content={
              <Fragment>
                {!fullFeature?.metrics && <Text size="sm">{`This feature doesn't have any metrics`}</Text>}
                <Text size="sm">
                  {fullFeature?.enabler ? 'Enabler ID: ' : 'Feature ID: '}
                  {fullFeature?.id}
                </Text>
                {fullFeature?.metrics && (
                  <Text size="sm">
                    {hasJiraBE() ? `Jira Epic: ${fullFeature.epicId}` : `Azure Devops Feature: ${fullFeature.epicId}`}
                  </Text>
                )}
                {fullFeature?.metrics && (
                  <Text size="sm">
                    Story points: {fullFeature.metrics.storyPoints.done} / {fullFeature.metrics.storyPoints.total}
                  </Text>
                )}
                {fullFeature?.metrics && (
                  <Text size="sm">
                    Stories: {fullFeature.metrics.storyCount.done} / {fullFeature.metrics.storyCount.total}
                  </Text>
                )}
              </Fragment>
            }>
            <Paper
              withBorder
              p={10}
              ml={8}
              mr={12}
              style={{
                height: '50px',
                width: '100%',
                '&:hover': {
                  backgroundColor: theme.palette.color.header,
                  cursor: 'pointer',
                },
              }}
              onClick={onOpenFeature}>
              <Flex justify={'space-between'} wrap={'nowrap'} align={'center'}>
                <Text size="xs" fw={400} lineClamp={1}>
                  {/* {feature.enabler ? 'E' : 'F'}-{feature.id} - */}
                  {truncate(feature.name, 30)}
                </Text>
                {programme && (
                  <Button
                    variant="light"
                    onClick={() => onProgramRedirect()}
                    size="compact-sm"
                    style={{ marginLeft: '10px', fontSize: '13px' }}
                    rightSection={<SvgLinkArrow />}>
                    {programme.name}
                  </Button>
                )}
              </Flex>
            </Paper>
          </Tooltip>
        </div>
      </Grid.Col>
      <Grid.Col span={8} p={0} style={{ position: 'relative' }}>
        <FeatureLine
          feature={feature}
          width={width}
          height={height}
          lastChild={lastChild}
          noBorder={true}
          startDate={startDate}
          endDate={endDate}
          fullFeature={fullFeature}
          color={isProjected ? theme.palette.color.predicted : color}
          columnDates={columnDates}
        />
      </Grid.Col>
    </Grid>
  );
};

export default FeatureRow;
