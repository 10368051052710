import styled from '@emotion/styled';
import { Button } from '@mantine/core';

import { lightColors } from '../../shared/styles/color';

export const SpotlightContainer = styled('div')(({ theme }) => ({
  border: `1px solid ${lightColors.tileBorder}`,
  borderRadius: '12px',
  position: 'absolute',
  width: 'calc(100% - 260px)',
  height: 'calc(100% - 20px)',
  top: '10px',
  zIndex: '1000',
  left: '250px',
  display: 'flex',
  background: theme.palette.mode === 'dark' ? '#191B1F' : 'white',
}));

export const MainSection = styled('div')(({ displaySearchSection }) => ({
  width: '100%',
  overflow: 'scroll',
}));

export const SearchSection = styled('div')({
  borderRight: `1px solid ${lightColors.tileBorder}`,
  width: '20%',
  maxWidth: '225px',
  overflow: 'scroll',
});

export const Header = styled('div')({
  borderBottom: `1px solid ${lightColors.tileBorder}`,
  height: '50px',
  position: 'relative',
  display: 'flex',
});

export const Content = styled('div')({
  width: '100%',
});

export const ContentSidebar = styled('div')({
  height: '100%',
  position: 'relative',
  minWidth: '250px',
});

export const SidebarContainer = styled('div')({
  margin: '20px 10px',
});

export const ContentInner = styled('div')({
  margin: '24px 20px',
});

export const AddAttachment = styled('div')({
  width: '120px',
  height: '80px',
  border: `1px dashed ${lightColors.tileBorder}`,
  display: 'block',
  textAlign: 'center',
  cursor: 'pointer',
});

export const GroupButton = styled(Button)({
  height: '28px',
  border: `1px solid ${lightColors.tileBorder}`,
  backgroundColor: 'white',
  color: '#242424',
  fontSize: '12px',
  fontWeight: 400,
  padding: '7px',
  borderRadius: '8px',
});

export const CircleIcon = styled('div')(({ color = '#FF0000' }) => ({
  width: '15px',
  height: '15px',
  borderRadius: '50%',
  border: `2px solid ${color}`,
  backgroundColor: 'transparent',
}));

export const SearchItemBox = styled('div')(({ theme, selected = false }) => ({
  width: '100%',
  height: '36px',
  borderRadius: '8px',
  border: `1px solid ${lightColors.tileBorder}`,
  display: 'flex',
  paddingLeft: '10px',
  marginBottom: '5px',
  alignItems: 'center',
  fontSize: '14px',
  cursor: 'pointer',
  background: theme.palette.mode === 'dark' ? '#191B1F' : 'white',
  backgroundColor: selected ? '#E8E8E8' : 'inherit',
  color: selected && theme.palette.mode === 'dark' ? 'black' : 'inherit',
}));

export const TableHead = styled('th')({
  fontSize: '12px',
  color: '#808080',
  borderTop: '1px solid #ddd',
  borderBottom: '1px solid #ddd',
  padding: '8px',
  textAlign: 'left',
  fontWeight: 400,
  lineHeight: '15px',
});

export const AttachmentImage = styled('img')({
  width: '350px',
  height: 'auto',
});

export const AttachmentContainer = styled('div')({
  height: '44px',
  listStyleType: 'none',
  borderBottom: `1px solid ${lightColors.tileBorder}`,
  lineHeight: '44px',
  fontSize: '14px',
  position: 'relative',
  overflow: 'visible',
});

export const ItemCount = styled('div')(({ theme }) => ({
  backgroundColor: lightColors.tileBorder,
  padding: '0 4px',
  borderRadius: '4px',
  fontSize: '11px',
  lineHeight: '20px',
  marginLeft: '5px',
  display: 'inline-block',
  color: theme.palette.mode === 'dark' ? 'black' : 'inherit',
}));
