import React, { useState, useEffect, useMemo } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Divider, Box, Text, Table } from '@mantine/core';
import AddIcon from '@mui/icons-material/Add';
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';

import { SyncFeatures } from '../../../components/Common/SyncFeatures';
import ModalContainer from '../../../components/Modal';
import { GET_PORTFOLIO_OBJECTIVES, UPDATE_PORTFOLIO_OBJECTIVE } from '../../../containers/Organisation/OKRs/graphql';
import { UPDATE_STRATEGIC_THEME, GET_STRATEGIC_THEMES } from '../../../containers/Organisation/StrategicThemes/graphql';
import LinearWithValueLabel from '../../../containers/ValueStream/components/OKRTable/LinearWithValueLabel';
import { GET_ALL_MILESTONES, UPDATE_MILESTONE } from '../../../containers/ValueStream/Milestones/graphql';
import { openDrawer } from '../../../reactiveVariables';
import { color } from '../../../shared/styles/color';
import { ContentInner } from '../style';
export const WhyTab = ({ okrs, selectedItem }) => {
  const { data: { milestones = [] } = {}, refetch: refetchMilestones } = useQuery(GET_ALL_MILESTONES);
  const { data: { portfolioObjectives = [] } = {} } = useQuery(GET_PORTFOLIO_OBJECTIVES);
  const { data: { strategic_themes = [] } = {} } = useQuery(GET_STRATEGIC_THEMES);

  const [showMilestoneModal, setShowMilestoneModal] = useState(false);
  const [showAddOKRModal, setShowAddOKRModal] = useState(false);
  const [showAddThemeModal, setShowAddThemeModal] = useState(false);

  const matchingThemes = strategic_themes.filter((theme) =>
    theme.projects?.some((project) => project.id === selectedItem.id),
  );

  const matchingProjectIds = useMemo(() => matchingThemes.map((theme) => theme.id), [matchingThemes]);

  const [selectedOkrs, setSelectedOkrs] = useState([]);
  const [selectedMilestones, setSelectedMilestones] = useState([]);
  const [selectedThemes, setSelectedThemes] = useState([]);

  useEffect(() => {
    if (!isEqual(selectedThemes, matchingProjectIds)) {
      setSelectedThemes(matchingProjectIds);
    }
  }, [strategic_themes, selectedItem]);

  const [updateObjective] = useMutation(UPDATE_PORTFOLIO_OBJECTIVE, {
    refetchQueries: [{ query: GET_PORTFOLIO_OBJECTIVES }],
  });
  const [updateMilestone] = useMutation(UPDATE_MILESTONE, {
    refetchQueries: [{ query: GET_ALL_MILESTONES }],
  });

  const [updateStrategicTheme] = useMutation(UPDATE_STRATEGIC_THEME, {
    refetchQueries: [{ query: GET_STRATEGIC_THEMES }],
  });

  const selectedItemsMilestones = milestones.filter((milestone) => milestone.strategic_theme === selectedItem.id);

  const handleSelectedOkrsChange = (newSelectedItems) => {
    setSelectedOkrs(newSelectedItems);
  };

  const handleSelectedMilestonesChange = (newSelectedItems) => {
    setSelectedMilestones(newSelectedItems);
  };

  const handleSelectedThemesChange = (newSelectedItems) => {
    setSelectedThemes(newSelectedItems);
  };

  const openSelectedDrawer = (data, type) => {
    openDrawer(data, type);
  };

  const handleOKRSelection = async (selectedOKRs) => {
    try {
      // 1. Update OKRs that should have the strategic_theme added
      const updatesToAdd = selectedOKRs.map((okrId) => ({
        variables: {
          portfolioObjectiveId: parseInt(okrId),
          portfolioObjective: {
            strategic_theme: [selectedItem.id.toString()], // Ensure it's a string
          },
        },
      }));

      // 2. Update OKRs that should have strategic_theme set to null
      const okrsToUnset = okrs.filter((okr) => !selectedOKRs.includes(okr.id));

      const updatesToUnset = okrsToUnset.map((okr) => ({
        variables: {
          portfolioObjectiveId: okr.id,
          portfolioObjective: {
            strategic_theme: null,
          },
        },
      }));

      // Execute all updates in parallel
      await Promise.all([
        ...updatesToAdd.map((update) => updateObjective(update)),
        ...updatesToUnset.map((update) => updateObjective(update)),
      ]);

      setShowAddOKRModal(false);
    } catch (error) {
      console.error('Error updating OKRs:', error);
    }
  };

  const handleMilestoneSelection = async () => {
    try {
      // 1. Update milestones that should have a strategic_theme set
      for (const milestoneId of selectedMilestones) {
        await updateMilestone({
          variables: {
            milestoneId: parseInt(milestoneId),
            milestone: {
              strategic_theme: selectedItem.id,
            },
          },
        });
      }

      // 2. Update milestones that should have strategic_theme set to null
      const milestonesToUnset = selectedItemsMilestones.filter(
        (milestone) => !selectedMilestones.includes(milestone.id),
      );

      for (const milestone of milestonesToUnset) {
        await updateMilestone({
          variables: {
            milestoneId: milestone.id,
            milestone: {
              strategic_theme: null,
            },
          },
        });
      }

      await refetchMilestones();
      setShowMilestoneModal(false);
    } catch (error) {
      console.error('Error updating milestones:', error);
    }
  };

  const calculateProgress = (value) => {
    // If value is greater than 100, assume it's an absolute value and normalize it
    if (value > 100) {
      return Math.min(100, value / 1000); // Normalize large numbers
    }
    // Otherwise treat it as a percentage
    return Math.min(100, value);
  };

  useEffect(() => {
    // Initialize selectedOkrs with currently linked OKRs
    if (okrs?.length) {
      setSelectedOkrs(okrs.map((okr) => okr.id));
    }
  }, [okrs]);

  // Initialize selectedMilestones only once when the component mounts or selectedItem changes
  useEffect(() => {
    if (selectedItemsMilestones?.length) {
      const milestoneIds = selectedItemsMilestones.map((milestone) => milestone.id);
      setSelectedMilestones(milestoneIds);
    } else {
      setSelectedMilestones([]);
    }
  }, [selectedItem.id]); // Only run when selectedItem changes

  const handleThemeSave = (themes) => {
    const updatesToAdd = strategic_themes.map((theme) => {
      const existingProjects = theme.projects || []; // Handle null safely
      const isSelected = themes.includes(theme.id); // Is this theme part of the selected themes?

      const updatedProjects = isSelected
        ? existingProjects.some((project) => project.id === selectedItem.id)
          ? existingProjects // Already present, no change needed
          : [...existingProjects, { id: selectedItem.id }] // Add if not present
        : existingProjects.filter((project) => project.id !== selectedItem.id); // Remove if present

      return {
        variables: {
          themeId: parseInt(theme.id),
          theme: {
            projects: updatedProjects,
          },
        },
      };
    });

    // Execute updates
    updatesToAdd.forEach((update) => updateStrategicTheme(update));

    setShowAddThemeModal(false);
  };

  return (
    <div>
      <ContentInner>
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Text style={{ fontSize: '12px', fontWeight: 550, marginTop: '10px' }}>OKRs</Text>
          <AddIcon style={{ cursor: 'pointer', color: color.secondary }} onClick={() => setShowAddOKRModal(true)} />
        </Box>

        {!!okrs.length && (
          <Table borderRadius={8} layout="fixed" mt={12}>
            <tbody>
              {okrs.map((okr, index) => (
                <React.Fragment key={okr.id}>
                  {/* Objective Row */}
                  <tr
                    style={{
                      marginTop: '16px',
                      marginBottom: index !== okrs.length - 1 ? '24px' : '0',
                    }}>
                    <td
                      style={{
                        paddingLeft: '18px',
                        textAlign: 'left',
                        width: '60%',
                        paddingTop: '20px',
                        paddingBottom: '8px',
                        verticalAlign: 'middle',
                      }}>
                      <div style={{ fontSize: '14px', fontWeight: 'bold' }}>{okr.name}</div>
                      <div
                        style={{
                          fontSize: '13px',
                          color: '#666666',
                          marginTop: '4px',
                        }}>
                        {okr.targetDate}
                      </div>
                    </td>
                    <td
                      style={{
                        paddingLeft: '8px',
                        textAlign: 'left',
                        display: 'flex',
                        alignItems: 'center',
                        width: '40%',
                        paddingTop: '20px',
                        paddingBottom: '8px',
                        verticalAlign: 'middle',
                        height: '100%',
                      }}>
                      <div
                        style={{
                          width: '120px',
                          marginRight: '10px',
                          display: 'flex',
                          alignItems: 'center',
                        }}>
                        <LinearWithValueLabel
                          value={Math.round(
                            okr.keyResults?.reduce((acc, kr) => {
                              const normalizedValue = calculateProgress(kr.targetValue || 0);
                              return acc + normalizedValue;
                            }, 0) / (okr.keyResults?.length || 1),
                          )}
                          hideLabel
                        />
                      </div>
                      <span style={{ fontSize: '12px', color: color.secondary }}>
                        {Math.round(
                          okr.keyResults?.reduce((acc, kr) => {
                            const normalizedValue = calculateProgress(kr.targetValue || 0);
                            return acc + normalizedValue;
                          }, 0) / (okr.keyResults?.length || 1),
                        )}
                        %
                      </span>
                    </td>
                  </tr>
                  {/* Key Results Rows */}
                  {okr.keyResults?.map((kr, krIndex) => (
                    <tr
                      key={kr.id}
                      style={{
                        marginBottom: krIndex === okr.keyResults.length - 1 ? '24px' : '0',
                      }}>
                      <td
                        style={{
                          paddingLeft: '36px',
                          textAlign: 'left',
                          fontSize: '13px',
                          color: color.secondary,
                          width: '60%',
                        }}>
                        • {kr.name}
                      </td>
                      <td
                        style={{
                          paddingLeft: '8px',
                          textAlign: 'left',
                          display: 'flex',
                          alignItems: 'center',
                          width: '40%',
                        }}>
                        <div style={{ width: '120px', marginRight: '10px' }}>
                          <LinearWithValueLabel value={calculateProgress(kr.targetValue)} hideLabel />
                        </div>
                        <span style={{ fontSize: '12px', color: color.secondary }}>
                          {calculateProgress(kr.targetValue)}%
                        </span>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        )}
        <Divider mt={20} mb={20} />
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
          <Text style={{ fontSize: '12px', fontWeight: 550 }}>Strategic Themes</Text>
          <AddIcon style={{ cursor: 'pointer', color: color.secondary }} onClick={() => setShowAddThemeModal(true)} />
        </Box>

        {matchingThemes?.length > 0 && (
          <Table borderRadius={8} layout="fixed" mt={12}>
            <tbody>
              {matchingThemes.map((theme) => {
                return (
                  <tr key={theme.id} onClick={() => openSelectedDrawer(theme, 'strategicTheme')}>
                    <td style={{ paddingLeft: '18px', textAlign: 'left', fontSize: '14px', width: '30%' }}>
                      {theme.name}
                    </td>
                    <td
                      style={{
                        paddingLeft: '8px',
                        textAlign: 'left',
                        fontSize: '12px',
                        color: color.secondary,
                        width: '50%',
                      }}>
                      {theme.description?.length > 50 ? theme.description.substring(0, 50) + '...' : theme.description}
                    </td>
                    <td
                      style={{
                        paddingLeft: '8px',
                        textAlign: 'left',
                        fontSize: '12px',
                        color: color.secondary,
                        width: '20%',
                      }}>
                      {theme.investment}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}

        <Divider mt={20} mb={20} />
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
          <Text style={{ fontSize: '12px', fontWeight: 550 }}>Key Events</Text>
          <AddIcon style={{ cursor: 'pointer', color: color.secondary }} onClick={() => setShowMilestoneModal(true)} />
        </Box>

        {!!selectedItemsMilestones.length && (
          <Table borderRadius={8} layout="fixed" mt={12}>
            <tbody>
              {selectedItemsMilestones.map((milestone) => {
                const eventDate = DateTime.fromISO(milestone.date).toFormat('dd MMM yyyy');
                return (
                  <tr key={milestone.id} onClick={() => openSelectedDrawer(milestone, 'milestone')}>
                    <td style={{ paddingLeft: '18px', textAlign: 'left', fontSize: '14px', width: '30%' }}>
                      {milestone.name}
                    </td>
                    <td
                      style={{
                        paddingLeft: '8px',
                        textAlign: 'left',
                        fontSize: '12px',
                        color: color.secondary,
                        width: '20%',
                      }}>
                      Event date: {eventDate}
                    </td>
                    <td
                      style={{
                        paddingLeft: '8px',
                        textAlign: 'left',
                        fontSize: '12px',
                        color: color.secondary,
                        width: '50%',
                      }}>
                      {milestone.description}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}

        <ModalContainer
          open={showAddOKRModal}
          title="Add OKRs"
          primaryButtonText="Add OKRs"
          width="420px"
          onSubmit={() => handleOKRSelection(selectedOkrs)}
          close={() => setShowAddOKRModal(false)}>
          <SyncFeatures
            name="Objectives"
            items={portfolioObjectives}
            onSelectionChange={handleSelectedOkrsChange}
            selected={selectedOkrs}
          />
        </ModalContainer>

        <ModalContainer
          open={showAddThemeModal}
          title="Add Strategic Themes"
          primaryButtonText="Add Strategic Theme"
          width="420px"
          onSubmit={() => handleThemeSave(selectedThemes)}
          close={() => {
            setSelectedThemes(matchingProjectIds);
            setShowAddThemeModal(false);
          }}>
          <SyncFeatures
            name="Strategic Themes"
            items={strategic_themes}
            onSelectionChange={handleSelectedThemesChange}
            selected={selectedThemes}
          />
        </ModalContainer>

        <ModalContainer
          open={showMilestoneModal}
          title="Add Key Events"
          primaryButtonText="Add Events"
          width="420px"
          onSubmit={handleMilestoneSelection}
          close={() => setShowMilestoneModal(false)}>
          <SyncFeatures
            name="Key Events"
            items={milestones}
            onSelectionChange={handleSelectedMilestonesChange}
            selected={selectedMilestones}
          />
        </ModalContainer>
      </ContentInner>
    </div>
  );
};
//
