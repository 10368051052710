import React from 'react';

import { Route } from 'react-router-dom';

import InitiativesRoadmap from './InitiativesRoadmap';
import OKRs from './OKRs/Summary';
import Index from './OKRs/View';
import OperationalPortfolio from './Portfolio/index';
import Projects from './Projects';
import { Reporting } from './Reporting';
import { StrategicThemes } from './StrategicThemes';
import ThinkTank from './ThinkTank';
import { RiskTable } from '../../containers/Delivery/components/Risks/ValueStreamRisk';
import { NewTable } from '../../containers/ValueStream/Initiatives/newTable';

const getOrganisationRoutes = (user) => (
  <>
    <Route user={user} path="/organisation/dashboard" element={<InitiativesRoadmap />} />
    <Route user={user} path="/organisation/okrs" element={<OKRs />} />
    <Route user={user} path="/organisation/okrs/:valueStreamId" element={<Index />} />
    <Route user={user} path="/organisation/portfolio" element={<OperationalPortfolio />} />
    <Route user={user} path="/organisation/initiatives" element={<NewTable type="portfolioInitiative" />} />
    <Route user={user} path="/organisation/thinkTank" element={<ThinkTank />} />
    <Route user={user} path="/organisation/projects" element={<Projects user={user} />} />
    <Route user={user} path="/organisation/projects/:projectId" element={<Projects user={user} />} />
    <Route user={user} path="/organisation/reporting" element={<Reporting />} />
    <Route user={user} path="/organisation/risks" element={<RiskTable />} />
    <Route user={user} path="/organisation/strategicThemes" element={<StrategicThemes />} />
  </>
);

export default getOrganisationRoutes;
