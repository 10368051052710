import { S3Client, PutObjectCommand, DeleteObjectCommand } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import config from 'Config';

const s3Client = new S3Client({
  region: 'eu-west-1',
  credentials: {
    accessKeyId: 'AKIAWEVLNFVWB44GQHHM',
    secretAccessKey: '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',
  },
});

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB limit

export const Uploader = async (event, type, onUpload) => {
  const file = event.target.files[0];
  if (!file) return;

  if (file.size > MAX_FILE_SIZE) {
    return;
  }

  const folderPath = config[location.hostname].realm;
  const key = `${folderPath}/${file.name}`;

  try {
    const command = new PutObjectCommand({
      Bucket: type,
      Key: key,
      Body: file,
      ContentType: file.type,
    });

    const signedUrl = await getSignedUrl(s3Client, command, { expiresIn: 3600 });

    const response = await fetch(signedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
      },
    });

    if (response.ok) {
      console.log('File uploaded successfully');
      onUpload(key);
    } else {
      console.error('Failed to upload file to S3');
    }
  } catch (error) {
    console.error('Error uploading file:', error);
  }
};

export const deleteFileFromS3 = async (fileKey, bucketName) => {
  try {
    const command = new DeleteObjectCommand({
      Bucket: bucketName,
      Key: fileKey,
    });

    const response = await s3Client.send(command);
    if (response) {
      console.log('File deleted successfully');
    }
  } catch (error) {
    console.error('Error deleting file:', error);
  }
};
