import React, { useState } from 'react';

import { Button, Group } from '@mantine/core';
import AddIcon from '@mui/icons-material/Add';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ListItemIcon, ListItemText, Menu, MenuItem, Divider } from '@mui/material';

import { returnPrioritySvg } from './helper';
import { ContentSidebar } from './style';
import CopySvg from '../../assets/images/Copy';
import InitiativeSvg from '../../assets/images/InitiativeIcon';
import SvgPriorityHigh from '../../assets/images/priorityHigh';
import SvgPriorityLow from '../../assets/images/priorityLow';
import SvgPriorityMedium from '../../assets/images/priorityMedium';
import SvgPriorityNo from '../../assets/images/priorityNo';
import SvgPriorityUrgent from '../../assets/images/priorityUrgent';
import { lightColors } from '../../shared/styles/color';

export const GroupMenu = ({ setShowNewLinksModal, handleAddSelection, onPriorityUpdate, priority }) => {
  const [addAnchorEl, setAddAnchorEl] = useState(null);
  const [addPriorityAnchorEl, setAddPriorityAnchorEl] = useState(null);

  const openAdd = Boolean(addAnchorEl);
  const openPriority = Boolean(addPriorityAnchorEl);

  const handleAddClick = (event) => {
    setAddAnchorEl(event.currentTarget);
  };

  const handleSetPriorityClick = (event) => {
    setAddPriorityAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAddAnchorEl(null);
    setAddPriorityAnchorEl(null);
  };

  return (
    <>
      <ContentSidebar style={{ borderLeft: 'none' }}>
        <Group justify="flex-start" gap={8} mt={20}>
          <div>
            <Button
              variant="light"
              onClick={handleAddClick}
              radius={8}
              style={{
                height: '28px',
                border: `1px solid ${lightColors.tileBorder}`,
                backgroundColor: 'white',
                color: '#242424',
                fontSize: '12px',
                fontWeight: 400,
                padding: '7px',
              }}>
              Add <AddIcon style={{ height: '15px' }} />
            </Button>

            <Menu
              anchorEl={addAnchorEl}
              open={Boolean(openAdd)}
              onClose={handleMenuClose}
              slotProps={{
                paper: {
                  sx: {
                    width: '200px',
                    borderRadius: '15px',
                    marginTop: '10px',
                  },
                },
              }}>
              <MenuItem
                onClick={() => {
                  handleAddSelection('Features');
                  handleMenuClose();
                }}>
                <ListItemIcon style={{ marginRight: 0, display: 'contents' }}>
                  <CopySvg style={{ marginRight: '5px' }} />
                </ListItemIcon>
                <ListItemText primary="Features" onClick={() => setAddAnchorEl(null)} />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleAddSelection('Initiatives');
                  handleMenuClose();
                }}>
                <ListItemIcon style={{ marginRight: 0, display: 'contents' }}>
                  <InitiativeSvg style={{ marginRight: '8px', marginLeft: '2px' }} />
                </ListItemIcon>
                <ListItemText primary="Initiatives" onClick={() => setAddAnchorEl(null)} />
              </MenuItem>

              <Divider style={{ margin: '8px 0' }} />
              <MenuItem disabled style={{ opacity: 0.6, fontSize: '12px', paddingLeft: '16px' }}>
                Existing
              </MenuItem>

              <MenuItem
                onClick={() => {
                  handleAddSelection('Existing Features');
                  handleMenuClose();
                }}>
                <ListItemIcon style={{ marginRight: 0, display: 'contents' }}>
                  <CopySvg style={{ marginRight: '5px' }} />
                </ListItemIcon>
                <ListItemText primary="Features" />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleAddSelection('Existing Initiatives');
                  handleMenuClose();
                }}>
                <ListItemIcon style={{ marginRight: 0, display: 'contents' }}>
                  <InitiativeSvg style={{ marginRight: '8px', marginLeft: '2px' }} />
                </ListItemIcon>
                <ListItemText primary="Initiatives" />
              </MenuItem>
            </Menu>
          </div>
          {/* <Button
            variant="light"
            radius={8}
            onClick={() => document.getElementById('file-input').click()}
            style={{
              height: '28px',
              border: `1px solid ${lightColors.tileBorder}`,
              backgroundColor: 'white',
              color: '#242424',
              fontSize: '12px',
              fontWeight: 400,
              padding: '7px',
            }}>
            Attach <KeyboardArrowDownIcon style={{ width: '20px' }} />
          </Button> */}
          <Button
            variant="light"
            radius={8}
            onClick={() => setShowNewLinksModal()}
            style={{
              height: '28px',
              border: `1px solid ${lightColors.tileBorder}`,
              backgroundColor: 'white',
              color: '#242424',
              fontSize: '12px',
              fontWeight: 400,
              padding: '7px',
            }}>
            Link <InsertLinkIcon style={{ width: '20px', marginLeft: '3px' }} />
          </Button>
          <Button
            variant="light"
            radius={8}
            onClick={handleSetPriorityClick}
            style={{
              height: '28px',
              border: `1px solid ${lightColors.tileBorder}`,
              backgroundColor: 'white',
              color: '#242424',
              fontSize: '12px',
              fontWeight: 400,
              padding: '7px',
            }}>
            <span style={{ marginRight: '5px' }}>
              {priority ? (
                returnPrioritySvg(priority)
              ) : (
                <SvgPriorityHigh style={{ width: '20px', marginRight: '10px' }} />
              )}
            </span>
            {priority ? priority : 'Set Priority'}
          </Button>

          <Menu
            anchorEl={addPriorityAnchorEl}
            open={Boolean(openPriority)}
            onClose={handleMenuClose}
            slotProps={{
              paper: {
                sx: {
                  width: '200px',
                  borderRadius: '15px',
                  marginTop: '10px',
                },
              },
            }}>
            <MenuItem
              onClick={() => {
                onPriorityUpdate('No priority');
                handleMenuClose();
              }}>
              <ListItemIcon style={{ display: 'contents' }}>
                <SvgPriorityNo />
              </ListItemIcon>
              <ListItemText primary="No priority" style={{ marginLeft: '5px' }} />
            </MenuItem>
            <MenuItem
              onClick={() => {
                onPriorityUpdate('Urgent');
                handleMenuClose();
              }}>
              <ListItemIcon style={{ display: 'contents' }}>
                <SvgPriorityUrgent />
              </ListItemIcon>
              <ListItemText primary="Urgent" style={{ marginLeft: '5px' }} />
            </MenuItem>
            <MenuItem
              onClick={() => {
                onPriorityUpdate('High');
                handleMenuClose();
              }}>
              <ListItemIcon style={{ display: 'contents' }}>
                <SvgPriorityHigh />
              </ListItemIcon>
              <ListItemText primary="High" style={{ marginLeft: '5px' }} />
            </MenuItem>
            <MenuItem
              onClick={() => {
                onPriorityUpdate('Medium');
                handleMenuClose();
              }}>
              <ListItemIcon style={{ display: 'contents' }}>
                <SvgPriorityMedium />
              </ListItemIcon>
              <ListItemText primary="Medium" style={{ marginLeft: '5px' }} />
            </MenuItem>
            <MenuItem
              onClick={() => {
                onPriorityUpdate('Low');
                handleMenuClose();
              }}>
              <ListItemIcon style={{ display: 'contents' }}>
                <SvgPriorityLow />
              </ListItemIcon>
              <ListItemText primary="Low" style={{ marginLeft: '5px' }} />
            </MenuItem>
          </Menu>
        </Group>
      </ContentSidebar>
    </>
  );
};
