import * as React from 'react';
import { useEffect, useState } from 'react';

import { Flex, ActionIcon, Button, Checkbox, Chip, Group, Modal } from '@mantine/core';
import EditIcon from '@mui/icons-material/Edit';
import { find } from 'lodash';

import { FlexDiv } from '../../Common/Elements';

const SubtaskDialog = ({ open, setOpen, setAddedSubtasks, addedSubtasks, subtasks }) => {
  const [selectedSubtasks, setSelectedSubtasks] = useState(addedSubtasks.map((task) => task.id.toString()));

  useEffect(() => {
    setSelectedSubtasks(addedSubtasks.map((task) => task.id.toString()));
  }, [addedSubtasks]);

  const saveSelection = () => {
    const savedSubtasks = selectedSubtasks.map((taskId) => {
      const task = find(addedSubtasks, { id: parseInt(taskId) }) || {
        ...find(subtasks, { id: parseInt(taskId) }),
        done: false,
      };

      return { id: task.id, name: task.name, done: task.done };
    });
    setAddedSubtasks(savedSubtasks);
    setOpen(false);
  };

  return (
    <Modal opened={open} onClose={() => setOpen(false)} zIndex={3000} title="Edit Task List" centered>
      <Chip.Group
        position="center"
        multiple
        value={selectedSubtasks}
        defaultValue={selectedSubtasks}
        onChange={setSelectedSubtasks}>
        <Group justify="center" mt="md">
          {subtasks.map((subtask) => (
            <Chip key={subtask.id} value={subtask.id.toString()}>
              {subtask.name}
            </Chip>
          ))}
        </Group>
      </Chip.Group>
      <Group position={'right'} mt="xl">
        <Button variant="outline" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button variant="outline" onClick={saveSelection}>
          Save
        </Button>
      </Group>
    </Modal>
  );
};

const Subtasks = ({ feature, isCreating, setValue, subtasks }) => {
  const [addedSubtasks, setAddedSubtasks] = useState(
    isCreating ? subtasks.filter((subtask) => subtask.isDefault) : feature.subtasks || [],
  );
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setValue('subtasks', addedSubtasks);
  }, [addedSubtasks]);

  const setDoneSubtasks = (taskNames) => {
    setAddedSubtasks(
      addedSubtasks.map((task) => ({
        ...task,
        done: taskNames.includes(task.id.toString()),
        status: taskNames.includes(task.id.toString()) ? 'Done' : 'To Do',
      })),
    );
  };

  return (
    <>
      <FlexDiv style={{ padding: '16px' }}>
        <SubtaskDialog
          open={opened}
          setOpen={setOpened}
          setAddedSubtasks={setAddedSubtasks}
          addedSubtasks={addedSubtasks}
          subtasks={subtasks}
        />
        <Checkbox.Group
          value={addedSubtasks.filter((task) => task.done).map((task) => task.id.toString())}
          label={
            <Flex align={'center'}>
              <span>List of tasks</span>
              <ActionIcon variant={'transparent'} size="xs" onClick={() => setOpened(true)} ml={4}>
                <EditIcon fontSize={'small'} />
              </ActionIcon>
            </Flex>
          }
          description={<span>What's needed for sign off?</span>}
          onChange={setDoneSubtasks}>
          {addedSubtasks.map((subtask) => (
            <Checkbox sx={{ display: 'flex' }} value={subtask.id.toString()} label={subtask.name} key={subtask.id} />
          ))}
        </Checkbox.Group>
      </FlexDiv>
    </>
  );
};

export default Subtasks;
