import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { Chip, Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SvgIcon } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

import { MENU_ITEMS } from './constants';
import SvgThinkTank from '../../assets/images/ThinkTank';

const Link = styled(NavLink)(({ theme }) => ({
  textDecoration: 'none',
  paddingRight: 0,
  color: theme.palette.text.primary,
  '& span': {
    fontSize: 12,
  },
  '&:hover': {
    textDecoration: 'none',
    color: theme.palette.color.purpulishPink,
  },
}));

const handleClick = (ev, disabled) => {
  if (disabled) {
    ev.preventDefault();
  }
};

const Page = ({ name, uri, subMenus, open, setOpen, disabled, isAdmin }) => {
  return (
    <>
      <ListItem
        key={uri}
        disablePadding
        secondaryAction={
          disabled && (
            <Chip
              size="small"
              sx={{ borderRadius: '4px', fontSize: '11px', fontWeight: 'bold', color: 'text.secondary' }}
              label="SOON"
            />
          )
        }>
        <ListItemButton onClick={() => !disabled && setOpen(open ? '' : uri)}>
          <ListItemText primary={name} />
          {disabled ? null : open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subMenus
            .filter((menu) => !menu.needAdmin || (menu.needAdmin && isAdmin))
            .map((menu) => (
              <Link onClick={(ev) => handleClick(ev, menu.soon)} key={menu.uri} to={menu.uri} state={null}>
                <ListItem
                  key={menu.uri}
                  disablePadding
                  secondaryAction={
                    menu.soon && (
                      <Chip
                        size="small"
                        sx={{ borderRadius: '4px', fontSize: '11px', fontWeight: 'bold', color: 'text.secondary' }}
                        label="SOON"
                      />
                    )
                  }>
                  <ListItemButton
                    disabled={menu.soon}
                    selected={menu.uri === location.pathname}
                    sx={{ margin: '0 8px 0 8px', borderRadius: '6px', pl: 4 }}>
                    <ListItemIcon sx={{ minWidth: '30px' }}>
                      <SvgIcon component={menu.icon} color={'primary'} />
                    </ListItemIcon>
                    <ListItemText primary={menu.name} />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
        </List>
      </Collapse>
    </>
  );
};

const MenuItems = ({ handleClick, roles = [], titles }) => {
  const [open, setOpen] = useState(location.pathname);
  const isAdmin = roles.includes('admin') || roles.includes('full-admin');
  const navigate = useNavigate();

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.menu',
        overflow: 'auto',
        maxHeight: 'calc(100% - 64px)',
      }}
      component="nav"
      aria-labelledby="nested-list-subheader">
      {MENU_ITEMS.map((item) => {
        if (item.name === 'Organisation') {
          item.subMenus = item.subMenus.filter((menu) => menu?.name !== 'Projects');

          const projectsName = titles?.projects || 'Projects';

          const existsInSubMenus = item.subMenus.some((menu) => menu?.name === projectsName);

          if (!existsInSubMenus) {
            // Find the index of OKRs
            const okrsIndex = item.subMenus.findIndex((menu) => menu.name === 'OKRs');

            // Create the Projects menu item with correct navigation
            const projectsMenuItem = {
              name: projectsName,
              uri: '/organisation/projects',
              icon: WorkOutlineIcon,
              onClick: () => navigate('/organisation/projects'),
            };

            // Insert Projects before OKRs
            if (okrsIndex !== -1) {
              item.subMenus.splice(okrsIndex, 0, projectsMenuItem);
            } else {
              // Fallback: just push to the end if OKRs not found
              item.subMenus.push(projectsMenuItem);
            }
          }
        }

        return item.needAdmin && !isAdmin ? (
          <></>
        ) : (
          <Page
            key={item.name}
            open={open.startsWith(item.uri) || (item.uri === '/delivery' && open === '/')}
            setOpen={setOpen}
            {...item}
            isAdmin={isAdmin}
          />
        );
      })}
    </List>
  );
};

export default MenuItems;
