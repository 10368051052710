import React, { useState, useEffect } from 'react';

import { Input, Checkbox, Text } from '@mantine/core';
import SearchIcon from '@mui/icons-material/Search';

import { SyncFeaturesContainer } from './styles';
import { color } from '../../../shared/styles/color';
import { SearchItemBox } from '../../Spotlight/style';

export const SyncFeatures = ({ name, items, onSelectionChange, selected }) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);

  // Sync `selectedItems` with `selected` prop
  useEffect(() => {
    setSelectedItems(selected || []);
  }, [selected]);

  // Filter the items based on the search input
  const filteredItems = items.filter((item) => item?.name?.toLowerCase().includes(searchValue.toLowerCase()));

  // Handle checkbox selection
  const handleCheckboxChange = (id) => {
    const updatedSelectedItems = selectedItems.includes(id)
      ? selectedItems.filter((itemId) => itemId !== id)
      : [...selectedItems, id];

    setSelectedItems(updatedSelectedItems);

    onSelectionChange(updatedSelectedItems);
  };

  return (
    <SyncFeaturesContainer>
      <Input
        radius="md"
        placeholder={`Search ${name}`}
        leftSection={<SearchIcon size={16} />}
        onChange={(event) => setSearchValue(event.currentTarget.value)}
        style={{ marginBottom: '10px', width: '100%' }}
      />

      <Text size="sm" mb={10} c={color.secondary}>
        {name}: {filteredItems.length}
      </Text>
      <div style={{ height: '230px', overflow: 'scroll' }}>
        {filteredItems.map((item) => (
          <label key={item.id} style={{ display: 'block', cursor: 'pointer' }}>
            <SearchItemBox>
              <Checkbox checked={selectedItems.includes(item.id)} onChange={() => handleCheckboxChange(item.id)} />
              <span style={{ marginLeft: '8px' }}>
                {item.name?.length > 45 ? `${item.name?.slice(0, 45)}...` : item.name}
              </span>
            </SearchItemBox>
          </label>
        ))}
      </div>
    </SyncFeaturesContainer>
  );
};
