import React, { forwardRef, useCallback, useImperativeHandle } from 'react';

import { useQuery } from '@apollo/client';
import { useTheme } from '@emotion/react';
import Grow from '@mui/material/Grow';
import makeStyles from '@mui/styles/makeStyles';
import { exportCSVData, getFieldValue } from 'utils/export';

import { headCells } from './constants';
import { GET_OBJECTIVES_FOR_PI } from './graphql';
import { getRowsData } from './helper';
import { styles } from './styles';
import EntityTable from '../../../../components/Common/EntityTable';
import { openDrawer } from '../../../../reactiveVariables';
import { getMappedValues } from '../../../../utils/tableUtils';
import { GET_TEAMS } from '../../../Settings/GraphQL/teams';
import { GET_PROGRAM_INCREMENTS } from '../../../Settings/ProgramIncrement/graphql';

const useStyles = makeStyles(styles);

const Objectives = (props, ref) => {
  const { searchQuery } = props;
  const classes = useStyles();
  const theme = useTheme();
  const searchQueryLowerCase = searchQuery.toLowerCase().trim();

  const { data: { programIncrements = [] } = {} } = useQuery(GET_PROGRAM_INCREMENTS);
  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);
  const { data: { objectives = [] } = {} } = useQuery(GET_OBJECTIVES_FOR_PI);

  useImperativeHandle(ref, () => ({
    export: () => exportObjectives(),
  }));

  const exportObjectives = useCallback(() => {
    const data = objectives
      .map((objective) => {
        const teamNames = getMappedValues(teams)[objective.teamId];

        const sprintValue =
          (
            Array.isArray(programIncrements) &&
            programIncrements.length &&
            programIncrements
              .filter((pi) => pi.id === objective.programIncrement)
              .map((pi) => pi?.sprints.find((item) => item.id === objective.sprintId))
              .find((item) => item?.name)
          )?.name || '';

        return {
          ...objective,
          team: teamNames,
          sprint: sprintValue,
        };
      })
      .map((rowData) => headCells.map((columnDef) => getFieldValue(rowData, columnDef)));

    exportCSVData(headCells, data, 'Objectives');
  }, [objectives]);

  const onRowClick = (event, row) => {
    const objective = objectives.find((objective) => objective?.id === row.id);
    openDrawer(objective, 'objective');
  };

  return (
    <Grow in={true}>
      <div className={classes.root}>
        <EntityTable
          headCells={headCells}
          rows={getRowsData(objectives, teams, programIncrements, theme)}
          searchQuery={searchQueryLowerCase}
          expand={false}
          handleRowClick={onRowClick}
        />
      </div>
    </Grow>
  );
};

export default forwardRef(Objectives);
