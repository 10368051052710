import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { useTheme } from '@emotion/react';
import { Divider, Grid, Input, Tabs, Text, Textarea, Tooltip } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import CloseIcon from '@mui/icons-material/Close';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Typography, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { GroupMenu } from './GroupMenu';
import { InitiativeOrFeature } from '../InitiativeOrFeatureCreate';
import { DetailsSidebar } from './DetailsSidebar';
import { getRagStatusColor } from './helper';
import { LinksArea } from './LinksArea';
import { RagStatus } from './RagStatus';
import { Sidebar } from './Sidebar';
import { CircleIcon, Content, ContentInner, Header, MainSection, SearchSection, SpotlightContainer } from './style';
import { WhatTab } from './Tabs/whatTab';
import { WhenTab } from './Tabs/whenTab';
import { WhoTab } from './Tabs/whoTab';
import { WhyTab } from './Tabs/whyTab';
import { WorkTab } from './Tabs/workTab';
import { Uploader } from './Uploader';
import SvgSidebar from '../../assets/images/Sidebar';
import ConfirmationDialog from '../../components/Common/ConfirmationDialog';
import { Field, renderInput } from '../../components/Common/FormFieldsHooks/MantineField';
import { SyncFeatures } from '../../components/Common/SyncFeatures';
import ModalContainer from '../../components/Modal';
import { GET_PORTFOLIO_OBJECTIVES } from '../../containers/Organisation/OKRs/graphql';
import { color, lightColors } from '../../shared/styles/color';

export const Spotlight = ({
  onClose,
  title,
  selectedItem,
  allItems,
  saveSpotlight,
  user,
  handleLinkDelete,
  handleSpotlightDelete,
  type,
  initiatives,
  features,
  updateInitiatives,
  updateFeatures,
  owner,
  teams,
  activeValueStreams,
  usersList,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [displaySearchSection, setDisplaySearchSection] = useState(true);

  const [showSummary, setShowSummary] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [showTitleInput, setShowTitleInput] = useState(false);
  const [showNewLinksModal, setShowNewLinksModal] = useState(false);
  const [showAddInitiativeModal, setShowAddInitiativeModal] = useState(false);
  const [showAddFeaturesModal, setShowAddFeaturesModal] = useState(false);
  const [showAddValueStreamsModal, setShowAddValueStreamsModal] = useState(false);

  const [selectedInitiatives, setSelectedInitiatives] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedValueStreams, setSelectedValueStreams] = useState(selectedItem?.value_streams || []);

  const [ragAnchorEl, setRagAnchorEl] = useState(null);
  const openRag = Boolean(ragAnchorEl);

  const [formState, setFormState] = useState({
    url: '',
    title: '',
    id: '',
  });

  const [displayAddModal, setDisplayAddModal] = useState(false);
  const [displayAddModalType, setDisplayAddModalType] = useState('');

  const [summary, setSummary] = useState(selectedItem?.summary || '');
  const [description, setDescription] = useState(selectedItem?.description || '');
  const [ragStatus, setRagStatus] = useState(selectedItem?.rag_status || '');
  const [spotlightTitle, setSpotlightTitle] = useState(selectedItem?.name || '');
  const [spotlightLinks, setSpotlightLinks] = useState(selectedItem?.links || []);
  const [spotlightAttachments, setSpotlightAttachments] = useState(selectedItem?.attachments || []);
  const [spotlightOwners, setSpotlightOwners] = useState(selectedItem?.members || []);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [typeToDelete, setTypeToDelete] = useState({
    id: '',
    type: '',
  });

  // Add query to get OKRs
  const { data: { portfolioObjectives = [] } = {} } = useQuery(GET_PORTFOLIO_OBJECTIVES);

  const filteredPortfolioObjects = selectedItem?.id
    ? portfolioObjectives.filter((portfolio) => {
        return portfolio?.strategic_theme?.some((themeId) => themeId === selectedItem.id.toString());
      })
    : [];

  useEffect(() => {
    setSummary(selectedItem?.summary || '');
    setDescription(selectedItem?.description || '');
    setRagStatus(selectedItem?.rag_status || '');
    setSpotlightTitle(selectedItem?.name || '');
    setSpotlightLinks(selectedItem?.links || []);
    setSpotlightAttachments(selectedItem?.attachments || []);
    setSpotlightOwners(selectedItem?.members || []);
  }, [selectedItem]);

  const handleSelectedInitiativesChange = (newSelectedItems) => {
    setSelectedInitiatives(newSelectedItems);
  };

  const handleSelectedFeaturesChange = (newSelectedItems) => {
    setSelectedFeatures(newSelectedItems);
  };

  useEffect(() => {
    if (selectedItem) {
      // Handle initiatives
      if (initiatives) {
        const selectedInitiativeIds = initiatives
          .filter((init) => init.strategic_theme === selectedItem.id)
          .map((init) => init.id);
        setSelectedInitiatives(selectedInitiativeIds);
      }

      // Handle features
      if (features) {
        const selectedFeatureIds = features.filter((feat) => feat.project === selectedItem.id).map((feat) => feat.id);
        setSelectedFeatures(selectedFeatureIds);
      }
    }
  }, [selectedItem, initiatives, features]);

  const handleSave = async (changes) => {
    try {
      await saveSpotlight(changes);
    } catch (error) {
      console.error('Failed to save changes:', error);
      // Reset UI state on error
      if (changes.name) setSpotlightTitle(selectedItem?.name || '');
      if (changes.rag_status) setRagStatus(selectedItem?.rag_status || '');
      if (changes.summary) setSummary(selectedItem?.summary || '');
      if (changes.description) setDescription(selectedItem?.description || '');
      // Show error to user
      showNotification({
        title: 'Error',
        message: 'Failed to save changes. Please try again.',
        color: 'red',
      });
    }
  };

  // Replace direct saveSpotlight calls with handleSave
  const onBlurTitle = () => {
    handleSave({ name: spotlightTitle });
    setShowTitleInput(false);
  };

  const onRagStatusChange = (color) => {
    setRagStatus(color);
    handleSave({ rag_status: color });
    handleRagMenuClose();
  };

  const onSummaryBlur = () => {
    handleSave({ summary });
    setShowSummary(false);
  };

  const onDescriptionBlur = () => {
    handleSave({ description });
    setShowDescription(false);
  };

  const handleFileChange = async (event) => {
    Uploader(event, type, onUpload);
  };

  const onUpload = (key) => {
    setSpotlightAttachments([...spotlightAttachments, key]);
    handleSave({ attachments: [...spotlightAttachments, key] });
  };

  const onMembersUpdate = (members) => {
    handleSave({ members });
  };

  const onOwnerUpdate = (owner) => {
    handleSave({ owner });
  };

  const onPriorityUpdate = (priority) => {
    handleSave({ priority });
  };

  const onTargetDateChange = (date, type) => {
    handleSave({ [type]: date });
  };

  const onAttachmentDelete = async (key) => {
    const updatedAttachments = spotlightAttachments.filter((_, index) => index !== key);

    setSpotlightAttachments(updatedAttachments);
    handleSave({ attachments: updatedAttachments });
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);

    const syntheticEvent = {
      target: { files: droppedFiles },
    };

    handleFileChange(syntheticEvent);
  };

  const onNewLinksSubmit = () => {
    const { url, title, id } = formState;

    // Custom validation for required fields
    if (!url || !title) {
      setFormErrors({
        url: !url,
        title: !title,
      });
      return;
    }

    if (id) {
      // Update existing link
      const updatedLinks = spotlightLinks.map((link) => (link.id === formState.id ? { ...link, ...formState } : link));
      setSpotlightLinks(updatedLinks);

      handleSave({ links: updatedLinks });
    } else {
      // Add new link
      const newLink = { ...formState, id: uuidv4() };
      setSpotlightLinks([...spotlightLinks, newLink]);
      handleSave({ links: [...spotlightLinks, newLink] });
    }

    // Reset form state after submission
    setFormState({ url: '', title: '', id: '' });
    setFormErrors({ url: false, title: false });
    setShowNewLinksModal(false);
  };

  const handleAddSelection = (option) => {
    if (option === 'Existing Initiatives') {
      setShowAddInitiativeModal(true);
    } else if (option === 'Existing Features') {
      setShowAddFeaturesModal(true);
    } else {
      setDisplayAddModalType(option);
      setDisplayAddModal(true);
    }
  };

  const handleRagMenuClose = () => {
    setRagAnchorEl(null);
  };

  const handleRagSelection = (color) => {
    setRagStatus(color);
    handleSave({ rag_status: color });
    handleRagMenuClose();
  };

  const handleLinkEdit = (link) => {
    setFormState(link);

    setShowNewLinksModal(true);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteConfirm = () => {
    if (typeToDelete.type === 'link') {
      handleLinkDelete(typeToDelete.id);
    } else {
      handleSpotlightDelete(selectedItem);
    }

    setOpenConfirmation(false);
  };

  const handleDelete = (type, id) => {
    setTypeToDelete({
      type,
      id,
    });
    setOpenConfirmation(true);
  };

  const featuresWithInitiatives = features.filter((feat) => !feat.initiativeId);

  return (
    <SpotlightContainer>
      <ModalContainer
        open={showNewLinksModal}
        title="Add link to project"
        description="Secondary information"
        primaryButtonText="Add link"
        onSubmit={onNewLinksSubmit}
        close={() => setShowNewLinksModal(false)}>
        <Grid>
          <Field
            name="url"
            data-autofocus
            value={formState.url}
            onChange={(e) => setFormState({ ...formState, url: e.target.value })}
            renderField={renderInput}
            placeholder="https://..."
            label="URL"
          />
          <Field
            name="title"
            renderField={renderInput}
            label="Title"
            value={formState.title}
            onChange={(e) => setFormState({ ...formState, title: e.target.value })}
          />
        </Grid>
      </ModalContainer>

      <ModalContainer
        open={showAddInitiativeModal}
        title={'Add Initiatives'}
        primaryButtonText={'Add Initiatives'}
        onSubmit={() => {
          updateInitiatives(selectedInitiatives);
          setShowAddInitiativeModal(false);
        }}
        width="420px"
        close={() => {
          setShowAddInitiativeModal(false);
        }}>
        <SyncFeatures
          name={'Initatives'}
          items={initiatives}
          onSelectionChange={handleSelectedInitiativesChange}
          selected={selectedInitiatives}
        />
      </ModalContainer>
      <ModalContainer
        open={showAddFeaturesModal}
        title={'Add Features'}
        primaryButtonText={'Add Features'}
        onSubmit={() => {
          updateFeatures(selectedFeatures);
          setShowAddFeaturesModal(false);
        }}
        width="420px"
        close={() => {
          setShowAddFeaturesModal(false);
        }}>
        <SyncFeatures
          name={'Features'}
          items={featuresWithInitiatives}
          onSelectionChange={handleSelectedFeaturesChange}
          selected={selectedFeatures}
        />
      </ModalContainer>
      <ModalContainer
        open={showAddValueStreamsModal}
        title={'Add Value Streams'}
        primaryButtonText={'Add Value Streams'}
        onSubmit={() => {
          handleSave({ value_streams: selectedValueStreams });
          setShowAddValueStreamsModal(false);
        }}
        width="420px"
        close={() => {
          setShowAddValueStreamsModal(false);
        }}>
        <SyncFeatures
          name={'Value Streams'}
          items={activeValueStreams}
          onSelectionChange={(streams) => setSelectedValueStreams(streams)}
          selected={selectedValueStreams}
        />
      </ModalContainer>
      <ConfirmationDialog
        open={openConfirmation}
        title={`Delete ${typeToDelete.type}`}
        text={`Are you sure you want to delete this ${typeToDelete.type}?`}
        handleOk={handleDeleteConfirm}
        handleCancel={() => setOpenConfirmation(false)}
      />

      <InitiativeOrFeature
        user={user}
        open={displayAddModal}
        type={displayAddModalType}
        close={() => setDisplayAddModal(false)}
        selectedItemId={selectedItem.id}
      />

      {displaySearchSection && (
        <SearchSection>
          <Header>
            <Text display="flex" style={{ alignItems: 'center', marginLeft: '20px' }}>
              {title}
            </Text>

            <Tooltip
              withArrow
              transitionProps={{ transition: 'pop', duration: 200 }}
              label="Close search"
              zIndex={1100}>
              <div style={{ position: 'absolute', right: '10px', top: '12px', cursor: 'pointer' }}>
                <SvgSidebar
                  style={{ position: 'relative', fill: theme.palette.mode === 'dark' ? 'white' : '#242424' }}
                  onClick={() => setDisplaySearchSection(false)}
                />
              </div>
            </Tooltip>
          </Header>
          <Sidebar title={title} allItems={allItems} selectedItem={selectedItem} />
        </SearchSection>
      )}
      <MainSection displaySearchSection={displaySearchSection}>
        <Header>
          {!displaySearchSection && (
            <Tooltip withArrow transitionProps={{ transition: 'pop', duration: 200 }} label="Open search" zIndex={1100}>
              <div style={{ position: 'absolute', left: '10px', top: '12px', cursor: 'pointer' }}>
                <SvgSidebar style={{ position: 'relative' }} onClick={() => setDisplaySearchSection(true)} />
              </div>
            </Tooltip>
          )}

          <MoreHorizIcon
            style={{ cursor: 'pointer', alignSelf: 'center', marginLeft: displaySearchSection ? '10px' : '40px' }}
            onClick={(event) => handleMenuOpen(event)}
          />

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            slotProps={{
              paper: {
                sx: {
                  width: '200px',
                  borderRadius: '15px',
                },
              },
            }}>
            <MenuItem
              onClick={() => {
                handleMenuClose();
                onClose();
                navigate('/organisation/projects');
              }}>
              New Project
            </MenuItem>
            <Divider />
            <MenuItem
              style={{ color: color.red }}
              onClick={() => {
                handleMenuClose();
                handleDelete('project');
                setOpenConfirmation(true);
              }}>
              Delete
            </MenuItem>
          </Menu>

          <CloseIcon
            style={{
              border: `1px solid ${lightColors.tileBorder}`,
              borderRadius: '8px',
              position: 'absolute',
              right: '12px',
              top: '14px',
              cursor: 'pointer',
            }}
            onClick={onClose}
          />
        </Header>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'stretch',
          }}>
          <Content style={{ height: '100%', minHeight: 'calc(100vh - 74px)', borderRight: '1px solid #E8E8E8' }}>
            <ContentInner>
              {selectedItem?.id && <Text>{selectedItem.id}</Text>}

              <div style={{ display: 'flex', marginBottom: !showTitleInput ? '20px' : '34px' }}>
                <span
                  onClick={(e) => setRagAnchorEl(e.currentTarget)}
                  style={{
                    cursor: 'pointer',
                    marginRight: '10px',
                    position: 'relative',
                    top: '28px',
                  }}>
                  <CircleIcon color={getRagStatusColor(ragStatus)} />
                </span>
                {!showTitleInput && (
                  <Typography
                    onClick={() => setShowTitleInput(true)}
                    style={{
                      fontSize: '20px',
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '20px',
                    }}>
                    {spotlightTitle || 'Spotlight'}
                  </Typography>
                )}
                {showTitleInput && (
                  <Input
                    radius="md"
                    onChange={(event) => setSpotlightTitle(event.currentTarget.value)}
                    onBlur={onBlurTitle}
                    style={{ position: 'relative', top: '18px' }}
                    value={spotlightTitle}
                    autoFocus
                  />
                )}
              </div>

              <RagStatus
                handleRagSelection={handleRagSelection}
                open={Boolean(openRag)}
                anchorEl={ragAnchorEl}
                handleRagMenuClose={handleRagMenuClose}
              />

              {!showSummary ? (
                <Text mt={16} style={{ cursor: 'pointer' }} onClick={() => setShowSummary(true)}>
                  {summary ? summary : 'Add a summary'}
                </Text>
              ) : (
                <Textarea
                  mt={16}
                  autoFocus
                  radius="md"
                  value={summary}
                  onChange={(event) => setSummary(event.currentTarget.value)}
                  placeholder="Add a summary"
                  minRows={2}
                  autosize
                  onBlur={onSummaryBlur}
                />
              )}

              <GroupMenu
                setShowNewLinksModal={() => {
                  setFormState({
                    url: '',
                    title: '',
                    id: '',
                  });
                  setShowNewLinksModal(true);
                }}
                handleAddSelection={(type) => handleAddSelection(type)}
                onPriorityUpdate={onPriorityUpdate}
                priority={selectedItem.priority}
              />
            </ContentInner>

            <Tabs color="rgba(0, 0, 0, 1)" defaultValue="what">
              <Tabs.List>
                <Tabs.Tab value="what">What</Tabs.Tab>
                <Tabs.Tab value="why">Why</Tabs.Tab>
                <Tabs.Tab value="when">When</Tabs.Tab>
                <Tabs.Tab value="who">Who</Tabs.Tab>
                <Text
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  •
                </Text>
                <Tabs.Tab value="work">Work</Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="what">
                <div>
                  <ContentInner>
                    <Text style={{ fontSize: '12px', fontWeight: 550 }}>Description</Text>

                    {!showDescription ? (
                      <Text mt={24} style={{ cursor: 'pointer' }} onClick={() => setShowDescription(true)}>
                        {description ? description : 'Add detailed description...'}
                      </Text>
                    ) : (
                      <Textarea
                        autoFocus
                        mt={24}
                        radius="md"
                        value={description}
                        placeholder="Add detailed description"
                        onChange={(event) => setDescription(event.currentTarget.value)}
                        minRows={7}
                        autosize
                        onBlur={onDescriptionBlur}
                      />
                    )}
                  </ContentInner>
                  <Divider mt={20} mb={20} />

                  <LinksArea
                    setShowNewLinksModal={setShowNewLinksModal}
                    links={spotlightLinks}
                    handleLinkEdit={handleLinkEdit}
                    handleLinkDelete={(id) => {
                      handleDelete('link', id);
                    }}
                  />

                  <Divider mt={20} mb={20} />

                  <WhatTab
                    handleFileChange={handleFileChange}
                    handleDrop={handleDrop}
                    spotlightAttachments={spotlightAttachments}
                    type={type}
                    onAttachmentDelete={onAttachmentDelete}
                  />
                </div>
              </Tabs.Panel>

              <Tabs.Panel value="why">
                <WhyTab okrs={filteredPortfolioObjects} selectedItem={selectedItem} />
              </Tabs.Panel>

              <Tabs.Panel value="when">
                <WhenTab
                  initiatives={initiatives}
                  selectedItem={selectedItem}
                  onTargetDateChange={onTargetDateChange}
                  usersList={usersList}
                />
              </Tabs.Panel>

              <Tabs.Panel value="who" style={{ paddingBottom: '20px' }}>
                <WhoTab
                  setSpotlightOwners={setSpotlightOwners}
                  spotlightOwners={spotlightOwners}
                  onMembersUpdate={onMembersUpdate}
                  onOwnerUpdate={onOwnerUpdate}
                  selectedItem={selectedItem}
                  showAddValueStreamsModal={setShowAddValueStreamsModal}
                  activeValueStreams={activeValueStreams}
                />
              </Tabs.Panel>

              <Tabs.Panel value="work">
                <WorkTab
                  initiatives={initiatives}
                  features={features}
                  selectedItem={selectedItem}
                  showAddInitiativeModal={setShowAddInitiativeModal}
                  showAddFeaturesModal={setShowAddFeaturesModal}
                />
              </Tabs.Panel>
            </Tabs>
          </Content>
          <DetailsSidebar selectedItem={selectedItem} owner={owner} teams={teams} />
        </div>
      </MainSection>
    </SpotlightContainer>
  );
};
