import styled from '@emotion/styled';
import { Paper } from '@mantine/core';
import { alpha } from '@mui/material/styles';
import { darken, lighten } from '@mui/system/colorManipulator';

import { color } from '../../../shared/styles/color';

export const Card = styled(Paper)(({ theme, ...props }) => ({
  position: 'relative',
  padding: '12px 12px 8px 22px',
  backgroundColor: theme.palette.color.paper,
  cursor: 'pointer',
  border: '0.5px solid ' + theme.palette.color.cardBorder,
  borderBottom: '0px',
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    width: '4px',
    top: '4px',
    bottom: '4px',
    left: '4px',
    backgroundColor: props.borderColor,
  },
  ...(props.isOverlay && { cursor: 'grabbing' }),
}));

export const CardBottom = styled(Paper)(({ theme, ...props }) => ({
  position: 'relative',
  padding: '12px 12px 12px 22px',
  backgroundColor: theme.palette.color.paper,
  marginBottom: '8px',
  cursor: 'pointer',
  border: '0.5px solid ' + theme.palette.color.cardBorder,
  ...(props.isOverlay && { cursor: 'grabbing' }),
}));

export const CardContainer = styled(Paper)(({ theme, ...props }) => ({
  '&:hover': {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? lighten(theme.palette.color.paper, 0.05)
        : darken(theme.palette.color.paper, 0.05),
    '& > *': {
      backgroundColor:
        theme.palette.mode === 'dark'
          ? lighten(theme.palette.color.paper, 0.1)
          : darken(theme.palette.color.paper, 0.1),
    },
  },
  ...(props.isOverlay && { cursor: 'grabbing' }),
  boxShadow:
    props.isPortfolioState && !props.isPortfolioPage ? `0px 0px 7px -1px ${color.companyColor} !important` : '',
  marginBottom: '10px',
}));

export const styles = (theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'visible',
  },
  appBar: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: 20,
    paddingLeft: 0,
    minHeight: 50,
    fontSize: 20,
  },
  toolbarLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 8,
    marginBottom: 4,
  },
  container: {
    height: 'calc(100% - 70px)',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    // width: 'fit-content',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 25,
    paddingBottom: 20,
    height: '50px',
    // width: 'fit-content'
  },
  piName: {
    paddingRight: 8,
  },
  divider: {
    width: 1,
    height: 14,
    backgroundColor: theme.palette.text.primary,
  },
  piStarts: {
    paddingLeft: 8,
  },
  headerItem: {
    // flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    backgroundColor: 'blue',
    borderRadius: 5,
    verticalAlign: 'middle',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  headerItemLeft: {
    backgroundColor: theme.palette.color.border,
    minWidth: '840px',
    width: '45%',
  },
  headerItemRight: {
    display: 'flex',
    backgroundColor: (props) => props.backgroundColor,
    minWidth: '1100px',
    width: '60%',
  },
  typo: {
    color: theme.palette.text.primary,
    lineHeight: '1.375em',
    letterSpacing: 0,
  },
  card: {
    marginLeft: '5px',
    marginRight: '5px',
    marginBottom: '5px',
    borderLeft: (props) => `0.3em solid ${props.borderColor}`,

    '&.dragging': {
      cursor: 'grabbing',
    },
  },
  dragging: {
    cursor: 'grabbing',
  },
  cardContent: {
    padding: '10px !important',
  },
  flexSection: {
    height: 'calc(100% - 50px)',
    flex: 2,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  flexColScroll: {
    flex: '1 1 auto',
    marginTop: '10px',
    borderRadius: 4,
    overflowY: 'auto',
  },
  flexNoShrink: {
    flexShrink: 0,
  },
  column: {
    height: '100%',
    width: '13%',
    minWidth: '270px',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '8px',
    paddingLeft: 0,
  },
  columnTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeight.values.semiBold,
    color: theme.palette.text.primary,
  },
  speedDial: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
  timeContainer: {
    display: 'flex',
    marginTop: '10px',
  },
  timeText: {
    alignItems: 'left',
    alignSelf: 'center',
    color: '#979797',
  },
  line: {
    padding: '0px',
    width: '0px',
    height: '100%',
    borderRight: `4px solid ${theme.palette.color.border}`,
  },
  sortWrapper: {
    backgroundColor: alpha(theme.palette.color.butterscotch, 0.3),
    padding: '2px 8px',
    marginBottom: '4px',
    borderRadius: 100,
    width: 'fit-content',
  },
  missingInfo: {
    color: theme.palette.color.blueGrey,
    fontSize: 10,
  },
  dot: {
    height: '6px',
    width: '6px',
    backgroundColor: 'green',
    borderRadius: 50,
    display: 'inline-block',
    marginRight: '5px',
  },
});
