import React, { useMemo, useState } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import FeatureBoard from 'components/Dashboard/FeatureBoard';
import { isEmpty, get } from 'lodash';

import { openDrawer, selectedInitiativeVar, selectedPiVar, selectedProgrammeVar } from '../../../../reactiveVariables';
import { GET_FEATURES_FOR_PI, GET_FEATURES_FOR_PROGRAMME } from '../../../Product/Features/graphql';
import { GET_BACKLOGS } from '../../../Settings/GraphQL/backlogs';
import { GET_TEAMS } from '../../../Settings/GraphQL/teams';
import { GET_SELECTED_PI_FULL } from '../../../Settings/ProgramIncrement/graphql';
import { GET_MILESTONES } from '../../../ValueStream/Milestones/graphql';
import { GET_OBJECTIVES_FOR_PI } from '../Objectives/graphql';
import ToolBarSummary from '../shared/ToolBar';

function DetailedView() {
  const [backlog, setBacklog] = useState(0);
  const [isCurrentPlan, setIsCurrentPlan] = useState(true);
  const [selectedItem, setSelectedItem] = useState('features');
  const [selectedPlan, setSelectedPlan] = useState('currentPlan');
  const [progressBy, setProgressBy] = useState('storyCount');
  const [includes, setIncludes] = useState('increment');
  const [selectedMilestone, setSelectedMilestone] = useState(null);
  const [anchorMilestone, setAnchorMilestone] = useState(false);
  const selectedPI = useReactiveVar(selectedPiVar);
  const [showObjectives, setShowObjectives] = useState(false);
  const [isHoverActive, setIsHoverActive] = useState(false);

  const selectedProgramme = useReactiveVar(selectedProgrammeVar);
  const selectedInitiative = useReactiveVar(selectedInitiativeVar);

  const { data: { backlogs = [] } = {} } = useQuery(GET_BACKLOGS);
  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);
  const { data: { objectives = [] } = {} } = useQuery(GET_OBJECTIVES_FOR_PI);
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES_FOR_PI);
  const { data: { milestones = [] } = {} } = useQuery(GET_MILESTONES);
  const { data: { selectedPiFull = {} } = {} } = useQuery(GET_SELECTED_PI_FULL);
  const { data: { features: allFeatures = [] } = {} } = useQuery(GET_FEATURES_FOR_PROGRAMME);

  const pi = selectedPiFull || {};

  const filteredFeatures =
    backlog || selectedInitiative
      ? features.filter(
          (feature) =>
            (!backlog || feature.backlogId === backlog) &&
            (!selectedInitiative || feature.initiativeId === selectedInitiative),
        )
      : features;

  const deferredFeatures = useMemo(
    () =>
      allFeatures.filter(
        (feature) =>
          (!backlog || feature.backlogId === backlog) &&
          !!feature.completedIncrements?.[selectedPiFull?.id] &&
          (!selectedInitiative || feature.initiativeId === selectedInitiative),
      ),
    [allFeatures, backlog, selectedPiFull, selectedInitiative],
  );

  const handleBacklog = (event, value) => setBacklog(value);

  const handleSwitch = (value) => {
    setSelectedItem(value);
    setShowObjectives(value === 'objectives');
  };

  const handlePlanSwitch = (value, isDisabled) => {
    if (!isDisabled) {
      setSelectedPlan(value);
      setIsCurrentPlan(value === 'currentPlan');
    }
  };

  const handleProgressSwitch = (value) => {
    setProgressBy(value);
  };

  const selectMilestone = (milestoneId) => {
    if (!anchorMilestone) {
      setSelectedMilestone(milestoneId);
      setIsHoverActive(true);
    }
  };

  const unSelectMilestone = () => {
    if (!anchorMilestone) {
      setSelectedMilestone(null);
      setIsHoverActive(false);
    }
  };

  const handleMilestoneClick = (milestoneId) => {
    setAnchorMilestone(!(anchorMilestone && milestoneId === selectedMilestone));
    setSelectedMilestone(milestoneId);
  };

  const createMilestone = () => openDrawer(null, 'milestone');

  const filterEmptyTeams = (team) => {
    if (selectedProgramme && !team.programmes?.includes(selectedProgramme)) return false;
    if (pi.status === 'planning') return true;

    const teamField = pi.status !== 'planning' ? (isCurrentPlan ? 'teamId' : 'originalState.teamId') : 'teamId';

    const teamFeatures = filteredFeatures.filter(
      (feature) => get(feature, teamField, null) === team.id && feature.programIncrement === pi.id,
    );

    const teamObjectives = objectives.filter((objective) => objective.teamId === team.id);

    return !(isEmpty(teamFeatures) && isEmpty(teamObjectives));
  };

  return (
    <>
      <ToolBarSummary
        title="Detailed View"
        backlog={backlog}
        backlogs={backlogs}
        features={features}
        selectedPI={selectedPI}
        isShownAllDependencies={false}
        showAllDependencies={false}
        selectedItem={selectedItem}
        selectedPlan={selectedPlan}
        progressBy={progressBy}
        includes={includes}
        handleIncludesSwitch={setIncludes}
        handleSwitch={handleSwitch}
        handlePlanSwitch={handlePlanSwitch}
        handleProgressSwitch={handleProgressSwitch}
        onBacklogChange={handleBacklog}
        createMilestone={createMilestone}
      />
      <FeatureBoard
        milestones={milestones}
        selectedMilestone={selectedMilestone}
        showCurrentState={isCurrentPlan}
        pi={pi}
        team={0}
        features={filteredFeatures}
        objectives={objectives}
        showObjectives={showObjectives}
        includes={includes}
        progressBy={progressBy}
        teams={teams.filter((team) => filterEmptyTeams(team))}
        selectedSprints={[]}
        handleMilestoneClick={handleMilestoneClick}
        handleMouseEnter={selectMilestone}
        handleMouseLeave={unSelectMilestone}
        isHoverActive={isHoverActive}
        deferredFeatures={deferredFeatures}
      />
    </>
  );
}

export default DetailedView;
