import React from 'react';

import { useTheme } from '@emotion/react';
import { Text } from '@mantine/core';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import SvgFeatures from '../../../../../assets/images/Features';
import { CardButton, DisplayID, IDWrapper } from '../../../../../components/Common/Elements';

const ThirdStep = ({ removeSelectedFeature, selectedFeatures = [], features }) => {
  const theme = useTheme();

  return (
    <div style={{ marginTop: '10px', overflow: 'auto', height: '500px' }}>
      {features
        .filter((feature) => selectedFeatures.includes(feature.id))
        .map((feature) => (
          <CardButton key={feature.id}>
            <IDWrapper style={{ backgroundColor: theme.palette.color.row }}>
              <SvgFeatures color={theme.palette.color.buttonIcon} />
              <DisplayID span={true} size="xs">
                {feature.id}
              </DisplayID>
            </IDWrapper>
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
                height: '100%',
                alignItems: 'center',
              }}>
              <div style={{ padding: '10px' }}>
                <Text lineClamp={1} fw={450} size="sm">
                  {feature.name}
                </Text>
              </div>
              <IconButton sx={{ padding: '8px' }} onClick={() => removeSelectedFeature(feature.id)}>
                <Close />
              </IconButton>
            </div>
          </CardButton>
        ))}
    </div>
  );
};

export default ThirdStep;
