import React, { useEffect, useRef, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import CustomBarComponent from './CustomBar';
import { nivoTheme } from '../../../../../ValueStream/components/Common';

const PlannedVsDelivered = ({ programIncrements, features }) => {
  const rectRef = useRef(null);
  const keys = ['planned', 'completed'];
  const commonProps = {
    margin: { top: 50, right: 40, bottom: 50, left: 60 },
    indexBy: 'pi',
    keys,
    padding: 0.03,
    innerPadding: 4,
  };

  const data = programIncrements.map((pi) => {
    function hasStoriesInPI(feature) {
      return !!feature.metrics?.metricsPerIncrement?.[pi?.id];
    }

    const piFeatures = features.filter((feature) => feature.programIncrement === pi.id || hasStoriesInPI(feature));
    const completedFeatures = piFeatures.filter((feature) => feature.status === 'Done').length;
    const enteredFeatures = piFeatures.filter((feature) => !feature.originalState).length;

    return {
      pi: pi.name,
      planned: piFeatures.length,
      completed: completedFeatures,
      detailedData: {
        planned: {
          total: piFeatures.length - enteredFeatures,
          entered: enteredFeatures,
        },
        completed: {
          total: completedFeatures,
        },
      },
    };
  });

  return (
    <ResponsiveBar
      {...commonProps}
      ref={rectRef}
      data={data}
      theme={nivoTheme()}
      barComponent={CustomBarComponent}
      groupMode="grouped"
      axisLeft={{
        tickSize: 0,
        tickValues: 5,
        tickPadding: 12,
        legend: 'Features',
        legendPosition: 'middle',
        legendOffset: -50,
      }}
      axisBottom={{
        tickSize: 0,
        tickPadding: 12,
        legend: 'Increments',
        legendPosition: 'middle',
        legendOffset: 40,
      }}
      indexScale={{ type: 'band', round: false }}
      isInteractive={true}
      animate={true}
      enableGridY={false}
      legends={[
        {
          anchor: 'top',
          direction: 'row',
          justify: false,
          translateX: -100,
          translateY: -50,
          itemsSpacing: 50,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: '#999',
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 8,
          symbolShape: 'circle',
          data: [
            { id: 'planned', label: 'Planned', color: '#d2d2dc' },
            { id: 'entered', label: 'Entered during Increment', color: '#edaa47' },
            { id: 'completed', label: 'Completed during Increment', color: '#4f74cc' },
          ],
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
              },
            },
          ],
        },
      ]}
    />
  );
};

export default PlannedVsDelivered;
//
