import { makeVar } from '@apollo/client';
import { notifications } from '@mantine/notifications';

export const selectedPiVar = makeVar(
  window.localStorage.getItem('selectedPI') && parseInt(window.localStorage.getItem('selectedPI')),
);
export const selectedValueStreamVar = makeVar(
  window.localStorage.getItem('selectedValueStream') && parseInt(window.localStorage.getItem('selectedValueStream')),
);
export const selectedProgrammeVar = makeVar(
  window.localStorage.getItem('selectedProgramme') && parseInt(window.localStorage.getItem('selectedProgramme')),
);
export const selectedTeamVar = makeVar([]);
export const selectedOwnerVar = makeVar([]);
export const selectedUsersVar = makeVar([]);
export const selectedThinkTankValueStreamVar = makeVar([]);
export const selectedSubmitterVar = makeVar([]);
export const selectedInitiativeVar = makeVar(null);

export const selectedProductThemeVar = makeVar(null);
export const selectedEnablerVar = makeVar(null);
export const stickyHeaderVar = makeVar(false);

export const accessTokenVar = makeVar(window.localStorage.getItem('accessToken'));

export const snackbarContent = makeVar({});
export const mainRef = makeVar({});
export const loggedInUserVar = makeVar({});
export const drawerLoadingVar = makeVar(false);
export const globalLoadingVar = makeVar(false);

export const drawerDetailsVar = makeVar({
  open: false,
  element: {},
  category: '',
  additionalData: {},
});

export const selectedProjectVar = makeVar(window.localStorage.getItem('selectedProject') || null);

export const saveUserInfo = (tokenParsed) =>
  loggedInUserVar({
    firstName: tokenParsed.given_name,
    lastName: tokenParsed.family_name,
    id: tokenParsed.sub,
    roles: tokenParsed.resource_access?.['big-agile']?.roles,
  });

export const openSnackbar = (element, category) => {
  window.Intercom('update', { hide_default_launcher: true });
  snackbarContent({ open: true, element, category });
};
export const closeSnackbar = () => {
  window.Intercom('update', { hide_default_launcher: false });
  snackbarContent({ ...snackbarContent(), open: false });
};

export const openDrawer = (element, category, additionalData) => {
  notifications.clean();
  drawerDetailsVar({ open: true, element, category, additionalData });
};

export const resetDrawerDetails = () =>
  drawerDetailsVar({
    open: false,
    element: {},
    category: '',
    additionalData: {},
  });
