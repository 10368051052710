import React from 'react';

import { Typography } from '@mui/material';

import { getFeatureBackgroundColor } from '../../../../utils/piColors';
import { getMappedValues } from '../../../../utils/tableUtils';
import { Display2 } from '../../../ValueStream/InitiativesDashboard/InitiativeDashboard.style';

function createData(item, teams, programIncrements, theme) {
  const { id, name, teamId, neededBySprint = '', status, ragStatus, owner } = item;
  const teamName = getMappedValues(teams)[teamId];

  const sprintValue =
    programIncrements
      ?.find((pi) => pi.id === item.programIncrement)
      ?.sprints?.find((item) => item.id === neededBySprint)?.name || '';

  const teamIds = [];
  const joinedTeam = [];

  item.targetFeatures.forEach((feature) => {
    const teamId = feature.feature.teamId;

    if (!teamIds.includes(teamId)) {
      teamIds.push(teamId);
    }
  });

  teamIds.forEach((teamId) => {
    const foundTeam = teams.find((team) => team.id === teamId);
    if (foundTeam) {
      joinedTeam.push(foundTeam.name);
    }
  });

  const needed_by_team = joinedTeam.join(', ');

  return {
    id,
    name,
    team: teamName,
    neededBySprint: sprintValue,
    status,
    owner,
    needed_by_team,
    ragStatus: (
      <Display2
        data-value={ragStatus}
        color={getFeatureBackgroundColor({ ragStatus }, theme)}
        bgColor={getFeatureBackgroundColor({ ragStatus }, theme)}
        variant="light"
        style={{ width: '65px' }}>
        {ragStatus.toUpperCase()}
      </Display2>
    ),
  };
}

export function getRowsData(data, teams, backlogs, theme) {
  let rows = [];
  if (Array.isArray(data) && data.length) {
    rows = data.map((item) => createData(item, teams, backlogs, theme));
  }
  return rows;
}
