import React, { forwardRef, useState, useMemo } from 'react';

import {
  closestCenter,
  DndContext,
  DragOverlay,
  MeasuringStrategy,
  MouseSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, useSortable, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Grid, Paper, rem } from '@mantine/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Backdrop, Box, Collapse, CircularProgress, IconButton } from '@mui/material';
import TableSortLabel from '@mui/material/TableSortLabel';
import { IconGripVertical } from '@tabler/icons-react';
import { Flipped, Flipper } from 'react-flip-toolkit';

import { canScroll } from '../../../containers/Delivery/utils';
import { orderArrayBy } from '../../../utils/helpers';
import { FlexDiv } from '../Elements';

const Table = ({
  fields,
  searchQuery,
  rows,
  expandFunc,
  onRowClick,
  updateInitiativeRank,
  enableDragAndDrop = false,
  setPadding = true,
}) => {
  const [activeRow, setActiveRow] = useState(null);
  const [orderBy, setOrderBy] = useState({ name: 'rank', order: 'asc' });

  const sortedRows = useMemo(() => {
    const filteredRows = searchQuery
      ? rows.filter((row) =>
          Object.values(row).some((value) => value?.toString().toLowerCase().includes(searchQuery.toLowerCase())),
        )
      : rows;

    return orderArrayBy(filteredRows, orderBy.name, orderBy.order);
  }, [rows, searchQuery, orderBy]);

  const measuringConfig = {
    droppable: {
      strategy: MeasuringStrategy.Always,
    },
  };

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: { distance: 5 },
  });

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 5 },
  });

  const sensors = useSensors(pointerSensor, mouseSensor);

  const handleDragStart = (event) => {
    setActiveRow(event.active.data.current);
  };

  const handleDragEnd = (event) => {
    setActiveRow(null);

    if (orderBy.name === 'rank') {
      const { active, over } = event;
      if (active.id !== over.id) {
        const oldIndex = sortedRows.findIndex((item) => item.id === active.id);
        const newIndex = sortedRows.findIndex((item) => item.id === over.id);

        const updatedRankOrder = arrayMove(sortedRows, oldIndex, newIndex);
        updateInitiativeRank(updatedRankOrder);
      }
    }
  };

  return (
    <>
      <DndContext
        sensors={sensors}
        measuring={measuringConfig}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        autoScroll={{ canScroll }}>
        <SortableContext items={sortedRows.map((row) => row.id)} strategy={verticalListSortingStrategy}>
          <Grid columns={20} style={{ marginBottom: '12px' }}>
            {fields.map((field) => (
              <Grid.Col key={field.id} span={field.width || 'auto'}>
                {field.id === 'action' ? (
                  field.label
                ) : (
                  <TableSortLabel
                    active={orderBy.name === field.id}
                    direction={orderBy.name === field.id ? orderBy.order : 'asc'}
                    onClick={() =>
                      setOrderBy({
                        name: field.id,
                        order: orderBy.order === 'asc' ? 'desc' : 'asc',
                      })
                    }>
                    {field.label}
                  </TableSortLabel>
                )}
              </Grid.Col>
            ))}
          </Grid>
          <Flipper flipKey={orderBy.name + orderBy.order}>
            <Grid grow gutter="xs" columns={20}>
              {sortedRows.map((row) => (
                <Row
                  key={row.id}
                  row={row}
                  fields={fields}
                  onRowClick={onRowClick}
                  expandFunction={expandFunc}
                  orderBy={orderBy}
                  enableDragAndDrop={enableDragAndDrop}
                />
              ))}
            </Grid>
          </Flipper>
        </SortableContext>
      </DndContext>
      <DragOverlay zIndex={10000}>
        {activeRow ? (
          <RowPaper row={activeRow} fields={fields} loading={false} open={false} setPadding={setPadding} />
        ) : null}
      </DragOverlay>
    </>
  );
};

const Row = ({ fields, row, expandFunction, onRowClick, orderBy, enableDragAndDrop }) => {
  const [open, setOpen] = useState(false);
  const [innerRows, setInnerRows] = useState(null);
  const [loading, setLoading] = useState(false);
  const { attributes, listeners, isDragging, setNodeRef, transform, transition } = useSortable({
    id: row.id,
    data: row,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <Grid.Col
      span={12}
      p="2px"
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...(orderBy.name === 'rank' && enableDragAndDrop ? listeners : {})}>
      <Flipped flipId={row.id} key={row.id}>
        <div>
          <RowPaper
            fields={fields}
            onRowClick={onRowClick}
            row={row}
            expandFunction={expandFunction}
            setOpen={setOpen}
            setInnerRows={setInnerRows}
            setLoading={setLoading}
            loading={loading}
            open={open}
            orderBy={orderBy}
            enableDragAndDrop={enableDragAndDrop}
          />
        </div>
      </Flipped>
      <Collapse in={open && !loading} timeout="auto">
        <FlexDiv style={{ padding: '0 24px' }}>
          <Box sx={{ margin: '12px 8px 0 8px', flexGrow: 1 }}>{innerRows}</Box>
        </FlexDiv>
      </Collapse>
    </Grid.Col>
  );
};

const RowPaper = forwardRef(
  (
    {
      fields,
      onRowClick,
      row,
      expandFunction,
      setOpen,
      open,
      setInnerRows,
      setLoading,
      loading,
      orderBy,
      enableDragAndDrop,
      setPadding,
    },
    ref,
  ) => {
    return (
      <Paper ref={ref} onClick={(event) => onRowClick(event, row)} style={{ cursor: 'pointer', width: '100%' }}>
        <Grid align={'center'} p="8px" columns={20} style={{ position: 'relative' }}>
          {orderBy.name === 'rank' && enableDragAndDrop && (
            <IconGripVertical
              style={{ width: rem(18), height: rem(18), position: 'absolute', left: '10px' }}
              stroke={1.5}
            />
          )}
          {fields.map((field) => (
            <Grid.Col
              key={field.id}
              span={field.width || fields.length / 20}
              display={'flex'}
              style={{
                alignItems: 'center',
                minHeight: '50px',
                fontSize: '0.875rem',
                paddingLeft: !setPadding
                  ? '10px'
                  : field.id === 'id' || field.id === 'rank'
                  ? row.expand
                    ? '24px'
                    : '58px'
                  : '8px',
              }}>
              {(field.id === 'id' || field.id === 'rank') && row.expand && (
                <>
                  <IconButton
                    id="expand"
                    aria-label="expand row"
                    size="small"
                    onClick={(event) => {
                      event.stopPropagation();
                      setOpen(!open);
                      setInnerRows(expandFunction && expandFunction(row, setLoading));
                    }}>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                  <Backdrop sx={{ position: 'absolute' }} open={loading}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </>
              )}
              {row[field.id]}
            </Grid.Col>
          ))}
        </Grid>
      </Paper>
    );
  },
);

RowPaper.displayName = 'RowPaper';

export default Table;
