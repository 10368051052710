import React, { useEffect } from 'react';

import { Button, Divider, Group, Modal, Text, Title, useMantineTheme } from '@mantine/core';
import { useForm } from 'react-hook-form';

import { lightColors } from '../../shared/styles/color';
import { FormContext } from '../Common/FormFieldsHooks/MantineField';

const ModalContainer = ({
  open,
  close,
  title,
  description,
  primaryButtonText,
  children,
  onSubmit,
  width = '600px',
  initialValues = {},
  disabled = false,
}) => {
  const theme = useMantineTheme();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (open && initialValues && Object.keys(initialValues).length > 0) {
      reset(initialValues);
    }
  }, [open, initialValues, reset]);

  const handleFormSubmit = async (data) => {
    await onSubmit(data);
    reset();
  };

  return (
    <Modal.Root
      style={{
        borderRadius: '20px',
        backgroundColor: theme.other.colours.background,
        boxShadow: '0px 2px 2px 0px #0000000D',
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
      }}
      opened={open}
      onClose={close}
      size={width}
      overlayProps={{ zIndex: 1000 }}
      zIndex={1000}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header
          style={{ borderBottom: `1px solid ${theme.other.colours.borderPaper}`, alignItems: 'flex-start' }}>
          <Modal.Title>
            <Group wrap="nowrap" align="flex-start" display="block">
              <Title size={'sm'} order={4}>
                {title}
              </Title>
              {description && <Text size="sm">{description}</Text>}
            </Group>
          </Modal.Title>

          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body p={16} style={{ boxShadow: '0px 2px 2px 0px #0000000D' }}>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <FormContext.Provider value={{ control, errors }}>{children}</FormContext.Provider>
          </form>
          <Divider m="12px -12px" />
          <Group justify="flex-end">
            <Button
              type="button"
              onClick={() => {
                reset();
                close();
              }}
              variant="light"
              radius={8}
              style={{ border: `1px solid ${lightColors.tileBorder}`, backgroundColor: 'white', color: '#242424' }}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="light"
              disabled={disabled}
              radius={8}
              style={{ backgroundColor: disabled ? '#ccc' : '#FF2276', color: '#fff' }}
              onClick={handleSubmit(handleFormSubmit)}>
              {primaryButtonText}
            </Button>
          </Group>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

export default ModalContainer;
