import React, { memo, useCallback, useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { useTheme } from '@emotion/react';
import { Badge, Checkbox, Collapse, Text, Tooltip } from '@mantine/core';
import { ActionIcon } from '@mantine/core';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Avatar, Typography, Zoom } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { borderRadius } from '@mui/system';
import { IconFlag } from '@tabler/icons-react';
import cn from 'classnames';
import { get, isEqual, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { PRIORITY_LABELS } from 'utils/formConstants';
import { getFeatureBackgroundColor } from 'utils/piColors';
import { truncate } from 'utils/stringUtils';

import { IdeationSortOptions } from '../constants';
import { Card, styles, CardBottom, CardContainer } from './styles';
import { Attention } from '../../../assets/images';
import { openDrawer } from '../../../reactiveVariables';
import { color } from '../../../shared/styles/color';
import { UPDATE_FEATURE_STAGE } from '../Features/graphql';

const useStyles = makeStyles(styles);

const notDisplayedSortingValues = (value) => value === 'name';

const RenderSorter = (props) => {
  const { classes, column, feature, columnSortBy, sortListOptions = IdeationSortOptions } = props;
  const [priorityLabel, setPriorityLabel] = useState('');

  const selectedSortValue = columnSortBy;
  const selectedSort = sortListOptions[column.value].find((item) => item.id === selectedSortValue);

  useEffect(() => {
    if (selectedSortValue === 'priority') {
      const label = feature[selectedSortValue] ? PRIORITY_LABELS[feature[selectedSortValue]] : '';
      setPriorityLabel(label);
    }

    return () => {
      setPriorityLabel('');
    };
  }, [feature, selectedSortValue]);

  if (notDisplayedSortingValues(selectedSortValue) || !selectedSort?.name) {
    return null;
  }

  const renderValue = () => {
    if (selectedSortValue === 'createdAt')
      return DateTime.fromISO(feature[selectedSortValue]).toLocaleString(DateTime.DATE_SHORT);
    return get(feature, selectedSortValue);
  };

  const displayValue = priorityLabel || (get(feature, selectedSortValue) ? truncate(renderValue(), 10) : '');

  if (!displayValue) {
    return null;
  }

  return (
    <div style={{ marginBottom: '4px', height: '22px', display: 'flex', alignItems: 'center' }}>
      <Badge size="sm" radius="4px" sx={{ padding: '4px', color: 'white', backgroundColor: '#59585C' }}>
        {displayValue}
      </Badge>
    </div>
  );
};

const RenderInitiative = ({ feature, initiatives }) => {
  const initiative = initiatives.find((initiative) => initiative.id === feature.initiativeId);
  if (!initiative) {
    return null;
  }

  return (
    <div style={{ marginBottom: '4px', height: '22px', display: 'flex', alignItems: 'center' }}>
      <Badge
        color={color.purpulishPink}
        size="sm"
        radius="4px"
        sx={{ padding: '4px', color: 'white', backgroundColor: '#59585C' }}>
        {truncate(initiative.name, 17)}
      </Badge>
    </div>
  );
};

const Idea = ({
  column,
  feature,
  columnSortBy,
  isOverlay,
  show,
  subtasks = [],
  sortListOptions = IdeationSortOptions,
  initiatives,
}) => {
  const theme = useTheme();
  const borderColor = getFeatureBackgroundColor(feature, theme);
  const classes = useStyles({ borderColor });
  const [opened, setOpened] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [updateFeature] = useMutation(UPDATE_FEATURE_STAGE);

  const handleClick = (feature, event) => {
    if (event.target?.control) return;
    openDrawer({ ...feature, isIdeationBoard: true }, 'feature');
  };

  const clickSubtask = (event, subtask) => {
    event.stopPropagation();

    const updatedSubtasks = [
      ...feature.subtasks?.filter((task) => task.id !== subtask.id),
      { ...subtask, done: event.target.checked, status: event.target.checked ? 'Done' : 'To Do' },
    ];

    updateFeature({
      variables: {
        feature: { subtasks: updatedSubtasks },
        featureId: feature.id,
      },
      optimisticResponse: {
        feature: { ...feature, subtasks: updatedSubtasks },
      },
    });
  };

  const renderIdea = useCallback(
    () => (
      <CardContainer>
        <Card
          shadow="xs"
          onClick={(event) => handleClick(feature, event)}
          isOverlay={isOverlay}
          style={{ maxWidth: '257px', margin: '10px auto 0px', borderRadius: '5px 5px 0px 0px' }}
          borderColor={borderColor}>
          <div
            style={{
              height: '22px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '5px',
            }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <Text size="xs" mr={'6px'} lh={'16px'} ta="center">
                {feature?.enabler ? 'E' : 'F'}-{feature.id}
              </Text>
              {feature.missingFields && (
                <Tooltip color="dark" label="Missing Information">
                  <ActionIcon size="sm" variant="transparent" color="gray">
                    <Attention />
                  </ActionIcon>
                </Tooltip>
              )}
            </div>
            {feature.risk_flagged && (
              <div style={{ position: 'absolute', right: '0px', top: '0px' }}>
                <ActionIcon variant="transparent" color={'red'}>
                  <IconFlag size={20} fill={'red'} stroke={1.5} />
                </ActionIcon>
              </div>
            )}
          </div>
          {initiatives?.length > 0 && <RenderInitiative feature={feature} initiatives={initiatives} />}
          <Text size="sm" fw={400} c={theme.palette.text.primary}>
            {feature.name}
          </Text>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '4px 0' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {feature.subtasks && feature.subtasks.length > 0 && (
                <div
                  style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                  onClick={(event) => {
                    setOpened(!opened);
                    setClicked(true);
                    event.stopPropagation();
                  }}>
                  <Text size="xs" fw={600} mr={8}>
                    Tasks {feature.subtasks.filter((task) => task.done).length} / {feature.subtasks.length}
                  </Text>
                  {opened ? <ArrowDropUp /> : <ArrowDropDown />}
                </div>
              )}
            </div>
            <RenderSorter
              classes={classes}
              column={column}
              feature={feature}
              columnSortBy={columnSortBy}
              sortListOptions={sortListOptions}
            />
          </div>
          {feature.subtasks && feature.subtasks.length > 0 && (
            <Collapse in={opened} transitionTimingFunction="linear">
              {clicked &&
                sortBy(feature.subtasks, 'name')?.map((subtask) => (
                  <Checkbox
                    key={subtask.id}
                    color="bigagilePink.4"
                    checked={subtask.done}
                    onClick={(e) => clickSubtask(e, subtask)}
                    label={subtask.name}
                  />
                ))}
            </Collapse>
          )}
          {feature.plannedReleaseDate && (
            <div className={classes.timeContainer}>
              <Typography className={cn(classes.typo, classes.timeText)} variant="caption">
                {DateTime.fromISO(feature.plannedReleaseDate).toFormat('D')}
              </Typography>
            </div>
          )}
        </Card>

        {feature.assignedTo && (
          <CardBottom
            radius="0px 0px 4px 4px"
            style={{
              borderColor: theme.palette.mode === 'dark' ? 'white' : theme.palette.color.cardBorder,
              display: 'flex',
              alignItems: 'center',
              paddingTop: '10px',
              marginBottom: '0px',
              height: '47px',
            }}>
            {feature.assignedTo && (
              <Tooltip color="dark" label={feature.assignedTo}>
                <Avatar
                  sx={{
                    width: 24,
                    height: 24,
                    fontSize: 10,
                    fontWeight: 'bold',
                    backgroundColor: '#d156a1',
                    marginRight: '5px',
                  }}>
                  {feature.assignedTo
                    ?.split(' ')
                    .map((n) => n[0])
                    .join('')}
                </Avatar>
              </Tooltip>
            )}
          </CardBottom>
        )}
      </CardContainer>
    ),
    [subtasks, opened, feature, columnSortBy, column, borderColor, isOverlay, classes, sortListOptions],
  );

  return isOverlay ? renderIdea() : <Zoom in={show}>{renderIdea()}</Zoom>;
};

export default memo(Idea, (prevProps, nextProps) => isEqual(prevProps, nextProps));
