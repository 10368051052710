import React from 'react';

import { Text, Box, Divider } from '@mantine/core';
import { Avatar } from '@mui/material';
import { DateTime } from 'luxon';

import { returnPrioritySvg } from './helper';
import { ContentSidebar } from './style';
import SvgMilestones from '../../assets/images/Milestones';

export const DetailsSidebar = ({ selectedItem, owner }) => {
  return (
    <ContentSidebar>
      <div style={{ marginBottom: '8px', paddingLeft: '16px' }}>
        <Text mt={12} style={{ fontSize: '14px', fontWeight: 500 }}>
          Details
        </Text>
      </div>
      <Divider my="sm" />

      <div style={{ padding: '0px 10px' }}>
        <Box>
          <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
            <li
              style={{
                display: 'flex',
                marginBottom: '12px',
              }}>
              <Text style={{ fontSize: '14px' }}>Owner:</Text>{' '}
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  marginLeft: '10px',
                }}>
                <div
                  key={owner}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '8px',
                  }}>
                  <Avatar
                    sx={{
                      width: 20,
                      height: 20,
                      fontSize: 10,
                      fontWeight: 'bold',
                      backgroundColor: '#d156a1',
                      marginRight: '8px',
                    }}>
                    {owner
                      .split(' ')
                      .map((n) => n[0])
                      .join('')}
                  </Avatar>
                  <Text style={{ fontSize: '14px' }}>{owner}</Text>
                </div>
              </Box>
            </li>

            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
              <Text style={{ fontSize: '14px' }}>Project:</Text>
              <Box style={{ display: 'flex', alignItems: 'center', marginLeft: '8px' }}>
                <SvgMilestones style={{ marginRight: '8px' }} />
                <Text style={{ fontSize: '14px' }}>{selectedItem.name}</Text>
              </Box>
            </li>

            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
              <Text style={{ fontSize: '14px' }}>Priority:</Text>
              <Box style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                {returnPrioritySvg(selectedItem.priority)}
                <Text style={{ fontSize: '12px', margin: 0, marginLeft: '10px' }}>
                  {selectedItem.priority || 'No priority'}
                </Text>
              </Box>
            </li>

            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
              <Text style={{ fontSize: '14px' }}>Start date:</Text>
              <Box style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                {selectedItem?.planned_start_date && (
                  <Text style={{ fontSize: '14px', margin: 0, whiteSpace: 'nowrap' }}>
                    {DateTime.fromISO(selectedItem?.planned_start_date).toFormat('MMMM dd, yyyy')}
                  </Text>
                )}
              </Box>
            </li>

            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
              <Text style={{ fontSize: '14px' }}>End date:</Text>
              <Box style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                {selectedItem?.planned_start_date && (
                  <Text style={{ fontSize: '14px', margin: 0, whiteSpace: 'nowrap' }}>
                    {DateTime.fromISO(selectedItem?.planned_end_date).toFormat('MMMM dd, yyyy')}
                  </Text>
                )}
              </Box>
            </li>
          </ul>
        </Box>
      </div>
    </ContentSidebar>
  );
};
