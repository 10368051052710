import React, { useState, useEffect, useRef, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { Box, Loader, Badge, Tooltip } from '@mantine/core';
import { DataTableToolbar } from 'components/Common/DataTableToolbar';
import { DataTable } from 'mantine-datatable';
import { useNavigate } from 'react-router-dom';

import { GET_STRATEGIC_THEMES } from './graphql';
import { GET_PROJECTS } from '../../../containers/Organisation/Projects/graphql';
import { openDrawer } from '../../../reactiveVariables';
import { truncate } from '../../../utils/stringUtils';

import '@mantine/core/styles.css';
import 'mantine-datatable/styles.css';

const PAGE_SIZE = 60;

export const StrategicThemes = () => {
  const [themes, setThemes] = useState([]);
  const [allStrategicThemes, setAllStrategicThemes] = useState([]);
  const [page, setPage] = useState(0);
  const [sortStatus, setSortStatus] = useState({ columnAccessor: 'name', direction: 'asc' });
  const [visibleColumns, setVisibleColumns] = useState({});
  const [tempVisibleColumns, setTempVisibleColumns] = useState({});
  const [tableHeight, setTableHeight] = useState('100vh');
  const [searchQuery, setSearchQuery] = useState('');

  const { data: { strategic_themes = [], loading } = {} } = useQuery(GET_STRATEGIC_THEMES);
  const { data: { projects = [] } = {} } = useQuery(GET_PROJECTS);

  const scrollViewportRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    const updateTableHeight = () => {
      const vh = window.innerHeight;
      setTableHeight(vh * 0.8);
    };

    updateTableHeight();
    window.addEventListener('resize', updateTableHeight);

    return () => {
      window.removeEventListener('resize', updateTableHeight);
    };
  }, []);

  useEffect(() => {
    if (strategic_themes.length) {
      setThemes(strategic_themes.slice(0, PAGE_SIZE));
      setAllStrategicThemes(strategic_themes);
    }
  }, [strategic_themes]);

  const loadMoreData = () => {
    if (loading || !allStrategicThemes.length) return;

    const nextPage = page + 1;
    const newThemes = allStrategicThemes.slice(0, (nextPage + 1) * PAGE_SIZE);

    if (newThemes.length > themes.length) {
      setThemes(newThemes);
      setPage(nextPage);
    }
  };

  const toggleTempColumn = (accessor) => {
    setTempVisibleColumns((prev) => ({ ...prev, [accessor]: !prev[accessor] }));
  };

  const handleSort = (status) => {
    setSortStatus(status);
    const { columnAccessor, direction } = status;

    const sortedData = [...allStrategicThemes].sort((a, b) => {
      // Default sorting for other columns
      const aValue = a[columnAccessor] ?? '';
      const bValue = b[columnAccessor] ?? '';
      return direction === 'asc'
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    });

    setAllStrategicThemes(sortedData);
    setThemes(sortedData.slice(0, PAGE_SIZE));
  };

  const staticColumns = useMemo(
    () => [
      {
        accessor: 'name',
        title: 'Name',
        sortable: true,
        width: 200,
        render: (record) => {
          return record?.name && record.name;
        },
      },
      {
        accessor: 'description',
        title: 'Description',
        sortable: true,
        width: 300,
        render: (record) => {
          return record?.description && record.description;
        },
      },
      {
        accessor: 'investment',
        title: 'Investment',
        sortable: true,
        width: 130,
        render: (record) => record?.investment && record.investment,
      },
      {
        accessor: 'projects',
        title: 'Projects',
        sortable: true,
        width: 130,
        render: (record) => {
          const matchingProjects = projects.filter((project) => record.projects.some((p) => p.id === project.id));

          return matchingProjects.length > 0 ? (
            <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
              {matchingProjects.map((project) => (
                <Tooltip
                  data-value={project?.name}
                  transition="scale"
                  label={project?.name}
                  zIndex={1000}
                  key={project.id}>
                  <Badge
                    key={project.id}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/organisation/projects/${project.id}`);
                    }}
                    color="#9D81EF"
                    size="sm"
                    radius="4px"
                    styles={{
                      root: {
                        cursor: 'pointer',
                      },
                    }}
                    sx={{ padding: '4px', color: 'white', backgroundColor: '#59585C' }}>
                    {truncate(project.name, { length: 17 })}
                  </Badge>
                </Tooltip>
              ))}
            </div>
          ) : (
            ''
          );
        },
      },
    ],
    [],
  );

  // Filter columns based on visibility
  const filteredColumns = staticColumns.filter((col) => visibleColumns[col.accessor]);

  useEffect(() => {
    if (Object.keys(visibleColumns).length === 0) {
      const initialVisibility = staticColumns.reduce((acc, col) => {
        acc[col.accessor] = true;
        return acc;
      }, {});
      setVisibleColumns(initialVisibility);
      setTempVisibleColumns(initialVisibility);
    }
  }, [staticColumns, visibleColumns]);

  const filteredThemes = useMemo(() => {
    return strategic_themes.filter(
      (theme) =>
        searchQuery.trim() === '' ||
        Object.values(theme).some((value) => value?.toString().toLowerCase().includes(searchQuery.toLowerCase())),
    );
  }, [searchQuery, strategic_themes]);

  return (
    <Box sx={{ height: '100%', overflowY: 'auto' }}>
      {/* <Text fw={600} style={{ fontSize: '20px' }}>
        Strategic Themes
      </Text> */}

      <DataTableToolbar
        addButtonUrl={() => openDrawer('', 'strategicTheme')}
        setSearchQuery={setSearchQuery}
        staticColumns={staticColumns}
        tempVisibleColumns={tempVisibleColumns}
        toggleTempColumn={toggleTempColumn}
        setVisibleColumns={setVisibleColumns}
      />

      <DataTable
        columns={filteredColumns}
        records={filteredThemes}
        sortStatus={sortStatus}
        onSortStatusChange={handleSort}
        highlightOnHover
        withColumnBorders
        fetching={loading}
        enableRowVirtualization
        onScrollToBottom={loadMoreData}
        onRowClick={(data) => {
          openDrawer(data.record, 'strategicTheme');
        }}
        height={tableHeight}
        emptyState={<div style={{ textAlign: 'center', padding: '20px' }}></div>}
        stickyHeader
        scrollViewportRef={scrollViewportRef}
        styles={{
          root: { backgroundColor: 'transparent' },
          table: { backgroundColor: 'transparent' },
          header: { backgroundColor: '#f7f7f8' },
          row: { backgroundColor: 'transparent' },
        }}
      />
      {loading && <Loader sx={{ margin: '10px auto', display: 'block' }} />}
    </Box>
  );
};
