import React, { useCallback, useState, useEffect, useMemo } from 'react';

import { useQuery, useReactiveVar, useMutation } from '@apollo/client';
import { DndContext, closestCorners } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useTheme } from '@emotion/react';
import { Box, Loader, Tooltip, ActionIcon, Badge } from '@mantine/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconGripVertical } from '@tabler/icons-react';
import { DataTableToolbar } from 'components/Common/DataTableToolbar';
import { some } from 'lodash';
import { DateTime } from 'luxon';
import { DataTable } from 'mantine-datatable';
import { useNavigate } from 'react-router-dom';

import FeatureList from './FeatureList';
import SvgDashboard from '../../../assets/images/Dashboard';
import { openDrawer } from '../../../reactiveVariables';
import {
  selectedProductThemeVar,
  selectedValueStreamVar,
  selectedOwnerVar,
  selectedTeamVar,
  selectedProjectVar,
} from '../../../reactiveVariables';
import { color } from '../../../shared/styles/color';
import { exportCSVData } from '../../../utils/export';
import { date } from '../../../utils/helpers';
import { useProjectedFeatures } from '../../../utils/hooks';
import { GET_PROJECTS } from '../../Organisation/Projects/graphql';
import { GET_INITIATIVE_STATE_CONFIG, GET_PORTFOLIO_STATES_CONFIG } from '../../Settings/GraphQL/configuration';
import { GET_SPRINTS } from '../../Settings/ProgramIncrement/graphql';
import { GET_ALL_INITIATIVES, GET_INITIATIVES, UPDATE_INITIATIVE_MANY } from '../../ValueStream/Initiatives/graphql';
import { Display2 } from '../../ValueStream/InitiativesDashboard/InitiativeDashboard.style';
import LinearWithValueLabel from '../components/OKRTable/LinearWithValueLabel';
import { getInitiativeBgColor, getMantineColor } from '../utils';
import { initiativeWithDates } from '../utils';
import { Overlay } from './overlay';

export const NewTable = ({ type }) => {
  const { data: { initiatives = [], loading } = {}, refetch: refetchInitiatives } = useQuery(
    type === 'portfolioInitiative' ? GET_ALL_INITIATIVES : GET_INITIATIVES,
  );
  const { data: { sprints = [] } = {} } = useQuery(GET_SPRINTS);
  const { data: { initiativeStates = [] } = {} } = useQuery(GET_INITIATIVE_STATE_CONFIG);
  const { data: { portfolioStates = [] } = {} } = useQuery(GET_PORTFOLIO_STATES_CONFIG);

  const { data: { projects = [] } = {} } = useQuery(GET_PROJECTS);

  const [updateInitiativeMany] = useMutation(UPDATE_INITIATIVE_MANY);

  const theme = useTheme();
  const navigate = useNavigate();

  const [sortStatus, setSortStatus] = useState({ columnAccessor: 'rank', direction: 'asc' });
  const [visibleColumns, setVisibleColumns] = useState({});
  const [tempVisibleColumns, setTempVisibleColumns] = useState({});
  const [tableHeight, setTableHeight] = useState('100vh');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedInitiativesState, setInitiativesState] = useState('');
  const [filteredInitiatives, setFilteredInitiatives] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const [draggedOverId, setDraggedOverId] = useState(null);

  useEffect(() => {
    const updateTableHeight = () => {
      const vh = window.innerHeight;
      setTableHeight(vh * 0.8);
    };

    updateTableHeight();
    window.addEventListener('resize', updateTableHeight);

    return () => {
      window.removeEventListener('resize', updateTableHeight);
    };
  }, []);

  const selectedProductTheme = useReactiveVar(selectedProductThemeVar);
  const selectedValueStream = useReactiveVar(selectedValueStreamVar);
  const selectedOwners = useReactiveVar(selectedOwnerVar);
  const selectedTeams = useReactiveVar(selectedTeamVar);
  const selectedProject = useReactiveVar(selectedProjectVar);

  const projectedFeatures = useProjectedFeatures();

  const updateInitiativeRank = async (updatedRankOrder) => {
    const updates = updatedRankOrder.map((item) => ({
      where: { id: { _eq: item.id } },
      _set: { ranks: item.ranks },
    }));

    await updateInitiativeMany({
      variables: { updates },
    });

    refetchInitiatives();
  };

  const filterByProductTheme = (initiative, selectedProductTheme) => {
    if (selectedProductTheme === 'x') {
      return initiative.productThemes.length === 0;
    }
    return !selectedProductTheme || some(initiative.productThemes, (theme) => theme.id === selectedProductTheme);
  };

  const filterByValueStream = useCallback(
    (initiative, selectedValueStream) => {
      if (type === 'initiative') {
        return (
          initiative.valueStream === selectedValueStream ||
          initiative.portfolioValueStreams.some((vs) => vs.id === selectedValueStream)
        );
      } else {
        return some(
          initiative.portfolioValueStreams,
          (portfolioValueStream) => portfolioValueStream.id === selectedValueStream,
        );
      }
    },
    [type],
  );

  const filterByOwner = (initiative, selectedOwners) => {
    return selectedOwners.includes(initiative.owner);
  };

  const filterByTeams = (initiative, selectedTeams) => {
    return initiative.teams?.some((team) => selectedTeams.includes(team.id));
  };

  useEffect(() => {
    const filtered = initiatives
      .filter((initiative) => {
        const themeFilter = filterByProductTheme(initiative, selectedProductTheme);

        const valueStreamFilter =
          type !== 'portfolioInitiative' ? filterByValueStream(initiative, selectedValueStream) : true;

        const portfolioFilter = type === 'portfolioInitiative' ? initiative.isPortfolioState === true : true;

        return themeFilter && valueStreamFilter && portfolioFilter;
      })
      .map((initiative) => initiativeWithDates(initiative, sprints, projectedFeatures));

    let finalInitiatives = filtered;

    if (selectedOwners.length) {
      finalInitiatives = finalInitiatives.filter((initiative) => filterByOwner(initiative, selectedOwners));
    }

    if (selectedTeams.length) {
      finalInitiatives = finalInitiatives.filter((initiative) => filterByTeams(initiative, selectedTeams));
    }

    if (selectedProject && type === 'initiative') {
      finalInitiatives = finalInitiatives.filter((initiative) => initiative.strategic_theme === selectedProject);
    }

    if (selectedInitiativesState.length) {
      finalInitiatives = finalInitiatives.filter((initiative) =>
        selectedInitiativesState.includes(
          type === 'portfolioInitiative' ? initiative.portfolio_state : initiative.initiative_state,
        ),
      );
    }

    if (type === 'portfolioInitiative') {
      finalInitiatives = finalInitiatives.filter((initiative) => initiative.isPortfolioState === true);
    }

    if (searchQuery.length) {
      const lowerCaseQuery = searchQuery.toLowerCase();
      finalInitiatives = finalInitiatives.filter(
        (initiative) =>
          initiative.name.toLowerCase().includes(lowerCaseQuery) ||
          initiative.initiative_state?.toLowerCase().includes(lowerCaseQuery),
      );
    }

    setFilteredInitiatives(finalInitiatives);
  }, [
    searchQuery,
    selectedProductTheme,
    selectedValueStream,
    selectedOwners,
    selectedTeams,
    selectedProject,
    initiatives,
    sprints,
    projectedFeatures,
    selectedInitiativesState,
    type,
    filterByValueStream,
  ]);

  const toggleTempColumn = (accessor) => {
    setTempVisibleColumns((prev) => ({ ...prev, [accessor]: !prev[accessor] }));
  };

  const statesForValueStream = initiativeStates
    .filter((state) => state.value_stream === selectedValueStream && state.inUse === true)
    .map((state) => {
      return {
        id: state.name,
        name: state.name,
        stateType: state.stateType,
      };
    });

  const [expandedRows, setExpandedRows] = useState([]);

  const handleRowExpand = (record) => {
    setExpandedRows((prev) =>
      prev.includes(record.id) ? prev.filter((id) => id !== record.id) : [...prev, record.id],
    );
  };

  const handleDragStart = (event) => {
    setActiveItem(event.active.data.current);
  };

  const isInitiativeDone = (initiative) => {
    const matchingColumn = portfolioStates
      .flatMap((stateConfig) => stateConfig.value.columns)
      .find((column) =>
        column.states.some(
          (state) => state.name === initiative.initiative_state || state.name === initiative.initiative_state?.name,
        ),
      );

    if (matchingColumn && initiative.isPortfolioState) {
      const matchingState = matchingColumn.states.find(
        (state) =>
          state.inUse && (state.name === initiative.portfolio_state?.name || state.name === initiative.portfolio_state),
      );

      if (matchingState?.stateType === 'Done') {
        return true;
      }
    }

    if (!initiative.isPortfolioState) {
      const stateData = statesForValueStream.find(
        (state) =>
          state.name ===
          (initiative.initiative_state?.name ? initiative.initiative_state?.name : initiative.initiative_state),
      );
      if (stateData?.stateType === 'Done') {
        return true;
      }
    }

    return false;
  };

  const handleDragEnd = (event) => {
    setDraggedOverId(null);
    if (sortStatus?.columnAccessor !== 'rank') return;

    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const currentList = [...sortedData];
    const oldIndex = currentList.findIndex((i) => i.id === active.id);
    const newIndex = currentList.findIndex((i) => i.id === over.id);

    if (oldIndex !== -1 && newIndex !== -1) {
      const updatedInitiatives = [...currentList];
      const [movedItem] = updatedInitiatives.splice(oldIndex, 1);
      updatedInitiatives.splice(newIndex, 0, movedItem);

      const rankedInitiatives = updatedInitiatives.map((item, index) => {
        const isDone = isInitiativeDone(item);

        return {
          ...item,
          ranks: {
            ...item.ranks,
            [selectedValueStream]: isDone
              ? null
              : sortStatus.direction === 'desc'
              ? updatedInitiatives.length - index
              : index + 1,
          },
        };
      });

      setFilteredInitiatives(rankedInitiatives);
      updateInitiativeRank(rankedInitiatives);
    }
  };

  const handleDragOver = (event) => {
    setDraggedOverId(event.over?.id || null);
  };

  const DragHandle = ({ record }) => {
    if (
      type === 'portfolioInitiative' ||
      sortStatus?.columnAccessor !== 'rank' ||
      !record ||
      !record.id ||
      isInitiativeDone(record)
    )
      return null;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
      id: record.id,
      data: { record },
      strategy: verticalListSortingStrategy,
      animateLayoutChanges: () => true,
    });

    return (
      <div
        ref={setNodeRef}
        style={{
          transform: transform ? CSS.Transform.toString(transform) : undefined,
          transition,
          height: '58px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '16px',
          position: 'relative',
          right: '3px',
        }}
        {...attributes}
        {...listeners}>
        <IconGripVertical size={16} style={{ cursor: 'grab' }} />
      </div>
    );
  };

  const sortedData = useMemo(() => {
    return [...filteredInitiatives].sort((a, b) => {
      const { columnAccessor, direction } = sortStatus;

      let aValue = a[columnAccessor] ?? '';
      let bValue = b[columnAccessor] ?? '';

      if (columnAccessor === 'rank') {
        aValue = a.ranks?.[selectedValueStream] ?? Infinity;
        bValue = b.ranks?.[selectedValueStream] ?? Infinity;
      }

      if (columnAccessor === 'features') {
        aValue = a.features?.nodes?.length ?? 0;
        bValue = b.features?.nodes?.length ?? 0;
      }

      if (columnAccessor === 'state') {
        aValue = a.initiative_state ?? '';
        bValue = b.initiative_state ?? '';
      }

      if (columnAccessor === 'progress') {
        aValue = a.features?.aggregate?.avg?.percentageDone || 0;
        bValue = b.features?.aggregate?.avg?.percentageDone || 0;
      }

      if (columnAccessor === 'view') {
        aValue = a.features?.aggregate?.count || 0;
        bValue = b.features?.aggregate?.count || 0;
      }

      if (columnAccessor === 'project') {
        const aValue = projects.find((project) => project.id === a.strategic_theme)?.name || '';
        const bValue = projects.find((project) => project.id === b.strategic_theme)?.name || '';
        return direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }

      if (columnAccessor === 'plannedRealisationDate' || columnAccessor === 'estimatedCompletionDate') {
        aValue = aValue ? new Date(aValue).getTime() : 0;
        bValue = bValue ? new Date(bValue).getTime() : 0;
      }

      if (columnAccessor === 'ragStatus') {
        aValue = a.isDone ? 'DONE' : a.ragStatus?.toUpperCase() ?? '';
        bValue = b.isDone ? 'DONE' : b.ragStatus?.toUpperCase() ?? '';

        return direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return direction === 'asc' ? aValue - bValue : bValue - aValue;
      }

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }

      return 0;
    });
  }, [filteredInitiatives, projects, selectedValueStream, sortStatus]);

  const staticColumns = useMemo(() => {
    const cellStyle = {
      width: '100%',
      height: '86px',
      display: 'flex',
      alignItems: 'center',
      padding: '8px',
    };

    const columns = [
      {
        accessor: 'view',
        title: 'View',
        sortable: true,
        textAlign: 'center',
        width: 70,
        render: (record) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {record.features?.aggregate?.count > 0 && (
                <Tooltip withArrow transitionProps={{ transition: 'pop', duration: 200 }} label="Open Initiative View">
                  <ActionIcon
                    ml={4}
                    variant="transparent"
                    color="var(--mantine-color-buttonIcon)"
                    onClick={() =>
                      navigate('/valueStream/initiative/' + record.id, { state: { fromInitiativesList: true } })
                    }>
                    <SvgDashboard />
                  </ActionIcon>
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        accessor: 'project',
        title: 'Project',
        sortable: true,
        cellsStyle: () => ({
          padding: 0,
        }),
        width: 180,
        render: (record) => {
          const project = projects.find((project) => project.id === record.strategic_theme);

          return (
            <div
              onClick={(e) => {
                e.stopPropagation();
                openDrawer(record, 'initiative', sortStatus);
              }}
              style={cellStyle}>
              {project?.name && (
                <Tooltip data-value={project?.name} transition={'scale'} label={project?.name} zIndex={1000}>
                  <Badge
                    color={color.purple}
                    size="sm"
                    radius="4px"
                    sx={{ padding: '4px', color: 'white', backgroundColor: '#59585C' }}>
                    {project?.name}
                  </Badge>
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        accessor: 'name',
        title: 'Name',
        sortable: true,
        cellsStyle: () => ({
          padding: 0,
        }),
        width: 300,
        render: (record) => (
          <div
            onClick={(e) => {
              e.stopPropagation();
              openDrawer(record, 'initiative', sortStatus);
            }}
            style={cellStyle}>
            {record.name}
          </div>
        ),
      },
      {
        accessor: 'features',
        title: 'Features',
        sortable: true,
        width: 105,
        padding: 0,
        cellsStyle: () => ({
          padding: 0,
        }),
        render: (record) => {
          return (
            record && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  openDrawer(record, 'initiative', sortStatus);
                }}
                style={cellStyle}>
                {record.features?.aggregate?.count + ' Features'}
              </div>
            )
          );
        },
      },
      {
        accessor: 'state',
        title: 'State',
        sortable: true,
        width: 100,
        cellsStyle: () => ({
          padding: 0,
        }),
        render: (record) => {
          const isPortfolioState = record.isPortfolioState;

          return (
            <div
              onClick={(e) => {
                e.stopPropagation();
                openDrawer(record, 'initiative', sortStatus);
              }}
              style={cellStyle}>
              {record && isPortfolioState ? record.portfolio_state : record?.initiative_state}
            </div>
          );
        },
      },
      {
        accessor: 'progress',
        title: 'Progress',
        sortable: true,
        width: 200,
        cellsStyle: () => ({
          padding: 0,
        }),
        render: (record) => {
          const progress = record.features?.aggregate?.avg?.percentageDone || 0;

          return (
            <div
              onClick={(e) => {
                e.stopPropagation();
                openDrawer(record, 'initiative', sortStatus);
              }}
              style={cellStyle}>
              <LinearWithValueLabel data-value={progress} value={progress.toFixed()} />{' '}
            </div>
          );
        },
      },
      {
        accessor: 'plannedRealisationDate',
        title: 'Target Realisation Date',
        sortable: true,
        width: 200,
        cellsStyle: () => ({
          padding: 0,
        }),
        render: (record) => {
          return (
            record.plannedRealisationDate && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  openDrawer(record, 'initiative', sortStatus);
                }}
                style={cellStyle}>
                {DateTime.fromISO(record.plannedRealisationDate).toLocaleString(DateTime.DATE_SHORT)}
              </div>
            )
          );
        },
      },
      {
        accessor: 'estimatedCompletionDate',
        title: 'Estimated Completion Date',
        sortable: true,
        width: 220,
        cellsStyle: () => ({
          padding: 0,
        }),
        render: (record) =>
          record.endDate && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                openDrawer(record, 'initiative', sortStatus);
              }}
              style={cellStyle}>
              {record.endDate.toLocaleString(DateTime.DATE_SHORT)}
            </div>
          ),
      },
      {
        accessor: 'ragStatus',
        title: 'Rag Status',
        sortable: true,
        width: 130,
        textAlign: 'center',
        cellsStyle: () => ({
          padding: 0,
        }),
        render: (record) => {
          const daysOverrun =
            record.plannedRealisationDate && record.endDate
              ? record.endDate.diff(date(record.plannedRealisationDate), 'days').toObject().days
              : 0;

          return (
            <Tooltip
              withArrow
              opened={record.plannedRealisationDate ? null : false}
              color={getMantineColor(record)}
              transitionProps={{ transition: 'pop', duration: 200 }}
              label={`${daysOverrun > 0 ? 'Overrun' : 'Early'} By: ${Math.abs(daysOverrun)} days`}>
              <div>
                <Display2
                  data-value={record.ragStatus}
                  color={getInitiativeBgColor(record, theme)}
                  bgColor={getInitiativeBgColor(record, theme)}
                  variant="light"
                  style={{ width: '65px' }}>
                  {record.isDone ? 'DONE' : record.ragStatus.toUpperCase()}
                </Display2>
              </div>
            </Tooltip>
          );
        },
      },
    ];

    if (type === 'initiative') {
      columns.unshift({
        accessor: 'rank',
        title: 'Rank',
        sortable: true,
        textAlign: 'center',
        width: 70,
        render: (record) => {
          const hasFeatures = record.features?.nodes?.length > 0;
          const isExpanded = expandedRows.includes(record.id);

          // Fetch rank for the selected value stream
          const rank = record.ranks?.[selectedValueStream] ?? '';

          return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {hasFeatures && (
                <div onClick={() => handleRowExpand(record)}>
                  {isExpanded ? <KeyboardArrowDownIcon size={16} /> : <KeyboardArrowUpIcon size={16} />}
                </div>
              )}
              <div>{rank}</div>
            </div>
          );
        },
      });
    }

    return columns;
  }, [expandedRows, navigate, projects, selectedValueStream, theme, type]);

  const filteredColumns = useMemo(
    () => staticColumns.filter((col) => visibleColumns[col.accessor]),
    [staticColumns, visibleColumns],
  );

  useEffect(() => {
    if (Object.keys(visibleColumns).length === 0) {
      const initialVisibility = staticColumns.reduce((acc, col) => {
        acc[col.accessor] = true;
        return acc;
      }, {});
      setVisibleColumns(initialVisibility);
      setTempVisibleColumns(initialVisibility);
    }
  }, [staticColumns, visibleColumns]);

  const getFeatures = (initiative) => {
    return initiative?.features?.nodes?.length ? <FeatureList initiative={initiative} /> : null;
  };

  const exportInitiatives = useCallback(() => {
    // Define CSV column headers
    const columns = [
      { label: 'ID' },
      { label: 'Name' },
      { label: 'Features' },
      { label: 'State' },
      { label: 'Progress' },
      { label: 'Target Realisation Date' },
      { label: 'Estimated Completion Date' },
      { label: 'Rag Status' },
    ];

    // Format the initiatives data for CSV
    const formattedIdeas = filteredInitiatives.map((init) => [
      init.id,
      init.name,
      init.features?.nodes?.length + 'Features',
      init.initiative_state,
      init.features?.aggregate?.avg?.percentageDone
        ? init.features?.aggregate?.avg?.percentageDone.toFixed(2) + '%'
        : 0 + '%',
      init.plannedRealisationDate,
      init.estimatedCompletionDate,
      init.isDone ? 'DONE' : init.ragStatus.toUpperCase(),
    ]);

    // Export CSV
    exportCSVData(columns, formattedIdeas, 'initiatives_export');
  }, [filteredInitiatives]);

  return (
    <Box sx={{ height: '100%', overflowY: 'auto' }}>
      <DataTableToolbar
        addButtonUrl={() => openDrawer('', 'portfolioInitiative')}
        stateOptions={initiativeStates
          .filter((state) => state.value_stream === selectedValueStream && state.inUse === true)
          .map((state) => {
            return {
              label: state.name,
              value: state.name,
            };
          })}
        setSelectState={setInitiativesState}
        setSearchQuery={setSearchQuery}
        exportData={exportInitiatives}
        staticColumns={staticColumns}
        tempVisibleColumns={tempVisibleColumns}
        toggleTempColumn={toggleTempColumn}
        setVisibleColumns={setVisibleColumns}
      />

      <DndContext
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}>
        <SortableContext items={filteredInitiatives.map((i) => i.id)} strategy={verticalListSortingStrategy}>
          <DataTable
            columns={[
              {
                accessor: 'drag',
                title: '',
                width: 30,
                textAlign: 'center',
                render: (record) => <DragHandle record={record} />,
              },
              ...filteredColumns,
            ]}
            records={sortedData}
            rowComponent={DragHandle}
            sortStatus={sortStatus}
            onSortStatusChange={setSortStatus}
            highlightOnHover
            withColumnBorders
            rowExpansion={{
              expandable: ({ record: { features } }) => features?.nodes.length > 0,
              allowMultiple: true,
              collapseProps: {
                transitionDuration: 300,
                animateOpacity: false,
                transitionTimingFunction: 'ease-out',
              },
              content: ({ record }) => (
                <Box p="sm" bg="gray.0">
                  {getFeatures(record)}
                </Box>
              ),
            }}
            fetching={loading}
            enableRowActions
            verticalAlignment="center"
            enableRowVirtualization
            height={tableHeight}
            emptyState={<div style={{ textAlign: 'center', padding: '20px' }}></div>}
            stickyHeader
            rowBackgroundColor={(record) => {
              if (draggedOverId === record.id) return { dark: '#232b25', light: '#f0f7f1' };
            }}
            styles={{
              root: { backgroundColor: 'transparent' },
              table: { backgroundColor: 'transparent' },
              header: { backgroundColor: '#f7f7f8' },
            }}
          />
        </SortableContext>
        <Overlay activeItem={activeItem} selectedValueStream={selectedValueStream} theme={theme} />
      </DndContext>
      {loading && <Loader sx={{ margin: '10px auto', display: 'block' }} />}
    </Box>
  );
};
