import React, { memo, useCallback, useState } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import { useTheme } from '@emotion/react';
import { Collapse, Text, Tooltip, ActionIcon, Badge } from '@mantine/core';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Avatar } from '@mui/material';
import { IconFlag } from '@tabler/icons-react';
import { isEqual, sortBy } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { getFeatureBackgroundColor } from 'utils/piColors';

import { Card, CardBottom, CardContainer } from './styles';
import SvgDashboard from '../../../assets/images/Dashboard';
import { openDrawer, selectedValueStreamVar } from '../../../reactiveVariables';
import { lightColors, color } from '../../../shared/styles/color';
import { truncate } from '../../../utils/stringUtils';
import { GET_PROJECTS } from '../../Organisation/Projects/graphql';

const RenderProject = ({ initiative }) => {
  const { data: { projects: strategic_themes = [] } = {} } = useQuery(GET_PROJECTS);

  // Use portfolioStrategicTheme for portfolio state, but fall back to strategic_theme if undefined
  const themeId =
    initiative.isPortfolioState && initiative.portfolioStrategicTheme !== undefined
      ? initiative.portfolioStrategicTheme
      : initiative.strategic_theme;

  const project = strategic_themes.find((theme) => theme.id === themeId);

  if (!project) {
    return null;
  }

  return (
    <div style={{ marginBottom: '4px', height: '22px', display: 'flex', alignItems: 'center' }}>
      <Badge color="#9D81EF" size="sm" radius="4px" sx={{ padding: '4px', color: 'white', backgroundColor: '#59585C' }}>
        {truncate(project.name, 17)}
      </Badge>
    </div>
  );
};

const KanbanItem = ({ feature, isOverlay, initiativeLength, showRank, valueStreams = [] }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const borderColor = getFeatureBackgroundColor(feature, theme);
  const [opened, setOpened] = useState(false);
  const [clicked, setClicked] = useState(false);

  const selectedValueStream = useReactiveVar(selectedValueStreamVar);

  const handleClick = (feature, event) => {
    if (event.target?.control) return;
    openDrawer({ ...feature }, 'initiative');
  };

  const handleNavigate = useCallback(
    (featureId, event) => {
      event.stopPropagation();
      navigate(`/valueStream/initiative/${featureId}`, { state: { from: '/valueStream/kanban' } });
    },
    [navigate],
  );

  const assignedStreams = valueStreams.filter(
    (stream) =>
      (feature.isPortfolioState && feature.portfolioValueStreams.length > 0
        ? feature.portfolioValueStreams.some((vs) => vs.id === stream.id)
        : stream.id === feature.valueStream) && stream.isActive,
  );

  const additionalValueStreams =
    feature.portfolioValueStreams?.length > 1 ? feature.portfolioValueStreams?.length - 1 : 0;

  const renderKanbanItem = useCallback(() => {
    if (!feature) return null;

    return (
      <CardContainer
        shadow="xs"
        data-testid={`Kanban-item-${feature.name}`}
        isPortfolioState={feature.isPortfolioState}
        isPortfolioPage={location.pathname === '/organisation/portfolio'}
        style={{ maxWidth: '257px', margin: '10px auto 0px' }}>
        <Card
          shadow="xs"
          onClick={(event) => handleClick(feature, event)}
          isOverlay={isOverlay}
          borderColor={borderColor}
          style={{ borderColor: lightColors.tileBorder, paddingBottom: '5px', borderRadius: '8px 8px 0px 0px' }}>
          <RenderProject initiative={feature} />

          {feature.risk_flagged && (
            <div style={{ position: 'absolute', right: '0px', top: '0px' }}>
              <ActionIcon variant="transparent" color={'red'}>
                <IconFlag size={20} fill={'red'} stroke={1.5} />
              </ActionIcon>
            </div>
          )}
          <Text
            size="sm"
            fw={400}
            c={theme.palette.text.primary}
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {feature.name}
          </Text>
          {window.location.pathname === '/organisation/portfolio' && (
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
              <Badge
                color={color.purpulishPink}
                size="sm"
                radius="4px"
                sx={{
                  padding: '4px',
                  color: 'white',
                  backgroundColor: '#59585C',
                }}>
                {assignedStreams[0]?.name ? truncate(assignedStreams[0]?.name, 17) : ''}
                {additionalValueStreams > 0 ? ` +${additionalValueStreams}` : ''}
              </Badge>
            </div>
          )}

          {feature.features && (
            <>
              <div
                style={{ display: 'flex', margin: '4px 0', alignItems: 'center' }}
                onClick={(event) => {
                  setOpened(!opened);
                  setClicked(true);
                  event.stopPropagation();
                }}>
                {feature.features.nodes.length > 0 && (
                  <>
                    <Text size="xs" fw={600}>
                      Features {feature.features.nodes.length}
                    </Text>
                    {opened ? <ArrowDropUp /> : <ArrowDropDown />}
                  </>
                )}
              </div>
              <Collapse in={opened} transitionTimingFunction="linear" style={{ marginBottom: '5px' }}>
                {clicked &&
                  sortBy(feature.features.nodes, 'name')?.map((feature) => (
                    <>
                      <Text style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {feature.status === 'Done' ? (
                          <CheckCircleIcon htmlColor="#2D9CDB" style={{ marginRight: '6px' }} />
                        ) : (
                          <RadioButtonUncheckedIcon
                            style={{ marginRight: '6px' }}
                            htmlColor={getFeatureBackgroundColor(feature, theme)}
                          />
                        )}
                        {feature.name.trimEnd()}
                      </Text>
                    </>
                  ))}
              </Collapse>
            </>
          )}
        </Card>

        <CardBottom
          radius="0px 0px 4px 4px"
          style={{
            borderColor: theme.palette.mode === 'dark' ? 'white' : theme.palette.color.cardBorder,
            display: 'flex',
            alignItems: 'center',
            paddingTop: '10px',
            marginBottom: '0px',
            height: '47px',
          }}>
          {feature.owner && (
            <Tooltip color="dark" label={feature.owner}>
              <Avatar
                sx={{
                  width: 24,
                  height: 24,
                  fontSize: 10,
                  fontWeight: 'bold',
                  backgroundColor: '#d156a1',
                  marginRight: '10px',
                }}>
                {feature.owner
                  ?.split(' ')
                  .map((n) => n[0])
                  .join('')}
              </Avatar>
            </Tooltip>
          )}
          {showRank && feature.ranks?.[selectedValueStream] && (
            <Text size="xs" c="darkGrey.0">
              Rank: {feature.ranks[selectedValueStream]}
            </Text>
          )}

          <Text ml={10}></Text>

          {feature.features?.aggregate?.count > 0 && (
            <Tooltip color="dark" label="Open Initiative view">
              <ActionIcon
                ml={4}
                variant="transparent"
                color="var(--mantine-color-buttonIcon)"
                onClick={(event) => handleNavigate(feature.id, event)}
                style={{ position: 'absolute', right: '10px' }}>
                <SvgDashboard />
              </ActionIcon>
            </Tooltip>
          )}
        </CardBottom>
      </CardContainer>
    );
  }, [opened, feature, borderColor, isOverlay, theme, clicked, initiativeLength, handleNavigate, valueStreams]);

  return renderKanbanItem();
};

const MemoizedKanbanItem = memo(KanbanItem, (prevProps, nextProps) => isEqual(prevProps, nextProps));
MemoizedKanbanItem.displayName = 'KanbanItem';

export default MemoizedKanbanItem;
