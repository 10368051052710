import { gql } from '@apollo/client';

export const STRATEGIC_THEME_FIELDS = gql`
  fragment StrategicThemeFields on strategic_themes {
    id
    name
    description
    investment
    projects
  }
`;

export const GET_STRATEGIC_THEMES = gql`
  ${STRATEGIC_THEME_FIELDS}
  query GetStrategicThemes {
    strategic_themes(order_by: { id: asc }) {
      ...StrategicThemeFields
    }
  }
`;

export const INSERT_STRATEGIC_THEME = gql`
  ${STRATEGIC_THEME_FIELDS}
  mutation InsertStrategicTheme($theme: strategic_themes_insert_input! = {}) {
    strategic_theme: insert_strategic_themes_one(object: $theme) {
      ...StrategicThemeFields
    }
  }
`;

export const UPDATE_STRATEGIC_THEME = gql`
  ${STRATEGIC_THEME_FIELDS}
  mutation UpdateStrategicTheme($themeId: Int!, $theme: strategic_themes_set_input = {}) {
    update_strategic_themes_by_pk(pk_columns: { id: $themeId }, _set: $theme) {
      ...StrategicThemeFields
    }
  }
`;

export const DELETE_STRATEGIC_THEME = gql`
  ${STRATEGIC_THEME_FIELDS}
  mutation DeleteStrategicTheme($id: Int!) {
    delete_strategic_themes_by_pk(id: $id) {
      ...StrategicThemeFields
    }
  }
`;
