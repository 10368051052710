import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { Button, Card, CardContent } from '@mui/material';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import { useForm } from 'react-hook-form';

import { GET_PROJECTS } from '../../../containers/Organisation/Projects/graphql';
import { GET_SELECTED_PI } from '../../../containers/Settings/ProgramIncrement/graphql';
import {
  DELETE_MILESTONE,
  INSERT_MILESTONE,
  UPDATE_MILESTONE,
} from '../../../containers/ValueStream/Milestones/graphql';
import { resetDrawerDetails, selectedValueStreamVar } from '../../../reactiveVariables';
import { BUTTONS } from '../../../utils/formConstants';
import { deleteUpdate, insertUpdate } from '../../../utils/graphQLUtils';
import { removeIdField, sortValues } from '../../../utils/helpers';
import {
  Field,
  FormContext,
  renderDatePicker,
  renderTextField,
  AutoCompleteWrapper,
} from '../../Common/FormFieldsHooks';

const MilestoneDetails = (props, ref) => {
  const { data: { selectedPI = [] } = {} } = useQuery(GET_SELECTED_PI);
  const { data: { projects: strategic_themes = [] } = {} } = useQuery(GET_PROJECTS);

  const [insertMilestone] = useMutation(INSERT_MILESTONE);
  const [updateMilestone] = useMutation(UPDATE_MILESTONE);
  const [deleteMilestone] = useMutation(DELETE_MILESTONE);

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);

  const selectedValueStream = useReactiveVar(selectedValueStreamVar);

  const {
    handleSubmit,
    watch,
    control,
    register,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      programIncrement: selectedPI,
      ...props.element,
    },
  });

  useEffect(() => {
    if (props.element) {
      register('id', { value: props.element.id });
    }
  }, [props.element]);

  useImperativeHandle(ref, () => ({
    handleSave: () => {
      handleSubmit(onSubmit)();
    },
    handleDelete: () => {
      if (props.element && props.element.id) setOpenConfirmation(true);
    },
    handleClose: () => {
      props.canEdit && isDirty ? setOpenWarning(!openWarning) : resetDrawerDetails();
    },
  }));

  const handleDeleteConfirm = () => {
    deleteMilestone({
      variables: { id: milestone.id },
      update: deleteUpdate('milestone'),
    });
    resetDrawerDetails();
  };

  const onSubmit = (values) => {
    const milestoneToSave = removeIdField(values);

    if (milestoneToSave.strategic_theme) {
      milestoneToSave.strategic_theme = milestoneToSave.strategic_theme.id;
    }

    values.id
      ? updateMilestone({
          variables: {
            milestone: milestoneToSave,
            milestoneId: values.id,
          },
        })
      : insertMilestone({
          variables: {
            milestone: {
              ...milestoneToSave,
              valueStream: selectedValueStream,
            },
          },
          update: insertUpdate('milestone'),
        });

    resetDrawerDetails();
  };

  const milestone = watch();

  return (
    <Card elevation={0}>
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContext.Provider value={{ control, errors }}>
            <Field name="name" renderField={renderTextField} label="Name" size={12} required />
            <Field required name="date" renderField={renderDatePicker} label="Event Date" size={12} />
            <AutoCompleteWrapper
              errors={errors}
              idField="id"
              label="Project"
              multiple={false}
              name="strategic_theme"
              control={control}
              options={sortValues(strategic_themes).filter((theme) => theme.name)}
              size={12}
            />
            <Field
              name="description"
              multiline
              maxRows="100"
              renderField={renderTextField}
              label="Description"
              size={12}
            />
          </FormContext.Provider>
        </form>
      </CardContent>
      <ConfirmationDialog
        open={openConfirmation}
        title={`Delete event: ${milestone.name}`}
        text="Are you sure you want to delete this event ?"
        handleOk={handleDeleteConfirm}
        handleCancel={() => setOpenConfirmation(false)}
      />
      <ConfirmationDialog
        open={openWarning}
        title="Unsaved changes"
        text="You have unsaved changes. What would you like to do?">
        <Button
          onClick={() => {
            setOpenWarning(!openWarning);
            handleSubmit(onSubmit)();
          }}
          color="primary">
          {BUTTONS.SAVE}
        </Button>
        <Button onClick={() => resetDrawerDetails()} color="primary">
          {BUTTONS.DISCARD}
        </Button>
        <Button onClick={() => setOpenWarning(!openWarning)} color="primary">
          {BUTTONS.CANCEL}
        </Button>
      </ConfirmationDialog>
    </Card>
  );
};

export default forwardRef(MilestoneDetails);
