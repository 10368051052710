import React, { useState, useEffect, useRef } from 'react';

import { Box, Text, Menu, MenuItem, Divider } from '@mantine/core';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import ConfirmationDialog from '../../../components/Common/ConfirmationDialog';
import { color, lightColors } from '../../../shared/styles/color';
import { ContentInner, AttachmentContainer, ItemCount } from '../style';

export const WhatTab = ({ handleFileChange, handleDrop, type, spotlightAttachments, onAttachmentDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAttachmentIndex, setSelectedAttachmentIndex] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const [fileError, setFileError] = useState('');
  const menuWrapperRef = useRef(null);

  const MAX_FILE_SIZE = 5 * 1024 * 1024;

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDropFiles = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;

    // Check file size before uploading
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > MAX_FILE_SIZE) {
        setFileError('File is too large. Maximum allowed size is 5MB.');
        return;
      }
    }

    setFileError('');
    handleDrop(event);
  };

  const handleFileChangeWithSizeCheck = (event) => {
    const files = event.target.files;
    const file = files[0];

    if (file && file.size > MAX_FILE_SIZE) {
      setFileError('File is too large. Maximum allowed size is 5MB.');
      return;
    }

    setFileError('');
    handleFileChange(event);
  };

  const handleMenuOpen = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedAttachmentIndex(index); // Set selected index here, before opening the confirmation
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = (file) => {
    const fileUrl = `https://${type}.s3.eu-west-1.amazonaws.com/${file}`;

    fetch(fileUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.blob();
      })
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = file;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => {
        console.error('Download failed:', error);
      });
  };

  // Close the menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuWrapperRef.current &&
        !menuWrapperRef.current.contains(event.target) &&
        !anchorEl.contains(event.target)
      ) {
        handleMenuClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [anchorEl]);

  return (
    <ContentInner style={{ margin: '18px 20px', paddingBottom: '45px' }}>
      <ConfirmationDialog
        open={openConfirmation}
        title={`Delete attachment`}
        text={`Are you sure you want to delete this attachment?`}
        handleOk={() => {
          if (selectedAttachmentIndex !== null) {
            onAttachmentDelete(selectedAttachmentIndex);
          }
          setSelectedAttachmentIndex(null);
          setOpenConfirmation(false);
          handleMenuClose();
        }}
        handleCancel={() => {
          setOpenConfirmation(false);
          setSelectedAttachmentIndex(null);
        }}
      />

      <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <Text fw={550}>
          Add attachments <ItemCount>{spotlightAttachments.length}</ItemCount>
        </Text>
        <AddIcon
          style={{ width: '20px', cursor: 'pointer' }}
          onClick={() => document.getElementById('file-input').click()}
        />
      </Box>

      <Text fw={400} c={color.secondary} style={{ fontSize: '12px' }}>
        Drag and drop a file here to upload or browse to choose a file. 5MB max file size.
      </Text>

      {fileError && (
        <Text c="red" style={{ fontSize: '12px' }}>
          {fileError}
        </Text>
      )}

      <input type="file" id="file-input" onChange={handleFileChangeWithSizeCheck} style={{ display: 'none' }} />

      <Box
        onDragOver={handleDragOver}
        onDrop={handleDropFiles}
        style={{
          border: '2px dashed #ccc',
          padding: '20px',
          marginTop: '10px',
          borderRadius: '4px',
          textAlign: 'center',
        }}>
        <Text>Drop files here</Text>
      </Box>

      {spotlightAttachments.length > 0 && (
        <ul style={{ padding: 0 }}>
          {spotlightAttachments.map((file, index) => (
            <li key={index} style={{ listStyleType: 'none', position: 'relative' }}>
              <AttachmentContainer>
                {file.substring(file.indexOf('/') + 1)}

                <MoreHorizIcon
                  style={{ cursor: 'pointer', position: 'relative', top: '10px', float: 'right' }}
                  onClick={(event) => handleMenuOpen(event, index)}
                />
              </AttachmentContainer>

              {selectedAttachmentIndex === index && Boolean(anchorEl) && (
                <div ref={menuWrapperRef}>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    slotProps={{
                      paper: {
                        sx: {
                          width: '200px',
                          borderRadius: '15px',
                          marginTop: '10px',
                        },
                      },
                    }}>
                    <div
                      style={{
                        position: 'absolute',
                        backgroundColor: 'white',
                        zIndex: 999,
                        border: `1px solid ${lightColors.tileBorder}`,
                        borderRadius: '8px',
                        right: '0px',
                        top: '30px',
                        width: '121px',
                      }}>
                      <MenuItem
                        onClick={() => {
                          handleDownload(file);
                          handleMenuClose();
                        }}>
                        Download
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        style={{ color: color.red }}
                        onClick={() => {
                          setOpenConfirmation(true); // Open confirmation dialog
                        }}>
                        Delete
                      </MenuItem>
                    </div>
                  </Menu>
                </div>
              )}
            </li>
          ))}
        </ul>
      )}
    </ContentInner>
  );
};
