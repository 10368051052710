import React from 'react';

import { Route } from 'react-router-dom';

import { DeliveryReadinessTable } from './DeliveryReadiness';
import FlowDashboard from './Flow';
import { NewTable } from './Initiatives/newTable';
import InitiativesDashboard from './InitiativesDashboard';
import InitiativeView from './InitiativeView';
import InitiativesKanban from './Kanban';
import Steps from './Steps';
import BigAgileTable from '../../components/Common/BigAgileTable';

const getValueStreamRoutes = (user) => (
  <>
    <Route user={user} path="/valueStream" element={<InitiativesDashboard />} />
    <Route user={user} path="/valueStream/steps" element={<Steps />} />
    <Route user={user} path="/valueStream/initiatives" element={<NewTable type="initiative" />} />
    <Route user={user} path="/valueStream/initiative/:initiativeId?" element={<InitiativeView />} />
    <Route user={user} path="/valueStream/kanban" element={<InitiativesKanban />} />
    <Route user={user} path="/valueStream/milestones" element={<BigAgileTable type="milestone" />} />
    <Route user={user} path="/valueStream/flow" element={<FlowDashboard />} />
    <Route user={user} path="/valueStream/productThemes" element={<BigAgileTable type="productTheme" />} />
    <Route user={user} path="/valueStream/deliveryReadiness" element={<DeliveryReadinessTable />} />
  </>
);

export default getValueStreamRoutes;
