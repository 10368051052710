import React, { useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import { Modal, SegmentedControl, Tooltip, useMantineColorScheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Checkbox, FormControlLabel, IconButton, Toolbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

import { SHOW_DEPENDENCIES, TOGGLE_PLAN_VALUES, TOGGLE_POINTS_VALUES, TOGGLE_VALUES } from './constants';
import { styles } from './styles';
import { envConfig } from '../../../../../app';
import Import from '../../../../../assets/images/Import';
import Select from '../../../../../components/Common/Select';
import { loggedInUserVar } from '../../../../../reactiveVariables';
import { TOOLING_NAME } from '../../../../App/constants';
import FeatureImportModal from '../FeatureImportModal';

const useStyles = makeStyles(styles);

const ToolBarSummary = (props) => {
  const {
    backlogs = [],
    backlog,
    selectedItem,
    selectedPlan,
    progressBy,
    onBacklogChange,
    showAllDependencies,
    isShownAllDependencies = true,
    changeShowAllDependencies,
    handleSwitch,
    handlePlanSwitch,
    handleProgressSwitch,
    createMilestone,
    title,
  } = props;
  const classes = useStyles(props);
  const isDisabledForObjectives = selectedItem === 'objectives';
  const { colorScheme } = useMantineColorScheme();
  const [openImport, setOpenImport] = useState(false);
  const matches = useMediaQuery('(min-width: 960px)');
  const user = useReactiveVar(loggedInUserVar);
  const { roles = [] } = user;
  const canImport = roles.includes(`cru-product`);

  return (
    <>
      <Toolbar className={classes.appBar} style={{ alignItems: 'center', marginBottom: '7px' }}>
        <span style={{ fontWeight: 600, fontSize: '20px', marginRight: '6px' }}>{title}</span>
        <IconButton
          key="add"
          aria-label="Add"
          color="inherit"
          size="small"
          sx={{ marginRight: '12px' }}
          onClick={createMilestone}>
          <AddBoxIcon />
        </IconButton>
        {!!backlogs.length && (
          <Select
            addAllOption={true}
            onChange={onBacklogChange}
            defaultValue={backlog || 0}
            placeholder="Project"
            border={false}
            options={backlogs}
            title="Project"
            align="left"
          />
        )}
        {canImport && (
          <Tooltip
            transition={'scale'}
            withArrow
            zIndex={1000}
            label={`Bulk import features from ${TOOLING_NAME[envConfig.tooling]}`}>
            <IconButton
              key="import"
              aria-label="Import"
              color="inherit"
              size="small"
              onClick={() => setOpenImport(true)}>
              <Import />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
      <Toolbar className={classes.appBar}>
        <SegmentedControl
          styles={{
            root: { backgroundColor: colorScheme === 'dark' ? '#484851' : '#FFFFFF' },
            label: { color: colorScheme === 'dark' ? '#ffffff' : '#db36c0' },
          }}
          radius="xl"
          value={selectedItem}
          color={colorScheme === 'dark' ? 'darkGrey.0' : 'bigagilePink.0'}
          onChange={handleSwitch}
          data={TOGGLE_VALUES}
        />
        <SegmentedControl
          disabled={isDisabledForObjectives}
          styles={{
            label: { color: colorScheme === 'dark' ? '#ffffff' : '#db36c0' },
            root: { margin: '0 12px', backgroundColor: colorScheme === 'dark' ? '#484851' : '#FFFFFF' },
          }}
          radius="xl"
          value={selectedPlan}
          color={colorScheme === 'dark' ? 'darkGrey.0' : 'bigagilePink.0'}
          onChange={handlePlanSwitch}
          data={TOGGLE_PLAN_VALUES}
        />
        {progressBy && (
          <SegmentedControl
            disabled={isDisabledForObjectives}
            styles={{
              label: { color: colorScheme === 'dark' ? '#ffffff' : '#db36c0' },
              root: { margin: '0 12px', backgroundColor: colorScheme === 'dark' ? '#484851' : '#FFFFFF' },
            }}
            radius="xl"
            value={progressBy}
            color={colorScheme === 'dark' ? 'darkGrey.0' : 'bigagilePink.0'}
            onChange={handleProgressSwitch}
            data={TOGGLE_POINTS_VALUES}
          />
        )}
        {isShownAllDependencies && (
          <div className={classes.objectivesToggle}>
            <FormControlLabel
              classes={{ label: classes.showAllDependencies }}
              control={
                <Checkbox
                  disabled={isDisabledForObjectives}
                  checked={showAllDependencies}
                  onChange={changeShowAllDependencies}
                  name="checkbox-show"
                />
              }
              label={SHOW_DEPENDENCIES}
            />
          </div>
        )}
      </Toolbar>
      <Modal
        zIndex={10000}
        opened={openImport}
        overflow="inside"
        centered
        size="50vw"
        fullScreen={!matches}
        onClose={() => setOpenImport(false)}
        title="Import Features">
        <FeatureImportModal onClose={setOpenImport} />
      </Modal>
    </>
  );
};

ToolBarSummary.propTypes = {
  backlogs: PropTypes.array,
  backlog: PropTypes.number,
  selectedItem: PropTypes.string,
  selectedPlan: PropTypes.string,
  onBacklogChange: PropTypes.func,
  showAllDependencies: PropTypes.bool,
  isShownAllDependencies: PropTypes.bool,
  changeShowAllDependencies: PropTypes.func,
  handleSwitch: PropTypes.func,
  handlePlanSwitch: PropTypes.func,
  createMilestone: PropTypes.func,
};

export default ToolBarSummary;
