import { Chip, Grid, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import PlannedVsDelivered from './PlannedDeliveredGraph';
import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_FEATURES_FOR_PROGRAMME } from '../../../../Product/Features/graphql';
import { GET_OBJECTIVES } from '../../Objectives/graphql';
import { GET_PROGRAM_INCREMENTS_FOR_PROGRAMME } from '../../../../Settings/ProgramIncrement/graphql';
import { some, takeRight } from 'lodash';
import { GET_DEPENDENCIES, GET_DEPENDENCIES_FOR_PROGRAMME } from '../../Dependencies/graphql';
import { GET_TEAMS } from '../../../../Settings/GraphQL/teams';
import { Text } from '@mantine/core';
import { Dot, Metric, MetricPaper } from '../../../../ValueStream/components/Common';
import { useDeliveryMetrics } from '../utils';
import { selectedInitiativeVar, selectedProgrammeVar, selectedTeamVar } from '../../../../../reactiveVariables';
import DaysToClose from './DaysToClose';
import ProgramPredictability from './ProgramPredicatability';
import ProgramIncrementVelocity from './ProgramIncrementVelocity';
import SvgFeatures from '../../../../../assets/images/Features';
import SvgDependencies from '../../../../../assets/images/Dependencies';
import SvgStories from '../../../../../assets/images/Stories';
import SvgTeams from '../../../../../assets/images/Teams';
import { DashboardCard } from '../../../../../components/Common/Elements';

const colors = ['#D8A24E', '#EAA4A5', '#4F74CB', '#B4CD67'];

const TopLine = ({ lastFourPis }) => {
  const piChips = lastFourPis?.map((pi, index) => (
    <Chip
      key={pi.id}
      label={
        <span>
          <Dot sx={{ height: '8px', width: '8px', backgroundColor: 'color.white' }} />
          {pi.name}
        </span>
      }
      sx={{
        height: '30px',
        color: 'color.white',
        fontWeight: 'bold',
        fontSize: '13px',
        backgroundColor: colors[index],
        borderRadius: 2,
        margin: '0 8px',
        '& .MuiChip-label': { padding: '0 6px' },
      }}
    />
  ));

  const firstChip = piChips.shift();

  return (
    <Grid
      xs={12}
      sx={{ borderRadius: '8px', height: '50px', backgroundColor: 'color.paper' }}
      style={{ margin: '8px 0px 6px 8px', padding: '0 16px' }}
      container
      alignItems={'center'}
      item
      spacing={1}>
      <Text color="secondary" sx={{ marginRight: '8px' }}>
        Past {lastFourPis.length}:{' '}
      </Text>
      {piChips.reduce((prev, curr) => [prev, ' + ', curr], firstChip)}
    </Grid>
  );
};

const MiddleLine = ({ features, dependencies, totalStories, totalStoryPoints, teams, nbOfPi }) => {
  const theme = useTheme();

  return (
    <Grid sx={{ height: '120px' }} container item spacing={1}>
      <MetricPaper title="Features" icon={<SvgFeatures color={theme.palette.color.buttonIcon} />}>
        <Metric
          value={(features.length / nbOfPi || 0).toFixed()}
          name="Avg Planned"
          dotColor={theme.palette.color.inProgress}
        />
        <Metric
          value={(features.filter((feature) => feature.status === 'Done').length / nbOfPi || 0).toFixed()}
          name="Avg Delivered"
          dotColor={theme.palette.color.done}
        />
      </MetricPaper>
      <MetricPaper title="Dependencies" icon={<SvgDependencies color={theme.palette.color.buttonIcon} />}>
        <Metric value={dependencies.length / nbOfPi} name="Avg Per Increment" />
        {/*<Metric value={dependencies.length / nbOfPi} name="Avg Cross Program" />*/}
      </MetricPaper>
      <MetricPaper title="Stories" icon={<SvgStories color={theme.palette.color.buttonIcon} />}>
        <Metric value={totalStories.toFixed()} name="Avg Story Count" />
        <Metric value={totalStoryPoints.toFixed()} name="Avg Story Points" />
      </MetricPaper>
      <MetricPaper title="Teams" icon={<SvgTeams color={theme.palette.color.buttonIcon} />}>
        <Metric value={teams.length} name="Current Teams" />
        <Metric value={(totalStoryPoints / (teams.length || 1) || 0).toFixed()} name="Avg SP Per Team" />
      </MetricPaper>
    </Grid>
  );
};

const BottomLine = ({ lastFourPIs, objectives, features }) => (
  <React.Fragment>
    <DashboardCard title="Planned v Delivered">
      <PlannedVsDelivered programIncrements={lastFourPIs} features={features} />
    </DashboardCard>
    <DashboardCard title="Days to Close" key="daysToClose">
      {!!lastFourPIs.length && !!features.length && (
        <DaysToClose key={1} programIncrements={lastFourPIs} features={features} />
      )}
    </DashboardCard>
    <DashboardCard title="Increment Velocity">
      {lastFourPIs && <ProgramIncrementVelocity programIncrements={lastFourPIs} />}
    </DashboardCard>
    <DashboardCard title="Programme Predictability" key="predictability">
      {!!lastFourPIs.length && !!objectives.length && (
        <ProgramPredictability programIncrements={lastFourPIs} objectives={objectives} />
      )}
    </DashboardCard>
  </React.Fragment>
);

const MultiPiDashboard = ({}) => {
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES_FOR_PROGRAMME);
  const { data: { objectives = [] } = {} } = useQuery(GET_OBJECTIVES);
  const { data: { programIncrements = [] } = {} } = useQuery(GET_PROGRAM_INCREMENTS_FOR_PROGRAMME);
  const { data: { dependencies = [] } = {} } = useQuery(GET_DEPENDENCIES_FOR_PROGRAMME);
  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);

  const selectedTeams = useReactiveVar(selectedTeamVar);
  const selectedInitiative = useReactiveVar(selectedInitiativeVar);
  const selectedProgramme = useReactiveVar(selectedProgrammeVar);

  const lastFourPIs = takeRight(
    programIncrements.filter((pi) => pi.status !== 'planning'),
    4,
  );

  const lastFourPiIds = lastFourPIs.map((pi) => pi.id);

  function hasStoriesInPI(feature, id) {
    return !!feature.metrics?.metricsPerIncrement?.[id];
  }

  const filteredFeatures = useMemo(
    () =>
      features.filter(
        (feature) =>
          feature.teamId &&
          (lastFourPiIds.includes(feature.programIncrement) ||
            some(lastFourPiIds, (id) => hasStoriesInPI(feature, id))) &&
          (!selectedTeams.length || selectedTeams.includes(feature.teamId)) &&
          (!selectedInitiative || feature.initiativeId === selectedInitiative),
      ),
    [features, selectedTeams, selectedInitiative, lastFourPiIds],
  );

  const { doneStories, toDoStories, inProgressStories, deferredStories, totalStoryPoints } = useDeliveryMetrics(
    filteredFeatures,
    lastFourPiIds,
  );

  const middleLineProps = {
    features: filteredFeatures,
    dependencies,
    teams: teams.filter((team) => team.programmes?.includes(selectedProgramme)),
    totalStoryPoints: totalStoryPoints / (lastFourPIs.length || 1),
    totalStories: (doneStories + toDoStories + inProgressStories + deferredStories) / (lastFourPIs.length || 1),
    nbOfPi: lastFourPIs.length,
  };

  return (
    <Grid container spacing={1}>
      {lastFourPIs.length && <TopLine lastFourPis={lastFourPIs} />}
      <Grid container item xs={12} spacing={1}>
        <MiddleLine {...middleLineProps} />
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <BottomLine features={filteredFeatures} objectives={objectives} lastFourPIs={lastFourPIs} />
      </Grid>
    </Grid>
  );
};

export default MultiPiDashboard;
//
