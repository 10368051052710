import * as d3 from 'd3';
import { DateTime } from 'luxon';
import React, { useRef } from 'react';
import { Flex } from '@mantine/core';
import { DivColumn } from './InitiativeDashboard.style';
import { darken } from '@mui/system/colorManipulator';
import { last } from 'lodash';
import { openDrawer } from '../../../reactiveVariables';

import DiamondSvg from '../../../assets/images/Diamond.svg';
import StarSvg from '../../../assets/images/Star.svg';
import { alpha } from '@mui/material/styles';
import { useTheme } from '@emotion/react';
import useDeepCompareEffect from 'use-deep-compare-effect';

const addTooltip = (svg, color, id, plannedDate, plannedStartDate, startDateTime, expectedDate, theme, initiative) => {
  const daysOverrun = expectedDate.diff(plannedDate, 'days').toObject().days;
  const tooltip = d3
    .select('body')
    .append('div')
    .attr('class', 'd3-tooltip')
    .style('position', 'absolute')
    .style('z-index', '10')
    .style('visibility', 'hidden')
    .style('padding', '10px')
    .style('border', `0.6px solid ${theme.palette.color.tileBorder}`)
    .style('background', theme.palette.color.background)
    .style('border-radius', '4px')
    .style('box-shadow', `0px 1px 0px 0px ${theme.palette.color.shadow}`)
    .style('color', 'black');

  svg
    .selectAll('.tooltip')
    .on('mouseover', function (event, d, i) {
      tooltip
        .html(
          `
                <div>
                    <span style="color:${theme.palette.text.primary}; font-weight: 600">
                        ${initiative.name}
                    </span>
                </div>
                          ${
                            plannedStartDate.isValid
                              ? `<div>
                    <span style="color:${theme.palette.text.secondary}">Planned Start: </span>
                    <span style="color:${theme.palette.text.primary}">${plannedStartDate.toLocaleString()}</span>
                </div>`
                              : ''
                          }
                          ${
                            startDateTime.isValid
                              ? `<div>
                    <span style="color:${theme.palette.text.secondary}">Actual Start: </span>
                    <span style="color:${theme.palette.text.primary}">${startDateTime.toLocaleString()}</span>
                </div>`
                              : ''
                          }
                ${
                  plannedDate.isValid
                    ? `<div>
                    <span style="color:${theme.palette.text.secondary}">Target Realisation: </span>
                    <span style="color:${theme.palette.text.primary}">${plannedDate.toLocaleString()}</span>
                </div>`
                    : ''
                }
                ${
                  expectedDate.isValid
                    ? `<div>
                    <span style="color:${theme.palette.text.secondary}">Expected Completion: </span>
                    <span style="color:${theme.palette.text.primary}">${expectedDate.toLocaleString()}</span>
                </div>
                `
                    : ''
                }
                ${
                  plannedDate.isValid && Math.abs(daysOverrun)
                    ? `<div>
                    <span style="color:${daysOverrun > 0 ? theme.palette.color.red : theme.palette.color.green}">${
                        daysOverrun > 0 ? 'Overrun' : 'Early'
                      } By: </span>
                    <span style="color:${
                      daysOverrun > 0 ? theme.palette.color.red : theme.palette.color.green
                    }">${Math.abs(daysOverrun)} days</span>
                </div>`
                    : ''
                }
          `,
        )
        .style('visibility', 'visible');
      d3.selectAll(`#id${id}`)
        .attr('stroke', darken(color ?? '#FFFFFF80', 0.5))
        .style('cursor', 'pointer');
      const plannedStartColor = getPlannedStartColor(plannedStartDate, startDateTime, color, initiative.isDone, theme);
      d3.select(`#id${id}-light`).attr('stroke', alpha(plannedStartColor, 0.75)).style('cursor', 'pointer');
    })
    .on('mousemove', function (event) {
      tooltip.style('top', event.pageY - 10 + 'px').style('left', event.pageX + 10 + 'px');
    })
    .on('mouseout', function (event) {
      tooltip.html(``).style('visibility', 'hidden');
      d3.selectAll(`#id${id}`).attr('stroke', color).style('cursor', 'default');
      const plannedStartColor = getPlannedStartColor(plannedStartDate, startDateTime, color, initiative.isDone, theme);
      d3.select(`#id${id}-light`).attr('stroke', alpha(plannedStartColor, 0.5)).style('cursor', 'default');
    });
};

const getPlannedStartColor = (plannedStartDate, startDateTime, defaultColor, isDone, theme) => {
  if (!plannedStartDate.isValid || !startDateTime.isValid || isDone) return defaultColor;
  return plannedStartDate > startDateTime ? theme.palette.color.green : theme.palette.color.red;
};

const Line = ({
  initiative,
  width,
  height,
  lastChild,
  noBorder,
  startDate,
  endDate,
  color,
  columnDates,
  firstChild,
  expanded,
}) => {
  const ref = useRef();
  const theme = useTheme();
  const visualizeTicks = (scale, ref, width, height, initiative, animate, tickArguments) => {
    if (tickArguments === undefined) tickArguments = [];

    scale.range([0, width]);

    const plannedRealisationDate = DateTime.fromISO(initiative.plannedRealisationDate);
    const plannedStartDate = DateTime.fromISO(initiative.plannedStartDate);
    const endDateTime = DateTime.fromISO(endDate);
    const startDateTime = DateTime.fromISO(startDate);

    const line = d3
      .line()
      .x((d) => scale(d))
      .y((d) => 35)
      .curve(d3.curveNatural);

    const svg = d3.select(ref.current).attr('width', '100%').attr('height', height);

    svg.selectAll('*').remove();

    const lineEndDate =
      endDateTime > plannedRealisationDate && startDateTime < plannedRealisationDate
        ? plannedRealisationDate
        : endDateTime;
    const lineStartDate = startDate ? startDateTime : plannedRealisationDate;
    const plannedStartColor = getPlannedStartColor(plannedStartDate, startDateTime, color, initiative.isDone, theme);

    const pathPlannedStart = svg
      .append('path')
      .datum([plannedStartDate.toJSDate(), lineStartDate.toJSDate()])
      .attr('id', 'id' + initiative.id + '-light')
      .classed('tooltip', true)
      .attr('d', line)
      .attr('stroke-linecap', 'round')
      .attr('class', 'line')
      .attr('stroke', alpha(plannedStartColor, 0.5))
      .attr('stroke-width', 24);
    pathPlannedStart.on('click', () => openDrawer(initiative, 'initiative'));

    const plannedStartLength = pathPlannedStart.node()?.getTotalLength() || 0;
    pathPlannedStart
      .attr('stroke-dasharray', plannedStartLength + ' ' + plannedStartLength)
      .attr('stroke-dashoffset', plannedStartLength)
      .attr('fill', 'none')
      .classed('tooltip', true)
      .transition()
      .ease(d3.easeLinear)
      .attr('stroke-dashoffset', 0)
      .duration(animate ? 250 : 1);

    const path = svg
      .append('path')
      .datum([lineStartDate.toJSDate(), lineEndDate.toJSDate()])
      .attr('id', 'id' + initiative.id)
      .classed('tooltip', true)
      .attr('d', line)
      .attr('stroke-linecap', 'round')
      .attr('class', 'line')
      .attr('stroke', color ?? '#FFFFFF80', 0.5)
      .attr('stroke-width', 24);

    path.on('click', () => {
      openDrawer(initiative, 'initiative');
    });

    const length = path.node()?.getTotalLength() || 0;

    path
      .attr('stroke-dasharray', length + ' ' + length)
      .attr('stroke-dashoffset', length)
      .attr('fill', 'none')
      .classed('tooltip', true)
      .transition()
      .ease(d3.easeLinear)
      .attr('stroke-dashoffset', 0)
      .duration(animate ? 250 : 1);

    if (plannedRealisationDate.isValid) {
      const secondLineEndDate = endDateTime > plannedRealisationDate ? endDateTime : plannedRealisationDate;
      const path2 = svg
        .append('path')
        .datum([lineEndDate.toJSDate(), secondLineEndDate.toJSDate()])
        .attr('d', line)
        .classed('tooltip', true)
        .attr('id', 'id' + initiative.id + '-light')
        .attr('class', 'line')
        .attr('stroke-linecap', 'round')
        .attr('stroke', alpha(color ?? '#FFFFFF80', 0.5))
        .attr('stroke-width', 24);

      const length2 = path2.node()?.getTotalLength() || 0;

      path2
        .attr('stroke-dasharray', length2 + ' ' + length2)
        .attr('stroke-dashoffset', length2)
        .classed('tooltip', true)
        .transition()
        .ease(d3.easeLinear)
        .attr('stroke-dashoffset', 0)
        .delay(250)
        .duration(animate ? 250 : 1);

      path2.on('click', () => {
        openDrawer(initiative, 'initiative');
      });
    }

    if (endDateTime.isValid) {
      const image = svg
        .selectAll(null)
        .data([{ x: endDateTime.toJSDate(), y: 10 }])
        .enter();

      image
        .append('circle')
        .attr('cx', (d) => scale(d.x))
        .attr('cy', 35)
        .attr('r', 11)
        .attr('stroke', 'white')
        .attr('stroke-width', 2)
        .attr('fill', 'none')
        .attr('opacity', 0)
        .classed('tooltip', true)
        .transition()
        .ease(d3.easeLinear)
        .delay(endDateTime < plannedRealisationDate ? 250 : 500)
        .attr('opacity', 1)
        .duration(animate ? 200 : 1);

      image
        .append('image')
        .attr('x', (d) => scale(d.x) - 8)
        .attr('y', 27)
        .attr('xlink:href', DiamondSvg)
        .attr('width', 16)
        .attr('height', 16)
        .attr('fill', 'white')
        .attr('opacity', 0)
        .classed('tooltip', true)
        .transition()
        .delay(endDateTime < plannedRealisationDate ? 250 : 500)
        .ease(d3.easeLinear)
        .attr('opacity', 1)
        .duration(animate ? 200 : 1);
    }

    if (plannedRealisationDate.isValid && plannedRealisationDate > startDateTime) {
      const estimatedDate = svg
        .selectAll(null)
        .data([{ x: plannedRealisationDate.toJSDate(), y: 10 }])
        .enter();

      estimatedDate
        .append('circle')
        .attr('cx', (d) => scale(d.x))
        .attr('cy', 35)
        .attr('r', 11)
        .attr('stroke', 'white')
        .attr('stroke-width', 2)
        .attr('fill', 'none')
        .classed('tooltip', true)
        .attr('opacity', 0)
        .transition()
        .ease(d3.easeLinear)
        .delay(endDateTime < plannedRealisationDate ? 500 : 250)
        .attr('opacity', 1)
        .duration(animate ? 200 : 1);

      estimatedDate
        .append('image')
        .attr('x', (d) => scale(d.x) - 8)
        .attr('y', 26)
        .attr('xlink:href', StarSvg)
        .attr('width', 16)
        .attr('height', 16)
        .classed('tooltip', true)
        .attr('fill', 'white')
        .attr('opacity', 0)
        .transition()
        .delay(endDateTime < plannedRealisationDate ? 500 : 250)
        .ease(d3.easeLinear)
        .attr('opacity', 1)
        .duration(animate ? 200 : 1);
    }

    addTooltip(
      svg,
      color,
      initiative.id,
      plannedRealisationDate,
      plannedStartDate,
      startDateTime,
      endDateTime,
      theme,
      initiative,
    );
    return svg.node();
  };

  const axisStartDate = columnDates[0].start.toJSDate();
  const axisEndDate = last(columnDates).end.toJSDate();

  useDeepCompareEffect(() => {
    const plannedRealisationDate = DateTime.fromISO(initiative.plannedRealisationDate);
    const plannedStartDate = DateTime.fromISO(initiative.plannedStartDate);
    if ((startDate && endDate) || (plannedStartDate && plannedRealisationDate))
      visualizeTicks(d3.scaleTime().domain([axisStartDate, axisEndDate]), ref, width, height, initiative, true);
  }, [width, height, endDate, startDate, columnDates, theme.palette.mode, initiative]);

  return (
    <>
      <svg
        ref={ref}
        preserveAspectRatio="xMinYMin meet"
        viewBox={`0 0 ${width} ${height}`}
        style={{ position: 'absolute' }}
      />
      <Flex style={{ height: '70px', width: '100%' }}>
        <DivColumn noBorder={noBorder} firstChild={firstChild} bottomBorder={!lastChild || expanded}></DivColumn>
        <DivColumn noBorder={noBorder} firstChild={firstChild} bottomBorder={!lastChild || expanded}></DivColumn>
        <DivColumn noBorder={noBorder} firstChild={firstChild} bottomBorder={!lastChild || expanded}></DivColumn>
        <DivColumn
          noBorder={noBorder}
          firstChild={firstChild}
          bottomBorder={!lastChild || expanded}
          style={{ borderRight: 'none' }}></DivColumn>
      </Flex>
    </>
  );
};

export default Line;
