export const ProductTheme = 'producttheme';

export const headCells = [
  {
    id: 'id',
    field: 'id',
    label: 'ID',
    disablePadding: false,
    filtering: false,
    defaultSort: 'asc',
    numeric: false,
    align: 'left',
    width: '3%',
  },
  {
    id: 'title',
    field: 'title',
    label: 'Title',
    disablePadding: false,
    numeric: false,
    align: 'left',
  },
  {
    id: 'strategicTheme',
    field: 'strategicTheme',
    label: 'Description',
    disablePadding: false,
    numeric: true,
    align: 'left',
  },
];
