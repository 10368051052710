import React, { useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { useTheme } from '@emotion/react';
import { Box, Text, Select, Divider, Combobox, InputBase } from '@mantine/core';
import { StarBorder as StarBorderIcon, Star as StarIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Menu, MenuItem, Avatar } from '@mui/material';

import ModalContainer from '../../../components/Modal';
import { GET_USERS } from '../../../containers/Settings/GraphQL/users';
import { color } from '../../../shared/styles/color';
import { ContentInner } from '../style';

const Section = ({
  title,
  items,
  modalOpen,
  setModalOpen,
  onUpdate,
  renderItem,
  editOwnerDetails,
  setEditOwnerDetails,
  spotlightOwners,
}) => {
  const theme = useTheme();

  const [selectedOption, setSelectedOption] = useState(editOwnerDetails.id);
  const [role, setRole] = useState(editOwnerDetails.role);
  const [showDropdown, setShowDropdown] = useState(false);

  const [data, setData] = useState([...new Set(spotlightOwners.map((owner) => owner.role))]);
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    if (editOwnerDetails) {
      setSelectedOption(editOwnerDetails.id);
      setRole(editOwnerDetails.role);
    }
  }, [editOwnerDetails]);

  const handleAddRole = (inputValue) => {
    if (!data.includes(inputValue)) {
      setData([...data, inputValue]);
      setFilteredData([...data, inputValue]);
    }
    setRole(inputValue);
  };

  const handleInputChange = (inputValue) => {
    setRole(inputValue);
    setFilteredData(data.filter((item) => item.toLowerCase().includes(inputValue.toLowerCase())));
  };

  return (
    <>
      <ContentInner
        style={{ margin: '18px 0px 9px', backgroundColor: theme.palette.mode === 'dark' ? '#242424' : color.grey }}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            padding: '0px 24px',
            height: '34px',
            color: theme.palette.mode === 'dark' ? '#C4C4C4' : 'inherit',
          }}>
          <Box style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
            <Text fw={550}>Owner</Text>
          </Box>
          <Box style={{ width: '45%', display: 'flex', alignItems: 'center' }}>
            <Text fw={550}>{title}</Text>
          </Box>
          <Box style={{ width: '45%' }}>
            <Text fw={550}>Role</Text>
          </Box>
          <AddIcon style={{ width: '20px', cursor: 'pointer' }} onClick={() => setModalOpen(true)} />
        </Box>
      </ContentInner>

      {spotlightOwners.map(renderItem)}

      <ModalContainer
        open={modalOpen}
        disabled={!(selectedOption && role)}
        close={() => {
          setModalOpen(false);
          setEditOwnerDetails({});
        }}
        onSubmit={() => {
          setModalOpen(false);
          handleAddRole(role);
          setRole('');
          onUpdate(selectedOption, role);
          setEditOwnerDetails({});
        }}
        primaryButtonText={'Add member'}
        title={'Add member to project'}>
        <Select
          label="Member"
          value={selectedOption}
          onChange={(value) => setSelectedOption(value)}
          data={items}
          searchable
          placeholder="Select a member"
          clearable
          required
          styles={{
            dropdown: {
              zIndex: 9999,
            },
          }}
        />

        <Combobox
          onOptionSubmit={(selectedRole) => setRole(selectedRole)}
          label="Role"
          required
          styles={{
            dropdown: {
              zIndex: 9999,
              display: showDropdown ? 'block' : 'none',
            },
          }}>
          <Combobox.Target>
            <InputBase
              placeholder="Select or type a role"
              value={role}
              onClick={() => setShowDropdown(true)}
              onBlur={() => setShowDropdown(false)}
              onChange={(event) => handleInputChange(event.currentTarget.value)}
              rightSection={
                <Box
                  style={{
                    pointerEvents: 'none',
                    position: 'relative',
                    bottom: '5px',
                  }}>
                  ⌄
                </Box>
              }
            />
          </Combobox.Target>

          <Combobox.Dropdown>
            {filteredData.length > 0 && (
              <Combobox.Options style={{ maxHeight: '300px', overflow: 'scroll' }}>
                {filteredData.map((item) => (
                  <Combobox.Option key={item} value={item} onClick={() => setShowDropdown(false)}>
                    {item}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            )}
            {role && (
              <Combobox.Footer>
                <Box
                  sx={{
                    padding: '8px',
                    borderTop: '1px solid #e9ecef',
                    cursor: 'pointer',
                    textAlign: 'center',
                    fontWeight: 500,
                  }}
                  onClick={() => {
                    handleAddRole(role);
                  }}>
                  Create "{role}"
                </Box>
              </Combobox.Footer>
            )}
          </Combobox.Dropdown>
        </Combobox>
      </ModalContainer>
    </>
  );
};

export const WhoTab = ({
  spotlightOwners,
  setSpotlightOwners,
  onMembersUpdate,
  onOwnerUpdate,
  selectedItem,
  activeValueStreams,
  showAddValueStreamsModal,
}) => {
  const theme = useTheme();

  const [showOwnerModal, setShowOwnerModal] = useState(false);
  const [editOwnerDetails, setEditOwnerDetails] = useState({ owner: '', id: '', role: '' });

  const [selectedStar, setSelectedStar] = useState(selectedItem?.owner);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);

  const { data: { users = [] } = {} } = useQuery(GET_USERS);

  const usersList = users.map((user) => ({
    label: `${user.firstName} ${user.lastName}`,
    value: user.id,
  }));

  const selectedValueStreams = selectedItem?.value_streams?.map((id) => {
    const stream = activeValueStreams.find((stream) => stream.id === id);
    return stream ? stream.name : null;
  });

  const handleUpdateOwners = (selectedOption, role) => {
    const updatedOwners = spotlightOwners.filter((owner) => owner.owner !== editOwnerDetails.id);

    updatedOwners.push({ owner: selectedOption, role });

    setSpotlightOwners(updatedOwners);
    onMembersUpdate(updatedOwners);
  };

  const handleMemberDelete = (user) => {
    const updatedMembers = spotlightOwners.filter((owner) => owner.owner !== user.owner);

    setSpotlightOwners(updatedMembers);
    onMembersUpdate(updatedMembers);
  };

  const editMember = (owner, user) => {
    setEditOwnerDetails({ owner: user.label, id: owner.owner, role: owner.role });
  };

  const handleMenuOpen = (event, member) => {
    setAnchorEl(event.currentTarget);
    setSelectedMember(member);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedMember(null);
  };

  return (
    <>
      <ContentInner
        style={{ margin: '18px 0px 15px', backgroundColor: theme.palette.mode === 'dark' ? '#242424' : color.grey }}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            padding: '0px 24px',
            height: '34px',
            color: theme.palette.mode === 'dark' ? '#C4C4C4' : 'inherit',
          }}>
          <Box style={{ width: '45%' }}>
            <Text fw={550}>Value Streams</Text>
          </Box>
          <AddIcon style={{ width: '20px', cursor: 'pointer' }} onClick={() => showAddValueStreamsModal(true)} />
        </Box>
      </ContentInner>

      {selectedValueStreams && (
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            marginLeft: '25px',
          }}>
          <Box>
            {selectedValueStreams.map((stream, index) => (
              <Text key={index}>{stream}</Text>
            ))}
          </Box>
        </Box>
      )}

      <Section
        title="Member"
        items={usersList}
        spotlightOwners={spotlightOwners}
        modalOpen={showOwnerModal}
        editOwnerDetails={editOwnerDetails}
        setEditOwnerDetails={setEditOwnerDetails}
        setModalOpen={setShowOwnerModal}
        onUpdate={handleUpdateOwners}
        renderItem={(owner) => {
          const user = usersList?.find((user) => user.value === owner.owner);

          return (
            <div key={owner.owner} style={{ marginLeft: '24px', fontSize: '14px' }}>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  height: '34px',
                }}>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '10px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setSelectedStar(owner.owner);
                    onOwnerUpdate(owner.owner);
                  }}>
                  {selectedStar === owner.owner ? (
                    <StarIcon style={{ color: color.purpulishPink }} />
                  ) : (
                    <StarBorderIcon style={{ color: color.purpulishPink }} />
                  )}
                </Box>

                <Box style={{ width: 'calc(50% - 24px)', display: 'flex', alignItems: 'center', paddingLeft: '32px' }}>
                  <Avatar
                    sx={{
                      width: 20,
                      height: 20,
                      fontSize: 10,
                      fontWeight: 'bold',
                      backgroundColor: color.purpulishPink,
                      marginRight: '8px',
                    }}>
                    {user?.label
                      .split(' ')
                      .slice(0, 2)
                      .map((n) => n[0])
                      .join('')}
                  </Avatar>
                  <Text>{user?.label}</Text>
                </Box>
                <Box style={{ width: 'calc(50% - 54px)' }}>
                  <Text>{owner?.role}</Text>
                </Box>
                <Box>
                  <MoreHorizIcon style={{ cursor: 'pointer' }} onClick={(event) => handleMenuOpen(event, owner)} />
                </Box>
              </Box>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl) && selectedMember === owner}
                onClose={handleMenuClose}
                slotProps={{
                  paper: {
                    sx: {
                      width: '200px',
                      borderRadius: '15px',
                      marginTop: '10px',
                    },
                  },
                }}>
                <MenuItem
                  onClick={() => {
                    editMember(owner, user);
                    setShowOwnerModal(true);
                    handleMenuClose();
                  }}>
                  Edit
                </MenuItem>
                <Divider />
                <MenuItem
                  style={{ color: color.red }}
                  onClick={() => {
                    handleMemberDelete(owner);
                    handleMenuClose();
                  }}>
                  Delete
                </MenuItem>
              </Menu>
            </div>
          );
        }}
      />
    </>
  );
};
