import React, { useMemo, useRef, useState } from 'react';

import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { DndContext, DragOverlay, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { useTheme } from '@emotion/react';
import { Tooltip, Modal } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Grid, IconButton, Typography } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';
import config from 'Config';
import { TOOLING_NAME } from 'containers/App/constants';
import { get, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { piStatusColor } from 'utils/piColors';
import { DATE_FORMAT } from 'utils/timeUtils';

import { getColumns, getIdeationMessage, sortFeaturesBy, validateColumns } from './helper';
import Idea from './idea.js';
import IdeationColumn from './ideationColumn';
import { styles } from './styles';
import Import from '../../../assets/images/Import';
import Select from '../../../components/Common/Select';
import FeatureImportModal from '../../../containers/Delivery/components/shared/FeatureImportModal';
import { GET_INITIATIVES } from '../../../containers/ValueStream/Initiatives/graphql';
import {
  openDrawer,
  selectedProductThemeVar,
  selectedTeamVar,
  loggedInUserVar,
  selectedProjectVar,
} from '../../../reactiveVariables';
import { filterProductTheme, removeIdField } from '../../../utils/helpers';
import { GET_BACKLOGS } from '../../Settings/GraphQL/backlogs';
import { GET_SELECTED_PI_FULL } from '../../Settings/ProgramIncrement/graphql';
import { GET_FEATURES_FOR_PROGRAMME, UPDATE_FEATURE_STAGE } from '../Features/graphql';

const useStyles = makeStyles(styles);
const envConfig = config[window.location.hostname];

export default function IdeaBoard() {
  const { data: { backlogs = [] } = {} } = useQuery(GET_BACKLOGS);
  const { data: { selectedPiFull = {} } = {} } = useQuery(GET_SELECTED_PI_FULL);
  const { data: { features: ideas = [] } = {} } = useQuery(GET_FEATURES_FOR_PROGRAMME);
  const { data: { initiatives = [] } = {} } = useQuery(GET_INITIATIVES);

  const [openImport, setOpenImport] = useState(false);
  const user = useReactiveVar(loggedInUserVar);

  const { roles = [] } = user;
  const canImport = roles.includes(`cru-product`);

  const matches = useMediaQuery('(min-width: 960px)');

  const theme = useTheme();

  const styleProps = {
    backgroundColor: piStatusColor(selectedPiFull?.status, theme, true),
  };
  const classes = useStyles(styleProps);

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: { distance: 5 },
  });
  const sensors = useSensors(mouseSensor);
  const selectedTeams = useReactiveVar(selectedTeamVar);
  const selectedProductTheme = useReactiveVar(selectedProductThemeVar);
  const selectedProject = useReactiveVar(selectedProjectVar);

  const droppedRef = useRef(null);
  const [droppedId, setDroppedId] = useState(null);
  const [animateId, setAnimateId] = useState(null);
  const [backlog, setBacklog] = useState(0);
  const [assignedTo, setAssignedTo] = useState([]);
  const [activeId, setActiveId] = useState(null);

  const [sortBy, setSorBy] = useState({
    funnel: window.localStorage.getItem('funnelSortBy') || 'name',
    analysis: window.localStorage.getItem('analysisSortBy') || 'name',
    backlog: window.localStorage.getItem('backlogSortBy') || 'name',
    candidate: window.localStorage.getItem('candidateSortBy') || 'name',
    planned: window.localStorage.getItem('plannedSortBy') || 'name',
    inProgress: window.localStorage.getItem('inProgressSortBy') || 'name',
    done: window.localStorage.getItem('doneSortBy') || 'name',
  });

  const funnelSortBy = sortBy['funnel'];
  const analysisSortBy = sortBy['analysis'];
  const backlogSortBy = sortBy['backlog'];
  const candidateSortBy = sortBy['candidate'];
  const plannedSortBy = sortBy['planned'];
  const inProgressSortBy = sortBy['inProgress'];
  const doneSortBy = sortBy['done'];

  const [updateFeature] = useMutation(UPDATE_FEATURE_STAGE);

  const getIdeasForStage = (stage, filterByPi) => {
    const data = ideas
      .filter(
        (idea) =>
          filterProductTheme(selectedProductTheme, idea) &&
          (!backlog || idea.backlogId === backlog) &&
          (!selectedTeams.length || selectedTeams.includes(idea.teamId)) &&
          (!assignedTo?.length || assignedTo.includes(idea.assignedTo)) &&
          (!selectedProject || idea.project === parseInt(selectedProject)) &&
          idea.stage === stage,
      )
      .filter((idea) => !filterByPi || idea.programIncrement === selectedPiFull?.id);

    return sortFeaturesBy(data, sortBy[stage]);
  };

  const ownedByList = useMemo(
    () =>
      uniq(ideas.filter((idea) => !!idea.assignedTo).map((idea) => idea.assignedTo)).map((idea) => ({
        label: idea,
        value: idea,
      })),
    [ideas],
  );

  const funnelFeatures = useMemo(
    () => getIdeasForStage('funnel'),
    [backlog, selectedTeams, selectedPiFull, ideas, funnelSortBy, assignedTo, selectedProductTheme, selectedProject],
  );
  const analysisFeatures = useMemo(
    () => getIdeasForStage('analysis'),
    [backlog, selectedTeams, selectedPiFull, ideas, analysisSortBy, assignedTo, selectedProductTheme, selectedProject],
  );
  const backlogFeatures = useMemo(
    () => getIdeasForStage('backlog'),
    [backlog, selectedTeams, selectedPiFull, ideas, backlogSortBy, assignedTo, selectedProductTheme, selectedProject],
  );
  const candidateFeatures = useMemo(
    () => getIdeasForStage('candidate', true),
    [backlog, selectedTeams, selectedPiFull, ideas, candidateSortBy, assignedTo, selectedProductTheme, selectedProject],
  );
  const plannedFeatures = useMemo(
    () => getIdeasForStage('planned', true),
    [backlog, selectedTeams, selectedPiFull, ideas, plannedSortBy, assignedTo, selectedProductTheme, selectedProject],
  );
  const implementationFeatures = useMemo(
    () => getIdeasForStage('inProgress', true),
    [
      backlog,
      selectedTeams,
      selectedPiFull,
      ideas,
      inProgressSortBy,
      assignedTo,
      selectedProductTheme,
      selectedProject,
    ],
  );
  const doneFeatures = useMemo(
    () => getIdeasForStage('done', true),
    [backlog, selectedTeams, selectedPiFull, ideas, doneSortBy, assignedTo, selectedProductTheme, selectedProject],
  );

  const onBacklogChange = (event, value) => setBacklog(value);
  const onAssignedToChange = (event, value) => setAssignedTo(value);

  const columns = getColumns(
    funnelFeatures,
    analysisFeatures,
    backlogFeatures,
    candidateFeatures,
    plannedFeatures,
    implementationFeatures,
    doneFeatures,
  );

  const piStartDate = get(selectedPiFull, 'sprints[0].startDate');

  const handleCreate = () => openDrawer(null, 'feature');

  const scrollToRef = (ref, droppedId) => {
    if (!droppedId || !ref.current) return;
    ref.current.focus();
    setAnimateId(droppedId);
    setDroppedId(null);
  };

  const onDragEnd = (event) => {
    if (!event || !event.over) return;
    const initialColumn = event.active?.data?.current?.column?.value;
    const droppedColumn = event.over.id;

    if (initialColumn === droppedColumn) return;

    const feature = event.active?.data?.current?.feature;
    const column = event.over?.data?.current?.column;

    if (column.isPerPI && !selectedPiFull) {
      showNotification({
        title: 'Error: No Increment',
        message: 'Cannot move feature into a Increment specific column.',
        color: 'red',
      });
      return;
    }

    if (validateColumns(event.over.id, feature)) {
      showNotification({
        title: 'Warning',
        message: getIdeationMessage(),
        color: 'orange.4',
      });
    }

    const featureToSave = {
      stage: event.over.id,
      ...(column.isPerPI && { programIncrement: selectedPiFull?.id }),
      missingFields: validateColumns(event.over.id, feature),
    };

    updateFeature({
      variables: {
        feature: removeIdField(featureToSave, 'milestones'),
        featureId: feature.id,
      },
      optimisticResponse: {
        feature: { ...feature, stage: event.over.id, updatedAt: DateTime.local().toISO() },
      },
    });

    setActiveId(null);
  };

  const sortByOption = (stage, sortOption) => {
    setSorBy({ ...sortBy, [stage]: sortOption });
    window.localStorage.setItem(`${stage}SortBy`, sortOption);
  };

  const handleDragStart = (event) => {
    setActiveId(event.active?.data?.current);
  };

  const renderDragOverlay = (data) => {
    return <Idea isOverlay={true} key="overlay" sortBy={sortBy} column={data.column} feature={data.feature} />;
  };

  scrollToRef(droppedRef, droppedId);

  const isStartDateInPast = DateTime.fromISO(piStartDate).diffNow() < 0;

  return (
    <div className={classes.root}>
      <Toolbar className={classes.appBar} disableGutters>
        <div className={classes.toolbarLeft}>
          <Typography fontSize={20} fontWeight={600}>
            Product Kanban
          </Typography>
          <IconButton
            key="add"
            aria-label="Add"
            color="inherit"
            onClick={handleCreate}
            size="small"
            sx={{ marginLeft: '6px' }}>
            <AddBoxIcon />
          </IconButton>
          {canImport && (
            <Tooltip
              transition={'scale'}
              withArrow
              zIndex={1000}
              label={`Bulk import features from ${TOOLING_NAME[envConfig.tooling]}`}>
              <IconButton
                key="import"
                aria-label="Import"
                color="inherit"
                size="small"
                onClick={() => setOpenImport(true)}>
                <Import />
              </IconButton>
            </Tooltip>
          )}
          <Select
            options={[{ name: 'All', id: 0 }, ...backlogs]}
            defaultValue={backlog}
            title="Project"
            placeholder="Project"
            border={false}
            onChange={onBacklogChange}
          />
          <Select
            options={ownedByList}
            defaultValue={backlog}
            title="Owner"
            placeholder="Owner"
            border={false}
            multiple={true}
            onChange={onAssignedToChange}
          />
        </div>
      </Toolbar>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={cn(classes.headerItem, classes.headerItemLeft)}>Pipeline</div>
          <div className={cn(classes.headerItem, classes.headerItemRight)}>
            {selectedPiFull ? (
              <>
                <span
                  className={classes.dot}
                  style={{ backgroundColor: piStatusColor(selectedPiFull?.status, theme) }}
                />
                <span className={classes.piName}>{selectedPiFull?.name}</span>
                <span className={classes.divider} />
                <span className={classes.piStarts}>
                  {isStartDateInPast ? 'Started' : 'Starts'} {DateTime.fromISO(piStartDate).toFormat(DATE_FORMAT.date)}
                </span>
              </>
            ) : (
              <span className={classes.piName}>No Increment found for this programme</span>
            )}
          </div>
        </div>
        <DndContext sensors={sensors} onDragStart={handleDragStart} onDragEnd={onDragEnd}>
          <Grid key="container" container className={classes.flexSection} justifyContent="center" spacing={1}>
            {columns.map((column, index) => (
              <IdeationColumn
                classes={classes}
                animateId={animateId}
                initiatives={initiatives}
                key={column.value}
                index={index}
                column={column}
                sortBy={sortBy}
                sortValue={sortBy[column.value]}
                sortByOption={sortByOption}
              />
            ))}
          </Grid>
          <DragOverlay>{activeId ? renderDragOverlay(activeId) : null}</DragOverlay>
        </DndContext>
      </div>

      <Modal
        zIndex={10000}
        opened={openImport}
        overflow="inside"
        centered
        size="50vw"
        fullScreen={!matches}
        onClose={() => setOpenImport(false)}
        title="Import Features">
        <FeatureImportModal onClose={setOpenImport} />
      </Modal>
    </div>
  );
}
