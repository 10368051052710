import React, { forwardRef, Fragment, memo } from 'react';

import { useTheme } from '@emotion/react';
import { Group, Progress, Text } from '@mantine/core';
import { Grid, Zoom } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { some } from 'lodash';

import { styles } from './styles';
import { Card } from '../../../containers/Product/IdeaBoard/styles';
import LinearWithValueLabel from '../../../containers/ValueStream/components/OKRTable/LinearWithValueLabel';
import { openDrawer } from '../../../reactiveVariables';
import { getFeatureBackgroundColor } from '../../../utils/piColors';
import Tooltip from '../../Common/Tooltip';

const useStyles = makeStyles(styles);

const FeatureCard = ({ noTooltip, feature, selectedMilestone, showObjectives, progressBy }, ref) => {
  const { id } = feature;
  const theme = useTheme();
  const zoomOut = selectedMilestone && !some(feature.milestones, (milestone) => milestone.id === selectedMilestone);
  const borderColor = getFeatureBackgroundColor(feature, theme);
  const classes = useStyles();

  const handleClick = () => {
    if (!showObjectives) {
      openDrawer(feature, 'feature');
    } else {
      openDrawer(feature, 'objective');
    }
  };

  const getPercentageDone = () => {
    const metrics = feature.metrics?.[progressBy];
    return (
      Math.round(
        (metrics?.done / (metrics?.toDo + metrics?.inProgress + metrics?.done + (metrics?.deferred || 0))) * 100,
      ) || 0
    );
  };

  const metrics = feature.metrics?.[progressBy];
  const incrementMetrics = feature.metrics?.metricsPerIncrement?.[feature.programIncrement]?.[progressBy];
  const incrementTotal =
    incrementMetrics?.toDo + incrementMetrics?.inProgress + incrementMetrics?.done + (incrementMetrics?.deferred || 0);
  const isCrossIncrement = incrementTotal !== metrics?.total;

  return (
    <div ref={ref}>
      <Tooltip
        key={feature.id}
        noTooltip={noTooltip}
        content={
          <>
            {!feature.metrics && <Text size="sm">{`This feature doesn't have an epic`}</Text>}
            {feature.metrics && (
              <Group justify="space-between" p={6}>
                <Text size="sm">{isCrossIncrement ? 'Overall' : 'Breakdown'}</Text>
                <Progress.Root size="xl" style={{ width: '150px' }}>
                  <Progress.Section
                    value={metrics.total ? (metrics.toDo / metrics.total) * 100 : 33}
                    color={theme.palette.color.toDo}>
                    <Progress.Label>{metrics.toDo}</Progress.Label>
                  </Progress.Section>
                  <Progress.Section
                    value={metrics.total ? (metrics.inProgress / metrics.total) * 100 : 33}
                    color={theme.palette.color.inProgress}>
                    <Progress.Label>{metrics.inProgress} </Progress.Label>
                  </Progress.Section>
                  <Progress.Section
                    value={metrics.total ? (metrics.done / metrics.total) * 100 : 34}
                    color={theme.palette.color.done}>
                    <Progress.Label>{metrics.done}</Progress.Label>
                  </Progress.Section>
                </Progress.Root>
              </Group>
            )}
            {isCrossIncrement && incrementMetrics && (
              <Group justify="space-between" p={6} pt={0}>
                <Text size="sm">Increment</Text>
                <Progress.Root size="xl" style={{ width: '150px' }}>
                  <Progress.Section
                    value={(incrementMetrics.toDo / incrementTotal) * 100}
                    color={theme.palette.color.toDo}>
                    <Progress.Label>{incrementMetrics.toDo}</Progress.Label>
                  </Progress.Section>
                  <Progress.Section
                    value={(incrementMetrics.inProgress / incrementTotal) * 100}
                    color={theme.palette.color.inProgress}>
                    <Progress.Label>{incrementMetrics.inProgress} </Progress.Label>
                  </Progress.Section>
                  <Progress.Section
                    value={(incrementMetrics.done / incrementTotal) * 100}
                    color={theme.palette.color.done}>
                    <Progress.Label>{incrementMetrics.done}</Progress.Label>
                  </Progress.Section>
                  <Progress.Section value={(incrementMetrics.deferred / incrementTotal) * 100} color="grey">
                    <Progress.Label>{incrementMetrics.deferred}</Progress.Label>
                  </Progress.Section>
                </Progress.Root>
              </Group>
            )}
          </>
        }>
        <Zoom in={!zoomOut}>
          <Card shadow="xs" onClick={handleClick} borderColor={borderColor}>
            <Grid container spacing={3}>
              <Grid item xs={12} container sx={{ paddingBottom: '5px' }} justifyContent={'space-between'}>
                <Text className={classes.typo} size={10.5} fw={600} noWrap align="left">
                  {feature.enabler ? 'E' : 'F'}-{id}
                </Text>
                <Text className={classes.typo} size={10.5} fw={600} noWrap align="left">
                  {feature.epicId}
                </Text>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Text size="xs" className={classes.typo} align="left" c="grey">
                {feature.name}
              </Text>
            </Grid>
            {feature?.metrics && (
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                spacing={3}
                className={classes.metricsProgress}>
                <LinearWithValueLabel color={borderColor} value={getPercentageDone()} />
              </Grid>
            )}
          </Card>
        </Zoom>
      </Tooltip>
    </div>
  );
};

export default memo(forwardRef(FeatureCard));
