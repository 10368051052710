import React, { forwardRef, useCallback, useImperativeHandle } from 'react';

import { useQuery } from '@apollo/client';
import { Grow } from '@mui/material';

import { getRowsData } from './helper';
import EntityTable from '../../../../components/Common/EntityTable';
import { openDrawer } from '../../../../reactiveVariables';
import { exportCSVData, getFieldValue } from '../../../../utils/export';
import { GET_SUBTASKS } from '../../GraphQL/configuration';

export const headCells = [
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    disablePadding: false,
    filtering: false,
    numeric: false,
    align: 'left',
    width: '70%',
  },
  {
    id: 'isDefault',
    field: 'isDefault',
    label: 'Default',
    disablePadding: false,
    numeric: false,
    align: 'left',
  },
];

const Index = ({ searchQuery }, ref) => {
  const searchQueryLowerCase = searchQuery.toLowerCase().trim();

  const { data: { subtasks = [] } = {} } = useQuery(GET_SUBTASKS);

  useImperativeHandle(ref, () => ({
    export: () => exportOKRs(),
  }));

  const exportOKRs = useCallback(() => {
    const data = subtasks.map((rowData) => headCells.map((columnDef) => getFieldValue(rowData, columnDef)));

    exportCSVData(headCells, data, 'OKRs');
  }, [subtasks]);

  const onRowClick = (event, row) => {
    const okr = subtasks.find((okr) => okr?.id === row.id);
    openDrawer(okr, 'subtask');
  };

  return (
    <Grow in={true}>
      <div>
        <EntityTable
          handleRowClick={onRowClick}
          headCells={headCells}
          rows={getRowsData(subtasks)}
          searchQuery={searchQueryLowerCase}
        />
      </div>
    </Grow>
  );
};

export default forwardRef(Index);
