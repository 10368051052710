import React, { useCallback, useState, useEffect, useMemo } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import { Box, Loader, Badge, Tooltip, useMantineTheme } from '@mantine/core';
import { DataTableToolbar } from 'components/Common/DataTableToolbar';
import { orderBy } from 'lodash';
import { DataTable } from 'mantine-datatable';
import { useNavigate } from 'react-router-dom';

import SvgPriorityHigh from '../../../assets/images/priorityHigh';
import SvgPriorityLow from '../../../assets/images/priorityLow';
import SvgPriorityMedium from '../../../assets/images/priorityMedium';
import SvgPriorityNo from '../../../assets/images/priorityNo';
import SvgPriorityUrgent from '../../../assets/images/priorityUrgent';
import { openDrawer } from '../../../reactiveVariables';
import { selectedSubmitterVar, selectedUsersVar, selectedThinkTankValueStreamVar } from '../../../reactiveVariables';
import { color } from '../../../shared/styles/color';
import { exportCSVData } from '../../../utils/export';
import { GET_ALL_IDEAS } from '../../Form/graphql';
import { GET_FEATURES } from '../../Product/Features/graphql';
import { GET_ALL_INITIATIVES } from '../../ValueStream/Initiatives/graphql';
import { Display2 } from '../../ValueStream/InitiativesDashboard/InitiativeDashboard.style';

const ThinkTank = () => {
  const { data: { ideas = [], loading } = {} } = useQuery(GET_ALL_IDEAS);
  const { data: { initiatives = [] } = {} } = useQuery(GET_ALL_INITIATIVES);
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES);

  const theme = useMantineTheme();
  const navigate = useNavigate();

  const selectedSubmitters = useReactiveVar(selectedSubmitterVar);
  const selectedValueStream = useReactiveVar(selectedThinkTankValueStreamVar);
  const selectedUsers = useReactiveVar(selectedUsersVar);

  const [sortStatus, setSortStatus] = useState({ columnAccessor: 'id', direction: 'desc' });
  const [visibleColumns, setVisibleColumns] = useState({});
  const [tempVisibleColumns, setTempVisibleColumns] = useState({});
  const [tableHeight, setTableHeight] = useState('100vh');
  const [searchQuery, setSearchQuery] = useState('');
  const [ideasState, setIdeasState] = useState('');

  const handleSort = (newSortStatus) => {
    setSortStatus(newSortStatus);
  };

  useEffect(() => {
    const updateTableHeight = () => {
      const vh = window.innerHeight;
      setTableHeight(vh * 0.8);
    };

    updateTableHeight();
    window.addEventListener('resize', updateTableHeight);

    return () => {
      window.removeEventListener('resize', updateTableHeight);
    };
  }, []);

  const filterIdeas = (ideas, users, states, submitter, valueStreams) => {
    return ideas.filter((idea) => {
      const ownerMatches = users.length === 0 || users.includes(idea.owner);
      const stateMatches = states.length === 0 || states.includes(idea.state);
      const submitterMatches = submitter.length === 0 || submitter.includes(idea.idea.creator);
      const valueStreamMatches =
        valueStreams === 'All' ||
        valueStreams === null ||
        valueStreams === undefined ||
        idea.idea.valueStreams?.some((vs) => vs.id === valueStreams);

      const totalSum = (idea.cost || 0) + (idea.people || 0) + (idea.tech || 0) + (idea.regulatory || 0);
      const totalSumString = totalSum.toString();

      const searchMatches =
        searchQuery.length === 0 ||
        idea.id.toString().includes(searchQuery) ||
        totalSumString.includes(searchQuery) ||
        Object.values(idea)
          .concat(Object.values(idea.idea))
          .filter((value) => typeof value === 'string')
          .some((value) => value.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (Array.isArray(idea.idea.valueStreams) &&
          idea.idea.valueStreams.some((vs) => vs.name.toLowerCase().includes(searchQuery.toLowerCase())));

      return ownerMatches && stateMatches && submitterMatches && valueStreamMatches && searchMatches;
    });
  };

  const getNestedValue = (obj, path) => {
    if (!path) return obj;

    const keys = path.split('.');
    return keys.reduce((acc, key) => acc?.[key], obj);
  };

  const filteredIdeas = orderBy(
    filterIdeas(ideas, selectedUsers, ideasState, selectedSubmitters, selectedValueStream) || [],
    [
      (idea) => {
        if (Object.prototype.hasOwnProperty.call(idea, sortStatus.columnAccessor)) {
          return idea[sortStatus.columnAccessor];
        }

        return getNestedValue(idea, `idea.${sortStatus.columnAccessor}`);
      },
    ],
    [sortStatus.direction],
  );

  const exportThinkTank = useCallback(() => {
    // Define CSV column headers
    const columns = [
      { label: 'ID' },
      { label: 'User ID' },
      { label: 'Idea Name' },
      { label: 'Themes' },
      { label: 'Creator' },
      { label: 'Problem' },
      { label: 'Priority' },
      { label: 'Other Ideas' },
      { label: 'Team Needed' },
      { label: 'Description' },
      { label: 'Unit Measure' },
      { label: 'Value Streams' },
      { label: 'Benefit Measure' },
      { label: 'Success Measure' },
      { label: 'Owner' },
      { label: 'State' },
      { label: 'Created At' },
      { label: 'Next Step' },
      { label: 'Next Step ID' },
      { label: 'Cost' },
      { label: 'People' },
      { label: 'Tech' },
      { label: 'Regulatory' },
    ];

    // Format the idea data for CSV
    const formattedIdeas = ideas.map((idea) => [
      idea.id,
      idea.user_id,
      idea.idea?.name || '',
      idea.idea?.themes?.map((theme) => theme.title).join(', ') || '',
      idea.idea?.creator || '',
      idea.idea?.problem || '',
      idea.idea?.priority || '',
      idea.idea?.otherIdeas || '',
      idea.idea?.teamNeeded || '',
      idea.idea?.description || '',
      idea.idea?.unitMeasure || '',
      idea.idea?.valueStreams?.map((vs) => vs.name).join(', ') || '',
      idea.idea?.benefitMeasure || '',
      idea.idea?.successMeasure || '',
      idea.owner || '',
      idea.state || '',
      idea.created_at || '',
      idea.next_step || '',
      idea.next_step_feature_or_initiative_id || '',
      idea.cost ?? '',
      idea.people ?? '',
      idea.tech ?? '',
      idea.regulatory ?? '',
    ]);

    // Export CSV
    exportCSVData(columns, formattedIdeas, 'ideas_export');
  }, [ideas]);

  const onRowClick = (row) => {
    const idea = ideas.find((idea) => idea.id === row?.record.id);
    openDrawer(
      { ...idea, priority: idea.idea.priority, valueStreams: idea.idea.valueStreams, createdAt: idea.created_at },
      'thinkTank',
    );
  };

  const toggleTempColumn = (accessor) => {
    setTempVisibleColumns((prev) => ({ ...prev, [accessor]: !prev[accessor] }));
  };

  const staticColumns = useMemo(
    () => [
      {
        accessor: 'id',
        title: 'ID',
        sortable: true,
        width: 50,
        render: (record) => {
          return record && record.id;
        },
      },
      {
        accessor: 'priority',
        title: 'Priority',
        sortable: true,
        textAlign: 'center',
        width: 80,
        render: (record) => {
          const priority = record.idea?.priority || 'No';
          const tooltipLabel = priority === 'No' ? 'No priority set' : priority;

          const getPriorityIcon = (priority) => {
            switch (priority) {
              case 'No':
                return <SvgPriorityNo />;
              case 'Low':
                return <SvgPriorityLow />;
              case 'Medium':
                return <SvgPriorityMedium />;
              case 'High':
                return <SvgPriorityHigh />;
              case 'Urgent':
                return <SvgPriorityUrgent />;
              default:
                return <SvgPriorityNo />;
            }
          };

          return (
            <Tooltip label={tooltipLabel} transition="fade" withinPortal>
              <span>{getPriorityIcon(priority)}</span>
            </Tooltip>
          );
        },
      },
      {
        accessor: 'name',
        title: 'Project Name',
        sortable: true,
        width: 300,
        render: (record) => {
          return record && record?.idea?.name;
        },
      },
      {
        accessor: 'owner',
        title: 'Owner',
        sortable: true,
        width: 150,
        render: (record) => record && record?.owner,
      },
      {
        accessor: 'creator',
        title: 'Submitted by',
        sortable: true,
        width: 150,
        render: (record) => record && record?.idea?.creator,
      },
      {
        accessor: 'valueStreams',
        title: 'Value Stream',
        sortable: true,
        width: 150,
        render: (record) => {
          const namesString = record?.idea?.valueStreams?.length
            ? record?.idea?.valueStreams.map((stream) => stream.name).join(', ')
            : '';
          return namesString;
        },
      },
      {
        accessor: 'state',
        title: 'State',
        sortable: true,
        width: 130,
        render: (record) => record.state && record.state,
      },
      {
        accessor: 'cost',
        title: 'Change Impact',
        sortable: true,
        width: 130,
        textAlign: 'center',
        render: (record) => {
          const total = record.cost + record.people + record.tech + record.regulatory;

          if (total >= 15) {
            return (
              <Badge
                size="m"
                radius="4px"
                style={{ padding: '4px', color: 'white', background: color.purpulishPink, fontSize: '13px' }}>
                {total}
              </Badge>
            );
          } else {
            return total;
          }
        },
      },
      {
        accessor: 'next_step',
        title: 'Next Step',
        sortable: true,
        width: 150,
        render: (record) => {
          const dataMap = record.next_step === 'Convert to feature' ? features : initiatives;
          const title = dataMap.find((item) => item.id === record.next_step_feature_or_initiative_id)?.name || '';

          return record.next_step_feature_or_initiative_id ? (
            <Tooltip withArrow transition="scale" position="top" transitionDuration={200} label={title}>
              <span>
                <Display2
                  variant="light"
                  bgColor={
                    record.next_step === 'Convert to feature' ? theme.other.colours.done : theme.other.colours.green
                  }>
                  {record.next_step === 'Convert to feature' ? 'feature' : 'initiative'}
                </Display2>
              </span>
            </Tooltip>
          ) : (
            record.next_step || 'Unassigned'
          );
        },
      },
    ],
    [features, initiatives],
  );

  // Filter columns based on visibility
  const filteredColumns = staticColumns.filter((col) => visibleColumns[col.accessor]);

  useEffect(() => {
    if (Object.keys(visibleColumns).length === 0) {
      const initialVisibility = staticColumns.reduce((acc, col) => {
        acc[col.accessor] = true;
        return acc;
      }, {});
      setVisibleColumns(initialVisibility);
      setTempVisibleColumns(initialVisibility);
    }
  }, [staticColumns, visibleColumns]);

  const stateOptions = [
    {
      label: 'New',
      value: 'New',
    },
    {
      label: 'In Analysis',
      value: 'In Analysis',
    },
    {
      label: 'Rejected',
      value: 'Rejected',
    },
    {
      label: 'Accepted',
      value: 'Accepted',
    },
  ];

  return (
    <Box sx={{ height: '100%', overflowY: 'auto' }}>
      <DataTableToolbar
        addButtonUrl={() => navigate('/submit-idea/new')}
        stateOptions={stateOptions}
        setSelectState={setIdeasState}
        setSearchQuery={setSearchQuery}
        exportData={exportThinkTank}
        staticColumns={staticColumns}
        tempVisibleColumns={tempVisibleColumns}
        toggleTempColumn={toggleTempColumn}
        setVisibleColumns={setVisibleColumns}
      />

      <DataTable
        columns={filteredColumns}
        records={filteredIdeas}
        sortStatus={sortStatus}
        onSortStatusChange={handleSort}
        highlightOnHover
        withColumnBorders
        onRowClick={onRowClick}
        fetching={loading}
        enableRowVirtualization
        height={tableHeight}
        emptyState={<div style={{ textAlign: 'center', padding: '20px' }}></div>}
        stickyHeader
        styles={{
          root: { backgroundColor: 'transparent' },
          table: { backgroundColor: 'transparent' },
          header: { backgroundColor: '#f7f7f8' },
          row: { backgroundColor: 'transparent' },
        }}
      />
      {loading && <Loader sx={{ margin: '10px auto', display: 'block' }} />}
    </Box>
  );
};

export default ThinkTank;
