import React from 'react';

import { Divider } from '@mantine/core';
import { ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';

import { CircleIcon } from './style';

export const RagStatus = ({ handleRagSelection, open, anchorEl, handleRagMenuClose }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleRagMenuClose}
      slotProps={{
        paper: {
          sx: {
            width: '200px',
            borderRadius: '15px',
            marginTop: '-20px',
          },
        },
      }}>
      <ListItem>
        <Typography variant="subtitle2" color="textSecondary">
          RAG Status
        </Typography>
      </ListItem>
      <Divider />
      <MenuItem onClick={() => handleRagSelection('Red')}>
        <ListItemIcon>
          <CircleIcon color="#FF0000" />
        </ListItemIcon>
        <ListItemText primary="Red" />
      </MenuItem>
      <MenuItem onClick={() => handleRagSelection('Amber')}>
        <ListItemIcon>
          <CircleIcon color="#F6C544" />
        </ListItemIcon>
        <ListItemText primary="Amber" />
      </MenuItem>
      <MenuItem onClick={() => handleRagSelection('Green')}>
        <ListItemIcon>
          <CircleIcon color="#50A771" />
        </ListItemIcon>
        <ListItemText primary="Green" />
      </MenuItem>
    </Menu>
  );
};
