import React, { useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getSprintMilestones } from 'components/Common/BigAgileTable/helper';
import Milestone from 'components/Common/Milestone';
import { split } from 'lodash';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';

import { styles } from './styles';
import { stickyHeaderVar } from '../../../../../reactiveVariables';

const useStyles = makeStyles(styles);

const FeatureTable = (props) => {
  const {
    pi,
    milestones,
    selectedMilestone,
    teams,
    hasUnplannedFeatures,
    unSelectMilestone,
    selectMilestone,
    handleMilestoneClick,
    anchorMilestone,
    isHoverActive,
    children,
    showAllDependencies,
    showTeamDependencies,
    showObjectives,
  } = props;

  useEffect(() => () => stickyHeaderVar(false), []);
  const stickyHeader = useReactiveVar(stickyHeaderVar);

  const classes = useStyles({
    cellWidth: `${100 / ((pi.sprints && pi.sprints.length + (hasUnplannedFeatures ? 2 : 1)) || 1)}%`,
    stickyHeader: stickyHeader,
  });

  const isVisibleToolTip = ({ id }) =>
    (isSelectedMilestone(id) && isHoverActive) || (isSelectedMilestone(id) && anchorMilestone);

  const isSelectedMilestone = (id) => id === selectedMilestone;

  const onLeave = ({ currentPosition, previousPosition }) => {
    const isAbove = currentPosition === Waypoint.above;
    const wasInside = previousPosition === Waypoint.inside;

    if (isAbove && wasInside) {
      stickyHeaderVar(true);
    }
  };

  const onEnter = ({ currentPosition, previousPosition }) => stickyHeaderVar(false);

  return (
    <>
      <Waypoint onLeave={onLeave} onEnter={onEnter} />
      <Table stickyHeader={stickyHeader} className={classes.table}>
        <TableHead>
          <TableRow sx={{ opacity: stickyHeader ? 0 : 1 }} className={classes.rowMilestones}>
            <TableCell align="right" className={classes.tableHeadCol} key="team" />
            {hasUnplannedFeatures && <TableCell key="unassignedmilestones" className={classes.tableCellNoBorder} />}
            {pi?.sprints?.map((sprint, index) => {
              const sprintMilestones = getSprintMilestones(pi, milestones, sprint, index);
              return (
                <TableCell key={sprint.id} className={classes.tableCellNoBorder}>
                  {sprintMilestones.map((milestone) => (
                    <Milestone
                      key={milestone.id}
                      milestone={milestone}
                      selectedMilestone={selectedMilestone}
                      isVisibleTooltip={isVisibleToolTip(milestone)}
                      showAllDependencies={showAllDependencies}
                      showTeamDependencies={showTeamDependencies}
                      showObjectives={showObjectives}
                      handleMouseLeave={unSelectMilestone}
                      handleMouseEnter={() => selectMilestone(milestone.id)}
                      handleMilestoneClick={() => {
                        handleMilestoneClick(milestone.id);
                      }}
                    />
                  ))}
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow>
            <TableCell key="header" className={classes.tableHeadCol}>
              <div className={classes.sprintItems}>Teams ({teams.length})</div>
            </TableCell>
            {hasUnplannedFeatures && (
              <TableCell key="nosprint" className={classes.tableHeadRow}>
                <span className={classes.teamName}>Unassigned</span>
              </TableCell>
            )}
            {pi?.sprints?.map((sprint) => {
              const startDate = split(sprint.startDate, '-');
              const endDate = split(sprint.endDate, '-');

              return (
                <TableCell key={sprint.id} className={classes.tableHeadRow}>
                  <div
                    className={
                      classes.sprintDates
                    }>{`${startDate[2]}/${startDate[1]} - ${endDate[2]}/${endDate[1]}`}</div>
                  <div className={classes.sprintItems}>{sprint.name.replace('Sprint', '').trim()}</div>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </>
  );
};

FeatureTable.propTypes = {
  pi: PropTypes.object.isRequired,
  milestones: PropTypes.array.isRequired,
  selectedMilestone: PropTypes.number,
  teams: PropTypes.array,
  hasUnplannedFeatures: PropTypes.bool.isRequired,
  unSelectMilestone: PropTypes.func.isRequired,
  selectMilestone: PropTypes.func.isRequired,
  handleMilestoneClick: PropTypes.func.isRequired,
  anchorMilestone: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default FeatureTable;
