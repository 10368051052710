import React from 'react';

import { useTheme } from '@emotion/react';

import { BigAgileSelect } from '../Select/CustomSelect';

const EnablerSelector = ({ value, handleChange }) => {
  const theme = useTheme();

  const values = [
    { id: '', name: 'All' },
    { id: 'enablers', name: 'Enabler' },
    { id: 'businessFeatures', name: 'Business' },
  ];

  const renderValue = (option) => (
    <span style={{ color: theme.palette.text.secondary }}>
      Feature Type:{' '}
      <span style={{ color: theme.palette.text.primary, fontStyle: option?.label === 'None' ? 'italic' : 'normal' }}>
        {option?.label}
      </span>
    </span>
  );

  return <BigAgileSelect options={values} value={value} onChange={handleChange} renderValue={renderValue} />;
};

export default EnablerSelector;
