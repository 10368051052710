import React, { useMemo, useState } from 'react';

import { ApolloProvider } from '@apollo/client';
import { localStorageColorSchemeManager, MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { GlobalStyle } from '../../../global-styles';
import App from '../index';
import { buildMantineTheme, buildTheme, ColorModeContext, resolver } from './theme';
import { client } from '../../../apollo';

const AppWrapper = (props) => {
  const [mode, setMode] = useState(localStorage.getItem('themeMode') || 'light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: (setColorScheme) => {
        const newMode = mode === 'light' ? 'dark' : 'light';
        localStorage.getItem('themeMode');
        localStorage.setItem('themeMode', newMode);
        setMode(newMode);
        setColorScheme(newMode);
      },
    }),
    [mode, setMode],
  );

  const colorSchemeManager = localStorageColorSchemeManager({ key: 'themeMode' });
  const theme = buildTheme(mode);

  return (
    <ApolloProvider client={client}>
      <ColorModeContext.Provider value={colorMode}>
        <MantineProvider
          cssVariablesResolver={resolver}
          colorSchemeManager={colorSchemeManager}
          defaultColorScheme="light"
          theme={buildMantineTheme(colorSchemeManager.get('light'))}
          withGlobalStyles
          withNormalizeCSS>
          <Notifications zIndex={1000000000} />
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalStyle />
            <App {...props} />
          </ThemeProvider>
        </MantineProvider>
      </ColorModeContext.Provider>
    </ApolloProvider>
  );
};

export default AppWrapper;
