import React from 'react';

import { useTheme } from '@emotion/react';

import { BigAgileSelect } from '../Select/CustomSelect';

const ProductThemeSelector = ({ productThemes = [], value, handleChange }) => {
  const theme = useTheme();

  const values = [
    { id: '', name: 'All' },
    { id: 'x', name: 'None' }, // 'None' option
    ...productThemes.map((productTheme) => ({ id: productTheme.id, name: productTheme.title })),
  ];

  const renderValue = (option) => (
    <span style={{ color: theme.palette.text.secondary }}>
      Product Theme:{' '}
      <span style={{ color: theme.palette.text.primary, fontStyle: option?.label === 'None' ? 'italic' : 'normal' }}>
        {option?.label}
      </span>
    </span>
  );

  return <BigAgileSelect options={values} value={value} onChange={handleChange} renderValue={renderValue} />;
};

export default ProductThemeSelector;
