import React, { useEffect, useState } from 'react';

import SectionMenu from 'components/Common/SectionHeader/components/SectionMenu';
import SectionWrapper from 'components/Common/SectionWrapper';

import HighLevelEstimate from './HighLevelEstimate';
import InitiativeStates from './InitiativeStates';
import PortfolioStates from './PortfolioStates';
import Subtasks from './Subtasks';
import BigAgileTable from '../../../components/Common/BigAgileTable';
import { SUBTASKS_TITLE, INITIATIVES_STATES } from '../../../components/Common/BigAgileTable/constants';

export const TABS = [
  {
    index: 0,
    name: 'Analysis Tasks',
  },
  {
    index: 1,
    name: 'T-Shirt Sizes',
  },
  {
    index: 2,
    name: 'Portfolio States',
  },
  {
    index: 3,
    name: 'Initiative States',
  },
];

const Configuration = () => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    window.Intercom('update');
  }, []);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <SectionMenu handleChange={handleTabChange} value={value} tabs={TABS} />
      <SectionWrapper>
        {value === 0 && <BigAgileTable type="subtask" Component={Subtasks} title={SUBTASKS_TITLE} />}
        {value === 1 && <BigAgileTable type="hlEstimate" Component={HighLevelEstimate} />}
        {value === 2 && <PortfolioStates />}
        {value === 3 && (
          <BigAgileTable type="initiativeState" Component={InitiativeStates} title={INITIATIVES_STATES} />
        )}
      </SectionWrapper>
    </>
  );
};

export default Configuration;
