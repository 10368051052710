/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Autocomplete, Badge, Button, Divider, Grid, Group, Modal, useMantineTheme } from '@mantine/core';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import LuxonUtils from '@mui/lab/AdapterLuxon';
import { TextField } from '@mui/material';
import { countBy, map, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';

import { InitiativeOrFeatureContainer, MainBody, commonButtonStyles } from './style';
import Calendar from '../../assets/images/Calendar';
import { Field, renderInput, renderInputTextArea } from '../../components/Common/FormFieldsHooks/MantineField';
import { INSERT_FEATURE } from '../../containers/Product/Features/graphql';
import { GET_PROGRAMMES } from '../../containers/Settings/GraphQL/programmes';
import { GET_USERS } from '../../containers/Settings/GraphQL/users';
import { GET_VALUE_STREAMS } from '../../containers/Settings/Streams/graphql';
import { INSERT_INITIATIVE } from '../../containers/ValueStream/Initiatives/graphql';
import { lightColors } from '../../shared/styles/color';
import { insertUpdate } from '../../utils/graphQLUtils';
import { FormContext } from '../Common/FormFieldsHooks/MantineField';

export const InitiativeOrFeature = ({ open, close, type, user, selectedItemId }) => {
  const theme = useMantineTheme();

  const { data: { users = [] } = {} } = useQuery(GET_USERS);
  const { data: { valueStreams = [] } = {} } = useQuery(GET_VALUE_STREAMS);
  const { data: { programmes = [] } = {} } = useQuery(GET_PROGRAMMES);

  const activeValueStreams = valueStreams
    .filter((stream) => stream.isActive === true)
    .map((stream) => ({
      value: stream.name,
      id: stream.id,
    }));

  const [insertInitiative] = useMutation(INSERT_INITIATIVE);
  const [insertFeature] = useMutation(INSERT_FEATURE);

  const [selectedDate, setSelectedDate] = useState(null);
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const [selectedValueStream, setSelectedValueStream] = useState(null);
  const [selectedProgramme, setSelectedProgramme] = useState(null);
  const [openValueStreamSelector, setOpenValueStreamSelector] = useState(false);
  const [openProgrammeSelector, setOpenProgrammeSelector] = useState(false);

  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState(null);

  useEffect(() => {
    if (selectedDate) {
      setOpenDatePicker(false);
    }
  }, [selectedDate]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    if (type === 'Features') {
      insertUpdate('feature'),
        insertFeature({
          variables: {
            feature: {
              name: data.title,
              description: data.description,
              assignedTo: selectedOwner || `${user.firstName} ${user.lastName}`,
              plannedReleaseDate: selectedDate,
              strategic_theme: selectedItemId,
              programmeId: selectedProgramme ? selectedProgramme.id : programmes[0].id,
            },
          },
        });
    } else {
      insertInitiative({
        update: insertUpdate('initiative'),
        variables: {
          initiative: {
            name: data.title,
            description: data.description,
            valueStream: selectedValueStream ? selectedValueStream.id : activeValueStreams[0]?.id,
            owner: selectedOwner || `${user.firstName} ${user.lastName}`,
            plannedRealisationDate: selectedDate,
            strategic_theme: selectedItemId,
          },
        },
      });
    }

    reset();
    close();
  };

  const handleDateClick = () => {
    setOpenDatePicker(true);
  };

  const handleDateChange = (newDate) => {
    setSelectedDate(DateTime.fromISO(newDate).toFormat('yyyy/MM/dd'));
    setOpenDatePicker(false);
  };

  const formatUsers = (users) => {
    const nameCounts = countBy(users, (user) => `${user.firstName} ${user.lastName}`);

    return sortBy(
      map(users, (user, index) => {
        const fullName = `${user.firstName} ${user.lastName}`;
        return nameCounts[fullName] > 1 ? `${fullName} (${index})` : fullName;
      }),
      'firstName',
    );
  };

  const CalendarButton = ({ selectedDate, handleDateClick, openDatePicker, handleDateChange }) => (
    <Button
      variant="light"
      onClick={handleDateClick}
      style={{
        ...commonButtonStyles,
        padding: selectedDate ? '7px 7px 6px 22px' : '7px 7px 7px 30px',
        width: selectedDate ? '100px' : '85px',
        marginLeft: '10px',
      }}>
      <Calendar style={{ width: '20px', position: 'absolute', left: '2px' }} />
      {selectedDate ? selectedDate : 'Date'}
      {openDatePicker && (
        <LocalizationProvider dateAdapter={LuxonUtils}>
          <DatePicker
            open={openDatePicker}
            onClose={() => setOpenDatePicker(false)}
            value={selectedDate}
            onChange={(date) => handleDateChange(date)}
            renderInput={(params) => <TextField {...params} style={{ width: '0px', marginLeft: '65px' }} />}
          />
        </LocalizationProvider>
      )}
    </Button>
  );

  const SelectorButton = ({ label, selectedValue, onClick }) => (
    <Button
      variant="light"
      onClick={onClick}
      style={{
        ...commonButtonStyles,
        padding: '6px',
        width: 'auto',
      }}>
      {selectedValue || label}
    </Button>
  );

  const CustomAutocomplete = ({ data, onChange, onClose, placeholder, position }) => (
    <Autocomplete
      data={data}
      maxDropdownHeight={300}
      dropdownOpened={true}
      autoFocus
      onChange={onChange}
      onOptionSubmit={onClose}
      onBlur={onClose}
      placeholder={placeholder}
      style={{ position: 'absolute', ...position }}
      styles={(theme) => ({
        dropdown: {
          maxHeight: 246,
          overflow: 'auto',
          border: `1px solid ${theme.colors.gray[4]}`,
          zIndex: 1100,
        },
        item: {
          '&[data-hover]': {
            backgroundColor: theme.colors.blue[0],
          },
        },
        input: {
          width: '240px',
        },
      })}
    />
  );

  return (
    <Modal.Root
      style={{
        borderRadius: '20px',
        backgroundColor: theme.other.colours.background,
        boxShadow: '0px 2px 2px 0px #0000000D',
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
      }}
      opened={open}
      onClose={close}
      size={'740px'}
      overlayProps={{ zIndex: 1000 }}
      zIndex={1000}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header
          style={{ borderBottom: `1px solid ${theme.other.colours.borderPaper}`, alignItems: 'flex-start' }}>
          <Badge
            radius="8px"
            style={{
              padding: '8px',
              height: '24px',
              color: '#808080',
              backgroundColor: '#E8E8E8',
              marginTop: '3px',
              textTransform: 'none',
              fontWeight: 400,
              fontSize: '12px',
              marginRight: '2px',
            }}>
            Project
          </Badge>
          <KeyboardArrowRightIcon color="#808080" style={{ width: '12px', marginTop: '3px' }} />
          <Badge
            radius="8px"
            style={{
              padding: '8px',
              height: '24px',
              color: '#242424',
              backgroundColor: '#E8E8E8',
              marginTop: '3px',
              marginLeft: '2px',
              textTransform: 'none',
              fontWeight: 400,
              fontSize: '12px',
            }}>
            Add {type}
          </Badge>

          <Modal.CloseButton />
        </Modal.Header>

        <Modal.Body p={16} style={{ boxShadow: '0px 2px 2px 0px #0000000D' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormContext.Provider value={{ control, errors }}>
              <InitiativeOrFeatureContainer>
                <MainBody>
                  <Grid style={{ display: 'block' }}>
                    <Field
                      name="title"
                      data-autofocus
                      required
                      renderField={renderInput}
                      placeholder={`${type} title`}
                    />
                    <Field
                      name="description"
                      renderField={renderInputTextArea}
                      placeholder="Description"
                      style={{ width: '100%' }}
                    />
                    <Group justify="flex-start" gap={8} mt={10} mb={10}>
                      <>
                        {/* Calendar Button */}
                        <CalendarButton
                          selectedDate={selectedDate}
                          handleDateClick={handleDateClick}
                          openDatePicker={openDatePicker}
                          handleDateChange={handleDateChange}
                        />

                        {/* Owner Selector */}
                        <SelectorButton
                          label="Assigned to you"
                          selectedValue={selectedOwner}
                          onClick={() => setOpenAutocomplete(true)}
                        />
                        {openAutocomplete && (
                          <CustomAutocomplete
                            data={formatUsers(users)}
                            onChange={(owner) => setSelectedOwner(owner)}
                            onClose={() => setOpenAutocomplete(false)}
                            placeholder="Assign to"
                            position={{ top: '257px', left: '111px' }}
                          />
                        )}

                        {/* Value Stream Selector */}
                        {type === 'Initiatives' && (
                          <SelectorButton
                            label={activeValueStreams[0]?.value}
                            selectedValue={selectedValueStream}
                            onClick={() => setOpenValueStreamSelector(true)}
                          />
                        )}
                        {openValueStreamSelector && (
                          <CustomAutocomplete
                            data={activeValueStreams}
                            onChange={(stream) => setSelectedValueStream(stream)}
                            onClose={() => setOpenValueStreamSelector(false)}
                            placeholder="Value stream"
                            position={{ top: '257px', left: '225px' }}
                          />
                        )}

                        {type === 'Features' && (
                          <SelectorButton
                            label={programmes[0]?.name}
                            selectedValue={selectedProgramme}
                            onClick={() => setOpenProgrammeSelector(true)}
                          />
                        )}

                        {openProgrammeSelector && (
                          <CustomAutocomplete
                            data={programmes.map((programme) => ({
                              value: programme.name,
                              id: programme.id,
                            }))}
                            onChange={(programme) => setSelectedProgramme(programme)}
                            onClose={() => setOpenProgrammeSelector(false)}
                            placeholder="Programme"
                            position={{ top: '257px', left: '225px' }}
                          />
                        )}
                      </>
                    </Group>
                  </Grid>
                </MainBody>
              </InitiativeOrFeatureContainer>
            </FormContext.Provider>
          </form>

          <Divider m="12px -12px" />
          <Group justify="flex-end">
            <Button
              type="cancel"
              onClick={close}
              variant="light"
              radius={8}
              style={{ border: `1px solid ${lightColors.tileBorder}`, backgroundColor: 'white', color: '#242424' }}>
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              variant="light"
              radius={8}
              style={{ backgroundColor: '#FF2276', color: '#fff' }}>
              Save
            </Button>
          </Group>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
