import React, { useContext, useMemo } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import { useMantineColorScheme } from '@mantine/core';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { AppBar, Avatar, MenuItem, Toolbar, Zoom } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuList from '@mui/material/Menu';
import { makeStyles, useTheme } from '@mui/styles';
import { styled } from '@mui/system';
import classNames from 'classnames';
import { last } from 'lodash';
import { TransitionGroup } from 'react-transition-group';

import { styles } from './styles';
import { ColorModeContext } from '../../containers/App/components/theme';
import { GET_PROJECTS } from '../../containers/Organisation/Projects/graphql';
import { GET_PROGRAMMES } from '../../containers/Settings/GraphQL/programmes';
import { GET_TEAMS } from '../../containers/Settings/GraphQL/teams';
import { GET_USERS } from '../../containers/Settings/GraphQL/users';
import { GET_PROGRAM_INCREMENTS } from '../../containers/Settings/ProgramIncrement/graphql';
import { GET_VALUE_STREAMS } from '../../containers/Settings/Streams/graphql';
import { GET_ALL_INITIATIVES, GET_INITIATIVES } from '../../containers/ValueStream/Initiatives/graphql';
import { GET_PRODUCT_THEMES, GET_ALL_PRODUCT_THEMES } from '../../containers/ValueStream/ProductTheme/graphql';
import {
  loggedInUserVar,
  resetDrawerDetails,
  selectedInitiativeVar,
  selectedOwnerVar,
  selectedPiVar,
  selectedProductThemeVar,
  selectedEnablerVar,
  selectedProgrammeVar,
  selectedSubmitterVar,
  selectedTeamVar,
  selectedThinkTankValueStreamVar,
  selectedUsersVar,
  selectedValueStreamVar,
  selectedProjectVar,
} from '../../reactiveVariables';
import { sortValues } from '../../utils/helpers';
import EnablerSelector from '../Common/Selectors/EnablerSelector';
import IncrementSelector from '../Common/Selectors/IncrementSelector';
import InitiativeSelector from '../Common/Selectors/InitiativeSelector';
import OwnerSelector from '../Common/Selectors/OwnerSelector';
import ProductThemeSelector from '../Common/Selectors/ProductThemeSelector';
import ProgrammeSelector from '../Common/Selectors/ProgramSelector';
import ProjectSelector from '../Common/Selectors/ProjectSelector';
import SubmitterSelector from '../Common/Selectors/SubmitterAndStateSelector';
import TeamSelector from '../Common/Selectors/TeamSelector';
import ValueStreamSelector from '../Common/Selectors/ValueStreamSelector';
import ToggleIcon from '../Common/ToggleIcon';

const useStyles = makeStyles(styles);

const ToolbarLeft = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '100%',
}));

const shouldShowTeamFilter = () => {
  return ['/', '/product/ideation', '/product/prioritisation', '/product/roadmap'].includes(location.pathname);
};

const shouldShowEnablerFilter = () => {
  return ['/product/prioritisation', '/product/features'].includes(location.pathname);
};

const shouldShowIncrementFilter = () => {
  return (
    location.pathname.startsWith('/delivery') || location.pathname === '/product/ideation' || location.pathname === '/'
  );
};

const shouldHideProgramSelector = () => {
  return (
    location.pathname.startsWith('/valueStream') ||
    location.pathname.startsWith('/organisation') ||
    (location.pathname.startsWith('/admin') && !location.pathname.startsWith('/admin/increments'))
  );
};

const shouldShowValueStreamSelector = () => {
  return (
    shouldHideProgramSelector &&
    (location.pathname.startsWith('/valueStream') ||
      location.pathname === '/organisation/dashboard' ||
      location.pathname === '/organisation/portfolio' ||
      location.pathname === '/organisation/risks' ||
      location.pathname === '/organisation/reporting') &&
    !location.pathname.startsWith('/valueStream/initiative/')
  );
};

const shouldShowInitiativeFilter = () => {
  return ['/', '/delivery/summary', '/delivery/detailed'].includes(location.pathname);
};

const shouldShowProductThemeFilter = () => {
  return [
    '/valueStream',
    '/product/ideation',
    '/product/prioritisation',
    '/product/roadmap',
    '/product/features',
    '/valueStream/kanban',
    '/organisation/portfolio',
    '/organisation/dashboard',
    '/organisation/initiatives',
    '/valueStream/initiatives',
    '/valueStream/deliveryReadiness',
  ].includes(location.pathname);
};

const shouldShowOwnerFilter = () => {
  return ['/valueStream/kanban', '/valueStream', '/valueStream/initiatives', '/valueStream/deliveryReadiness'].includes(
    location.pathname,
  );
};

const shouldSubmitterFilter = () => {
  return ['/organisation/thinkTank'].includes(location.pathname);
};

const shouldShowDashboardTeamsFilter = () => {
  return ['/valueStream', '/valueStream/kanban', '/valueStream/initiatives'].includes(location.pathname);
};

const shouldShowAllTeamsFilter = () => {
  return [
    '/organisation/portfolio',
    '/organisation/initiatives',
    '/organisation/dashboard',
    '/valueStream/deliveryReadiness',
  ].includes(location.pathname);
};

const shouldShowProjectFilter = () => {
  return [
    '/valueStream/initiatives',
    '/valueStream/kanban',
    '/valueStream',
    '/product/ideation',
    '/organisation/dashboard',
    '/valueStream/deliveryReadiness',
  ].includes(location.pathname);
};

const Filters = ({
  selectedProgramme,
  selectedPi,
  selectedValueStream,
  programmes,
  programIncrements,
  valueStreams,
  productThemes,
}) => {
  const shouldShowPortfolioOwnerFilter = () => {
    return ['/organisation/portfolio', '/organisation/initiatives', '/organisation/dashboard'].includes(
      location.pathname,
    );
  };

  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);
  const { data: { users = [] } = {} } = useQuery(GET_USERS);

  const { data: { initiatives = [] } = {} } = useQuery(
    shouldShowPortfolioOwnerFilter() ? GET_ALL_INITIATIVES : GET_INITIATIVES,
  );

  const { data: { projects: strategic_themes = [] } = {} } = useQuery(GET_PROJECTS);
  const selectedProject = useReactiveVar(selectedProjectVar);

  const activeValueStreams = valueStreams.filter((stream) => stream.isActive === true);
  const activeProjects = strategic_themes.filter((theme) => theme.isActive === true);

  const selectedTeams = useReactiveVar(selectedTeamVar);
  const selectedOwners = useReactiveVar(selectedOwnerVar);
  const selectedUsers = useReactiveVar(selectedUsersVar);
  const selectedSubmitters = useReactiveVar(selectedSubmitterVar);
  const selectedThinkTankValueStream = useReactiveVar(selectedThinkTankValueStreamVar);
  const selectedInitiative = useReactiveVar(selectedInitiativeVar);
  const selectedProductTheme = useReactiveVar(selectedProductThemeVar);
  const selectedEnabler = useReactiveVar(selectedEnablerVar);

  const isInValueStream = programmes.some((pi) => pi.id === selectedProgramme);
  const isInProgramme = programIncrements.some((pi) => pi.id === selectedPi);

  const filteredPis = useMemo(
    () => programIncrements?.filter((pi) => pi.programme === selectedProgramme) || [],
    [programIncrements, selectedProgramme],
  );

  const filterTeamsByValueStream = (valueStreamId) => {
    return teams.filter((team) => {
      return team.programmes.some((programmeId) => {
        const programme = programmes.find((p) => p.id === programmeId);
        return programme && programme.valueStream.id === valueStreamId;
      });
    });
  };

  const filteredTeams = useMemo(() => {
    if (shouldShowDashboardTeamsFilter()) {
      // If shouldShowDashboardTeamsFilter is true, use filterTeamsByValueStream
      return filterTeamsByValueStream(selectedValueStream);
    }
    // Else, use the original filtering based on selectedProgramme
    return teams?.filter((team) => team.programmes?.includes(selectedProgramme)) || [];
  }, [teams, selectedProgramme, shouldShowDashboardTeamsFilter(), selectedValueStream]);

  const ownersList = Array.from(
    new Set(
      initiatives
        .filter((initiative) => initiative.owner !== null || initiative.features?.nodes?.length > 0)
        .flatMap((initiative) => {
          const owners = [];
          if (initiative.owner !== null) {
            owners.push(initiative.owner);
          }
          // Add feature.assignedTo from each feature in the nodes array
          if (initiative.features?.nodes?.length > 0) {
            initiative.features.nodes.forEach((feature) => {
              if (feature.assignedTo) {
                owners.push(feature.assignedTo);
              }
            });
          }
          return owners;
        }),
    ),
  );

  const portfolioOwnersList = Array.from(
    new Set(
      initiatives
        .filter((initiative) => initiative.isPortfolioState === true && initiative.owner !== null)
        .map((initiative) => initiative.owner),
    ),
  );
  const projectOwnersList = Array.from(new Set(activeProjects.map((project) => project.owner).filter(Boolean)));

  const mappedProjectOwners = projectOwnersList
    .map((ownerId) => {
      const user = users.find((user) => user.id === ownerId);
      return user ? { label: `${user.firstName} ${user.lastName}`, value: user.id } : null;
    })
    .filter(Boolean);

  if (selectedProgramme && !isInValueStream) selectedProgrammeVar(null);
  if (selectedPi && !isInProgramme) selectedPiVar(null);

  const handleChangeProgramme = (event, value) => {
    const programme = programmes.find((programme) => programme.id === value);
    const selectedPIs = programIncrements?.filter((pi) => pi.programme === value);
    const activePis = selectedPIs.filter((pi) => pi.status === 'active');
    const planningPis = selectedPIs.filter((pi) => pi.status === 'planning');
    const newPi = (last(activePis) || planningPis?.[0] || last(selectedPIs))?.id || 0;

    resetDrawerDetails();
    window.localStorage.setItem('selectedValueStream', programme?.valueStream?.id);
    window.localStorage.setItem('selectedProgramme', value);
    window.localStorage.setItem('selectedPI', newPi);
    selectedValueStreamVar(programme?.valueStream?.id);
    selectedProgrammeVar(value);
    selectedPiVar(newPi);
  };

  const handleChangeVS = (event, value) => {
    if (value === 'All') {
      selectedValueStreamVar(value);
      return;
    }

    const programme = programmes.find((programme) => programme.valueStream?.id === value);
    const selectedPIs = programIncrements?.filter((pi) => pi.programme === value);
    const activePi = selectedPIs.filter((pi) => pi.status === 'active');
    const newPi = (last(activePi) || last(selectedPIs))?.id || 0;

    resetDrawerDetails();
    window.localStorage.setItem('selectedValueStream', value);
    window.localStorage.setItem('selectedProgramme', programme?.id);
    window.localStorage.setItem('selectedPI', newPi);
    selectedValueStreamVar(value);
    selectedProgrammeVar(programme?.id);
    selectedPiVar(newPi);
  };

  const handleChangeTeam = (value) => selectedTeamVar(value);
  const handleChangeOwner = (value) => selectedOwnerVar(value);
  const handleChangeSubmitter = (value) => selectedSubmitterVar(value);
  const handleChangeUsers = (value) => selectedUsersVar(value);
  const thinkTankValueStreamChange = (value) => selectedThinkTankValueStreamVar(value);
  const handleChangeInitiative = (value) => selectedInitiativeVar(Number(value));
  const handleChangeProductTheme = (event, value) => selectedProductThemeVar(value);
  const handleChangeEnabler = (event, value) => selectedEnablerVar(value);

  const handleChangePi = (event, value) => {
    resetDrawerDetails();
    window.localStorage.setItem('selectedPI', value);
    selectedPiVar(value);
  };

  const handleChangeProject = (event, value) => {
    selectedProjectVar(value);
    window.localStorage.setItem('selectedProject', value);
  };

  return (
    <TransitionGroup component={null}>
      {!!programmes.length && !shouldHideProgramSelector() && (
        <Zoom>
          <div style={{ marginLeft: '95px' }}>
            <ProgrammeSelector
              handleChange={handleChangeProgramme}
              value={selectedProgramme || ''}
              valueStreams={valueStreams}
            />
          </div>
        </Zoom>
      )}
      {shouldShowValueStreamSelector() && (
        <Zoom>
          <div style={{ marginLeft: '95px' }}>
            <ValueStreamSelector
              handleChange={handleChangeVS}
              allOption={
                window.location.pathname === '/organisation/dashboard' ||
                window.location.pathname === '/organisation/portfolio' ||
                window.location.pathname === '/organisation/reporting' ||
                window.location.pathname === '/organisation/risks'
              }
              value={selectedValueStream || ''}
              valueStreams={activeValueStreams}
            />
          </div>
        </Zoom>
      )}
      {!!filteredPis.length && shouldShowIncrementFilter() && (
        <Zoom>
          <div style={{ marginLeft: '24px' }}>
            <IncrementSelector handleChange={handleChangePi} value={selectedPi || ''} options={filteredPis} />
          </div>
        </Zoom>
      )}
      {!!filteredTeams.length && (shouldShowTeamFilter() || shouldShowDashboardTeamsFilter()) && (
        <Zoom>
          <div style={{ marginLeft: '24px' }}>
            <TeamSelector
              handleChange={handleChangeTeam}
              value={selectedTeams || ''}
              options={sortValues(filteredTeams)}
            />
          </div>
        </Zoom>
      )}
      {!!teams.length && shouldShowAllTeamsFilter() && (
        <Zoom>
          <div
            style={{
              marginLeft:
                location.pathname === '/valueStream/kanban' ||
                location.pathname === '/valueStream/initiatives' ||
                location.pathname === '/organisation/dashboard' ||
                location.pathname === '/organisation/portfolio' ||
                location.pathname === '/valueStream/deliveryReadiness'
                  ? '24px'
                  : '95px',
            }}>
            <TeamSelector handleChange={handleChangeTeam} value={selectedTeams || ''} options={sortValues(teams)} />
          </div>
        </Zoom>
      )}
      {!!initiatives.length && shouldShowInitiativeFilter() && (
        <Zoom>
          <div style={{ marginLeft: '24px' }}>
            <InitiativeSelector
              handleChange={handleChangeInitiative}
              value={selectedInitiative || ''}
              initiatives={initiatives}
            />
          </div>
        </Zoom>
      )}
      {productThemes &&
        !!productThemes.length &&
        shouldShowProductThemeFilter() && ( //removed SelectedProgramme && - for future reference (on the showAll filters)
          <Zoom>
            <div style={{ marginLeft: '24px' }}>
              <ProductThemeSelector
                handleChange={handleChangeProductTheme}
                value={selectedProductTheme || ''}
                productThemes={productThemes}
              />
            </div>
          </Zoom>
        )}

      {shouldShowEnablerFilter() && (
        <Zoom>
          <div style={{ marginLeft: '24px' }}>
            <EnablerSelector handleChange={handleChangeEnabler} value={selectedEnabler || ''} />
          </div>
        </Zoom>
      )}
      {((location.pathname === '/organisation/reporting' && !!projectOwnersList.length) ||
        (!!initiatives.length && (shouldShowPortfolioOwnerFilter() || shouldShowOwnerFilter()))) && (
        <Zoom>
          <div
            style={{
              marginLeft: '24px',
            }}>
            <OwnerSelector
              handleChange={handleChangeOwner}
              value={selectedOwners || ''}
              options={
                location.pathname === '/organisation/reporting'
                  ? mappedProjectOwners
                  : shouldShowPortfolioOwnerFilter()
                  ? portfolioOwnersList.map((owner) => ({
                      label: owner,
                      value: owner,
                    }))
                  : ownersList.map((owner) => ({
                      label: owner,
                      value: owner,
                    }))
              }
            />
          </div>
        </Zoom>
      )}

      {shouldSubmitterFilter() && (
        <Zoom>
          <div style={{ marginLeft: '24px' }}>
            <SubmitterSelector
              handleSubmitterChange={handleChangeSubmitter}
              handleUsersChange={handleChangeUsers}
              thinkTankValueStreamChange={thinkTankValueStreamChange}
              submittersValue={selectedSubmitters || ''}
              usersValue={selectedUsers || ''}
              thinkTankValueStreamValue={selectedThinkTankValueStream || 'All'}
              valueStreams={activeValueStreams}
            />
          </div>
        </Zoom>
      )}
      {!!activeProjects.length && shouldShowProjectFilter() && (
        <Zoom>
          <div style={{ marginLeft: '24px' }}>
            <ProjectSelector
              handleChange={handleChangeProject}
              value={selectedProject || ''}
              projects={activeProjects}
              allOption={true}
            />
          </div>
        </Zoom>
      )}
    </TransitionGroup>
  );
};

function HeaderBar(props) {
  const { anchorEl, open, handleDrawerClick, handleLogout, handleClick, handleClose } = props;

  const theme = useTheme();
  const classes = useStyles();
  const colorMode = useContext(ColorModeContext);
  const user = useReactiveVar(loggedInUserVar);
  const { colorScheme, setColorScheme } = useMantineColorScheme();

  const selectedValueStream = useReactiveVar(selectedValueStreamVar);
  const selectedProgramme = useReactiveVar(selectedProgrammeVar);
  const selectedPi = useReactiveVar(selectedPiVar);

  const initials = user.firstName?.charAt(0) + user.lastName?.charAt(0);

  const { loading: valueStreamLoading, data: { valueStreams = [] } = {} } = useQuery(GET_VALUE_STREAMS);
  const { loading: piLoading, data: { programIncrements = [] } = {} } = useQuery(GET_PROGRAM_INCREMENTS);
  const { loading: programmesLoading, data: { programmes = [] } = {} } = useQuery(GET_PROGRAMMES);

  const { data: { productThemes = [] } = {} } = useQuery(
    selectedValueStream === null || isNaN(selectedValueStream) ? GET_ALL_PRODUCT_THEMES : GET_PRODUCT_THEMES,
    {
      variables: {
        valueStream: selectedValueStream ? selectedValueStream : valueStreams[0]?.id,
      },
    },
  );

  const filter = {
    selectedValueStream,
    selectedProgramme,
    selectedPi,
    valueStreams,
    programmes,
    programIncrements,
    productThemes,
  };

  return (
    <AppBar className={classNames(classes.appBar, open)} variant="outlined">
      <Toolbar className={classes.toolbar} disableGutters>
        <ToolbarLeft open={open}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '90px' }}>
            <IconButton
              aria-label="open drawer"
              classes={{
                label: 'buttonLabel',
              }}
              color={theme.palette.mode === 'light' ? 'secondary' : 'primary'}
              className={classNames(classes.menuButton, open)}
              size="large"
              onClick={handleDrawerClick}>
              <MenuIcon />
            </IconButton>
          </div>
          <span className={classes.logoText}>BigAgile</span>
          {!valueStreamLoading && !piLoading && !programmesLoading && <Filters {...filter} />}
        </ToolbarLeft>
        <div className={classes.toolbarRight}>
          <IconButton style={{ marginLeft: 1 }}>
            <NotificationsIcon />
          </IconButton>
          <IconButton
            style={{ marginLeft: 1 }}
            onClick={() => {
              colorMode.toggleColorMode(setColorScheme);
            }}>
            <ToggleIcon on={colorScheme === 'dark'} onIcon={<Brightness7Icon />} offIcon={<Brightness4Icon />} />
          </IconButton>
          <div className={classes.accountIconDelimiter} />
          <IconButton aria-label="Account" className={classes.accountIconButton} onClick={handleClick} size="large">
            <Avatar sx={{ bgcolor: theme.palette.color.done }}>{initials}</Avatar>
          </IconButton>
          <MenuList id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem key={0} onClick={handleLogout}>
              Logout
            </MenuItem>
          </MenuList>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default HeaderBar;
