import React, { useState, useEffect, useRef, useMemo } from 'react';

import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { Box, Anchor, Loader, Select as MantineSelect, Badge, Tooltip } from '@mantine/core';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { DataTableToolbar } from 'components/Common/DataTableToolbar';
import { find } from 'lodash';
import { DateTime } from 'luxon';
import { DataTable } from 'mantine-datatable';
import { STAGE_SELECTS } from 'utils/formConstants';
import { filterProductTheme } from 'utils/helpers';
import { truncate } from 'utils/stringUtils';

import {
  selectedOwnerVar,
  selectedTeamVar,
  openDrawer,
  selectedProjectVar,
  selectedProductThemeVar,
} from '../../../reactiveVariables';
import { color } from '../../../shared/styles/color';
import { getMappedValues } from '../../../utils/tableUtils';
import { STAGE_MAPPING } from '../../Product/Features/constants';
import { GET_FEATURES_FOR_VS } from '../../Product/Features/graphql';
import { UPDATE_FEATURE } from '../../Product/Features/graphql';
import { GET_SUBTASKS } from '../../Settings/GraphQL/configuration';
import { GET_ALL_INITIATIVES } from '../../ValueStream/Initiatives/graphql';

import '@mantine/core/styles.css';
import 'mantine-datatable/styles.css';

const PAGE_SIZE = 60;

export const DeliveryReadinessTable = () => {
  const [features, setFeatures] = useState([]);
  const [allFeatures, setAllFeatures] = useState([]);
  const [page, setPage] = useState(0);
  const [sortStatus, setSortStatus] = useState({ columnAccessor: 'name', direction: 'asc' });
  const [visibleColumns, setVisibleColumns] = useState({});
  const [tempVisibleColumns, setTempVisibleColumns] = useState({});
  const [tableHeight, setTableHeight] = useState('100vh');
  const [searchQuery, setSearchQuery] = useState('');
  const [stages, setStages] = useState([]);

  const [updateFeature] = useMutation(UPDATE_FEATURE);

  const { data: queryData, loading } = useQuery(GET_FEATURES_FOR_VS);
  const { data: { initiatives = [] } = {} } = useQuery(GET_ALL_INITIATIVES);
  const { data: { subtasks = [] } = {} } = useQuery(GET_SUBTASKS);

  const tasks = subtasks.filter((task) => task.isDefault === true);
  const scrollViewportRef = useRef(null);

  const selectedOwners = useReactiveVar(selectedOwnerVar);
  const selectedTeams = useReactiveVar(selectedTeamVar);
  const selectedProductTheme = useReactiveVar(selectedProductThemeVar);
  const selectedProject = useReactiveVar(selectedProjectVar);

  useEffect(() => {
    const updateTableHeight = () => {
      const vh = window.innerHeight;
      setTableHeight(vh * 0.8);
    };

    updateTableHeight();
    window.addEventListener('resize', updateTableHeight);

    return () => {
      window.removeEventListener('resize', updateTableHeight);
    };
  }, []);

  useEffect(() => {
    if (queryData?.features) {
      const filteredFeatures = queryData.features.filter(
        (feature) => feature.status === 'To Do' && feature.program_increment?.status === 'planning',
      );
      // Add default subtask statuses
      const enrichedFeatures = filteredFeatures.map((feature) => ({
        ...feature,
        subtasks:
          feature.subtasks?.map((subtask) => ({
            ...subtask,
            status: subtask.status ?? (subtask.done ? 'Done' : 'To Do'),
          })) || [],
      }));

      // Sort by name
      const sortedFeatures = enrichedFeatures.sort((a, b) => {
        const nameA = a.name || '';
        const nameB = b.name || '';
        return nameA.localeCompare(nameB);
      });

      // Set the paged and full list of features
      setFeatures(sortedFeatures.slice(0, PAGE_SIZE));
      setAllFeatures(sortedFeatures);
    }
  }, [queryData]);

  const loadMoreData = () => {
    if (loading || !allFeatures.length) return;

    const nextPage = page + 1;
    const newFeatures = allFeatures.slice(0, (nextPage + 1) * PAGE_SIZE);

    if (newFeatures.length > features.length) {
      setFeatures(newFeatures);
      setPage(nextPage);
    }
  };

  const toggleTempColumn = (accessor) => {
    setTempVisibleColumns((prev) => ({ ...prev, [accessor]: !prev[accessor] }));
  };

  const onRowClick = (feature) => {
    openDrawer(feature, 'feature');
  };

  const handleSort = (status) => {
    setSortStatus(status);
    const { columnAccessor, direction } = status;

    const sortedData = [...allFeatures].sort((a, b) => {
      if (columnAccessor === 'done_status') {
        const isADone = areAllRelevantSubtasksDone(a?.subtasks, tasks);
        const isBDone = areAllRelevantSubtasksDone(b?.subtasks, tasks);

        return direction === 'asc' ? Number(isADone) - Number(isBDone) : Number(isBDone) - Number(isADone);
      }

      if (columnAccessor === 'initiativeId') {
        const initiativeA = find(initiatives, { id: a.initiativeId })?.name || '';
        const initiativeB = find(initiatives, { id: b.initiativeId })?.name || '';
        return direction === 'asc' ? initiativeA.localeCompare(initiativeB) : initiativeB.localeCompare(initiativeA);
      }

      if (columnAccessor === 'program_increment' || columnAccessor === 'programme' || columnAccessor === 'team') {
        const aValue = a[columnAccessor]?.name ?? '';
        const bValue = b[columnAccessor]?.name ?? '';
        return direction === 'asc'
          ? String(aValue).localeCompare(String(bValue))
          : String(bValue).localeCompare(String(aValue));
      }

      if (columnAccessor === 'wsjf' || columnAccessor === 'size') {
        const aValue = a[columnAccessor];
        const bValue = b[columnAccessor];

        if (aValue === null && bValue === null) return 0;
        if (aValue === null) return direction === 'asc' ? -1 : 1;
        if (bValue === null) return direction === 'asc' ? 1 : -1;

        return direction === 'asc' ? aValue - bValue : bValue - aValue;
      }

      // Default sorting for other columns
      const aValue = a[columnAccessor] ?? '';
      const bValue = b[columnAccessor] ?? '';
      return direction === 'asc'
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    });

    setAllFeatures(sortedData);
    setFeatures(sortedData.slice(0, PAGE_SIZE));
  };

  const handleSubtaskStatusChange = (selectedFeature, subtaskId, value) => {
    setFeatures((prevFeatures) =>
      prevFeatures.map((feature) =>
        feature.id === selectedFeature.id
          ? {
              ...feature,
              subtasks: feature.subtasks.map((subtask) =>
                subtask.id === subtaskId ? { ...subtask, status: value } : subtask,
              ),
            }
          : feature,
      ),
    );

    const selectedSubtask = selectedFeature.subtasks.find((subtask) => subtask.id === subtaskId);

    const updatedSubtask = {
      ...selectedSubtask,
      status: value,
      done: value === 'Done' ? true : false,
    };

    const updatedSubtasks = selectedFeature.subtasks.map((subtask) =>
      subtask.id === subtaskId ? updatedSubtask : subtask,
    );

    updateFeature({
      variables: {
        featureId: selectedFeature.id,
        feature: {
          subtasks: updatedSubtasks,
        },
      },
    });
  };

  const areAllRelevantSubtasksDone = (subtasks) => {
    const relevantSubtasks = subtasks?.filter((subtask) => tasks.some((task) => task.name === subtask.name));

    // Check if all those relevant subtasks are marked as 'done'
    return relevantSubtasks.length > 0 && relevantSubtasks.every((s) => s.status === 'Done' || s.done === true);
  };

  const staticColumns = useMemo(
    () => [
      {
        accessor: 'done_status',
        title: 'Ready?',
        sortable: true,
        textAlign: 'center',
        width: 80,
        render: (record) =>
          areAllRelevantSubtasksDone(record?.subtasks) ? <TaskAltIcon /> : <RadioButtonUncheckedIcon />,
      },
      {
        accessor: 'programme',
        title: 'Programme',
        sortable: true,
        width: 150,
        render: (record) => {
          return (
            record?.programme && (
              <Tooltip
                data-value={record?.programme?.name}
                transition={'scale'}
                label={record?.programme?.name}
                zIndex={1000}>
                <Badge
                  color={'#7762B6'}
                  size="sm"
                  radius="4px"
                  sx={{ padding: '4px', color: 'white', backgroundColor: '#7762B6' }}>
                  {record?.programme?.name}
                </Badge>
              </Tooltip>
            )
          );
        },
      },
      {
        accessor: 'initiativeId',
        title: 'Parent (Initiative/Project)',
        sortable: true,
        width: 300,
        render: (record) => {
          const initiative = find(initiatives, { id: record.initiativeId }) || {};

          return (
            initiative?.name && (
              <Tooltip data-value={initiative?.name} transition={'scale'} label={initiative?.name} zIndex={1000}>
                <Badge
                  color={color.purpulishPink}
                  size="sm"
                  radius="4px"
                  sx={{ padding: '4px', color: 'white', backgroundColor: '#59585C' }}>
                  {initiative?.name}
                </Badge>
              </Tooltip>
            )
          );
        },
      },
      {
        accessor: 'wsjf',
        title: 'WSJF',
        sortable: true,
        render: (record) => record.wsjf && record.wsjf,
      },
      {
        accessor: 'name',
        title: 'Name',
        sortable: true,
        cursor: 'pointer',
        width: 300,
        render: (record) => (
          <Box style={{ cursor: 'pointer' }} onClick={() => onRowClick(record)}>
            {record.name}
          </Box>
        ),
      },
      {
        accessor: 'confluenceLink',
        title: 'Wiki Link',
        sortable: true,
        render: (record) =>
          record.confluenceLink && (
            <Anchor href={record.confluenceLink} target="_blank">
              {truncate(record.confluenceLink, 10)}
            </Anchor>
          ),
      },
      {
        accessor: 'plannedReleaseDate',
        title: 'Target Date',
        sortable: true,
        render: (record) => {
          const plannedReleaseDate = record.plannedReleaseDate;
          if (plannedReleaseDate) {
            const date = DateTime.fromISO(plannedReleaseDate);
            return date.isValid && date.toFormat('dd/MM/yyyy');
          }
          return '';
        },
      },
      {
        accessor: 'stage',
        title: 'State',
        sortable: true,
        width: '100px',
        render: (record) => record.stage && getMappedValues(STAGE_MAPPING)[record.stage],
      },
      {
        accessor: 'program_increment',
        title: 'Increment(s)',
        sortable: true,
        render: (record) => record.program_increment && record.program_increment.name,
      },
    ],
    [initiatives],
  );

  // Generate dynamic columns from tasks array
  const dynamicColumns = useMemo(
    () =>
      tasks.map((task) => ({
        accessor: `task_${task.id}`,
        title: task.name,
        width: '150px',
        render: (record) => {
          const subtask = record?.subtasks?.find((s) => s.name === task.name);
          return subtask ? renderSubtaskCell(subtask, record) : null;
        },
      })),
    [tasks],
  );

  const renderSubtaskCell = (subtask, feature) => {
    return (
      <MantineSelect
        placeholder={'Select'}
        data={['To Do', 'In Progress', 'Done', 'Not Required']}
        value={subtask?.status}
        onChange={(value) => handleSubtaskStatusChange(feature, subtask?.id, value)}
        styles={() => ({
          input: {
            borderColor:
              subtask?.status === 'To Do'
                ? color.toDo
                : subtask?.status === 'In Progress'
                ? color.inProgress
                : subtask?.status === 'Done'
                ? color.done
                : color.red, // For 'Not Required'
          },
        })}
      />
    );
  };

  const remainingColumns = useMemo(
    () => [
      { accessor: 'size', title: 'WSJF Size', sortable: true, render: (record) => record.size && record.size },
      {
        accessor: 'assignedTo',
        title: 'Owner',
        sortable: true,
        width: '150px',
        render: (record) => record.assignedTo && record.assignedTo,
      },
      {
        accessor: 'team',
        title: 'Team',
        sortable: true,
        render: (record) => record.team && record.team?.name,
        width: '150px',
      },
    ],
    [],
  );

  const allColumns = useMemo(
    () => [...staticColumns, ...dynamicColumns, ...remainingColumns],
    [staticColumns, dynamicColumns, remainingColumns],
  );

  // Filter columns based on visibility
  const filteredColumns = allColumns.filter((col) => visibleColumns[col.accessor]);

  useEffect(() => {
    if (Object.keys(visibleColumns).length === 0 && dynamicColumns.length > 0) {
      const initialVisibility = allColumns.reduce((acc, col) => {
        acc[col.accessor] = true;
        return acc;
      }, {});
      setVisibleColumns(initialVisibility);
      setTempVisibleColumns(initialVisibility);
    }
  }, [allColumns, dynamicColumns, visibleColumns]);

  const filteredFeatures = useMemo(() => {
    return allFeatures.filter(
      (feature) =>
        (selectedOwners.length === 0 || selectedOwners.includes(feature.assignedTo)) &&
        (selectedTeams.length === 0 || selectedTeams.includes(feature.teamId)) &&
        (selectedProject === null || selectedProject === feature.project) &&
        filterProductTheme(selectedProductTheme, feature) &&
        (!stages.length || stages.includes(feature.stage)) &&
        (searchQuery.trim() === '' ||
          Object.values(feature).some(
            (value) => typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase()),
          ) ||
          feature.program_increment?.name?.toLowerCase().includes(searchQuery.toLowerCase())),
    );
  }, [allFeatures, selectedOwners, selectedTeams, selectedProject, selectedProductTheme, stages, searchQuery]);

  return (
    <Box sx={{ height: '100%', overflowY: 'auto' }}>
      <DataTableToolbar
        stateOptions={STAGE_SELECTS}
        setSelectState={setStages}
        setSearchQuery={setSearchQuery}
        staticColumns={staticColumns}
        tempVisibleColumns={tempVisibleColumns}
        toggleTempColumn={toggleTempColumn}
        setVisibleColumns={setVisibleColumns}
      />

      <DataTable
        columns={filteredColumns}
        records={filteredFeatures}
        sortStatus={sortStatus}
        onSortStatusChange={handleSort}
        highlightOnHover
        withColumnBorders
        fetching={loading}
        enableRowVirtualization
        onScrollToBottom={loadMoreData}
        height={tableHeight}
        emptyState={<div style={{ textAlign: 'center', padding: '20px' }}></div>}
        stickyHeader
        scrollViewportRef={scrollViewportRef}
        styles={{
          root: { backgroundColor: 'transparent' },
          table: { backgroundColor: 'transparent' },
          header: { backgroundColor: '#f7f7f8' },
          row: { backgroundColor: 'transparent' },
        }}
      />
      {loading && <Loader sx={{ margin: '10px auto', display: 'block' }} />}
    </Box>
  );
};
