import styled from '@emotion/styled';
import { Paper } from '@mantine/core';
import { darken, lighten } from '@mui/system/colorManipulator';

import { color } from '../../../shared/styles/color';

export const SyncFeaturesContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: 'auto',
}));
