import React, { useMemo, useState } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import { useMediaQuery } from '@mantine/hooks';
import { Grid, useTheme } from '@mui/material';
import { get, isEmpty, isEqual, last, mergeWith, some } from 'lodash';
import { DateTime } from 'luxon';

import IncrementBurnUp from './IncrementBurnup';
import ProgressionChart from './ProgressionChart';
import SvgFeatures from '../../../../../assets/images/Features';
import SvgObjectives from '../../../../../assets/images/Objectives';
import SvgShowDependencies from '../../../../../assets/images/ShowDependencies';
import SvgStories from '../../../../../assets/images/Stories';
import SvgTeams from '../../../../../assets/images/Teams';
import { DashboardCard, Metric, MetricPaper } from '../../../../../components/Common/Elements';
import Switch from '../../../../../components/Common/Switch';
import { selectedInitiativeVar, selectedProgrammeVar, selectedTeamVar } from '../../../../../reactiveVariables';
import { GET_FEATURES_FOR_PROGRAMME } from '../../../../Product/Features/graphql';
import { GET_TEAMS } from '../../../../Settings/GraphQL/teams';
import { GET_SELECTED_PI_FULL } from '../../../../Settings/ProgramIncrement/graphql';
import { GET_DEPENDENCIES_FOR_PI } from '../../Dependencies/graphql';
import { GET_OBJECTIVES_FOR_PI } from '../../Objectives/graphql';
import { useDeliveryMetrics } from '../utils';

const TopLine = ({
  doneFeatures,
  totalFeatures,
  dependencies,
  avgSP,
  totalStories,
  totalStoryPoints,
  teams,
  objectives,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery('(min-width: 960px)');

  const totalPlannedValue = objectives.reduce(
    (acc, obj) => {
      if (obj.uncommitted) {
        acc.uncommitted += obj.plannedValue;
      } else {
        acc.committed += obj.plannedValue;
      }
      return acc;
    },
    { uncommitted: 0, committed: 0 },
  );

  return (
    <Grid sx={{ height: matches ? '130px' : '260px' }} container item spacing={1} justifyContent="space-between">
      <MetricPaper xs={6} md={2.4} title="Features" icon={<SvgFeatures color={theme.palette.color.buttonIcon} />}>
        <Metric value={totalFeatures - doneFeatures} name="In Progress" dotColor={theme.palette.color.inProgress} />
        <Metric value={doneFeatures} name="Done" dotColor={theme.palette.color.done} />
      </MetricPaper>
      <MetricPaper
        xs={6}
        md={2.4}
        title="Objectives (Values)"
        icon={<SvgObjectives color={theme.palette.color.buttonIcon} />}>
        <Metric
          value={`${objectives.filter((obj) => !obj.uncommitted).length} (${totalPlannedValue.committed})`}
          name="Committed"
          dotColor={theme.palette.color.inProgress}
        />
        <Metric
          value={`${objectives.filter((obj) => obj.uncommitted).length} (${totalPlannedValue.uncommitted})`}
          name="Uncommitted"
          dotColor={theme.palette.color.done}
        />
      </MetricPaper>
      <MetricPaper
        xs={6}
        md={2.4}
        title="Dependencies"
        icon={<SvgShowDependencies color={theme.palette.color.buttonIcon} />}>
        <Metric
          value={dependencies.filter((dependency) => dependency.status !== 'Done').length}
          name="In Progress"
          dotColor={theme.palette.color.inProgress}
        />
        <Metric
          value={dependencies.filter((dependency) => dependency.status === 'Done').length}
          name="Done"
          dotColor={theme.palette.color.done}
        />
      </MetricPaper>
      <MetricPaper xs={6} md={2.4} title="Stories" icon={<SvgStories color={theme.palette.color.buttonIcon} />}>
        <Metric value={totalStories} name="In Total" />
        <Metric value={totalStoryPoints} name="Story Points (SP)" />
      </MetricPaper>
      <MetricPaper xs={6} md={2.4} title="Teams" icon={<SvgTeams color={theme.palette.color.buttonIcon} />}>
        <Metric value={teams.length} name="In Total" />
        <Metric value={avgSP.toFixed()} name="Avg SP Per Team" />
      </MetricPaper>
    </Grid>
  );
};

const BottomLine = ({
  pointsData,
  storiesData,
  lineData,
  sprints,
  typeProgress,
  setTypeProgress,
  typeBurnup,
  setTypeBurnup,
}) => (
  <React.Fragment>
    <Grid item xs={12} md={6}>
      <DashboardCard
        title="Features Progression"
        gridStyle={{ paddingBottom: '4px' }}
        cardStyle={{ maxHeight: null, height: 'calc(35vh - 20px)' }}
        xs={12}>
        <ProgressionChart renderData={pointsData} title="Total Features" />
      </DashboardCard>
      <DashboardCard
        title="Stories Progression"
        gridStyle={{ paddingBottom: '4px' }}
        cardStyle={{ maxHeight: null, height: 'calc(35vh - 20px)' }}
        xs={12}
        action={
          <Switch
            sx={{ marginBottom: 0 }}
            value={typeProgress}
            onChange={(value) => setTypeProgress(value)}
            data={[
              { label: 'Story Points', value: 'storyPoints' },
              { label: 'Story Count', value: 'storyCount' },
            ]}
          />
        }>
        <ProgressionChart
          renderData={storiesData}
          title={typeProgress === 'storyPoints' ? 'Total Story Points' : 'Total Stories'}
        />
      </DashboardCard>
    </Grid>
    <Grid item xs={12} md={6}>
      <DashboardCard
        title="Increment Burnup"
        cardStyle={{ maxHeight: null, height: 'calc(70vh - 36px)' }}
        xs={12}
        action={
          <Switch
            sx={{ marginBottom: 0 }}
            value={typeBurnup}
            onChange={(value) => setTypeBurnup(value)}
            data={[
              { label: 'Story Points', value: 'storyPoints' },
              { label: 'Story Count', value: 'storyCount' },
            ]}
          />
        }>
        <IncrementBurnUp lineData={lineData} sprints={sprints} type={typeBurnup} />
      </DashboardCard>
    </Grid>
  </React.Fragment>
);

const Overview = () => {
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES_FOR_PROGRAMME);
  const { data: { selectedPiFull = {} || {} } = {} } = useQuery(GET_SELECTED_PI_FULL);
  const { data: { dependencies = [] } = {} } = useQuery(GET_DEPENDENCIES_FOR_PI);
  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);
  const { data: { objectives = [] } = {} } = useQuery(GET_OBJECTIVES_FOR_PI);

  const theme = useTheme();
  const selectedTeams = useReactiveVar(selectedTeamVar);
  const selectedProgramme = useReactiveVar(selectedProgrammeVar);
  const selectedInitiative = useReactiveVar(selectedInitiativeVar);

  const [typeProgress, setTypeProgress] = useState('storyCount');
  const [typeBurnup, setTypeBurnup] = useState('storyCount');

  const completedBurnupField = typeBurnup === 'storyPoints' ? 'completedStoryPoints' : 'completedStoryCount';
  const scopeBurnupField = typeBurnup === 'storyPoints' ? 'storyPointsScope' : 'storiesScope';

  function hasStoriesInPI(feature) {
    return !!feature.metrics?.metricsPerIncrement?.[selectedPiFull?.id];
  }

  const filteredFeatures = useMemo(
    () =>
      features.filter(
        (feature) =>
          (feature.programIncrement === selectedPiFull?.id ||
            hasStoriesInPI(feature) ||
            !!feature.completedIncrements?.[selectedPiFull?.id]) &&
          (!selectedTeams.length || selectedTeams.includes(feature.teamId)) &&
          (!selectedInitiative || feature.initiativeId === selectedInitiative),
      ),
    [features, selectedTeams, selectedInitiative, selectedPiFull],
  );

  const filteredDependencies = useMemo(
    () =>
      dependencies.filter(
        (dependency) => dependency.teamId && (!selectedTeams.length || selectedTeams.includes(dependency.teamId)),
      ),
    [dependencies, selectedTeams],
  );

  const filteredTeams = teams.filter((team) => {
    if (selectedTeams.length) return selectedTeams.includes(team.id);

    const hasFeatures = some(filteredFeatures, (feature) => feature.teamId === team.id);
    const hasDependencies = some(filteredDependencies, (dependency) => dependency.teamId === team.id);
    const isInProgramme = team.programmes?.includes(selectedProgramme);
    const hasBoard = team.board;

    return isInProgramme && hasBoard && (hasFeatures || hasDependencies);
  });

  const {
    doneFeatures,
    toDoFeatures,
    inProgressFeatures,
    deferredFeatures,
    doneStories,
    toDoStories,
    inProgressStories,
    totalStoryPoints,
    deferredStoryPoints,
    deferredStories,
    toDoStoryPoints,
    inProgressStoryPoints,
  } = useDeliveryMetrics(filteredFeatures, [selectedPiFull?.id]);

  const totalStoryCount = doneStories + toDoStories + inProgressStories + deferredStories;
  const totalFeatures = doneFeatures + toDoFeatures + inProgressFeatures + deferredFeatures;

  const storyPointsPerSprint = useMemo(
    () =>
      filteredFeatures
        .filter((feature) => feature.storyPointsPerSprint)
        .map((feature) => feature.storyPointsPerSprint)
        .reduce((total, featureData) => {
          return mergeWith(total, featureData, (objValue, srcValue) => {
            if (!objValue) return srcValue;
            return {
              ...objValue,
              completedStoryPoints: objValue.completedStoryPoints + srcValue.completedStoryPoints,
              completedStoryCount: (objValue.completedStoryCount || 0) + (srcValue.completedStoryCount || 0),
              plannedStoryPoints: objValue.plannedStoryPoints + srcValue.plannedStoryPoints,
            };
          });
        }, {}),
    [filteredFeatures],
  );

  let alreadyDone = 0;

  const completedSprints =
    (selectedPiFull?.sprints &&
      selectedPiFull?.sprints.filter((sprint) => DateTime.local() > DateTime.fromISO(sprint.endDate))) ||
    [];

  const totalDone = (
    (selectedPiFull?.sprints &&
      selectedPiFull?.sprints.filter((sprint) => DateTime.local() > DateTime.fromISO(sprint.startDate))) ||
    []
  ).reduce(
    (final, sprintData) => {
      final.completedStoryPoints += storyPointsPerSprint[sprintData.id]?.completedStoryPoints || 0;
      final.completedStoryCount += storyPointsPerSprint[sprintData.id]?.completedStoryCount || 0;
      return final;
    },
    { completedStoryPoints: 0, completedStoryCount: 0 },
  );

  const averageCount = Math.round(
    (completedSprints.length &&
      completedSprints.reduce((final, sprintData) => {
        final += storyPointsPerSprint[sprintData.id]?.[completedBurnupField] || 0;
        return final;
      }, 0) / completedSprints.length) ||
      0,
  );

  const completedData = completedSprints.reduce((final, sprint) => {
    const newFinal = final.concat([
      {
        x: sprint.name,
        y: alreadyDone + (storyPointsPerSprint[sprint.id]?.[completedBurnupField] || 0),
      },
    ]);
    alreadyDone += get(storyPointsPerSprint[sprint.id], completedBurnupField, 0);
    return newFinal;
  }, []);

  const lastCompletedSprint = last(completedData) || {};
  let projectionDone = lastCompletedSprint.y || 0;

  const projectionData =
    (selectedPiFull?.sprints &&
      selectedPiFull?.sprints
        .filter((sprint) => DateTime.local() < DateTime.fromISO(sprint.endDate))
        .reduce(
          (final, sprint) => {
            const newFinal = final.concat([
              {
                x: sprint.name,
                y: projectionDone + averageCount,
              },
            ]);
            projectionDone += averageCount;
            return newFinal;
          },
          isEmpty(lastCompletedSprint) ? [] : [{ ...lastCompletedSprint }],
        )) ||
    [];

  const totalData =
    (selectedPiFull?.sprints &&
      selectedPiFull?.sprints.map((sprint, index) => {
        const lastSprint = selectedPiFull.sprints.length === index + 1;
        return {
          x: sprint.name,
          y:
            (!lastSprint &&
              !selectedTeams.length &&
              !selectedInitiative &&
              selectedPiFull?.scopePerSprint?.[sprint.id]?.[scopeBurnupField]) ||
            (typeBurnup === 'storyPoints' ? totalStoryPoints : totalStoryCount),
        };
      })) ||
    [];

  const lineData = [
    {
      id: 'Progress',
      label: 'Progress',
      color: theme.palette.color.done,
      data: [{ x: '', y: 0 }].concat(completedData),
    },
    {
      id: 'Forecast',
      label: 'Forecast',
      color: theme.palette.color.amber,
      data: projectionData,
    },
    {
      id: 'Scope',
      label: 'Scope',
      color: '#eaeaeb',
      data: [
        {
          x: '',
          y:
            selectedTeams.length || selectedInitiative
              ? typeBurnup === 'storyPoints'
                ? totalStoryPoints
                : totalStoryCount
              : selectedPiFull?.scopePerSprint?.[selectedPiFull?.sprints?.[0]?.id]?.[scopeBurnupField] ||
                (typeBurnup === 'storyPoints' ? totalStoryPoints : totalStoryCount),
        },
      ].concat(totalData),
    },
  ];

  const pointsData = [
    {
      id: 'To Do',
      label: 'To Do',
      value: toDoFeatures,
      color: theme.palette.color.toDo,
    },
    {
      id: 'In Progress',
      label: 'In Progress',
      value: inProgressFeatures,
      color: theme.palette.color.inProgress,
    },
    {
      id: 'Done',
      label: 'Done',
      value: doneFeatures,
      color: theme.palette.color.done,
    },
    {
      id: 'Deferred',
      label: 'Deferred',
      value: deferredFeatures,
      color: 'grey',
    },
  ];

  const storiesData = [
    {
      id: 'To Do',
      label: 'To Do',
      value: typeProgress === 'storyPoints' ? toDoStoryPoints : toDoStories,
      color: theme.palette.color.toDo,
    },
    {
      id: 'In Progress',
      label: 'In Progress',
      value: typeProgress === 'storyPoints' ? inProgressStoryPoints : inProgressStories,
      color: theme.palette.color.inProgress,
    },
    {
      id: 'Done',
      label: 'Done',
      value: typeProgress === 'storyPoints' ? totalDone.completedStoryPoints : totalDone.completedStoryCount,
      color: theme.palette.color.done,
    },
    {
      id: 'Deferred',
      label: 'Deferred',
      value: typeProgress === 'storyPoints' ? deferredStoryPoints : deferredStories,
      color: 'grey',
    },
  ];

  return (
    <Grid container spacing={1}>
      <Grid container item xs={12} spacing={1}>
        <TopLine
          teams={filteredTeams}
          totalFeatures={totalFeatures}
          doneFeatures={doneFeatures}
          totalStoryPoints={totalStoryPoints || 0}
          features={filteredFeatures}
          avgSP={totalStoryPoints / filteredTeams.length || 0}
          totalStories={totalStoryCount || 0}
          dependencies={filteredDependencies}
          objectives={objectives}
        />
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <BottomLine
          pointsData={pointsData}
          storiesData={storiesData}
          lineData={lineData}
          sprints={selectedPiFull?.sprints}
          setTypeBurnup={setTypeBurnup}
          typeBurnup={typeBurnup}
          setTypeProgress={setTypeProgress}
          typeProgress={typeProgress}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(Overview, (prevProps, nextProps) => isEqual(prevProps, nextProps));
