import React from 'react';

import { DragOverlay } from '@dnd-kit/core';
import { IconGripVertical } from '@tabler/icons-react';
import { DateTime } from 'luxon';
import { truncate } from 'utils/stringUtils';

import LinearWithValueLabel from '../components/OKRTable/LinearWithValueLabel';

export const Overlay = ({ activeItem, selectedValueStream, theme }) => {
  const dark = theme.palette.mode === 'dark';

  return (
    <DragOverlay>
      {activeItem ? (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '58px',
            backgroundColor: 'white',
            boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
            borderRadius: '8px',
            alignItems: 'center',
            fontSize: '14px ',
            cursor: 'grab',
            color: dark ? '#212529' : 'white',
          }}>
          <div
            style={{
              width: '30px',
              textAlign: 'center',
              flexShrink: 0,
              backgroundColor: 'white',
              lineHeight: '58px',
            }}>
            <IconGripVertical size={16} style={{ cursor: 'grab' }} />
          </div>
          <div
            style={{
              width: '70px',
              textAlign: 'center',
              flexShrink: 0,
              backgroundColor: 'white',
              lineHeight: '58px',
            }}>
            {activeItem.record.ranks?.[selectedValueStream] ?? '-'}
          </div>
          <div
            style={{
              width: '300px',
              minWidth: 0,
              flexShrink: 0,
              backgroundColor: 'white',
              height: '58px',
              lineHeight: '58px',
            }}>
            {truncate(activeItem.record.name, 30)}
          </div>
          <div
            style={{
              width: '100px',
              minWidth: 0,
              flexShrink: 0,
              backgroundColor: 'white',
              height: '58px',
              lineHeight: '58px',
            }}>
            {activeItem.record.features?.aggregate?.count} Features
          </div>
          <div
            style={{
              width: '100px',
              minWidth: 0,
              flexShrink: 0,
              backgroundColor: 'white',
              height: '58px',
              lineHeight: '58px',
            }}>
            {activeItem.record.isPortfolioState
              ? activeItem.record.portfolio_state
              : activeItem.record.initiative_state}
          </div>
          <div
            style={{
              width: '200px',
              minWidth: 0,
              flexShrink: 0,
              backgroundColor: 'white',
              height: '58px',
              alignContent: 'center',
              paddingRight: '15px',
            }}>
            <LinearWithValueLabel
              data-value={activeItem.record.features?.aggregate?.avg?.percentageDone || 0}
              value={(activeItem.record.features?.aggregate?.avg?.percentageDone || 0).toFixed()}
            />
          </div>
          <div
            style={{
              width: '200px',
              minWidth: 0,
              flexShrink: 0,
              backgroundColor: 'white',
              height: '58px',
              lineHeight: '58px',
            }}>
            {activeItem.record.plannedRealisationDate &&
              DateTime.fromISO(activeItem?.record.plannedRealisationDate).toLocaleString(DateTime.DATE_SHORT)}
          </div>
          <div
            style={{
              width: '200px',
              minWidth: 0,
              flexShrink: 0,
              backgroundColor: 'white',
              height: '58px',
              lineHeight: '58px',
            }}>
            {activeItem.record.endDate &&
              DateTime.fromISO(activeItem.record.endDate).toLocaleString(DateTime.DATE_SHORT)}
          </div>
        </div>
      ) : null}
    </DragOverlay>
  );
};
