import React from 'react';

import SvgPriorityHigh from '../../assets/images/priorityHigh';
import SvgPriorityLow from '../../assets/images/priorityLow';
import SvgPriorityMedium from '../../assets/images/priorityMedium';
import SvgPriorityNo from '../../assets/images/priorityNo';
import SvgPriorityUrgent from '../../assets/images/priorityUrgent';
import { color } from '../../shared/styles/color';

export const getRagStatusColor = (rag_status) => {
  switch (rag_status) {
    case 'Red':
      return color.red;
    case 'Amber':
      return '#F6C544';
    case 'Green':
      return color.green;
    default:
      return '#989899';
  }
};

export const returnPrioritySvg = (priority) => {
  switch (priority) {
    case 'No':
      return <SvgPriorityNo />;
    case 'Low':
      return <SvgPriorityLow />;
    case 'Medium':
      return <SvgPriorityMedium />;
    case 'High':
      return <SvgPriorityHigh />;
    case 'Urgent':
      return <SvgPriorityUrgent />;
    default:
      return <SvgPriorityNo />;
  }
};
