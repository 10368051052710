import React, { useState, useEffect } from 'react';

import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Checkbox, Text, Badge } from '@mantine/core';
import { Chip } from '@mui/material';
import { some } from 'lodash';

import SvgFeatures from '../../../../../assets/images/Features';
import Search from '../../../../../components/Common/BigAgileTable/TableToolBar/Search';
import { CardButton, DisplayID, IDWrapper } from '../../../../../components/Common/Elements';
import { color } from '../../../../../shared/styles/color';

const Wrapper = styled('div')(({ theme, ...props }) => ({
  width: 'auto',
}));

const SecondStep = ({ existingFeatures, addSelectedFeature, selectedFeatures = [], features, setSelectedFeatures }) => {
  const [searchQuery, setSearchQuery] = useState(null);
  const [selectAll, setSelectAll] = useState(false);

  const theme = useTheme();
  const onSearchChange = (event) => {
    setSearchQuery(event.target?.value);
  };

  // Filter features that aren't already existing
  const availableFeatures = features.filter((feature) => !some(existingFeatures, { epicId: feature.id }));

  // Handle Select All checkbox
  const handleSelectAll = (event) => {
    const isChecked = event.currentTarget.checked;
    setSelectAll(isChecked);

    if (isChecked) {
      const allAvailableIds = availableFeatures.map((feature) => feature.id);
      setSelectedFeatures(allAvailableIds);
    } else {
      setSelectedFeatures([]);
    }
  };

  // Handle individual feature selection
  const handleFeatureSelect = (id) => {
    const newSelectedFeatures = selectedFeatures.includes(id)
      ? selectedFeatures.filter((featureId) => featureId !== id)
      : [...selectedFeatures, id];

    setSelectedFeatures(newSelectedFeatures);

    // Update selectAll state based on new selection
    const allSelected = availableFeatures.every((feature) => newSelectedFeatures.includes(feature.id));
    setSelectAll(allSelected);
  };

  // Update selectAll state when features or selectedFeatures change
  useEffect(() => {
    const allSelected =
      availableFeatures.length > 0 && availableFeatures.every((feature) => selectedFeatures.includes(feature.id));
    setSelectAll(allSelected);
  }, [selectedFeatures, features, existingFeatures]);

  return (
    <Wrapper>
      <Search handleChange={onSearchChange} fullWidth={true} />
      <Badge radius="5px" p={4} color={color.purpulishPink} ml={10} mt={10}>
        {selectedFeatures.length} Selected
      </Badge>
      <div style={{ paddingTop: '10px', overflow: 'auto', height: '500px' }}>
        {availableFeatures.length > 0 && (
          <Checkbox
            checked={selectAll}
            onChange={handleSelectAll}
            style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: '10px' }}
          />
        )}
        {features
          .filter((feature) => !searchQuery || feature.name?.toLowerCase()?.includes(searchQuery?.toLowerCase()))
          .map((feature) => (
            <CardButton key={feature.id} onClick={() => handleFeatureSelect(feature.id)}>
              <IDWrapper style={{ backgroundColor: theme.palette.color.row }}>
                <SvgFeatures color={theme.palette.color.buttonIcon} />
                <DisplayID span={true} size="xs">
                  {feature.id}
                </DisplayID>
              </IDWrapper>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'space-between',
                  height: '100%',
                  alignItems: 'center',
                }}>
                <div style={{ padding: '10px' }}>
                  <Text lineClamp={1} fw={450} size="sm">
                    {feature.name}
                  </Text>
                </div>
                {some(existingFeatures, { epicId: feature.id }) ? (
                  <Chip
                    size="small"
                    sx={{
                      borderRadius: '4px',
                      fontSize: '11px',
                      fontWeight: 'bold',
                      color: 'text.secondary',
                      marginRight: '10px',
                    }}
                    label="Already Exists"
                  />
                ) : (
                  <Checkbox
                    onClick={() => addSelectedFeature(feature.id)}
                    checked={selectedFeatures.includes(feature.id)}
                    sx={{ padding: '8px' }}
                  />
                )}
              </div>
            </CardButton>
          ))}
      </div>
    </Wrapper>
  );
};

export default SecondStep;
