import React from 'react';
import { useState } from 'react';

import { useTheme } from '@emotion/react';
import { Box, Divider, Table, Text } from '@mantine/core';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Menu, MenuItem } from '@mui/material';

import { ContentInner, TableHead, ItemCount } from './style';
import { color } from '../../shared/styles/color';

export const LinksArea = ({ setShowNewLinksModal, links, handleLinkEdit, handleLinkDelete, showCopyLink = true }) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLink, setSelectedLink] = useState(null);

  const handleMenuOpen = (event, link) => {
    setAnchorEl(event.currentTarget);
    setSelectedLink(link);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedLink(null);
  };

  return (
    <div>
      <ContentInner style={{ margin: '18px 20px' }}>
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <Text fw={550}>
            Links <ItemCount>{links.length}</ItemCount>
          </Text>
          <AddIcon style={{ width: '20px', cursor: 'pointer' }} onClick={() => setShowNewLinksModal(true)} />
        </Box>
      </ContentInner>

      {!!links.length && (
        <Table borderRadius={8} layout="fixed">
          <thead
            style={{
              backgroundColor: theme.palette.mode === 'dark' ? '#242424' : color.grey,
            }}>
            <tr>
              <TableHead style={{ color: theme.palette.mode === 'dark' ? '#C4C4C4' : 'inherit' }}>Item</TableHead>
              <TableHead style={{ color: theme.palette.mode === 'dark' ? '#C4C4C4' : 'inherit' }}>Link</TableHead>
            </tr>
          </thead>

          <tbody>
            {links.map((link) => (
              <tr key={link.id}>
                <td style={{ padding: '8px', textAlign: 'left', fontSize: '14px' }}>{link.title}</td>
                <td style={{ padding: '8px', textAlign: 'left', display: 'flex', justifyContent: 'space-between' }}>
                  <span style={{ fontSize: '14px', flex: 1 }}>{link.url}</span>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {showCopyLink && (
                      <span
                        style={{
                          cursor: 'pointer',
                          color: '#2F6DE1',
                          fontSize: '12px',
                          marginRight: '8px',
                        }}
                        onClick={() => navigator.clipboard.writeText(link.url)}>
                        Copy link
                      </span>
                    )}
                    <MoreHorizIcon style={{ cursor: 'pointer' }} onClick={(event) => handleMenuOpen(event, link)} />

                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl) && selectedLink === link}
                      onClose={handleMenuClose}
                      slotProps={{
                        paper: {
                          sx: {
                            width: '200px',
                            borderRadius: '15px',
                            marginTop: '10px',
                          },
                        },
                      }}>
                      <MenuItem
                        onClick={() => {
                          handleLinkEdit(selectedLink);
                          handleMenuClose();
                        }}>
                        Edit
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        style={{ color: color.red }}
                        onClick={() => {
                          handleLinkDelete(link.id);
                          handleMenuClose();
                        }}>
                        Delete
                      </MenuItem>
                    </Menu>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};
