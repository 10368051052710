import React, { useCallback, useState, useEffect, useMemo } from 'react';

import { useQuery, useReactiveVar, useMutation } from '@apollo/client';
import { Box, Loader, Badge, Tooltip, Select, Textarea } from '@mantine/core';
import { ActionIcon } from '@mantine/core';
import { IconFlag } from '@tabler/icons-react';
import { DataTableToolbar } from 'components/Common/DataTableToolbar';
import { orderBy } from 'lodash';
import { DateTime } from 'luxon';
import { DataTable } from 'mantine-datatable';
import { useNavigate } from 'react-router-dom';

import { GET_RISKS, UPDATE_RISK } from './graphql';
import { selectedValueStreamVar, openDrawer } from '../../../../reactiveVariables';
import { color } from '../../../../shared/styles/color';
import { exportCSVData } from '../../../../utils/export';
import { removeIdField } from '../../../../utils/helpers';
import { GET_FEATURES, UPDATE_FEATURE_WITHOUT_MILESTONES } from '../../../Product/Features/graphql';
import { GET_TEAMS } from '../../../Settings/GraphQL/teams';
import { GET_ALL_INITIATIVES, UPDATE_INITIATIVE } from '../../../ValueStream/Initiatives/graphql';

export const RiskTable = () => {
  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);

  const { data: { risks: risks = [] } = {}, loading } = useQuery(GET_RISKS);
  const { data: { initiatives = [] } = {} } = useQuery(GET_ALL_INITIATIVES);
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES);

  const [updateRisk] = useMutation(UPDATE_RISK);
  const [updateInitiative] = useMutation(UPDATE_INITIATIVE);
  const [updateFeature] = useMutation(UPDATE_FEATURE_WITHOUT_MILESTONES);

  const selectedValueStream = useReactiveVar(selectedValueStreamVar);

  const [sortStatus, setSortStatus] = useState({ columnAccessor: 'id', direction: 'desc' });
  const [visibleColumns, setVisibleColumns] = useState({});
  const [tempVisibleColumns, setTempVisibleColumns] = useState({});
  const [tableHeight, setTableHeight] = useState('100vh');
  const [searchQuery, setSearchQuery] = useState('');

  const navigate = useNavigate();

  const handleSort = (newSortStatus) => {
    setSortStatus(newSortStatus);
  };

  useEffect(() => {
    const updateTableHeight = () => {
      const vh = window.innerHeight;
      setTableHeight(vh * 0.8);
    };

    updateTableHeight();
    window.addEventListener('resize', updateTableHeight);

    return () => {
      window.removeEventListener('resize', updateTableHeight);
    };
  }, []);

  const filterRisks = () => {
    return risks.filter((risk) => {
      const isOrganisationRisksPage = location.pathname === '/organisation/risks';

      if (isOrganisationRisksPage && !risk.flagged) {
        return false;
      }

      const searchMatches =
        searchQuery.length === 0 ||
        Object.values(risk)
          .filter((value) => typeof value === 'string')
          .some((value) => value.toLowerCase().includes(searchQuery.toLowerCase()));

      const valueStreamMatches = selectedValueStream === null || risk.value_stream === selectedValueStream;

      return searchMatches && valueStreamMatches;
    });
  };

  const filteredRisks = orderBy(
    filterRisks() || [],
    [
      (risk) => {
        if (Object.prototype.hasOwnProperty.call(risk, sortStatus.columnAccessor)) {
          return risk[sortStatus.columnAccessor];
        }
      },
    ],
    [sortStatus.direction],
  );

  const exportThinkTank = useCallback(() => {
    // Define CSV column headers
    const columns = [
      { label: 'ID' },
      { label: 'Name' },
      { label: 'Level' },
      { label: 'State' },
      { label: 'Description' },
      { label: 'Mitigation' },
      { label: 'Impact' },
      { label: 'Impact Detail' },
      { label: 'Probability' },
      { label: 'Proximity' },
      { label: 'Target Resolution Date' },
      { label: 'Last Updated' },
      { label: 'Owner' },
    ];

    // Format the idea data for CSV
    const formattedRisks = risks.map((risk) => [
      risk.id,
      risk.name,
      risk.level,
      risk.state,
      risk.description,
      risk.mitigation,
      risk.impact,
      risk.impact_detail,
      risk.probability,
      risk.proximity,
      risk.target_resolution_date,
      risk.updated_at,
      risk.owner,
    ]);

    // Export CSV
    exportCSVData(columns, formattedRisks, 'risks_export');
  }, [risks]);

  const onRowClick = (record) => {
    openDrawer(record, 'risk');
  };

  const onAssociatedWorkClick = (record) => {
    const associated_work = record.associated_work[0];

    if (associated_work.type === 'features') {
      const data = features.find((feature) => feature.id === associated_work.id);
      openDrawer(data, 'feature');
    }

    if (associated_work.type === 'initiatives') {
      const data = initiatives.find((init) => init.id === associated_work.id);
      openDrawer(data, 'initiative');
    }

    if (associated_work.type === 'strategic_themes') {
      navigate(`/organisation/projects/${associated_work.id}`);
    }
  };

  const toggleTempColumn = (accessor) => {
    setTempVisibleColumns((prev) => ({ ...prev, [accessor]: !prev[accessor] }));
  };

  const handleUpdate = async (field, value, record) => {
    try {
      // Create a copy of the record and remove updated_at
      const updatedRisk = { ...record, [field]: value };
      delete updatedRisk.updated_at; // Remove `updated_at` before sending it

      updateRisk({
        variables: { risk: removeIdField(updatedRisk), riskId: record.id },
        optimisticResponse: { risk: updatedRisk },
      });
    } catch (error) {
      console.error('Error updating risk:', error);
    }
  };

  const handleRiskFlagUpdate = async (flagged, record) => {
    if (record.associated_work.length) {
      const type = record.associated_work[0]?.type;

      if (type === 'initiatives') {
        updateInitiative({
          variables: {
            initiativeId: record.associated_work[0]?.id,
            initiative: { risk_flagged: flagged },
          },
        });
      }

      if (type === 'features') {
        updateFeature({
          variables: {
            featureId: record.associated_work[0]?.id,
            feature: {
              risk_flagged: flagged,
            },
          },
        });
      }
    }
  };

  const staticColumns = useMemo(
    () => [
      {
        accessor: 'flagged',
        title: 'Escalated Risks',
        sortable: true,
        width: 150,
        render: (record) => {
          const isFlagged = record?.flagged;

          return (
            <ActionIcon
              variant="transparent"
              color={isFlagged ? 'red' : 'gray'}
              onClick={(e) => {
                e.stopPropagation();
                handleRiskFlagUpdate(!isFlagged, record);
                handleUpdate('flagged', !isFlagged, record);
              }}>
              <IconFlag size={20} fill={isFlagged ? 'red' : 'none'} stroke={1.5} />
            </ActionIcon>
          );
        },
      },
      {
        accessor: 'id',
        title: 'ID',
        sortable: true,
        width: 50,
        render: (record) => {
          return (
            <Box style={{ cursor: 'pointer' }} onClick={() => onRowClick(record)}>
              {record && record.id}
            </Box>
          );
        },
      },
      {
        accessor: 'name',
        title: 'Name',
        sortable: true,
        width: 150,
        render: (record) => {
          return (
            <Box style={{ cursor: 'pointer' }} onClick={() => onRowClick(record)}>
              {record.name}
            </Box>
          );
        },
      },
      {
        accessor: 'level',
        title: 'Level',
        sortable: true,
        width: 180,
        render: (record) => (
          <Select
            data={['Team', 'Programme', 'Value Stream', 'Organisation']}
            value={record?.level}
            onChange={(value) => handleUpdate('level', value, record)}
            onClick={(e) => e.stopPropagation()}
          />
        ),
      },
      {
        accessor: 'state',
        title: 'State',
        sortable: true,
        width: 180,
        render: (record) => (
          <Select
            data={['open', 'resolved', 'owned', 'accepted', 'mitigated', 'closed'].map((state) => ({
              value: state,
              label: state.charAt(0).toUpperCase() + state.slice(1),
            }))}
            value={record.state}
            onChange={(value) => {
              handleUpdate('state', value, record);
            }}
            onClick={(e) => e.stopPropagation()}
          />
        ),
      },
      {
        accessor: 'description',
        title: 'Description',
        sortable: true,
        width: 350,
        render: (record) => (
          <Textarea
            defaultValue={record?.description || ''}
            autosize
            minRows={4}
            onClick={(e) => e.stopPropagation()}
            onBlur={(event) => handleUpdate('description', event.target.value, record)}
          />
        ),
      },
      {
        accessor: 'impact_detail',
        title: 'Impact Detail',
        sortable: true,
        width: 350,
        render: (record) => (
          <Textarea
            defaultValue={record?.impact_detail || ''}
            autosize
            minRows={4}
            onClick={(e) => e.stopPropagation()}
            onBlur={(event) => handleUpdate('impact_detail', event.target.value, record)}
          />
        ),
      },
      {
        accessor: 'mitigation',
        title: 'Mitigation',
        sortable: true,
        width: 350,
        render: (record) => (
          <Textarea
            defaultValue={record?.mitigation || ''}
            autosize
            minRows={4}
            onClick={(e) => e.stopPropagation()}
            onBlur={(event) => handleUpdate('mitigation', event.target.value, record)}
          />
        ),
      },
      {
        accessor: 'impact',
        title: 'Impact',
        sortable: true,
        width: 150,
        render: (record) => (
          <Select
            data={['High', 'Medium', 'Low']}
            value={record?.impact}
            onChange={(value) => handleUpdate('impact', value, record)}
            onClick={(e) => e.stopPropagation()}
            styles={() => ({
              input: {
                borderColor:
                  record?.proximity === 'High'
                    ? color.red
                    : record?.proximity === 'Medium'
                    ? color.amber
                    : record?.proximity === 'Low'
                    ? color.green
                    : '',
              },
            })}
          />
        ),
      },
      {
        accessor: 'probability',
        title: 'Probability',
        sortable: true,
        width: 150,
        render: (record) => (
          <Select
            data={['High', 'Medium', 'Low']}
            value={record?.probability}
            onClick={(e) => e.stopPropagation()}
            onChange={(value) => handleUpdate('probability', value, record)}
            styles={() => ({
              input: {
                borderColor:
                  record?.probability === 'High'
                    ? color.red
                    : record?.probability === 'Medium'
                    ? color.amber
                    : record?.probability === 'Low'
                    ? color.green
                    : '',
              },
            })}
          />
        ),
      },
      {
        accessor: 'proximity',
        title: 'Proximity',
        sortable: true,
        width: 150,
        render: (record) => (
          <Select
            data={['High', 'Medium', 'Low']}
            value={record?.proximity}
            onClick={(e) => e.stopPropagation()}
            onChange={(value) => handleUpdate('proximity', value, record)}
            styles={() => ({
              input: {
                borderColor:
                  record?.proximity === 'High'
                    ? color.red
                    : record?.proximity === 'Medium'
                    ? color.amber
                    : record?.proximity === 'Low'
                    ? color.green
                    : '',
              },
            })}
          />
        ),
      },
      {
        accessor: 'risk_score',
        title: 'Risk Score',
        sortable: true,
        width: 150,
        render: (record) => {
          const scoreMap = { High: 3, Medium: 2, Low: 1 };
          const impact = scoreMap[record?.impact] || 0;
          const probability = scoreMap[record?.probability] || 0;
          const proximity = scoreMap[record?.proximity] || 0;
          return (
            <Box style={{ cursor: 'pointer' }} onClick={() => onRowClick(record)}>
              {impact * probability * proximity}
            </Box>
          );
        },
      },
      {
        accessor: 'target_resolution_date',
        title: 'Target Resolution Date',
        sortable: true,
        width: 200,
        render: (record) => {
          const formattedDate = record?.target_resolution_date
            ? DateTime.fromISO(record.target_resolution_date).toFormat('dd/MM/yyyy')
            : '';
          return (
            <Box style={{ cursor: 'pointer' }} onClick={() => onRowClick(record)}>
              {formattedDate}
            </Box>
          );
        },
      },
      {
        accessor: 'associated_work',
        title: 'Associated Work',
        sortable: true,
        width: 250,
        render: (record) => {
          return record.associated_work ? (
            <Tooltip
              data-value={record.associated_work?.[0]?.name}
              transition={'scale'}
              label={record.associated_work?.[0]?.name}
              zIndex={1000}>
              <Badge
                variant="light"
                color={
                  record.associated_work?.[0]?.type === 'strategic_themes'
                    ? color.purple
                    : record.associated_work?.[0]?.type === 'features'
                    ? color.green
                    : color.purplePink
                }
                size="md"
                cursor="pointer"
                radius="4px"
                sx={{ padding: '4px', color: 'white' }}
                onClick={() => onAssociatedWorkClick(record)}>
                <div style={{ cursor: 'pointer' }}>{record.associated_work?.[0]?.name}</div>
              </Badge>
            </Tooltip>
          ) : (
            ''
          );
        },
      },
      {
        accessor: 'owner',
        title: 'Owner',
        sortable: true,
        width: 150,
        render: (record) => {
          return (
            <Box style={{ cursor: 'pointer' }} onClick={() => onRowClick(record)}>
              {record.owner}
            </Box>
          );
        },
      },
      {
        accessor: 'team',
        title: 'Team',
        sortable: true,
        width: 150,
        render: (record) => {
          const team = teams.find((team) => team.id === record.team);

          return (
            <Box style={{ cursor: 'pointer' }} onClick={() => onRowClick(record)}>
              {team?.name}
            </Box>
          );
        },
      },
      {
        accessor: 'updated_at',
        title: 'Last Updated',
        sortable: true,
        width: 200,
        render: (record) => {
          if (record.updated_at) {
            const date = DateTime.fromISO(record.updated_at);
            return (
              <Box style={{ cursor: 'pointer' }} onClick={() => onRowClick(record)}>
                {date.isValid && date.toFormat('dd/MM/yyyy')}
              </Box>
            );
          }
          return '';
        },
      },
    ],
    [risks],
  );

  // Filter columns based on visibility
  const filteredColumns = staticColumns.filter((col) => visibleColumns[col.accessor]);

  useEffect(() => {
    if (Object.keys(visibleColumns).length === 0) {
      const initialVisibility = staticColumns.reduce((acc, col) => {
        acc[col.accessor] = true;
        return acc;
      }, {});
      setVisibleColumns(initialVisibility);
      setTempVisibleColumns(initialVisibility);
    }
  }, [staticColumns, visibleColumns]);

  return (
    <Box sx={{ height: '100%', overflowY: 'auto' }}>
      <DataTableToolbar
        addButtonUrl={() => openDrawer('', 'risk')}
        setSearchQuery={setSearchQuery}
        exportData={exportThinkTank}
        staticColumns={staticColumns}
        tempVisibleColumns={tempVisibleColumns}
        toggleTempColumn={toggleTempColumn}
        setVisibleColumns={setVisibleColumns}
      />

      <DataTable
        columns={filteredColumns}
        records={filteredRisks}
        sortStatus={sortStatus}
        onSortStatusChange={handleSort}
        highlightOnHover
        withColumnBorders
        fetching={loading}
        enableRowVirtualization
        height={tableHeight}
        emptyState={<div style={{ textAlign: 'center', padding: '20px' }}></div>}
        stickyHeader
        styles={{
          root: { backgroundColor: 'transparent' },
          table: { backgroundColor: 'transparent' },
          header: { backgroundColor: '#f7f7f8' },
          row: { backgroundColor: 'transparent' },
        }}
      />
      {loading && <Loader sx={{ margin: '10px auto', display: 'block' }} />}
    </Box>
  );
};
