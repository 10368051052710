import React, { forwardRef, useImperativeHandle } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Input, Textarea, Stack, Box, Title, Badge, Group, Avatar } from '@mantine/core';
import { Event } from '@mui/icons-material';
import LinkIcon from '@mui/icons-material/Link';
import { Card, CardContent, Grid, Chip, Typography, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import { sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';

import SvgPriorityHigh from '../../../assets/images/priorityHigh';
import SvgPriorityLow from '../../../assets/images/priorityLow';
import SvgPriorityMedium from '../../../assets/images/priorityMedium';
import SvgPriorityNo from '../../../assets/images/priorityNo';
import SvgPriorityUrgent from '../../../assets/images/priorityUrgent';
import { UPDATE_IDEA } from '../../../containers/Form/graphql';
import { GET_FEATURE_BY_ID } from '../../../containers/Product/Features/graphql';
import { GET_USERS } from '../../../containers/Settings/GraphQL/users';
import { GET_VALUE_STREAMS } from '../../../containers/Settings/Streams/graphql';
import { GET_ALL_INITIATIVES } from '../../../containers/ValueStream/Initiatives/graphql';
import { resetDrawerDetails } from '../../../reactiveVariables';
import { openDrawer } from '../../../reactiveVariables';
import { color } from '../../../shared/styles/color';
import { DATE_FORMAT } from '../../../utils/timeUtils';
import { AutoCompleteWrapper, FormContext, Field, renderSelectField } from '../../Common/FormFieldsHooks';

const DisabledInput = styled(Input)`
  > input {
    background-color: #ebebeb;
    cursor: not-allowed;
    color: black;
  }
`;

const DisabledTextarea = styled(Textarea)`
  > div > textarea {
    background-color: #ebebeb;
    cursor: not-allowed;
    color: black;
  }
`;

const ThinkTankDetails = (props, ref) => {
  const { initialValues, element } = props;

  const { data: { valueStreams = [] } = {} } = useQuery(GET_VALUE_STREAMS);
  const { data: { users = [] } = {} } = useQuery(GET_USERS);
  const { data: { initiatives = [] } = {} } = useQuery(GET_ALL_INITIATIVES);
  const { data } = useQuery(GET_FEATURE_BY_ID, {
    variables: { id: element.next_step_feature_or_initiative_id },
    skip: element.next_step_feature_or_initiative_id === null || element.next_step !== 'Convert to feature',
  });

  const feature = data?.features_by_pk || {};

  const [updateIdea] = useMutation(UPDATE_IDEA);
  const activeValueStreams = valueStreams.filter((stream) => stream.isActive === true);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    shouldUnregister: true,
    defaultValues: {
      ...element,
      cost: element.cost ? element.cost : '',
      people: element.people ? element.people : '',
      tech: element.tech ? element.tech : '',
      regulatory: element.regulatory ? element.regulatory : '',
    },
  });

  useImperativeHandle(ref, () => ({
    handleSave: () => {
      handleSubmit(onSubmit)();
    },
    handleClose: () => {
      resetDrawerDetails();
    },
  }));

  const openLinkedInitiativeOrFeature = () => {
    if (element.next_step === 'Convert to initiative') {
      const initiative = initiatives.find(
        (initiative) => initiative?.id === element.next_step_feature_or_initiative_id,
      );
      openDrawer(initiative, 'initiative');
    }

    if (element.next_step === 'Convert to feature') {
      openDrawer(feature, 'feature');
    }
  };

  const onSubmit = (values) => {
    const isArrayofNumbers =
      Array.isArray(values.valueStreams) && values.valueStreams.every((value) => typeof value === 'number');

    let selectedValueStreams;

    if (isArrayofNumbers) {
      selectedValueStreams = activeValueStreams
        .filter((valueStream) => values.valueStreams.includes(valueStream.id))
        .map((valueStream) => ({ id: valueStream.id, name: valueStream.name }));
    } else {
      selectedValueStreams = values.valueStreams;
    }

    const idea = {
      ...initialValues.idea,
      priority: values.priority,
      valueStreams: selectedValueStreams,
    };

    updateIdea({
      variables: {
        idea,
        id: element.id,
        next_step: values.next_step,
        next_step_feature_or_initiative_id: element.next_step_feature_or_initiative_id,
        state: values.state,
        owner: values.owner,
        cost: values.cost,
        people: values.people,
        tech: values.tech,
        regulatory: values.regulatory,
      },
    }).then(() => {
      if (!element.next_step_feature_or_initiative_id) {
        const drawerOptions = {
          'Convert to initiative': 'initiative',
          'Convert to feature': 'feature',
        };

        const type = drawerOptions[values.next_step];

        if (type) {
          const descriptionBuilder = `
            ${initialValues.idea.description}
            <br />
            <br />
            When we deliver it, what problem do we solve?
            <br />
            ${initialValues.idea.problem}
            <br />
            <br />
            How does the business benefit?
            <br />
            ${initialValues.idea.benefitMeasure}
            <br />
            <br />
            How will we know it’s successful?
            <br />
            ${initialValues.idea.successMeasure}
            <br />
            <br />
            What will we measure?
            <br />
            ${initialValues.idea.unitMeasure}
            <br />
            <br />
            What other ideas did you consider before deciding this?
            <br />
            ${initialValues.idea.otherIdeas}
            <br />
            <br />
            What teams would you need?
            <br />
            ${initialValues.idea.teamNeeded}
          `;

          openDrawer(
            {
              type,
              name: initialValues.idea.name,
              description: descriptionBuilder,
              createdFromThinkTank: {
                idea,
                id: element.id,
                next_step: values.next_step,
                state: values.state,
                owner: values.owner,
              },
            },
            type,
          );
        } else {
          resetDrawerDetails();
        }
      } else {
        resetDrawerDetails();
      }
    });
  };

  return (
    <Card elevation={0}>
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContext.Provider value={{ control, errors }}>
            <Grid container justifyContent="flex-start" item xs={12} spacing={2} mb={1}>
              <Grid item xs="auto">
                <Chip
                  sx={{
                    backgroundColor: 'color.paper',
                    fontWeight: 'bold',
                    borderRadius: 2,
                  }}
                  label={`ID - ${element.id}`}
                />
              </Grid>
              <Grid item xs="auto">
                <Chip
                  avatar={<Event style={{ width: '22px' }} />}
                  sx={{ backgroundColor: 'color.paper', borderRadius: 2 }}
                  label={`Submitted on: ${DateTime.fromISO(initialValues.created_at).toFormat(DATE_FORMAT.date)}`}
                />
              </Grid>
              {initialValues?.idea?.creator && (
                <Grid container item xs={'auto'} alignItems="center">
                  <Typography>Submitted by:&nbsp;</Typography>
                  <Typography color="textSecondary">{initialValues?.idea?.creator}</Typography>
                </Grid>
              )}

              {element.next_step_feature_or_initiative_id && (
                <Grid container item xs={'auto'} alignItems="center">
                  <Badge
                    color={color.purpulishPink}
                    size="sm"
                    radius="4px"
                    onClick={openLinkedInitiativeOrFeature}
                    style={{ padding: '0px', paddingRight: '4px', cursor: 'pointer' }}
                    sx={{
                      color: 'white',
                      backgroundColor: '#59585C',
                    }}>
                    <Group gap={0} spacing={0} noWrap>
                      <Avatar size={22} radius="xl" sx={{ backgroundColor: 'white' }}>
                        <LinkIcon style={{ width: '16px', height: '16px', color: 'white' }} />
                      </Avatar>
                      {element.next_step === 'Convert to feature'
                        ? `F:${element.next_step_feature_or_initiative_id}`
                        : `I:${element.next_step_feature_or_initiative_id}`}
                    </Group>
                  </Badge>
                </Grid>
              )}
            </Grid>

            <AutoCompleteWrapper
              errors={errors}
              idField="priority"
              label="Priority"
              name="priority"
              multiple={false}
              disabled={!!element.next_step_feature_or_initiative_id}
              control={control}
              value={'No Priority'}
              displayIcon
              options={[
                {
                  id: 'Urgent',
                  name: 'Urgent',
                  icon: <SvgPriorityUrgent />,
                },
                {
                  id: 'High',
                  name: 'High',
                  icon: <SvgPriorityHigh />,
                },
                {
                  id: 'Medium',
                  name: 'Medium',
                  icon: <SvgPriorityMedium />,
                },
                {
                  id: 'Low',
                  name: 'Low',
                  icon: <SvgPriorityLow />,
                },
                {
                  id: 'No Priority',
                  name: 'No Priority',
                  icon: <SvgPriorityNo />,
                },
              ]}
              getOptionLabel={(option) => option.name || option}
            />
            <AutoCompleteWrapper
              errors={errors}
              idField="owner"
              label="Owner"
              name="owner"
              disabled={!!element.next_step_feature_or_initiative_id}
              multiple={false}
              control={control}
              options={sortBy(
                users.map((user) => `${user.firstName} ${user.lastName}`),
                'firstName',
              )}
              getOptionLabel={(option) => option.name || option}
            />
            <AutoCompleteWrapper
              errors={errors}
              idField="valueStreams"
              label="Value stream"
              name="valueStreams"
              disabled={!!element.next_step_feature_or_initiative_id}
              multiple={true}
              control={control}
              options={activeValueStreams}
            />
            <AutoCompleteWrapper
              errors={errors}
              idField="state"
              label="State"
              name="state"
              disabled={!!element.next_step_feature_or_initiative_id}
              multiple={false}
              control={control}
              options={[
                {
                  id: 'new',
                  name: 'New',
                },
                {
                  id: 'analysis',
                  name: 'In Analysis',
                },
                {
                  id: 'rejected',
                  name: 'Rejected',
                },
                {
                  id: 'accepted',
                  name: 'Accepted',
                },
              ]}
              getOptionLabel={(option) => option.name || option}
            />

            <fieldset style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
              <legend style={{ fontSize: '12px', padding: '0 10px' }}>Change Impact</legend>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Field name="cost" renderField={renderSelectField} label="Cost" size={6} style={{ width: '315px' }}>
                  <MenuItem key="1" value={1}>
                    1
                  </MenuItem>
                  <MenuItem key="2" value={2}>
                    2
                  </MenuItem>
                  <MenuItem key="3" value={3}>
                    3
                  </MenuItem>
                  <MenuItem key="4" value={4}>
                    4
                  </MenuItem>
                  <MenuItem key="5" value={5}>
                    5
                  </MenuItem>
                </Field>

                <Field name="people" renderField={renderSelectField} label="People" size={6} style={{ width: '315px' }}>
                  <MenuItem key="1" value={1}>
                    1
                  </MenuItem>
                  <MenuItem key="2" value={2}>
                    2
                  </MenuItem>
                  <MenuItem key="3" value={3}>
                    3
                  </MenuItem>
                  <MenuItem key="4" value={4}>
                    4
                  </MenuItem>
                  <MenuItem key="5" value={5}>
                    5
                  </MenuItem>
                </Field>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Field name="tech" renderField={renderSelectField} label="Tech" size={6} style={{ width: '315px' }}>
                  <MenuItem key="1" value={1}>
                    1
                  </MenuItem>
                  <MenuItem key="2" value={2}>
                    2
                  </MenuItem>
                  <MenuItem key="3" value={3}>
                    3
                  </MenuItem>
                  <MenuItem key="4" value={4}>
                    4
                  </MenuItem>
                  <MenuItem key="5" value={5}>
                    5
                  </MenuItem>
                </Field>

                <Field
                  name="regulatory"
                  renderField={renderSelectField}
                  label="Regulatory"
                  size={6}
                  style={{ width: '315px' }}>
                  <MenuItem key="1" value={1}>
                    1
                  </MenuItem>
                  <MenuItem key="2" value={2}>
                    2
                  </MenuItem>
                  <MenuItem key="3" value={3}>
                    3
                  </MenuItem>
                  <MenuItem key="4" value={4}>
                    4
                  </MenuItem>
                  <MenuItem key="5" value={5}>
                    5
                  </MenuItem>
                </Field>
              </div>
            </fieldset>

            <AutoCompleteWrapper
              errors={errors}
              idField="next_step"
              label="Next step"
              name="next_step"
              multiple={false}
              disabled={!!element.next_step_feature_or_initiative_id}
              control={control}
              options={[
                {
                  id: 'unassigned',
                  name: 'Unassigned',
                },
                {
                  id: 'initiative',
                  name: 'Convert to initiative',
                },
                {
                  id: 'feature',
                  name: 'Convert to feature',
                },
                {
                  id: 'reject',
                  name: 'Reject',
                },
              ]}
              getOptionLabel={(option) => option.name || option}
            />
          </FormContext.Provider>
        </form>

        <Input.Wrapper label="Title">
          <DisabledInput value={element?.idea?.name} readOnly="readonly" />
        </Input.Wrapper>

        <DisabledTextarea label="Description" value={element?.idea?.description} readOnly="readonly" mt={10} rows={6} />

        <Title order={6} mt={10} fw={500}>
          Themes
        </Title>
        <Box
          style={{
            border: `1px solid #E8E8E8`,
            padding: '16px',
            borderRadius: '4px',
            marginTop: '5px',
            cursor: 'not-allowed',
            backgroundColor: '#ebebeb',
          }}>
          <Stack spacing="md">
            {element?.idea?.themes.map((theme) => (
              <Box
                key={theme.id}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <span style={{ fontSize: '14px' }}>{theme.title}</span>
              </Box>
            ))}
          </Stack>
        </Box>
        <DisabledTextarea
          label="When we deliver it, what problem do we solve?"
          value={element?.idea?.problem}
          readOnly="readonly"
          mt={10}
          rows={6}
        />

        <DisabledTextarea
          label="How does the business benefit?"
          value={element?.idea?.benefitMeasure}
          readOnly="readonly"
          mt={10}
          rows={6}
        />

        <DisabledTextarea
          label="How will we know it’s successful?"
          value={element?.idea?.successMeasure}
          readOnly="readonly"
          mt={10}
          rows={6}
        />

        <Input.Wrapper label="What will we measure?" mt={10}>
          <DisabledInput value={element?.idea?.unitMeasure} readonly="readonly" />
        </Input.Wrapper>

        <DisabledTextarea
          label="What other ideas did you consider before deciding this?"
          value={element?.idea?.otherIdeas}
          readonly="readonly"
          mt={10}
          rows={6}
        />

        <Input.Wrapper label="What teams would you need?" mt={10}>
          <DisabledInput readOnly="readonly" value={element?.idea?.teamNeeded} />
        </Input.Wrapper>
      </CardContent>
    </Card>
  );
};

export default forwardRef(ThinkTankDetails);
