import React, { useState, useCallback, useEffect } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import { useTheme } from '@emotion/react';
import { Text, Tooltip, ActionIcon, Badge } from '@mantine/core';
import { ExpandMore } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableHead, TableRow, Avatar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { IconFlag } from '@tabler/icons-react';
import { find } from 'lodash';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { getFeatureBackgroundColor } from 'utils/piColors';

import SvgDashboard from '../../../assets/images/Dashboard';
import { styles } from '../../../components/Dashboard/FeatureBoard/styles';
import { openDrawer, stickyHeaderVar } from '../../../reactiveVariables';
import { lightColors } from '../../../shared/styles/color';
import { color } from '../../../shared/styles/color';
import { STAGE_SELECTS } from '../../../utils/formConstants';
import { GET_PROJECTS } from '../../Organisation/Projects/graphql';
import { GET_TEAMS } from '../../Settings/GraphQL/teams';
import { GET_PROGRAM_INCREMENTS_FOR_PROGRAMME } from '../../Settings/ProgramIncrement/graphql';
import LinearWithValueLabel from '../../ValueStream/components/OKRTable/LinearWithValueLabel';
import { GET_ALL_INITIATIVES } from '../../ValueStream/Initiatives/graphql';
import { DialogFeaturePopup } from '../../ValueStream/Kanban/DialogFeaturePopup';
import { DialogPopup } from '../../ValueStream/Kanban/DialogPopup';
import { FeatureCard } from '../../ValueStream/Kanban/styles';
import { getInitiativeBgColor } from '../../ValueStream/utils';
import { Card, CardBottom, CardContainer } from '../IdeaBoard/styles';

const useStyles = makeStyles(styles);

export const InitiativeIncrements = () => {
  const theme = useTheme();
  const stickyHeader = useReactiveVar(stickyHeaderVar);

  const classes = useStyles({ stickyHeader: stickyHeader });
  const navigate = useNavigate();

  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);
  const { data: { initiatives = [] } = {} } = useQuery(GET_ALL_INITIATIVES);
  const { data: { programIncrements = [] } = {} } = useQuery(GET_PROGRAM_INCREMENTS_FOR_PROGRAMME);
  const { data: { projects: strategic_themes = [] } = {} } = useQuery(GET_PROJECTS);

  const activeIncrements = programIncrements.filter(
    (increment) => increment.status === 'active' || increment.status === 'planning',
  );

  const activeIncrementIds = activeIncrements.map((pi) => pi.id);

  const filteredInitiatives = initiatives.filter((initiative) =>
    initiative.features.nodes.some((feature) => feature.pi?.id && activeIncrementIds.includes(feature.pi.id)),
  );

  const sortedInitiatives = [...filteredInitiatives].sort((a, b) => {
    const projectA = strategic_themes.find((theme) => theme.id === a.strategic_theme);
    const projectB = strategic_themes.find((theme) => theme.id === b.strategic_theme);

    const projectNameA = projectA?.name || null;
    const projectNameB = projectB?.name || null;

    if (projectNameA && projectNameB) {
      // Both have a project - Sort by project name, then by initiative name
      return projectNameA.localeCompare(projectNameB) || a.name.localeCompare(b.name);
    }

    if (!projectNameA && !projectNameB) {
      // Both have no project →-Sort by initiative name only
      return a.name.localeCompare(b.name);
    }

    // One has a project and one doesn't - Initiatives without a project go last
    return projectNameA ? -1 : 1;
  });

  const [showAllFeatures, setShowAllFeatures] = useState({});
  const [hoveredInitiative, setHoveredInitiative] = useState(null);
  const [hoveredFeature, setHoveredFeature] = useState(null);

  useEffect(() => () => stickyHeaderVar(false), []);

  const handleInitiativeClick = (initiative) => {
    openDrawer({ ...initiative }, 'initiative');
  };

  const handleFeatureClick = (feature) => {
    openDrawer({ ...feature }, 'feature');
  };

  const handleNavigate = useCallback(
    (featureId, event) => {
      event.stopPropagation();
      navigate(`/valueStream/initiative/${featureId}`, {
        state: { from: '/valueStream/kanban', tab: 'breakdown' },
      });
    },
    [navigate],
  );

  const toggleShowAllFeaturesForStage = (initiativeId, stageValue) => {
    setShowAllFeatures((prev) => ({
      ...prev,
      [initiativeId]: {
        ...prev[initiativeId],
        [stageValue]: !prev?.[initiativeId]?.[stageValue],
      },
    }));
  };

  const renderInitiativesDetails = (initiative) => {
    const plannedInitiative = activeIncrements.map((increment) => {
      const featuresForIncrement = initiative.features.nodes.filter((feature) => feature.pi?.id === increment.id);

      const displayedFeatures = showAllFeatures[initiative.id]?.[increment.name]
        ? featuresForIncrement
        : featuresForIncrement.slice(0, 3);

      const hasNoFutureFeatures = activeIncrements
        .slice(activeIncrements.indexOf(increment) + 1)
        .every((futureIncrement) =>
          initiative.features.nodes.every((feature) => feature.pi?.id !== futureIncrement.id),
        );

      return (
        <TableCell
          key={increment.id}
          style={{
            minWidth: '220px',
            maxWidth: '220px',
            verticalAlign: 'top',
            borderRight: `1px solid ${theme.palette.color.paleGrey2}`,
            backgroundColor:
              featuresForIncrement.length === 0 && hasNoFutureFeatures
                ? '#dbe2f5'
                : theme.palette.mode === 'dark'
                ? 'black'
                : lightColors.background,
          }}>
          {displayedFeatures.map((feature) => {
            const borderColor = getFeatureBackgroundColor(feature, theme);

            return (
              <>
                {feature && hoveredFeature === feature.id && (
                  <DialogFeaturePopup feature={feature} setIsFeatureHovered={setHoveredFeature} teams={teams} />
                )}

                <CardContainer>
                  <Card
                    shadow="xs"
                    key={feature.id}
                    onClick={() => handleFeatureClick(feature)}
                    onMouseEnter={() => setHoveredFeature(feature.id)}
                    onMouseLeave={() => setHoveredFeature(null)}
                    style={{ margin: '10px auto 0px', borderRadius: '5px 5px 0px 0px' }}
                    borderColor={borderColor}>
                    <div
                      style={{
                        height: '22px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '5px',
                      }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <Text size="xs" mr={'6px'} lh={'16px'} ta="center">
                          {feature?.enabler ? 'E' : 'F'}-{feature.id}
                        </Text>
                      </div>
                      {feature.risk_flagged && (
                        <div style={{ position: 'absolute', right: '0px', top: '0px' }}>
                          <ActionIcon variant="transparent" color={'red'}>
                            <IconFlag size={20} fill={'red'} stroke={1.5} />
                          </ActionIcon>
                        </div>
                      )}
                    </div>
                    <Text size="sm" fw={400} c={theme.palette.text.primary}>
                      {feature.name.length > 80 ? feature.name.substring(0, 80) + '...' : feature.name}
                    </Text>

                    <div style={{ display: 'flex' }}>
                      {(feature.plannedReleaseDate || feature.plannedReleaseDate) && (
                        <div className={classes.timeContainer}>
                          <Badge
                            size="sm"
                            radius="4px"
                            sx={{ padding: '4px', color: 'white', backgroundColor: '#59585C' }}>
                            {feature.plannedStartDate && DateTime.fromISO(feature.plannedStartDate).toFormat('D')}

                            {feature.plannedStartDate && feature.plannedReleaseDate && '\xa0 - \xa0'}

                            {feature.plannedReleaseDate && DateTime.fromISO(feature.plannedReleaseDate).toFormat('D')}
                          </Badge>
                        </div>
                      )}
                    </div>
                  </Card>

                  <CardBottom
                    radius="0px 0px 4px 4px"
                    style={{
                      borderColor: theme.palette.mode === 'dark' ? 'white' : theme.palette.color.cardBorder,
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: '10px',
                      marginBottom: '0px',
                      height: '47px',
                    }}>
                    {feature.assignedTo && (
                      <Tooltip color="dark" label={feature.assignedTo}>
                        <Avatar
                          sx={{
                            width: 24,
                            height: 24,
                            fontSize: 10,
                            fontWeight: 'bold',
                            backgroundColor: color.purpulishPink,
                            marginRight: '10px',
                          }}>
                          {feature.assignedTo
                            ?.split(' ')
                            .map((n) => n[0])
                            .join('')}
                        </Avatar>
                      </Tooltip>
                    )}

                    {feature.stage && (
                      <Badge
                        size="sm"
                        radius="4px"
                        color={color.purpulishPink}
                        sx={{ padding: '4px', color: 'white', backgroundColor: color.purpulishPink }}>
                        {find(STAGE_SELECTS, { value: feature.stage })?.label}
                      </Badge>
                    )}
                  </CardBottom>
                </CardContainer>
              </>
            );
          })}
          {featuresForIncrement.length > 3 && (
            <FeatureCard
              onClick={() => toggleShowAllFeaturesForStage(initiative.id, increment.name)}
              style={{
                backgroundColor: '#EFEFEF',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '0px',
                color: theme.palette.mode === 'dark' ? 'white' : 'black',
                height: '34px',
              }}>
              <div
                style={{
                  color: theme.palette.text.secondary,
                  fontSize: '12px',
                  alignSelf: 'center',
                }}>
                {showAllFeatures?.[initiative.id]?.[increment.name] ? 'Hide features' : 'Show all features'}{' '}
                <ExpandMore style={{ alignSelf: 'center' }} />
              </div>
            </FeatureCard>
          )}
        </TableCell>
      );
    });

    const borderColor = getInitiativeBgColor(initiative, theme);
    const progress = initiative.features?.aggregate?.avg?.percentageDone || 0;

    const countMatchingFeatures = (features = [], activeIncrements = []) => {
      if (!Array.isArray(features) || !features.length) return { totalCount: 0, doneCount: 0 };

      const activeIncrementIds = activeIncrements.map((increment) => increment.id);

      return features.reduce(
        (counts, feature) => {
          if (feature.pi && activeIncrementIds.includes(feature.pi.id)) {
            counts.totalCount++;
            if (feature.stage?.toLowerCase() === 'done') {
              counts.doneCount++;
            }
          }
          return counts;
        },
        { totalCount: 0, doneCount: 0 },
      );
    };

    const { totalCount, doneCount } = countMatchingFeatures(initiative.features?.nodes || [], activeIncrements);

    const project = strategic_themes.find((theme) => theme.id === initiative.strategic_theme);

    return (
      <TableRow key={initiative.id} className={classes.row} style={{ height: 'auto', backgroundColor: '#F7F7F8' }}>
        <TableCell
          className={classes.tableHeadCol}
          onClick={() => handleInitiativeClick(initiative)}
          style={{
            minWidth: '220px',
            maxWidth: '220px',
            borderLeft: `5px solid ${borderColor}`,
            height: 'auto',
            borderRight: `1px solid  ${theme.palette.color.paleGrey2}`,
            borderBottom: `1px solid  ${theme.palette.color.paleGrey2}`,
            position: 'relative',
          }}>
          {project && (
            <Badge
              color={color.purple}
              size="sm"
              radius="4px"
              mb={10}
              onClick={() => navigate(`/organisation/projects/${project.id}`)}
              styles={{
                padding: '4px',
                color: 'white',
                root: {
                  cursor: 'pointer',
                },
              }}>
              {project?.name}
            </Badge>
          )}

          <div
            style={{ cursor: 'pointer' }}
            onMouseEnter={() => setHoveredInitiative(initiative.id)}
            onMouseLeave={() => setHoveredInitiative(null)}>
            {initiative.features?.aggregate?.count > 0 && (
              <Tooltip color="dark" label="Open Initiative view" zIndex={1000}>
                <ActionIcon
                  variant="transparent"
                  color="var(--mantine-color-buttonIcon)"
                  onClick={(event) => handleNavigate(initiative.id, event)}
                  style={{ position: 'relative', top: '3px' }}
                  ml={-4}>
                  <SvgDashboard />
                </ActionIcon>
              </Tooltip>
            )}
            <span className={classes.teamName} style={{ marginLeft: '0px' }}>
              {initiative.name}
            </span>

            {initiative.owner && (
              <div style={{ display: 'flex', alignItems: 'baseline', marginTop: '10px' }}>
                <Text size="sm" c={theme.palette.text.secondary}>
                  Owner: {initiative.owner}
                </Text>
              </div>
            )}

            <div style={{ display: 'flex', alignItems: 'baseline', marginTop: '10px' }}>
              <Text size="sm" c={theme.palette.text.secondary}>
                Features: {totalCount}
              </Text>
            </div>

            <div style={{ display: 'flex', alignItems: 'baseline', marginTop: '10px' }}>
              <Text size="sm" c={theme.palette.text.secondary}>
                Done: {doneCount}
              </Text>
            </div>

            <div style={{ minWidth: '100%', marginTop: '10px' }}>
              <LinearWithValueLabel data-value={progress} value={progress.toFixed()} minWidth={0} />
            </div>
          </div>
        </TableCell>
        {plannedInitiative}
        {hoveredInitiative === initiative.id && <DialogPopup initiative={initiative} />}
      </TableRow>
    );
  };
  const onLeave = ({ currentPosition, previousPosition }) => {
    const isAbove = currentPosition === Waypoint.above;
    const wasInside = previousPosition === Waypoint.inside;

    if (isAbove && wasInside) {
      stickyHeaderVar(true);
    }
  };

  const onEnter = () => {
    stickyHeaderVar(false);
  };

  return (
    <>
      <Table className={classes.table} style={{ tableLayout: 'inherit' }} stickyHeader={stickyHeader}>
        <TableHead>
          <Waypoint onLeave={onLeave} onEnter={onEnter} />
          <TableRow>
            <TableCell
              key="header"
              className={classes.tableHeadRow}
              style={{ minWidth: '280px', maxWidth: '280px', height: 'auto' }}>
              Initiatives
            </TableCell>
            {activeIncrements.map((program) => (
              <TableCell
                key={program.id}
                className={classes.tableHeadRow}
                style={{
                  minWidth: '220px',
                  maxWidth: '220px',
                  height: 'auto',
                  borderBottom: '1px solid  #dfe2e8',
                }}>
                {program.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{sortedInitiatives.map((initiative) => renderInitiativesDetails(initiative))}</TableBody>
      </Table>
    </>
  );
};
