import { gql } from '@apollo/client';

export const PROJECT_FIELDS = gql`
  fragment ProjectsFields on projects {
    id
    name
    description
    summary
    rag_status
    links
    attachments
    financial_benefit
    priority
    members
    owner
    delivery_teams
    value_streams
    programmes
    isActive
    milestones
    planned_start_date
    planned_end_date
    updated_at
    created_at
    previous_rag_status
    rag_justification
    path_to_green
    risk_flagged
  }
`;

export const GET_PROJECTS = gql`
  ${PROJECT_FIELDS}
  query GetProjects {
    projects(order_by: { id: asc }) {
      ...ProjectsFields
    }
  }
`;

export const INSERT_PROJECT = gql`
  ${PROJECT_FIELDS}
  mutation InsertProject($theme: projects_insert_input! = {}) {
    project: insert_projects_one(object: $theme) {
      ...ProjectsFields
    }
  }
`;

export const UPDATE_PROJECT = gql`
  ${PROJECT_FIELDS}
  mutation UpdateProject($themeId: Int!, $theme: projects_set_input = {}) {
    update_projects_by_pk(pk_columns: { id: $themeId }, _set: $theme) {
      ...ProjectsFields
    }
  }
`;

export const PROJECT_MILESTONES_FIELDS = gql`
  fragment ProjectMilestonesFields on project_milestones {
    id
    name
    description
    agreed_date
    forecasted_date
    project
    rag_status
    owner
  }
`;

export const GET_PROJECT_MILESTONES = gql`
  ${PROJECT_MILESTONES_FIELDS}
  query GetProjectMilestones {
    project_milestones(order_by: { id: asc }) {
      ...ProjectMilestonesFields
    }
  }
`;

export const INSERT_PROJECT_MILESTONES = gql`
  ${PROJECT_MILESTONES_FIELDS}
  mutation InsertProjectMilestone($milestone: project_milestones_insert_input! = {}) {
    project_milestone: insert_project_milestones_one(object: $milestone) {
      ...ProjectMilestonesFields
    }
  }
`;

export const UPDATE_PROJECT_MILESTONES = gql`
  ${PROJECT_MILESTONES_FIELDS}
  mutation UpdateProjectMilestone($milestoneId: Int!, $milestone: project_milestones_set_input = {}) {
    update_project_milestones_by_pk(pk_columns: { id: $milestoneId }, _set: $milestone) {
      ...ProjectMilestonesFields
    }
  }
`;

export const DELETE_PROJECT_MILESTONE = gql`
  mutation DeleteProjectMilestone($id: Int!) {
    milestone: delete_project_milestones_by_pk(id: $id) {
      id
    }
  }
`;
