import { Button } from '@mantine/core';
import { lightColors } from '../../shared/styles/color';
import styled from '@emotion/styled';

export const SpotlightContainer = styled('div')({
  border: `1px solid ${lightColors.tileBorder}`,
  borderRadius: '12px',
  backgroundColor: 'white',
  background: 'white',
  position: 'absolute',
  width: 'calc(100% - 260px)',
  height: 'calc(100% - 20px)',
  top: '10px',
  zIndex: '1000',
  left: '250px',
  display: 'flex',
});

export const InitiativeOrFeatureContainer = styled('div')({
  border: `1px solid ${lightColors.tileBorder}`,
  borderRadius: '12px',
  width: '740px',
  display: 'contents',
});

export const MainBody = styled('div')({});

export const commonButtonStyles = {
  height: '28px',
  border: `1px solid ${lightColors.tileBorder}`,
  backgroundColor: 'white',
  color: '#242424',
  fontSize: '12px',
  fontWeight: 400,
  position: 'relative',
  borderRadius: '8px',
  display: 'inherit',
};
