import { DateTime } from 'luxon';

const createData = (item, theme, usersList, activeValueStreams) => {
  const { name, id, owner, value_streams, planned_start_date, planned_end_date } = item;

  let user = [];

  if (usersList.length) {
    user = usersList
      ?.filter((user) => user.id === owner)
      .map((user) => user.name)
      .join(', ');
  }

  let streams = [];

  if (activeValueStreams.length) {
    streams = activeValueStreams
      ?.filter((stream) => value_streams?.includes(stream?.id))
      .map((stream) => stream.name)
      .join(', ');
  }

  return {
    id,
    name,
    owner: user,
    value_streams: streams,
    planned_start_date: planned_start_date ? DateTime.fromISO(planned_start_date).toFormat('MMMM dd, yyyy') : '',
    planned_end_date: planned_end_date ? DateTime.fromISO(planned_end_date).toFormat('MMMM dd, yyyy') : '',
  };
};

export const getRowsData = (data, theme, usersList, activeValueStreams) => {
  let rows = [];
  if (Array.isArray(data) && data.length) {
    rows = data.map((item) => createData(item, theme, usersList, activeValueStreams));
  }
  return rows;
};
