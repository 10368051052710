import React, { useState, useMemo, useEffect } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import SectionMenu from 'components/Common/SectionHeader/components/SectionMenu';
import { orderBy } from 'lodash';
import { useLocation } from 'react-router-dom';

import FeatureKanban from './featureKanban';
import KanbanTab from './kanbanTab';
import Timelines from './Timelines';
import {
  openDrawer,
  selectedProductThemeVar,
  selectedOwnerVar,
  selectedTeamVar,
  selectedValueStreamVar,
  selectedProjectVar,
} from '../../../reactiveVariables';
import { useProjectedFeatures } from '../../../utils/hooks';
import { styles } from '../../Product/IdeaBoard/styles';
import { GET_SPRINTS } from '../../Settings/ProgramIncrement/graphql';
import { GET_INITIATIVES } from '../../ValueStream/Initiatives/graphql';
import { initiativeWithDates } from '../../ValueStream/utils';

const useStyles = makeStyles(styles);

export default function InitiativesKanban() {
  const classes = useStyles();
  const location = useLocation();

  const { data: { initiatives = [] } = {}, refetch: refetchInitiatives } = useQuery(GET_INITIATIVES);
  const { data: { sprints = [] } = {} } = useQuery(GET_SPRINTS);

  const selectedProductTheme = useReactiveVar(selectedProductThemeVar);
  const selectedOwners = useReactiveVar(selectedOwnerVar);
  const selectedValueStream = useReactiveVar(selectedValueStreamVar);
  const selectedTeams = useReactiveVar(selectedTeamVar);
  const selectedProject = useReactiveVar(selectedProjectVar);
  const projectedFeatures = useProjectedFeatures();

  const filterByProductTheme = (initiative, selectedProductTheme) => {
    if (selectedProductTheme === 'x') {
      return initiative.productThemes.length === 0;
    }
    return !selectedProductTheme || initiative.productThemes.some((theme) => theme.id === selectedProductTheme);
  };

  const filterByValueStream = (initiative, selectedValueStream) => {
    return (
      !selectedValueStream ||
      initiative.valueStream === selectedValueStream ||
      initiative.portfolioValueStreams?.some((portfolioValueStream) => portfolioValueStream.id === selectedValueStream)
    );
  };

  const filterByOwner = (initiative, selectedOwners) => {
    return selectedOwners.includes(initiative.owner);
  };

  const filterByTeams = (initiative, selectedTeams) => {
    return initiative.teams?.some((team) => selectedTeams.includes(team.id));
  };

  const filterByProject = (initiative, selectedProject) => {
    if (!selectedProject) {
      return true;
    }
    return initiative.strategic_theme === parseInt(selectedProject);
  };

  const initiativesWithDatesAndRag = useMemo(() => {
    let filteredInitiatives = initiatives
      .filter((initiative) => {
        const themeFilter = filterByProductTheme(initiative, selectedProductTheme);
        const valueStreamFilter = filterByValueStream(initiative, selectedValueStream);
        const projectFilter = filterByProject(initiative, selectedProject);
        return themeFilter && valueStreamFilter && projectFilter;
      })
      .map((initiative) => initiativeWithDates(initiative, sprints, projectedFeatures));

    if (selectedOwners.length) {
      filteredInitiatives = filteredInitiatives.filter((initiative) => filterByOwner(initiative, selectedOwners));
    }

    if (selectedTeams.length) {
      filteredInitiatives = filteredInitiatives.filter((initiative) => filterByTeams(initiative, selectedTeams));
    }

    return orderBy(filteredInitiatives, ['rank'], ['asc']);
  }, [
    selectedProductTheme,
    selectedValueStream,
    selectedOwners,
    selectedTeams,
    selectedProject,
    initiatives,
    sprints,
    projectedFeatures,
  ]);

  const TABS = [
    {
      index: 0,
      name: 'Initiatives',
    },
    {
      index: 1,
      name: 'Features',
    },
    {
      index: 2,
      name: 'Breakdown',
    },
  ];

  const [value, setValue] = useState(() => {
    return location.state?.activeTab === 'breakdown' ? 2 : 0;
  });

  useEffect(() => {
    if (location.state?.activeTab) {
      setValue(location.state.activeTab === 'breakdown' ? 2 : 0);
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  const handleCreate = () => openDrawer(null, value === 1 ? 'feature' : 'initiative');

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const refetchListOfInitiatives = () => {
    refetchInitiatives();
  };

  return (
    <div className={classes.root}>
      <Toolbar className={classes.appBar} disableGutters>
        <div className={classes.toolbarLeft}>
          <Typography fontSize={20} fontWeight={600}>
            Value Stream Kanban
          </Typography>
          <IconButton
            key="add"
            aria-label="Add"
            color="inherit"
            onClick={handleCreate}
            size="small"
            sx={{ marginLeft: '6px' }}>
            <AddBoxIcon />
          </IconButton>
        </div>
      </Toolbar>

      <SectionMenu handleChange={handleTabChange} value={value} tabs={TABS} />
      <section style={{ marginTop: '20px', overflowY: 'auto' }}>
        {value === 0 && (
          <KanbanTab initiatives={initiativesWithDatesAndRag} refetchListOfInitiatives={refetchListOfInitiatives} />
        )}
        {value === 1 && <FeatureKanban selectedOwners={selectedOwners} selectedProductTheme={selectedProductTheme} />}
        {value === 2 && <Timelines initiatives={initiativesWithDatesAndRag} />}
      </section>
    </div>
  );
}
