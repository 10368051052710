import * as d3 from 'd3';
import { DateTime } from 'luxon';
import React, { useEffect, useRef } from 'react';
import { Flex, Grid, Tooltip } from '@mantine/core';
import { Column, DivColumn } from './InitiativeDashboard.style';
import { darken } from '@mui/system/colorManipulator';
import { last } from 'lodash';
import { openDrawer } from '../../../reactiveVariables';
import { useTheme } from '@emotion/react';

const FeatureLine = ({
  feature,
  width,
  height,
  lastChild,
  noBorder,
  startDate,
  endDate,
  color,
  columnDates,
  fullFeature,
}) => {
  const ref = useRef();
  const theme = useTheme();

  const visualizeTicks = (scale, ref, width, height, feature, tickArguments) => {
    if (tickArguments === undefined) tickArguments = [];

    scale.range([0, width]);

    const line = d3
      .line()
      .x((d) => scale(d))
      .y((d) => 30)
      .curve(d3.curveNatural);

    const svg = d3.select(ref.current).attr('width', '100%').attr('height', height);

    const tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'd3-tooltip')
      .style('position', 'absolute')
      .style('z-index', '10')
      .style('visibility', 'hidden')
      .style('padding', '10px')
      .style('border', `0.6px solid ${theme.palette.color.tileBorder}`)
      .style('background', theme.palette.color.background)
      .style('border-radius', '4px')
      .style('box-shadow', `0px 1px 0px 0px ${theme.palette.color.shadow}`)
      .style('color', 'black');

    svg.selectAll('*').remove();

    const lineEndDate = DateTime.fromISO(endDate);
    const lineStartDate = DateTime.fromISO(startDate);
    const percentageDone = fullFeature?.metrics?.percentageDone || 0;

    const path = svg
      .append('path')
      .datum([lineStartDate.toJSDate(), lineEndDate.toJSDate()])
      .attr('d', line)
      .attr('stroke-linecap', 'round')
      .attr('class', 'line')
      .attr('stroke', color ?? '#FFFFFF80')
      .attr('stroke-width', 11)
      .on('mouseover', function (event, d, i) {
        tooltip
          .html(
            `
                <div>
                    <span style="color:${theme.palette.text.primary}; font-weight: 600">
                        ${feature.name}
                    </span>
                </div>
                <div>
                    <span style="color:${theme.palette.text.secondary}">Percentage Done: </span>
                    <span style="color:${theme.palette.text.primary}">${percentageDone}%</span>
                </div>
                <div>
                    <span style="color:${theme.palette.text.secondary}">Start Date: </span>
                    <span style="color:${theme.palette.text.primary}">${lineStartDate.toLocaleString()}</span>
                </div>
                <div>
                    <span style="color:${theme.palette.text.secondary}">End Date: </span>
                    <span style="color:${theme.palette.text.primary}">${lineEndDate.toLocaleString()}</span>
                </div>
            `,
          )
          .style('visibility', 'visible');
        d3.select(this)
          .transition()
          .attr('stroke', darken(color ?? '#FFFFFF80', 0.5))
          .style('cursor', 'pointer');
      })
      .on('mousemove', function (event) {
        tooltip.style('top', event.pageY - 10 + 'px').style('left', event.pageX + 10 + 'px');
      })
      .on('mouseout', function (event) {
        tooltip.html(``).style('visibility', 'hidden');
        d3.select(this)
          .transition()
          .attr('stroke', color ?? '#FFFFFF80')
          .style('cursor', 'default');
      })
      .on('click', () => openDrawer(fullFeature, 'feature'));

    const length = path.node()?.getTotalLength() || 0;

    path
      .attr('stroke-dasharray', length + ' ' + length)
      .attr('stroke-dashoffset', length)
      .attr('fill', 'none')
      .transition()
      .ease(d3.easeLinear)
      .attr('stroke-dashoffset', 0)
      .duration(500);

    return svg.node();
  };

  const axisStartDate = columnDates[0].start.toJSDate();
  const axisEndDate = last(columnDates).end.toJSDate();

  useEffect(() => {
    if (endDate && startDate) {
      visualizeTicks(d3.scaleTime().domain([axisStartDate, axisEndDate]), ref, width, height, feature);
    }
  }, [width, height, columnDates, endDate, startDate, feature]);

  return (
    <>
      <svg
        ref={ref}
        preserveAspectRatio="xMinYMin meet"
        viewBox={`0 0 ${width} ${height}`}
        style={{ position: 'absolute' }}
      />
      <Flex style={{ height: lastChild ? '70px' : '60px', width: '100%' }}>
        <DivColumn noBorder={noBorder} />
        <DivColumn noBorder={noBorder} />
        <DivColumn noBorder={noBorder} />
        <DivColumn noBorder={noBorder} style={{ borderRight: 'none' }} />
      </Flex>
    </>
  );
};

export default FeatureLine;
