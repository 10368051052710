export const DEPENDENCIES = 'dependencies';

export const LOAD_DEPENDENCIES = {
  REQUEST: 'LOAD_DEPENDENCIES_REQUEST',
  SUCCESS: 'LOAD_DEPENDENCIES_SUCCESS',
  FAILURE: 'LOAD_DEPENDENCIES_FAILURE',
};

export const SAVE_DEPENDENCY = {
  REQUEST: 'SAVE_DEPENDENCY_REQUEST',
  SUCCESS: 'SAVE_DEPENDENCY_SUCCESS',
  FAILURE: 'SAVE_DEPENDENCY_FAILURE',
};

export const DELETE_DEPENDENCY = {
  REQUEST: 'DELETE_DEPENDENCY_REQUEST',
  SUCCESS: 'DELETE_DEPENDENCY_SUCCESS',
  FAILURE: 'DELETE_DEPENDENCY_FAILURE',
};

export const FETCH_ISSUES = {
  REQUEST: 'FETCH_ISSUES_REQUEST',
  SUCCESS: 'FETCH_ISSUES_SUCCESS',
  FAILURE: 'FETCH_ISSUES_FAILURE',
};

export const headCells = [
  {
    id: 'id',
    field: 'id',
    label: 'ID',
    disablePadding: false,
    filtering: false,
    defaultSort: 'asc',
    numeric: false,
    align: 'left',
    width: '3%',
  },
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    disablePadding: false,
    filtering: false,
    numeric: false,
    align: 'left',
  },
  {
    id: 'team',
    field: 'team',
    label: 'Team',
    disablePadding: false,
    numeric: false,
    align: 'left',
  },
  {
    id: 'owner',
    field: 'owner',
    label: 'Owner',
    disablePadding: false,
    numeric: false,
    align: 'left',
  },
  {
    id: 'neededBySprint',
    field: 'neededBySprint',
    label: 'Needed By',
    disablePadding: false,
    numeric: true,
    align: 'left',
  },
  {
    id: 'status',
    field: 'status',
    label: 'Status',
    disablePadding: false,
    numeric: false,
    align: 'left',
  },
  {
    id: 'ragStatus',
    field: 'ragStatus',
    label: 'RAG Status',
    disablePadding: false,
    numeric: false,
    align: 'left',
  },
  {
    id: 'needed_by_team',
    field: 'needed_by_team',
    label: 'Needed By Team',
    disablePadding: false,
    numeric: false,
    align: 'left',
  },
];
