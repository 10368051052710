import { gql } from '@apollo/client';

export const OBJECTIVE_FIELDS = gql`
  fragment ObjectiveFields on objectives {
    deliveredValue
    description
    id
    name
    notes
    plannedValue
    programIncrement
    ragStatus
    sprintId
    status
    teamId
    updatedAt
    commitment
  }
`;

export const INSERT_OBJECTIVE = gql`
  ${OBJECTIVE_FIELDS}
  mutation InsertFeature($objective: [objectives_insert_input!] = {}) {
    objective: insert_objectives(objects: $objective) {
      returning {
        ...ObjectiveFields
      }
    }
  }
`;

export const UPDATE_OBJECTIVE = gql`
  ${OBJECTIVE_FIELDS}
  mutation UpdateFeature($objectiveId: Int!, $objective: objectives_set_input = {}) {
    update_objectives_by_pk(pk_columns: { id: $objectiveId }, _set: $objective) {
      ...ObjectiveFields
    }
  }
`;

export const DELETE_OBJECTIVE = gql`
  ${OBJECTIVE_FIELDS}
  mutation DeleteObjective($id: Int!) {
    objective: delete_objectives_by_pk(id: $id) {
      ...ObjectiveFields
    }
  }
`;

export const GET_OBJECTIVES = gql`
  ${OBJECTIVE_FIELDS}
  query GetObjectives {
    objectives: objectives(order_by: { id: asc }) {
      ...ObjectiveFields
    }
  }
`;

export const GET_OBJECTIVES_FOR_PI = gql`
  ${OBJECTIVE_FIELDS}
  query GetObjectives($pi: Int) {
    selectedPI @client @export(as: "pi")
    objectives: objectives(where: { programIncrement: { _eq: $pi } }) {
      ...ObjectiveFields
    }
  }
`;

export const SUBSCRIBE_OBJECTIVES = gql`
  ${OBJECTIVE_FIELDS}
  subscription GetObjectives {
    objectives: objectives(order_by: { id: asc }) {
      ...ObjectiveFields
    }
  }
`;
