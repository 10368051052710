import React, { useState } from 'react';

import { Input } from '@mantine/core';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';

import { getRagStatusColor } from './helper';
import { CircleIcon, SearchItemBox, SidebarContainer } from './style';

export const Sidebar = ({ title, allItems, selectedItem }) => {
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState('');

  let filteredItems = allItems;

  if (searchValue) {
    filteredItems = allItems.filter((item) => item.name?.toLowerCase().includes(searchValue.toLowerCase()));
  }

  return (
    <SidebarContainer>
      <Input
        radius="md"
        placeholder={title ? `Search ${title}` : 'Search'}
        leftSection={<SearchIcon size={16} />}
        onChange={(event) => setSearchValue(event.currentTarget.value)}
        style={{ marginBottom: '10px' }}
      />

      {filteredItems.map((item) => {
        return (
          <SearchItemBox
            key={item.id}
            selected={selectedItem && item.id === selectedItem.id}
            onClick={() => {
              navigate(`/organisation/projects/${item.id}`);
            }}
            style={{ minHeight: '48px', padding: '40px 12px', display: 'flex', alignItems: 'center' }}>
            <CircleIcon style={{ marginRight: '5px', flexShrink: 0 }} color={getRagStatusColor(item.rag_status)} />
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '80%',
              }}>
              {item.name?.length > 20 ? `${item.name.substring(0, 40)}...` : item.name}
            </div>
          </SearchItemBox>
        );
      })}
    </SidebarContainer>
  );
};
