import React, { forwardRef, useImperativeHandle, useState, useMemo } from 'react';

import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { showNotification } from '@mantine/notifications';
import { Button, Card, CardContent, MenuItem } from '@mui/material';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import {
  AutoCompleteWrapper,
  Field,
  FormContext,
  renderSelectField,
  renderTextField,
  renderDatePicker,
} from 'components/Common/FormFieldsHooks';
import { sortBy } from 'lodash';
import { useForm } from 'react-hook-form';
import { BUTTONS } from 'utils/formConstants';

import { DELETE_RISK, INSERT_RISK, UPDATE_RISK } from '../../containers/Delivery/components/Risks/graphql';
import { GET_PROJECTS } from '../../containers/Organisation/Projects/graphql';
import { GET_FEATURES } from '../../containers/Product/Features/graphql';
import { GET_TEAMS } from '../../containers/Settings/GraphQL/teams';
import { GET_USERS } from '../../containers/Settings/GraphQL/users';
import {
  GET_PROGRAM_INCREMENTS_FOR_PROGRAMME,
  GET_SELECTED_PI,
} from '../../containers/Settings/ProgramIncrement/graphql';
import { GET_VALUE_STREAMS } from '../../containers/Settings/Streams/graphql';
import { GET_ALL_INITIATIVES } from '../../containers/ValueStream/Initiatives/graphql';
import { resetDrawerDetails, selectedProgrammeVar } from '../../reactiveVariables';
import { deleteUpdate, insertUpdate } from '../../utils/graphQLUtils';
import { sortValues } from '../../utils/helpers';

const RisksDetails = (props, ref) => {
  const { element: risk } = props;
  const { data: { programIncrements = [] } = {} } = useQuery(GET_PROGRAM_INCREMENTS_FOR_PROGRAMME);
  const { data: { selectedPI = [] } = {} } = useQuery(GET_SELECTED_PI);
  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);
  const { data: { users = [] } = {} } = useQuery(GET_USERS);
  const { data: { valueStreams = [] } = {} } = useQuery(GET_VALUE_STREAMS);
  const { data: { initiatives = [] } = {} } = useQuery(GET_ALL_INITIATIVES);
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES);
  const { data: { projects: strategic_themes = [] } = {} } = useQuery(GET_PROJECTS);

  const selectedProgramme = useReactiveVar(selectedProgrammeVar);

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);

  const [insertRisk] = useMutation(INSERT_RISK);
  const [updateRisk] = useMutation(UPDATE_RISK);
  const [deleteRisk] = useMutation(DELETE_RISK);

  const activeValueStreams = valueStreams.filter((stream) => stream.isActive === true);

  const associated_work = [...initiatives, ...features, ...strategic_themes];

  const {
    handleSubmit,
    control,
    watch,
    formState: { isDirty, errors },
  } = useForm({
    shouldUnregister: true,
    defaultValues: risk
      ? {
          ...risk,
          associated_work: risk.associated_work?.[0] || null,
        }
      : {
          name: '',
          programIncrement: selectedPI,
          state: 'open',
          associated_work: null,
        },
  });

  const modifiedRisk = watch();
  useImperativeHandle(ref, () => ({
    handleSave: () => {
      handleSubmit(onSubmit)();
    },
    handleDelete: () => {
      if (props.element && props.element.id) setOpenConfirmation(true);
    },
    handleClose: () => {
      props.canEdit && isDirty ? setOpenWarning(!openWarning) : resetDrawerDetails();
    },
  }));

  const handleDeleteConfirm = () => {
    deleteRisk({
      variables: { riskId: props.element.id },
      update: deleteUpdate('risk'),
    });
    resetDrawerDetails();
  };

  const onSubmit = (values) => {
    const formattedValues = {
      ...values,
      value_stream: values.value_stream ? values.value_stream.id : null,
      associated_work: values.associated_work ? [values.associated_work] : null,
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    risk?.id
      ? updateRisk({ variables: { risk: formattedValues, riskId: risk.id } }).then(() => {
          showNotification({
            title: 'Risk Updated',
            message: `Risk ${risk.id} was successfully updated`,
          });
          resetDrawerDetails();
        })
      : insertRisk({ variables: { risk: formattedValues }, update: insertUpdate('risk') }).then(() => {
          showNotification({
            title: 'Risk Created',
            message: `Risk was successfully created`,
          });
          resetDrawerDetails();
        });
  };

  const riskScore = () => {
    const scoreMap = { high: 3, medium: 2, low: 1 };
    const impact = scoreMap[modifiedRisk?.impact?.toLowerCase()] || 0;
    const probability = scoreMap[modifiedRisk?.probability?.toLowerCase()] || 0;
    const proximity = scoreMap[modifiedRisk?.proximity?.toLowerCase()] || 0;

    return impact * probability * proximity;
  };

  return (
    <Card elevation={0}>
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContext.Provider value={{ control, errors }}>
            <Field name="name" renderField={renderTextField} label="Name" required size={12} />
            <Field name="level" renderField={renderSelectField} label="Level" size={12}>
              <MenuItem key="Team" value="Team">
                Team
              </MenuItem>
              <MenuItem key="Programme" value="Programme">
                Programme
              </MenuItem>
              <MenuItem key="Value Stream" value="Value Stream">
                Value Stream
              </MenuItem>
              <MenuItem key="Organisation" value="Organisation">
                Organisation
              </MenuItem>
            </Field>

            <AutoCompleteWrapper
              errors={errors}
              idField="id"
              label="Value Stream"
              name="value_stream"
              multiple={false}
              control={control}
              changeFunc={(data) => {
                return data?.map((option) => ({ id: option.id }));
              }}
              options={sortBy(activeValueStreams, 'name').filter((stream) => stream.name)}
              size={12}
            />

            <Field name="programIncrement" required renderField={renderSelectField} label="Increment" size={12}>
              {programIncrements.map((element) => (
                <MenuItem key={element.id} value={element.id}>
                  {element.name}
                </MenuItem>
              ))}
            </Field>
            <Field name="state" renderField={renderSelectField} label="State" size={12}>
              <MenuItem key="open" value="open">
                Open
              </MenuItem>
              <MenuItem key="resolved" value="resolved">
                Resolved
              </MenuItem>
              <MenuItem key="owned" value="owned">
                Owned
              </MenuItem>
              <MenuItem key="accepted" value="accepted">
                Accepted
              </MenuItem>
              <MenuItem key="mitigated" value="mitigated">
                Mitigated
              </MenuItem>
              <MenuItem key="closed" value="closed">
                Closed
              </MenuItem>
            </Field>
            <Field
              name="description"
              multiline
              maxRows="100"
              minRows="3"
              renderField={renderTextField}
              label="Description"
              size={12}
            />
            <Field
              name="impact_detail"
              multiline
              maxRows="100"
              minRows="3"
              renderField={renderTextField}
              label="Impact Detail"
              size={12}
            />
            <Field
              name="mitigation"
              multiline
              maxRows="100"
              minRows="3"
              renderField={renderTextField}
              label="Mitigation"
              size={12}
            />
            <Field name="impact" renderField={renderSelectField} label="Impact" size={12}>
              <MenuItem key="High" value="High">
                High
              </MenuItem>
              <MenuItem key="Medium" value="Medium">
                Medium
              </MenuItem>
              <MenuItem key="Low" value="Low">
                Low
              </MenuItem>
            </Field>

            <Field name="probability" renderField={renderSelectField} label="Probability" size={12}>
              <MenuItem key="high" value="high">
                High
              </MenuItem>
              <MenuItem key="medium" value="medium">
                Medium
              </MenuItem>
              <MenuItem key="low" value="low">
                Low
              </MenuItem>
            </Field>

            <Field name="proximity" renderField={renderSelectField} label="Proximity" size={12}>
              <MenuItem key="high" value="high">
                High
              </MenuItem>
              <MenuItem key="medium" value="medium">
                Medium
              </MenuItem>
              <MenuItem key="low" value="low">
                Low
              </MenuItem>
            </Field>
            <Field
              name="name"
              renderField={renderTextField}
              label="Risk Score"
              disabled
              size={12}
              value={riskScore()}
            />

            <Field
              name="target_resolution_date"
              renderField={renderDatePicker}
              label="Target Resolution Date"
              size={6}
            />

            <Field name="team" renderField={renderSelectField} label="Team" size={12}>
              <MenuItem key="" value={null}>
                <em>None</em>
              </MenuItem>
              {sortValues(teams.filter((team) => team.programmes?.includes(selectedProgramme))).map((element) => (
                <MenuItem key={element.id} value={element.id}>
                  {element.name}
                </MenuItem>
              ))}
            </Field>
            <AutoCompleteWrapper
              errors={errors}
              idField="id"
              label="Owner"
              name="owner"
              multiple={false}
              control={control}
              options={sortBy(
                users.map((user) => `${user.firstName} ${user.lastName}`),
                'firstName',
              )}
              getOptionLabel={(option) => option.name || option}
            />
            <AutoCompleteWrapper
              errors={errors}
              idField="id"
              label="Associated Work"
              name="associated_work"
              multiple={false}
              control={control}
              options={sortBy(
                associated_work.map((work) => ({
                  id: work.id,
                  name: work.name,
                  type: work.__typename,
                })),
                'name',
              )}
              changeFunc={(data) => data}
              getOptionLabel={(option) =>
                option.type === 'features'
                  ? `F${option.id}: ${option.name}`
                  : option.type === 'strategic_themes'
                  ? `P${option.id}: ${option.name}`
                  : `I${option.id}: ${option.name}`
              }
            />
          </FormContext.Provider>
        </form>
      </CardContent>
      <ConfirmationDialog
        open={openConfirmation}
        title={`Delete risk: ${risk?.name}`}
        text="Are you sure you want to delete this risk ?"
        handleOk={handleDeleteConfirm}
        handleCancel={() => setOpenConfirmation(false)}
      />
      <ConfirmationDialog
        open={openWarning}
        title="Unsaved changes"
        text="You have unsaved changes. What would you like to do?">
        <Button
          onClick={() => {
            setOpenWarning(!openWarning);
            handleSubmit(onSubmit)();
          }}
          color="primary">
          {BUTTONS.SAVE}
        </Button>
        <Button onClick={() => resetDrawerDetails()} color="primary">
          {BUTTONS.DISCARD}
        </Button>
        <Button onClick={() => setOpenWarning(!openWarning)} color="primary">
          {BUTTONS.CANCEL}
        </Button>
      </ConfirmationDialog>
    </Card>
  );
};

export default forwardRef(RisksDetails);
