import React, { useState } from 'react';

import { Box, Menu, Button, Checkbox } from '@mantine/core';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Download from '@mui/icons-material/GetApp';
import { IconButton } from '@mui/material';
import { IconSettings } from '@tabler/icons-react';
import Search from 'components/Common/BigAgileTable/TableToolBar/Search';
import ButtonIcon from 'components/Common/Button/ButtonIcon';
import Select from 'components/Common/Select';

import { lightColors } from '../../../shared/styles/color';

export const DataTableToolbar = ({
  addButtonUrl,
  stateOptions,
  setSelectState,
  setSearchQuery,
  exportData,
  staticColumns,
  tempVisibleColumns,
  toggleTempColumn,
  setVisibleColumns,
}) => {
  const [menuOpened, setMenuOpened] = useState(false);

  const handleMenuClose = () => {
    setVisibleColumns(tempVisibleColumns);
    setMenuOpened(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 8,
        marginBottom: 4,
      }}>
      <div>
        {addButtonUrl && (
          <IconButton
            key="add"
            aria-label="Add"
            color="inherit"
            onClick={addButtonUrl}
            size="small"
            style={{ marginRight: '6px', marginBottom: '20px' }}>
            <AddBoxIcon />
          </IconButton>
        )}
        <Menu
          shadow="md"
          width={250}
          opened={menuOpened}
          onClose={handleMenuClose}
          closeOnClickOutside
          closeOnEscape
          withinPortal
          transition="pop"
          mb={20}
          position="bottom-start"
          withArrow>
          <Menu.Target>
            <Button
              leftIcon={<IconSettings size={16} />}
              onClick={() => setMenuOpened((o) => !o)}
              style={{
                backgroundColor: 'white',
                border: `1px solid ${lightColors.tableBorder}`,
                color: 'black',
                fontWeight: 'normal',
                height: '41px',
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                },
              }}>
              Toggle Columns
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Box p="xs">
              {staticColumns.map((col) => (
                <Checkbox
                  key={col.accessor}
                  label={col.title}
                  checked={tempVisibleColumns[col.accessor]}
                  onChange={() => toggleTempColumn(col.accessor)}
                  mb="xs"
                />
              ))}
            </Box>
          </Menu.Dropdown>
        </Menu>

        {stateOptions && (
          <Select
            rootStyle={{ marginLeft: '10px', position: 'relative', top: '-7px' }}
            title="State"
            onChange={(event, values) => setSelectState(values)}
            defaultValue={stateOptions.map((state) => state.value)}
            placeholder="Select a state..."
            multiple={true}
            options={stateOptions}
            border={false}
          />
        )}
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginTop: '-18px',
          gap: '14px',
        }}>
        <Search handleChange={(e) => setSearchQuery(e.target.value)} />
        {exportData && <ButtonIcon icon={Download} handleClick={exportData} />}
      </div>
    </div>
  );
};
