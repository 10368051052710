import * as React from 'react';

const SvgPriorityNo = (props) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.91576 4C9.32288 4 8.8421 4.4511 8.8421 5.00752V6.99248C8.8421 7.5489 9.32288 8 9.91576 8H10.9263C11.5192 8 12 7.5489 12 6.99248V5.00752C12 4.4511 11.5192 4 10.9263 4H9.91576ZM4.42105 5.00752C4.42105 4.4511 4.90183 4 5.49471 4H6.50529C7.09817 4 7.57895 4.4511 7.57895 5.00752V6.99248C7.57895 7.5489 7.09817 8 6.50529 8H5.49471C4.90183 8 4.42105 7.5489 4.42105 6.99248V5.00752ZM0 5.00752C0 4.4511 0.480777 4 1.07365 4H2.08424C2.67712 4 3.15789 4.4511 3.15789 5.00752V6.99248C3.15789 7.5489 2.67712 8 2.08424 8H1.07365C0.480777 8 0 7.5489 0 6.99248V5.00752Z"
      fill="#808080"
    />
  </svg>
);

export default SvgPriorityNo;
