import React from 'react';

import { useTheme } from '@emotion/react';
import { Grid, Text } from '@mantine/core';
import { isNumber } from 'lodash';
import { DateTime } from 'luxon';

import { Metric, MetricPaper, MilestonesHealth, TopInitiatives, UpcomingMilestones } from './MetricPaper';
import SvgCycle from '../../../assets/images/Cycle';
import SvgDecrease from '../../../assets/images/Decrease';
import SvgIncrease from '../../../assets/images/Increase';
import SvgRocket from '../../../assets/images/Rocket';
import Tooltip from '../../../components/Common/Tooltip';

const ValueStreamMetrics = ({
  milestones,
  initiatives,
  predictability,
  previousPredictability,
  pageTitle = 'Value Stream Dashboard',
}) => {
  const theme = useTheme();

  const onTrackInitiatives = initiatives.filter((initiative) => initiative.isDone || initiative.ragStatus === 'green');
  const upcomingMilestones = milestones
    .filter((milestone) => DateTime.fromISO(milestone.date) > DateTime.now())
    .slice(0, 4);

  return (
    <div style={{ marginBottom: '24px' }}>
      <Text size="lg" fw={700} mb={16}>
        {pageTitle}
      </Text>
      <Grid>
        <Grid.Col span={3} p={0}>
          <MetricPaper
            title="Initiatives"
            xs={12}
            sx={{ padding: '8px' }}
            icon={<SvgRocket color={theme.palette.color.buttonIcon} />}>
            <Metric value={onTrackInitiatives.length} name="On Track" style={{ backgroundColor: '#FFFFFF30' }} />
            <Metric
              value={initiatives.length - onTrackInitiatives.length}
              name="At Risk"
              color={theme.palette.color.red}
              style={{ backgroundColor: 'blue' }}
            />
          </MetricPaper>
          <MetricPaper
            title="Predictability"
            xs={12}
            sx={{ padding: '8px' }}
            icon={<SvgCycle color={theme.palette.color.buttonIcon} />}>
            <Tooltip
              content={`Predictability for previous period:
              ${isNumber(previousPredictability) ? `${previousPredictability.toFixed()}%` : previousPredictability}`}>
              <div>
                {previousPredictability > predictability ? (
                  <SvgDecrease height={30} width={30} />
                ) : (
                  <SvgIncrease height={30} width={30} />
                )}
              </div>
            </Tooltip>
            <Text size="xl" fz={30} fw={700} style={{ marginLeft: '16px' }}>
              {isNumber(predictability) ? `${predictability.toFixed()}%` : predictability}
            </Text>
          </MetricPaper>
        </Grid.Col>
        <MilestonesHealth title="Events Health" milestones={milestones} initiatives={initiatives} />
        <UpcomingMilestones title="Upcoming Events" milestones={upcomingMilestones} />
        <TopInitiatives
          title="Top 4 Initiatives"
          initiatives={initiatives.filter((initiative) => initiative.startDate && initiative.endDate)}
        />
      </Grid>
    </div>
  );
};

export default ValueStreamMetrics;
