import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { useTheme } from '@emotion/react';
import { Box, Collapse, Text, Grid } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem, Divider, Avatar, Tooltip } from '@mui/material';
import { sortBy } from 'lodash';

import ConfirmationDialog from '../../../components/Common/ConfirmationDialog';
import { Field, renderInput } from '../../../components/Common/FormFieldsHooks/MantineField';
import ModalContainer from '../../../components/Modal';
import { UPDATE_FEATURE_MANY } from '../../../containers/Product/Features/graphql';
import LinearWithValueLabel from '../../../containers/ValueStream/components/OKRTable/LinearWithValueLabel';
import { UPDATE_INITIATIVE } from '../../../containers/ValueStream/Initiatives/graphql';
import { openDrawer } from '../../../reactiveVariables';
import { color } from '../../../shared/styles/color';
import { ContentInner } from '../style';

const InitiativeRow = ({
  initiative,
  handleExpandClick,
  expandedInitiatives,
  handleWikiLinkClick,
  handleMenuClick,
  handleCopyLink,
  handleCloseMenu,
  handleDeleteLink,
  anchorEl,
  selectedInitiativeId,
  onRowClick,
  theme,
}) => {
  const progress = initiative?.features?.aggregate?.avg?.percentageDone || 0;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '8px 16px',
        backgroundColor: theme.palette.mode === 'dark' ? '#191B1F' : '#fff',
        position: 'relative',
      }}>
      {/* Initiative Name */}
      <div style={{ width: '40%', display: 'flex' }}>
        {initiative.features?.nodes?.length > 0 && (
          <ExpandMoreIcon
            style={{
              cursor: 'pointer',
              marginRight: '8px',
              width: '20px',
              height: '20px',
              transform: expandedInitiatives[initiative.id] ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s ease',
            }}
            onClick={() => handleExpandClick(initiative.id)}
          />
        )}
        <div style={{ fontSize: '14px', cursor: 'pointer' }} onClick={() => onRowClick(initiative.id, 'initiative')}>
          {initiative.name}
        </div>
      </div>

      {/* Owner */}
      <div style={{ width: '8%', fontSize: '12px', color: color.secondary }}>
        {initiative.owner && (
          <Tooltip withArrow placement="top" title={initiative.owner}>
            <Avatar
              sx={{
                width: 20,
                height: 20,
                fontSize: 10,
                fontWeight: 'bold',
                backgroundColor: '#d156a1',
                marginLeft: '8px',
              }}>
              {initiative.owner
                .split(' ')
                .map((n) => n[0])
                .join('')}
            </Avatar>
          </Tooltip>
        )}
      </div>

      {/* State */}
      <div style={{ width: '12%', fontSize: '12px', color: color.secondary }}>{initiative.initiative_state}</div>

      {/* Progress */}
      <div style={{ width: '18%', fontSize: '12px', color: color.secondary, marginRight: '14px' }}>
        <LinearWithValueLabel data-value={progress} value={progress.toFixed()} hideLabel />
      </div>

      {/* Doc */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: 'calc(20% - 8px)',
          fontSize: '12px',
          color: color.secondary,
          cursor: initiative.wikiLink ? 'inherit' : 'pointer',
        }}
        onClick={
          !initiative.wikiLink ? () => handleWikiLinkClick('initiative', initiative.id, initiative.wikiLink) : null
        }>
        {initiative.wikiLink ? (
          <a
            href={
              initiative.wikiLink.startsWith('http://') || initiative.wikiLink.startsWith('https://')
                ? initiative.wikiLink
                : `https://${initiative.wikiLink}`
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
              color: color.secondary,
              cursor: 'pointer',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            onClick={(e) => e.stopPropagation()}>
            {initiative.wikiLink}
          </a>
        ) : (
          'Set link'
        )}
        {initiative.wikiLink && (
          <MoreVertIcon
            fontSize="small"
            style={{ marginLeft: '8px', cursor: 'pointer' }}
            onClick={(e) => handleMenuClick(e, 'initiative', initiative.id, initiative.wikiLink)}
          />
        )}
      </div>

      {/* Menu */}
      {anchorEl && selectedInitiativeId === initiative.id && (
        <ReusableMenu
          anchorEl={anchorEl}
          handleCloseMenu={handleCloseMenu}
          handleCopyLink={handleCopyLink}
          handleWikiLinkClick={handleWikiLinkClick}
          handleDeleteLink={handleDeleteLink}
          id={initiative.id}
          link={initiative.wikiLink}
          type={'initiative'}
        />
      )}
    </div>
  );
};

const FeatureRow = ({
  feature,
  handleWikiLinkClick,
  handleMenuClick,
  selectedFeatureId,
  anchorEl,
  handleCloseMenu,
  handleCopyLink,
  handleDeleteLink,
  onRowClick,
  independantFeature = false,
  theme,
}) => {
  const progress = feature?.percentageDone || 0;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '8px 16px',
        backgroundColor: theme.palette.mode === 'dark' ? '#191B1F' : '#fff',
      }}>
      <div
        style={{
          fontSize: '14px',
          width: independantFeature ? 'calc(40% + 8px)' : '40%',
          paddingLeft: independantFeature ? '0px' : '38px',
          marginRight: independantFeature ? '0px' : '6px',
          cursor: 'pointer',
        }}
        onClick={() => {
          onRowClick(feature.id, 'feature');
        }}>
        {feature.name}
      </div>

      <div style={{ width: 'calc(8% - 6px)', fontSize: '12px', color: color.secondary, paddingLeft: '2px' }}>
        {feature.assignedTo && (
          <Tooltip withArrow placement="top" title={feature.assignedTo}>
            <Avatar sx={{ width: 20, height: 20, fontSize: 10, fontWeight: 'bold', backgroundColor: '#d156a1' }}>
              {feature.assignedTo
                .split(' ')
                .map((n) => n[0])
                .join('')}
            </Avatar>
          </Tooltip>
        )}
      </div>

      <div style={{ width: 'calc(12% - -1px)', fontSize: '12px', color: color.secondary }}>{feature.status}</div>

      <div style={{ fontSize: '12px', color: color.secondary, width: '18%' }}>
        <LinearWithValueLabel data-value={progress} value={progress.toFixed()} hideLabel />
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: 'calc(20% + 5px)',
          fontSize: '12px',
          paddingLeft: '14px',
          color: color.secondary,
          cursor: feature.confluenceLink ? 'inherit' : 'pointer',
        }}
        onClick={
          !feature.confluenceLink ? () => handleWikiLinkClick('feature', feature.id, feature.confluenceLink) : null
        }>
        {feature.confluenceLink ? (
          <a
            href={
              feature.confluenceLink.startsWith('http://') || feature.confluenceLink.startsWith('https://')
                ? feature.confluenceLink
                : `https://${feature.confluenceLink}`
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
              color: color.secondary,
              cursor: 'pointer',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            onClick={(e) => e.stopPropagation()}>
            {feature.confluenceLink}
          </a>
        ) : (
          'Set link'
        )}
        {feature.confluenceLink && (
          <MoreVertIcon
            fontSize="small"
            style={{ cursor: 'pointer' }}
            onClick={(e) => handleMenuClick(e, 'feature', feature.id, feature.confluenceLink)}
          />
        )}
      </div>

      {anchorEl && selectedFeatureId === feature.id && (
        <ReusableMenu
          anchorEl={anchorEl}
          handleCloseMenu={handleCloseMenu}
          handleCopyLink={handleCopyLink}
          handleWikiLinkClick={handleWikiLinkClick}
          handleDeleteLink={handleDeleteLink}
          id={feature.id}
          link={feature.confluenceLink}
          type={'feature'}
        />
      )}
    </div>
  );
};

const ReusableMenu = ({
  anchorEl,
  handleCloseMenu,
  handleCopyLink,
  handleWikiLinkClick,
  handleDeleteLink,
  id,
  link,
  type,
}) => (
  <div>
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
      styles={{ backgroundColor: 'white' }}
      slotProps={{
        paper: {
          sx: {
            width: '100px',
            borderRadius: '15px',
            marginTop: '10px',
          },
        },
      }}>
      <MenuItem
        sx={{ fontSize: 14 }}
        onClick={() => {
          handleCopyLink();
          handleCloseMenu();
        }}>
        Copy link
      </MenuItem>
      <MenuItem
        sx={{ fontSize: 14 }}
        onClick={() => {
          handleWikiLinkClick(type, id, link);
          handleCloseMenu();
        }}>
        Edit
      </MenuItem>
      <Divider />
      <MenuItem
        sx={{ fontSize: 14, color: 'red' }}
        onClick={() => {
          handleDeleteLink();
          handleCloseMenu();
        }}>
        Delete
      </MenuItem>
    </Menu>
  </div>
);

export const WorkTab = ({ initiatives, selectedItem, showAddInitiativeModal, showAddFeaturesModal, features }) => {
  const theme = useTheme();

  const [expandedInitiatives, setExpandedInitiatives] = useState({});
  const [showWikiModal, setShowWikiModal] = useState(false);
  const [selectedInitiativeId, setSelectedInitiativeId] = useState(null);
  const [selectedFeatureId, setSelectedFeatureId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentLink, setCurrentLink] = useState('');
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const [updateInitiative] = useMutation(UPDATE_INITIATIVE);
  const [updateFeature] = useMutation(UPDATE_FEATURE_MANY);

  const selectedItemsInitiatives = initiatives.filter((init) => init.strategic_theme === selectedItem.id);
  const selectedItemsFeatures = features.filter((feat) => feat.project === selectedItem.id);

  const [formState, setFormState] = useState({ wiki: '' });

  const onRowClick = (id, type) => {
    if (type === 'initiative') {
      const initiative = initiatives.find((initiative) => initiative?.id === id);
      openDrawer(initiative, 'initiative');
    }
    if (type === 'feature') {
      const feature = features.find((feature) => feature?.id === id);
      openDrawer(feature, 'feature');
    }
  };

  const onWikiSubmit = () => {
    if (selectedInitiativeId) {
      updateInitiative({
        variables: {
          initiativeId: selectedInitiativeId,
          initiative: { wikiLink: formState.wiki },
        },
      }).then(() => setShowWikiModal(false));
    } else if (selectedFeatureId) {
      updateFeature({
        variables: {
          updates: {
            where: { id: { _eq: selectedFeatureId } },
            _set: { confluenceLink: formState.wiki },
          },
        },
      }).then(() => setShowWikiModal(false));
    }

    setSelectedFeatureId(null);
    setSelectedInitiativeId(null);
  };

  const handleExpandClick = (initiativeId) => {
    setExpandedInitiatives((prevState) => ({
      ...prevState,
      [initiativeId]: !prevState[initiativeId],
    }));
  };

  const handleWikiLinkClick = (type, id, wikiLink) => {
    if (type === 'feature') {
      setSelectedFeatureId(id);
      setSelectedInitiativeId(null);
    } else {
      setSelectedInitiativeId(id);
      setSelectedFeatureId(null);
    }
    setFormState((prevState) => ({ ...prevState, wiki: wikiLink || '' }));
    setShowWikiModal(true);
  };

  const handleMenuClick = (event, type, id, link) => {
    if (anchorEl && anchorEl.id === id) {
      setAnchorEl(null);
    } else {
      if (type === 'feature') {
        setSelectedFeatureId(id);
        setSelectedInitiativeId(null);
      } else {
        setSelectedInitiativeId(id);
        setSelectedFeatureId(null);
      }
      setAnchorEl(event.currentTarget);
      setCurrentLink(link);
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(currentLink);
    handleCloseMenu();

    showNotification({
      title: 'Link copied!',
    });
  };

  const handleDeleteLink = () => {
    setOpenConfirmation(true);
    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDeleteConfirm = () => {
    if (selectedInitiativeId) {
      updateInitiative({
        variables: {
          initiativeId: selectedInitiativeId,
          initiative: { wikiLink: null },
        },
      }).then(() => setOpenConfirmation(false));
    } else if (selectedFeatureId) {
      updateFeature({
        variables: {
          featureId: selectedFeatureId,
          feature: {
            confluenceLink: null,
          },
        },
      }).then(() => setOpenConfirmation(false));

      showNotification({
        title: 'Wiki deleted!',
      });

      setSelectedFeatureId(null);
      setSelectedInitiativeId(null);
    }
  };

  return (
    <div style={{ paddingBottom: '10px' }}>
      <ContentInner style={{ margin: '18px 18px' }}>
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <Text fw={550}>Initiatives ({selectedItemsInitiatives.length})</Text>
          <AddIcon style={{ width: '20px', cursor: 'pointer' }} onClick={() => showAddInitiativeModal(true)} />
        </Box>
      </ContentInner>

      {selectedItemsInitiatives.length > 0 && (
        <div style={{ overflow: 'hidden' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '8px 16px',
              backgroundColor: theme.palette.mode === 'dark' ? '#242424' : color.grey,
              fontSize: '14px',
            }}>
            <div style={{ width: '40%' }}>Initiative</div>
            <div style={{ width: '8%', fontSize: '12px' }}>Owner</div>
            <div style={{ width: '12%', fontSize: '12px' }}>State</div>
            <div style={{ width: '20%', fontSize: '12px' }}>Progress</div>
            <div style={{ width: '20%', fontSize: '12px' }}>Doc</div>
          </div>

          {sortBy(selectedItemsInitiatives, 'name').map((initiative) => (
            <React.Fragment key={initiative.id}>
              <InitiativeRow
                initiative={initiative}
                handleExpandClick={handleExpandClick}
                expandedInitiatives={expandedInitiatives}
                handleWikiLinkClick={handleWikiLinkClick}
                handleMenuClick={handleMenuClick}
                handleDeleteLink={handleDeleteLink}
                handleCopyLink={handleCopyLink}
                selectedInitiativeId={selectedInitiativeId}
                anchorEl={anchorEl}
                handleCloseMenu={handleCloseMenu}
                onRowClick={onRowClick}
                theme={theme}
              />

              <Collapse in={expandedInitiatives[initiative.id]}>
                {sortBy(initiative.features.nodes, 'name').map((feature) => (
                  <FeatureRow
                    key={feature.id}
                    feature={feature}
                    handleMenuClick={handleMenuClick}
                    handleWikiLinkClick={handleWikiLinkClick}
                    handleDeleteLink={handleDeleteLink}
                    handleCopyLink={handleCopyLink}
                    selectedFeatureId={selectedFeatureId}
                    anchorEl={anchorEl}
                    handleCloseMenu={handleCloseMenu}
                    onRowClick={onRowClick}
                    theme={theme}
                  />
                ))}
              </Collapse>
            </React.Fragment>
          ))}
        </div>
      )}

      <ContentInner style={{ margin: '18px 18px' }}>
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <Text fw={550}>Independent features ({selectedItemsFeatures.length})</Text>
          <AddIcon style={{ width: '20px', cursor: 'pointer' }} onClick={() => showAddFeaturesModal(true)} />
        </Box>
      </ContentInner>

      {selectedItemsFeatures.length > 0 && (
        <div style={{ overflow: 'hidden' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '8px 16px',
              backgroundColor: theme.palette.mode === 'dark' ? '#242424' : color.grey,
              fontSize: '14px',
            }}>
            <div style={{ width: '40%' }}>Feature</div>
            <div style={{ width: '8%', fontSize: '12px' }}>Owner</div>
            <div style={{ width: '12%', fontSize: '12px' }}>State</div>
            <div style={{ width: '20%', fontSize: '12px' }}>Progress</div>
            <div style={{ width: '20%', fontSize: '12px' }}>Doc</div>
          </div>

          {sortBy(selectedItemsFeatures, 'name').map((feature) => (
            <FeatureRow
              key={feature.id}
              feature={feature}
              handleMenuClick={handleMenuClick}
              handleWikiLinkClick={handleWikiLinkClick}
              handleDeleteLink={handleDeleteLink}
              handleCopyLink={handleCopyLink}
              selectedFeatureId={selectedFeatureId}
              anchorEl={anchorEl}
              handleCloseMenu={handleCloseMenu}
              onRowClick={onRowClick}
              independantFeature
              theme={theme}
            />
          ))}
        </div>
      )}

      <ModalContainer
        open={showWikiModal}
        title="Add wiki link"
        primaryButtonText="Add wiki"
        onSubmit={onWikiSubmit}
        close={() => setShowWikiModal(false)}>
        <Grid>
          <Field
            name="wiki"
            value={formState.wiki}
            onChange={(e) => setFormState({ ...formState, wiki: e.target.value })}
            renderField={renderInput}
            placeholder="https://..."
            label="Wiki"
          />
        </Grid>
      </ModalContainer>

      <ConfirmationDialog
        open={openConfirmation}
        title={`Delete wiki`}
        text={`Are you sure you want to delete - ${currentLink} `}
        handleOk={handleDeleteConfirm}
        handleCancel={() => setOpenConfirmation(false)}
      />
    </div>
  );
};
