import React from 'react';

import { Route } from 'react-router-dom';

import DeliverySummary from './components/DeliverySummary';
import DetailedView from './components/DetailedView';
import BigAgileTable from '../../components/Common/BigAgileTable';
import Reports from '../Reports';
import DeliveryDashboard from './components/Dashboard';
import { RiskTable } from './components/Risks/ValueStreamRisk';

const getDeliveryRoutes = (user) => (
  <>
    <Route user={user} index element={<DeliveryDashboard />} />
    <Route user={user} path="/delivery/summary" element={<DeliverySummary />} />
    <Route user={user} path="/delivery/detailed" element={<DetailedView />} />
    <Route user={user} path="/delivery/dependencies" element={<BigAgileTable type="dependency" />} />
    <Route user={user} path="/delivery/objectives" element={<BigAgileTable type="objective" />} />
    <Route user={user} path="/delivery/roam" element={<BigAgileTable type="risk" />} />
    <Route user={user} path="/valueStream/risks" element={<RiskTable />} />
    <Route user={user} path="/delivery/metrics" element={<Reports />} />
  </>
);

export default getDeliveryRoutes;
