import {
  ActionIcon,
  Checkbox,
  Divider,
  Group,
  Menu,
  Paper,
  rem,
  ScrollArea,
  Stack,
  Text,
  TextInput,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useQuery } from '@apollo/client';
import { GET_FEATURES } from '../../../Product/Features/graphql';
import { GET_INITIATIVES } from '../../../ValueStream/Initiatives/graphql';
import { sortBy, uniq, uniqBy } from 'lodash';
import { Display2 } from '../../../ValueStream/InitiativesDashboard/InitiativeDashboard.style';
import React, { useMemo, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';

const ThirdPage = ({ keyResult, setValue }) => {
  return (
    <>
      <Output keyResult={keyResult} setValue={setValue} />
    </>
  );
};

const Output = ({ keyResult, setValue }) => {
  const theme = useMantineTheme();
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES);
  const { data: { initiatives = [] } = {} } = useQuery(GET_INITIATIVES);

  const [search, setSearch] = useState('');

  const keyResultFeatures = useMemo(
    () => features.filter((feature) => keyResult.features.includes(feature.id)),
    [features, keyResult.features],
  );
  const keyResultInitiatives = useMemo(
    () => initiatives.filter((initiative) => keyResult.initiatives.includes(initiative.id)),
    [initiatives, keyResult.initiatives],
  );

  const elements = [...features, ...initiatives];
  const keyResultElements = sortBy([...keyResultInitiatives, ...keyResultFeatures], 'name');

  const data = search
    ? elements.filter((row) =>
        Object.values(row).some((fieldValue) => fieldValue?.toString().toLowerCase().includes(search.toLowerCase())),
      )
    : elements;

  const updateElement = (checked, element) => {
    if (element.__typename === 'features') {
      setValue(
        'features',
        checked ? [...keyResult.features, element.id] : keyResult.features.filter((f) => f !== element.id),
      );
    } else {
      setValue(
        'initiatives',
        checked ? [...keyResult.initiatives, element.id] : keyResult.initiatives.filter((i) => i !== element.id),
      );
    }
  };

  const isChecked = (element) => {
    return element.__typename === 'features'
      ? keyResult.features.includes(element.id)
      : keyResult.initiatives.includes(element.id);
  };

  return (
    <>
      <Stack gap={1}>
        <Title order={6}>Search for initiatives and features</Title>
        <Text mb={8} c="dimmed">
          Initiatives: {keyResult.initiatives.length} Features: {keyResult.features.length}
        </Text>
        <TextInput
          radius={8}
          leftSectionPointerEvents="none"
          leftSection={<SearchIcon />}
          placeholder="Search for initiatives and features"
          onChange={(event) => setSearch(event.currentTarget.value)}
        />
      </Stack>
      <Divider mt={12} mb={12} />
      <ScrollArea h="45vh">
        {(search
          ? sortBy(data.slice(0, 20), 'name')
          : uniqBy(
              [...keyResultElements, ...sortBy(data.slice(0, 20), 'name')],
              (element) => element.id + element.__typename,
            )
        ).map((element) => (
          <Paper
            withBorder
            p={12}
            radius={8}
            mt={8}
            key={element.id}
            style={{ boxShadow: '0px 2px 2px 0px #0000000D' }}>
            <Group preventGrowOverflow justify="space-between" wrap="nowrap">
              <Group preventGrowOverflow wrap="nowrap">
                <Text c="dimmed">#{element.id}</Text>
                <Text>{element.name}</Text>
              </Group>
              <Group preventGrowOverflow wrap="nowrap">
                <Display2
                  variant="light"
                  bgColor={
                    element.__typename === 'features'
                      ? element.enabler
                        ? '#F6C544'
                        : theme.other.colours.done
                      : theme.other.colours.green
                  }>
                  {element.__typename === 'features' ? (element.enabler ? 'Enabler' : 'Feature') : 'Initiative'}
                </Display2>
                <Checkbox
                  checked={isChecked(element)}
                  onChange={(event) => updateElement(event.currentTarget.checked, element)}
                />
              </Group>
            </Group>
          </Paper>
        ))}
      </ScrollArea>
    </>
  );
};
export default ThirdPage;
