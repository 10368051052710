import React from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import {
  Alert,
  Badge,
  Dialog,
  Divider,
  Group,
  Paper,
  rgba,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { values } from 'lodash';

import SvgHexagone from '../../../assets/images/Hexagon';
import SvgWarning from '../../../assets/images/Warning';
import { GET_TEAMS } from '../../../containers/Settings/GraphQL/teams';
import { GET_PROGRAM_INCREMENTS } from '../../../containers/Settings/ProgramIncrement/graphql';
import LinearWithValueLabel from '../../../containers/ValueStream/components/OKRTable/LinearWithValueLabel';
import { closeSnackbar, selectedPiVar, snackbarContent } from '../../../reactiveVariables';
import { getStatusColorMantine } from '../../../utils/piColors';
export default function InfoDialog() {
  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);
  const { data: { programIncrements = [] } = {} } = useQuery(GET_PROGRAM_INCREMENTS);

  const content = useReactiveVar(snackbarContent);
  const theme = useMantineTheme();
  const color = getStatusColorMantine(content.element || {}, theme);
  const selectedPi = useReactiveVar(selectedPiVar);

  useHotkeys([['escape', () => closeSnackbar()]]);

  const truncateString = (str) => {
    return str.length > 160 ? str.slice(0, 160) + '...' : str;
  };

  const getPiName = () => programIncrements.find((pi) => pi.id === content?.element?.programIncrement)?.name;

  return (
    <Dialog
      withinPortal={false}
      style={{ borderWidth: '2px', borderColor: color }}
      zIndex={3000}
      withBorder
      position={{ bottom: 20, left: 20 }}
      opened={content.open}
      size="lg"
      radius="md">
      <Text size="sm" mb="xs" fw={500} pr={8} pl={8}>
        {content.element?.name}
      </Text>

      {content.element?.commitment && (
        <Text size="xs" mb="xs" lh={0} fw={300} pr={8} pl={8} pb={content.element?.description ? 10 : 0}>
          {content.element.commitment === 'Committed' ? (
            <Tooltip withArrow placement="top" title={'Committed'}>
              <TaskAltIcon style={{ width: '16px', position: 'relative', top: '-1px', marginRight: '5px' }} />
            </Tooltip>
          ) : (
            <Tooltip withArrow placement="top" title={'Uncommitted'}>
              <RadioButtonUncheckedIcon
                style={{ width: '16px', position: 'relative', top: '-1px', marginRight: '5px' }}
              />
            </Tooltip>
          )}

          {content.element?.commitment}
        </Text>
      )}

      {content.element?.commitment && content.element?.description && (
        <Text size="xs" mb="xs" fw={300} pr={8} pl={8} pb={0} mt={-10}>
          {truncateString(content.element.description)}
        </Text>
      )}

      {content.category === 'dependency' && content.element?.owner && (
        <Text size="xs" mb="xs" lh={0} fw={300} pr={8} pl={8} pb={0}>
          Owner: {content.element?.owner}
        </Text>
      )}
      {content.category === 'feature' && content.element?.epicId && (
        <Group justify="space-between" grow mb="xs">
          <Text size="sm" fw={600} pr={8} pl={8}>
            {content.element?.epicId}
          </Text>
          <LinearWithValueLabel color={color} value={content.element?.metrics?.percentageDoneStories || 0} />
        </Group>
      )}
      {content.element?.errors &&
        values(content.element.errors).map((error, index, array) => (
          <Alert
            icon={<SvgWarning />}
            color={theme.other.colours.red}
            p={4}
            mb={index === array.length - 1 ? 'xs' : 4}
            title={error}
            key={index}
          />
        ))}
      {content.element?.programIncrement !== selectedPi && content.element?.completedIncrements?.[selectedPi] && (
        <Alert
          icon={<SvgWarning />}
          color={theme.other.colours.slateGrey}
          p={4}
          mb={'xs'}
          title={`Feature deferred to Increment ${getPiName()}`}
          key="deferred"
        />
      )}
      {!!content.element?.dependencies?.length && (
        <Paper p="xs" mb={4} withBorder shadow="none">
          <Group m={4}>
            <Badge radius="5px" p={4} color="grey">
              {content.element?.dependencies.length}
            </Badge>
            <Text>Dependencies</Text>
          </Group>
          <Divider ml={4} mr={4} mt={8} mb={8} />
          {content.element?.dependencies.map((dep, index) => (
            <Group preventGrowOverflow={false} wrap="nowrap" m={4} key={index}>
              <ThemeIcon
                variant="outline"
                style={{ backgroundColor: rgba(getStatusColorMantine(dep, theme), 0.15) }}
                color={getStatusColorMantine(dep, theme)}>
                <SvgHexagone />
              </ThemeIcon>
              <Stack gap={0}>
                <Text lineClamp={1}>{dep.name}</Text>
                <Text c="dimmed">
                  Owned: {(teams.find((team) => team.id === dep.teamId) || {}).name} {dep.owner && `(${dep.owner})`}
                </Text>
              </Stack>
            </Group>
          ))}
        </Paper>
      )}
      {content.element?.metrics?.teams?.length > 1 && (
        <Paper p="xs" mb={4} withBorder shadow="none">
          <Group m={4}>
            <Badge radius="5px" p={4} color="grey">
              {content.element?.metrics?.teams?.length}
            </Badge>
            <Text>Teams</Text>
          </Group>
          <Divider ml={4} mr={4} mt={8} mb={8} />
          {content.element?.metrics?.teams.map((teamData, index) => (
            <Group preventGrowOverflow={false} wrap="nowrap" m={4} key={index}>
              <ThemeIcon
                variant="outline"
                style={{ backgroundColor: rgba(theme.other.colours.purplePink, 0.15) }}
                color={theme.other.colours.purplePink}>
                <SvgHexagone />
              </ThemeIcon>
              <Stack gap={0}>
                <Text lineClamp={1}>{(teams.find((team) => team.id == teamData.teamId) || {}).name}</Text>
                <Text c="dimmed">{teamData.issuesCount} Stories Planned</Text>
              </Stack>
            </Group>
          ))}
        </Paper>
      )}
      {!!content.element?.targetFeatures?.length && (
        <Paper p="xs" mb={4} withBorder shadow="none">
          <Group m={4}>
            <Badge radius="5px" p={4} color="grey">
              {content.element?.targetFeatures.length}
            </Badge>
            <Text>Target Features</Text>
          </Group>
          <Divider ml={4} mr={4} mt={8} mb={8} />
          {content.element?.targetFeatures.map((feature, index) => (
            <Group preventGrowOverflow={false} wrap="nowrap" m={4} key={index}>
              <ThemeIcon
                variant="outline"
                style={{ backgroundColor: rgba(getStatusColorMantine(feature?.feature || {}, theme), 0.15) }}
                color={getStatusColorMantine(feature?.feature, theme)}>
                <SvgHexagone />
              </ThemeIcon>
              <Stack gap={0}>
                <Text lineClamp={1}>{feature.feature?.name}</Text>
                <Text c="dimmed">Owned: {(teams.find((team) => team.id === feature.feature?.teamId) || {}).name}</Text>
              </Stack>
            </Group>
          ))}
        </Paper>
      )}
    </Dialog>
  );
}
