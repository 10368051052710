import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { useTheme } from '@emotion/react';
import { Slide } from '@mui/material';
import { styled } from '@mui/system';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { exportCSVData } from 'utils/export';

import { headCells } from './constants';
import { GET_PROJECTS, INSERT_PROJECT, UPDATE_PROJECT } from './graphql';
import { getRowsData } from './helper';
import TableToolBar from '../../../components/Common/BigAgileTable/TableToolBar';
import Table from '../../../components/Common/Table';
import { Spotlight } from '../../../components/Spotlight';
import { UPDATE_FEATURE_MANY, GET_FEATURES } from '../../Product/Features/graphql';
import { GET_MENU_TITLE_CONFIG } from '../../Settings/GraphQL/configuration';
import { GET_TEAMS } from '../../Settings/GraphQL/teams';
import { GET_USERS } from '../../Settings/GraphQL/users';
import { GET_VALUE_STREAMS } from '../../Settings/Streams/graphql';
import { GET_ALL_INITIATIVES, UPDATE_INITIATIVE_MANY } from '../../ValueStream/Initiatives/graphql';

const Container = styled('div')(() => ({
  margin: 0,
  padding: 0,
  width: '100%',
  minWidth: 'fit-content',
}));

const Projects = ({ user }, ref) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const theme = useTheme();

  const { data: { projects: strategic_themes = [] } = {}, refetch: refetchThemes } = useQuery(GET_PROJECTS);
  const { data: { initiatives = [] } = {}, refetch: refetchInitiatives } = useQuery(GET_ALL_INITIATIVES);
  const { data: { menuTitles = [] } = {} } = useQuery(GET_MENU_TITLE_CONFIG);
  const { data: { users = [] } = {} } = useQuery(GET_USERS);
  const { data: { valueStreams = [] } = {} } = useQuery(GET_VALUE_STREAMS);
  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);
  const { data: { features = [] } = {}, refetch: refetchFeatures } = useQuery(GET_FEATURES);

  const usersList = users.map((user) => ({
    name: `${user.firstName} ${user.lastName}`,
    id: user.id,
  }));

  const activeValueStreams = valueStreams.filter((stream) => stream.isActive === true);
  const activeStrategicThemes = strategic_themes.filter((theme) => theme.isActive === true);

  const [insertStrategicTheme] = useMutation(INSERT_PROJECT);
  const [updateStrategicTheme] = useMutation(UPDATE_PROJECT);
  const [updateInitiativeMany] = useMutation(UPDATE_INITIATIVE_MANY);
  const [updateFeature] = useMutation(UPDATE_FEATURE_MANY);

  const [tableRows, setTableRows] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState({});
  const [openSpotlight, setOpenSpotlight] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isCreatingNew, setIsCreatingNew] = useState(false);

  let owner = '';

  if (usersList.length && selectedTheme?.owner) {
    const foundOwner = usersList.find((user) => user.id === selectedTheme.owner);
    owner = foundOwner ? foundOwner.name : '';
  }

  let teamNames = [];

  if (teams.length) {
    teamNames = teams?.filter((team) => selectedTheme?.delivery_teams?.includes(team?.id)).map((team) => team.name);
  }

  // Export function
  useImperativeHandle(ref, () => ({
    export: () => exportThemes(),
  }));

  const exportThemes = useCallback(() => {
    const headCellsWithoutAction = headCells.filter((columnDef) => columnDef.id !== 'action');
    const data = strategic_themes.map((rowData) => ({
      name: rowData.name,
      rag_status: rowData.rag_status,
      description: rowData.description,
      summary: rowData.summary,
    }));
    exportCSVData(headCellsWithoutAction, data, 'strategic_themes');
  }, [strategic_themes]);

  useEffect(() => {
    setTableRows(getRowsData(activeStrategicThemes, theme, usersList, activeValueStreams));
  }, [strategic_themes, theme, users]);

  useEffect(() => {
    if (projectId) {
      const theme = activeStrategicThemes.find((theme) => theme.id === parseInt(projectId));

      if (theme) {
        setSelectedTheme(theme);
        setOpenSpotlight(true);
        setIsCreatingNew(false);
      } else if (activeStrategicThemes.length > 0) {
        // Only redirect if we have loaded the themes and the ID wasn't found
        navigate('/organisation/projects');
      }
    } else if (!isCreatingNew) {
      // Only close if we're not explicitly creating a new project
      setOpenSpotlight(false);
    }
  }, [projectId, activeStrategicThemes, navigate, isCreatingNew]);

  const onRowClick = (event, row) => {
    const theme = activeStrategicThemes.find((theme) => theme?.id === row.id);
    navigate(`/organisation/projects/${theme.id}`);
  };

  const onHandleSearch = (e) => setSearchQuery(e.target.value);

  const strategicThemesTitle = menuTitles?.[0]?.value?.[0]?.projects;
  const title = strategicThemesTitle ?? 'Projects';

  const saveSpotlight = async (item) => {
    try {
      if (selectedTheme?.id) {
        await updateStrategicTheme({
          variables: { themeId: selectedTheme.id, theme: item },
        });
        console.log('Theme updated successfully');
      } else {
        const { data } = await insertStrategicTheme({
          variables: {
            theme: {
              ...item,
              isActive: true, // Ensure isActive is set for new themes
            },
          },
        });
        if (data?.project) {
          setSelectedTheme(data.project);
          refetchThemes();
          console.log('Theme inserted successfully');
        } else {
          throw new Error('Failed to create theme - no data returned');
        }
      }
    } catch (error) {
      console.error('Error saving theme:', error);
      // Re-throw the error so the Spotlight component can handle it
      throw error;
    }
  };

  const handleThemeDelete = async () => {
    if (selectedTheme?.id) {
      await updateStrategicTheme({
        variables: {
          themeId: selectedTheme.id,
          theme: {
            isActive: false,
          },
        },
      }).then(() => {
        refetchThemes();
        setOpenSpotlight(false);
        navigate('/organisation/projects');
      });
    }
  };

  const updateInitiatives = (initiativeIds) => {
    const initiativesToUpdate = initiatives.filter((initiative) => initiativeIds.includes(initiative.id));
    const currentInitiativesList = initiatives
      ?.filter((init) => init.strategic_theme === selectedTheme.id)
      .map((init) => init.id);
    const initiativesToUnset = currentInitiativesList.filter((id) => !initiativeIds.includes(id));

    const allUpdates = [
      ...initiativesToUpdate.map((item) => ({
        where: { id: { _eq: item.id } },
        _set: { strategic_theme: selectedTheme.id },
      })),
      ...initiativesToUnset.map((id) => ({
        where: { id: { _eq: id } },
        _set: { strategic_theme: null },
      })),
    ];

    if (allUpdates.length) {
      updateInitiativeMany({ variables: { updates: allUpdates } })
        .then(() => refetchInitiatives())
        .catch((error) => console.error('Error updating initiatives:', error));
    }
  };

  const updateFeatures = (featureIds) => {
    const featuresToUpdate = features.filter((feature) => featureIds.includes(feature.id));
    const currentFeaturesList = features?.filter((feat) => feat.project === selectedTheme.id).map((feat) => feat.id);
    const featuresToUnset = currentFeaturesList.filter((id) => !featureIds.includes(id));

    const allUpdates = [
      ...featuresToUpdate.map((item) => ({
        where: { id: { _eq: item.id } },
        _set: { project: selectedTheme.id },
      })),
      ...featuresToUnset.map((id) => ({
        where: { id: { _eq: id } },
        _set: { project: null },
      })),
    ];

    if (allUpdates.length) {
      updateFeature({ variables: { updates: allUpdates } })
        .then(() => refetchFeatures())
        .catch((error) => console.error('Error updating features:', error));
    }
  };

  return (
    <>
      <Container>
        <TableToolBar
          title={title}
          onHandleAdd={() => {
            setSelectedTheme({});
            setOpenSpotlight(true);
            setIsCreatingNew(true);
          }}
          handleSearch={onHandleSearch}
        />
        <Slide in direction="right" timeout={location.state?.from ? 400 : 0}>
          <div>
            <Table
              onRowClick={onRowClick}
              fields={headCells}
              searchQuery={searchQuery}
              rows={tableRows}
              enableDragAndDrop={false}
              setPadding={false}
            />
          </div>
        </Slide>
      </Container>
      {openSpotlight && (
        <Spotlight
          title={title}
          onClose={() => {
            setOpenSpotlight(false);
            setIsCreatingNew(false);
            navigate('/organisation/projects');
          }}
          selectedItem={selectedTheme}
          allItems={activeStrategicThemes}
          saveSpotlight={saveSpotlight}
          user={user}
          handleSpotlightDelete={handleThemeDelete}
          initiatives={initiatives}
          type="strategic-themes"
          updateInitiatives={updateInitiatives}
          updateFeatures={updateFeatures}
          features={features}
          owner={owner}
          teams={teamNames}
          activeValueStreams={activeValueStreams}
          usersList={usersList}
        />
      )}
    </>
  );
};

export default forwardRef(Projects);
