import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { showNotification } from '@mantine/notifications';
import { Card, CardContent, MenuItem } from '@mui/material';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import _, { mapValues, omitBy } from 'lodash';
import { useForm } from 'react-hook-form';

import {
  DELETE_OBJECTIVE,
  INSERT_OBJECTIVE,
  UPDATE_OBJECTIVE,
} from '../../../containers/Delivery/components/Objectives/graphql';
import { GET_TEAMS } from '../../../containers/Settings/GraphQL/teams';
import {
  GET_PROGRAM_INCREMENTS_FOR_PROGRAMME,
  GET_SELECTED_PI,
} from '../../../containers/Settings/ProgramIncrement/graphql';
import { resetDrawerDetails, selectedProgrammeVar } from '../../../reactiveVariables';
import { deleteUpdate, insertUpdate } from '../../../utils/graphQLUtils';
import { Field, FormContext, renderSelectField, renderTextField, renderCheckbox } from '../../Common/FormFieldsHooks';

const required = (value) => (value ? undefined : 'Required');

const ObjectiveDetails = (props, ref) => {
  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);
  const { data: { programIncrements = [] } = {} } = useQuery(GET_PROGRAM_INCREMENTS_FOR_PROGRAMME);
  const { data: { selectedPI = [] } = {} } = useQuery(GET_SELECTED_PI);

  const selectedProgramme = useReactiveVar(selectedProgrammeVar);

  const [insertObjective] = useMutation(INSERT_OBJECTIVE);
  const [updateObjective] = useMutation(UPDATE_OBJECTIVE);
  const [deleteObjective] = useMutation(DELETE_OBJECTIVE);

  const [openConfirmation, setOpenConfirmation] = useState(false);

  const {
    handleSubmit,
    watch,
    control,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      programIncrement: selectedPI,
      ...props.element,
    },
  });

  const objective = watch();

  useImperativeHandle(ref, () => ({
    handleSave: () => {
      handleSubmit(onSubmit)();
    },
    handleDelete: () => {
      if (props.element && props.element.id) setOpenConfirmation(true);
    },
    handleClose: () => resetDrawerDetails(),
  }));

  const handleDeleteConfirm = () => {
    deleteObjective({ variables: { id: objective.id }, update: deleteUpdate('objective') }).then(() =>
      resetDrawerDetails(),
    );
  };

  const onSubmit = (values) => {
    const objectiveToSave = mapValues(
      omitBy(values, (property, key) => key === 'id'),
      (property) => (property === '' ? null : property),
    );

    values.id
      ? updateObjective({
          variables: { objective: objectiveToSave, objectiveId: values.id },
        }).then(() => {
          showNotification({
            title: 'Objective Updated',
            message: `Objective ${values?.id} was successfully updated`,
          });
          resetDrawerDetails();
        })
      : insertObjective({
          variables: { objective: objectiveToSave },
          update: insertUpdate('objective'),
        }).then(() => {
          showNotification({
            title: 'Objective Created',
            message: `Objective was successfully created`,
          });
          resetDrawerDetails();
        });
  };

  const renderSelectItems = (array) =>
    array.map((element) => (
      <MenuItem key={element.id} value={element.id}>
        {element.name}
      </MenuItem>
    ));

  const pi = _.find(programIncrements, { id: objective.programIncrement }) || {};

  return (
    <Card elevation={0}>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <FormContext.Provider value={{ control, errors }}>
            <Field name="name" renderField={renderTextField} label="Name" required validate={required} />
            <Field name="description" multiline maxRows="10" renderField={renderTextField} label="Description" />
            <Field name="notes" multiline maxRows="100" renderField={renderTextField} label="Notes" />
            <Field name="programIncrement" renderField={renderSelectField} label="Increment">
              {renderSelectItems(programIncrements)}
            </Field>
            <Field name="teamId" renderField={renderSelectField} label="Team">
              <MenuItem key="" value="">
                <em>None</em>
              </MenuItem>
              {teams
                .filter((team) => team.programmes?.includes(selectedProgramme))
                .map((element) => (
                  <MenuItem key={element.id} value={element.id}>
                    {element.name}
                  </MenuItem>
                ))}
            </Field>
            <Field name="sprintId" renderField={renderSelectField} label="Sprint">
              <MenuItem key="" value="">
                <em>None</em>
              </MenuItem>
              {renderSelectItems(pi.sprints || [])}
            </Field>
            <Field name="plannedValue" renderField={renderTextField} type="number" label="Planned Value" />
            <Field name="deliveredValue" renderField={renderTextField} type="number" label="Delivered Value" />

            <Field name="commitment" defaultValue={'Committed'} renderField={renderSelectField} label="Commitment">
              <MenuItem key={1} value={'Committed'}>
                Committed
              </MenuItem>
              <MenuItem key={2} value={'Uncommitted'}>
                Uncommitted
              </MenuItem>
            </Field>

            <Field name="ragStatus" defaultValue="Green" renderField={renderSelectField} label="Rag Status">
              <MenuItem key={1} value="Green">
                Green
              </MenuItem>
              <MenuItem key={2} value="Amber">
                Amber
              </MenuItem>
              <MenuItem key={3} value="Red">
                Red
              </MenuItem>
            </Field>
            <Field name="status" defaultValue="To Do" renderField={renderSelectField} label="Status">
              <MenuItem key={1} value="To Do">
                To Do
              </MenuItem>
              <MenuItem key={2} value="In Progress">
                In Progress
              </MenuItem>
              <MenuItem key={3} value="Done">
                Done
              </MenuItem>
            </Field>
          </FormContext.Provider>
        </form>
      </CardContent>
      <ConfirmationDialog
        open={openConfirmation}
        title={`Delete objective: ${objective.name}`}
        text="Are you sure you want to delete this objective ?"
        handleOk={handleDeleteConfirm}
        handleCancel={() => setOpenConfirmation(false)}
      />
    </Card>
  );
};

export default forwardRef(ObjectiveDetails);
