import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { DndContext, DragOverlay, MouseSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { useTheme } from '@emotion/react';
import { Indicator, Text } from '@mantine/core';
import { Badge, Chip, TableCell, TableRow, Zoom } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { find, flatten, get, groupBy, isEmpty, last, orderBy, some, uniq, uniqBy } from 'lodash';
import { DateTime } from 'luxon';

import { useLines } from './hooks';
import { styles } from './styles';
import SummaryTeamSprint from './SummaryTeamSprint';
import TeamCard from './TeamCard';
import InfoDialog from '../../../../components/Common/InfoDialog';
import {
  closeSnackbar,
  openDrawer,
  openSnackbar,
  resetDrawerDetails,
  selectedInitiativeVar,
  selectedPiVar,
  selectedProgrammeVar,
  selectedValueStreamVar,
} from '../../../../reactiveVariables';
import { date } from '../../../../utils/helpers';
import { useIsFirstRender } from '../../../../utils/hooks';
import { GET_FEATURES, GET_FEATURES_FOR_PI, UPDATE_FEATURE_STAGE } from '../../../Product/Features/graphql';
import { GET_BACKLOGS } from '../../../Settings/GraphQL/backlogs';
import { GET_PROGRAMMES } from '../../../Settings/GraphQL/programmes';
import { GET_TEAMS } from '../../../Settings/GraphQL/teams';
import { GET_PROGRAM_INCREMENTS, GET_SELECTED_PI_FULL } from '../../../Settings/ProgramIncrement/graphql';
import { GET_MILESTONES } from '../../../ValueStream/Milestones/graphql';
import { canScroll, dragDependencyEnd, dragFeatureEnd, sortTeams } from '../../utils';
import { GET_DEPENDENCIES, GET_DEPENDENCIES_FOR_PI, UPDATE_DEPENDENCY_FIELDS } from '../Dependencies/graphql';
import { GET_OBJECTIVES_FOR_PI } from '../Objectives/graphql';
import Draggable from '../Risks/draggable';
import FeatureTable from '../shared/FeatureTable';
import ToolBarSummary from '../shared/ToolBar';

const useStyles = makeStyles(styles);

function DeliverySummary() {
  const [showObjectives, setShowObjectives] = useState(false);
  const [selectedItem, setSelectedItem] = useState('features');
  const [selectedMilestone, setSelectedMilestone] = useState(null);
  const [anchorMilestone, setAnchorMilestone] = useState(false);
  const [backlog, setBacklog] = useState(0);
  const [showAllDependencies, setShowAllDependencies] = useState(false);
  const [showTeamDependencies, setShowTeamDependencies] = useState(null);
  const [isCurrentPlan, setIsCurrentPlan] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState('currentPlan');
  const [isHoverActive, setIsHoverActive] = useState(false);
  const selectedProgramme = useReactiveVar(selectedProgrammeVar);
  const selectedInitiative = useReactiveVar(selectedInitiativeVar);

  const theme = useTheme();

  const { data: { backlogs = [] } = {} } = useQuery(GET_BACKLOGS);
  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);
  const { data: { objectives = [] } = {} } = useQuery(GET_OBJECTIVES_FOR_PI);
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES_FOR_PI);
  const { data: { dependencies = [] } = {} } = useQuery(GET_DEPENDENCIES_FOR_PI);
  const { data: { milestones = [] } = {} } = useQuery(GET_MILESTONES);
  const { data: { selectedPiFull = {} } = {} } = useQuery(GET_SELECTED_PI_FULL);
  const { data: { programIncrements = [] } = {} } = useQuery(GET_PROGRAM_INCREMENTS);
  const { data: { programmes = [] } = {} } = useQuery(GET_PROGRAMMES);

  const { data: { dependencies: allDependencies = [] } = {} } = useQuery(GET_DEPENDENCIES);
  const { data: { features: allFeatures = [] } = {} } = useQuery(GET_FEATURES);

  const [updateFeature] = useMutation(UPDATE_FEATURE_STAGE);
  const [updateDependency] = useMutation(UPDATE_DEPENDENCY_FIELDS);

  const [elementDragged, setElementDragged] = useState(null);
  const isFirst = useIsFirstRender();

  const filteredFeatures = useMemo(
    () =>
      isFirst
        ? []
        : backlog || selectedInitiative
        ? features.filter(
            (feature) =>
              (!backlog || feature.backlogId === backlog) &&
              (!selectedInitiative || feature.initiativeId === selectedInitiative),
          )
        : features,
    [isFirst, features, backlog, selectedInitiative],
  );

  const deferredFeatures = useMemo(
    () =>
      isFirst
        ? []
        : allFeatures.filter(
            (feature) =>
              (!backlog || feature.backlogId === backlog) &&
              !!feature.completedIncrements?.[selectedPiFull?.id] &&
              selectedPiFull?.id !== feature.programIncrement &&
              (!selectedInitiative || feature.initiativeId === selectedInitiative),
          ),
    [isFirst, allFeatures, backlog, selectedPiFull, selectedInitiative],
  );

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: { distance: 5 },
  });
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 5 },
  });

  const sensors = useSensors(pointerSensor, mouseSensor);

  const handleDragStart = (event) => {
    deleteLine();
    setElementDragged(event.active?.data?.current);
  };

  const handleDragEnd = (event) => {
    if (!event || !event.over) return;

    const element = event.active?.data?.current;

    if (element.__typename === 'features') {
      dragFeatureEnd(event, updateFeature);
    } else if (element.__typename === 'dependencies') {
      dragDependencyEnd(event, updateDependency);
    }

    setElementDragged(null);
  };

  const filteredDependencies =
    backlog || selectedInitiative
      ? dependencies
          .filter((dep) => !isEmpty(dep.targetFeatures))
          .filter((dep) =>
            some(
              dep.targetFeatures.map((feature) => find(features, { id: feature.id }) || {}),
              (feature) =>
                (!backlog || feature.backlogId === backlog) &&
                (!selectedInitiative || feature.initiativeId === selectedInitiative),
            ),
          )
      : dependencies;

  const pi = selectedPiFull || {};

  const hasUnplannedFeatures = some(filteredFeatures, (feature) => !feature.committedSprint);
  const hasUnassignedFeatures = some(filteredFeatures, (feature) => !feature.teamId);
  const isUnassignedFeatures = !!filteredFeatures.filter((feature) => !feature.teamId).length;

  const classes = useStyles();
  const { deleteLine, drawLine, clearLines, drawAllLines, drawLineFromDep, drawLinesForMilestone, drawTeamLines } =
    useLines(
      elementDragged,
      selectedMilestone,
      showAllDependencies,
      showTeamDependencies,
      filteredFeatures,
      filteredDependencies,
    );

  useEffect(() => {
    setAnchorMilestone(false);
    setSelectedMilestone(null);
    setShowAllDependencies(false);
    setShowTeamDependencies(null);
  }, [selectedPiFull]);

  useEffect(() => {
    setAnchorMilestone(false);
    setSelectedMilestone(null);
    setShowAllDependencies(false);
    setShowTeamDependencies(null);
    deleteLine();
  }, [selectedInitiative]);

  const handlePlanSwitch = (value) => {
    setSelectedPlan(value);
    setIsCurrentPlan(value === 'currentPlan');
    setShowAllDependencies(false);
    setShowTeamDependencies(null);
    deleteLine();
  };

  const handleClick = (feature) => openDrawer(feature, 'feature');
  const handleObjectiveClick = (objective) => openDrawer(objective, 'objective');
  const handleDependencyClick = (dependency) => openDrawer(dependency, 'dependency');
  const createMilestone = () => openDrawer(null, 'milestone');

  const handleSwitch = (value) => {
    clearLines();
    setAnchorMilestone(false);
    setSelectedMilestone(null);
    setShowAllDependencies(false);
    setShowTeamDependencies(null);
    setShowObjectives(value === 'objectives');
    setSelectedItem(value);
  };

  const selectMilestone = (milestoneId) => {
    if (!anchorMilestone) {
      if (!showAllDependencies && !showTeamDependencies && !showObjectives) {
        setSelectedMilestone(milestoneId);
        drawLinesForMilestone(milestoneId);
      }
      setIsHoverActive(true);
    }
  };

  const unSelectMilestone = () => {
    if (!anchorMilestone) {
      if (!showAllDependencies && !showTeamDependencies) {
        setSelectedMilestone(null);
        deleteLine();
      }
      setIsHoverActive(false);
    }
  };

  const changeShowAllDependencies = () => {
    setSelectedMilestone(null);
    deleteLine();
    setShowTeamDependencies(null);
    setShowAllDependencies(!showAllDependencies);
    if (!showAllDependencies) drawAllLines();
  };

  const changeShowTeamDependencies = (teamId) => {
    setSelectedMilestone(null);
    setShowAllDependencies(false);
    deleteLine();
    setShowTeamDependencies(teamId);
    if (!showTeamDependencies || teamId !== showTeamDependencies) drawTeamLines(teamId);
  };

  const onBacklogChange = (event, value) => {
    setBacklog(value);
    setSelectedMilestone(null);
    setShowAllDependencies(false);
    setShowTeamDependencies(null);
    deleteLine();
  };

  const onProgramRedirect = (programme, piToRedirect) => {
    const selectedPIs = programIncrements?.filter((pi) => pi.programme === programme.id);
    const activePi = selectedPIs.filter((pi) => pi.status === 'active');
    const newPi = piToRedirect || (last(activePi) || last(selectedPIs))?.id || 0;

    deleteLine();
    resetDrawerDetails();
    window.localStorage.setItem('selectedValueStream', programme?.valueStream?.id);
    window.localStorage.setItem('selectedProgramme', programme.id);
    window.localStorage.setItem('selectedPI', newPi);
    selectedPiVar(newPi);
    selectedProgrammeVar(programme.id);
    selectedValueStreamVar(programme?.valueStream?.id);
  };

  const handleMilestoneClick = (milestoneId) => {
    if (!showObjectives) {
      if ((anchorMilestone && milestoneId !== selectedMilestone) || showAllDependencies || showTeamDependencies) {
        deleteLine();
        drawLinesForMilestone(milestoneId);
      }
      setShowAllDependencies(false);
      setShowTeamDependencies(null);
      setAnchorMilestone(!(anchorMilestone && milestoneId === selectedMilestone));
      setSelectedMilestone(milestoneId);
    }
  };

  const getItemsForSprint = (teamObjectives, teamFeatures, teamDeferredFeatures, teamDependencies, sprint) => {
    const sprintField =
      pi.status !== 'planning'
        ? isCurrentPlan
          ? 'committedSprint'
          : 'originalState.committedSprint'
        : 'committedSprint';

    const objectives = teamObjectives
      .filter((objective) => objective.sprintId === sprint.id)
      .map((objective) => renderObjective(objective));
    const features = teamFeatures
      .filter((feature) => get(feature, sprintField) === sprint.id)
      .map((feature) => renderFeature(feature));
    const deferredFeaturesChips = teamDeferredFeatures
      .filter(
        (feature) =>
          feature.completedIncrements?.[selectedPiFull?.id]?.[
            isCurrentPlan ? 'committedSprint' : 'originalCommittedSprint'
          ] === sprint.id,
      )
      .map((feature) => renderFeature({ ...feature, isDescoped: true }));
    const dependencies = teamDependencies
      .filter((dependency) => dependency.neededBySprint === sprint.id)
      .map((dependency) => (
        <Draggable
          idPrefix="D"
          enabled={pi.status === 'planning'}
          element={dependency}
          data={dependency}
          key={dependency.id}
          style={{ display: 'inline-block' }}>
          {renderDependency(dependency)}
        </Draggable>
      ));

    return (
      <>
        {objectives}
        {features}
        {deferredFeaturesChips}
        {dependencies}
      </>
    );
  };

  const renderFeature = (feature, draggingEnabled = true) => {
    const zoomOut =
      showObjectives ||
      (selectedMilestone && !some(feature.milestones, (milestone) => milestone.id === selectedMilestone));

    if (zoomOut) return;

    return (
      <Draggable
        idPrefix="F"
        enabled={pi.status === 'planning' && draggingEnabled}
        element={feature}
        data={feature}
        key={feature.id}
        style={{ display: 'inline-block' }}>
        <Zoom key={feature.id} in={true}>
          {feature.estimatedSprint || feature.isDescoped ? (
            renderChip(feature)
          ) : (
            <Indicator label="!" size={16} zIndex={1} color="#4286f4">
              {renderChip(feature)}
            </Indicator>
          )}
        </Zoom>
      </Draggable>
    );
  };

  const renderChip = (feature, isOverlay) => {
    const id = `f${feature.id}`;
    const ragStatus = feature.ragStatus;
    const backgroundColor =
      feature.isDescoped || !feature.committedSprint
        ? theme.palette.color.greyIcon
        : feature.status === 'Done'
        ? theme.palette.color.done
        : ragStatus === 'Amber'
        ? theme.palette.color.amber
        : ragStatus === 'Red'
        ? theme.palette.color.red
        : theme.palette.color.green;

    return (
      <Chip
        sx={{ borderRadius: feature.enabler ? 0 : 'default' }}
        data-tip
        data-for="features"
        id={id}
        label={feature.id}
        aria-label={feature.name}
        onClick={() => !isOverlay && handleClick(feature)}
        onMouseEnter={() => !isOverlay && drawLine(feature)}
        onMouseLeave={() => {
          !isOverlay && closeSnackbar();
          !isOverlay && !selectedMilestone && !showAllDependencies && !showTeamDependencies && deleteLine();
        }}
        style={{ background: backgroundColor }}
        className={classes.chip}
      />
    );
  };

  const renderObjective = (objective) => {
    if (!showObjectives) return;

    const { status, ragStatus } = objective;
    const backgroundColor =
      status === 'Done'
        ? theme.palette.color.done
        : ragStatus === 'Amber'
        ? theme.palette.color.amber
        : ragStatus === 'Red'
        ? theme.palette.color.red
        : theme.palette.color.green;

    return (
      <Zoom key={objective.id} in={true}>
        <Chip
          data-tip
          data-for="objectives"
          label={objective.id}
          aria-label={objective.name}
          onClick={() => handleObjectiveClick(objective)}
          onMouseEnter={() => openSnackbar(objective, 'objective')}
          onMouseLeave={() => closeSnackbar()}
          style={{
            background: objective.commitment === 'Committed' ? backgroundColor : 'white',
            border: objective.commitment !== 'Committed' ? `2px solid ${backgroundColor}` : 'none',
            color: objective.commitment !== 'Committed' ? backgroundColor : 'white',
          }}
          className={classes.objective}
        />
      </Zoom>
    );
  };

  const shouldDisplayDependency = (dependency) => {
    if (selectedMilestone) {
      return (
        dependency.targetFeatures &&
        some(
          dependency.targetFeatures.map((feature) => find(allFeatures, { id: feature.id }) || {}),
          (feature) => some(feature.milestones, (milestone) => milestone.id === selectedMilestone),
        )
      );
    }
    return !showObjectives;
  };

  const renderDependency = (dependency) => {
    const zoomIn = shouldDisplayDependency(dependency);
    if (!zoomIn) return;

    return (
      <Zoom key={dependency.id} in={true}>
        {renderDependencyChip(dependency, false)}
      </Zoom>
    );
  };

  const renderDependencyChip = (dependency, isOverlay) => {
    const ragStatus = dependency.ragStatus;
    const dependencyColor =
      dependency.status === 'Done'
        ? theme.palette.color.done
        : ragStatus === 'Amber'
        ? theme.palette.color.amber
        : ragStatus === 'Red'
        ? theme.palette.color.red
        : theme.palette.color.green;
    const id = `d${dependency.id}`;

    return (
      <Chip
        data-tip
        data-for="dependencies"
        id={id}
        label={dependency.id}
        aria-label={dependency.name}
        onClick={() => !isOverlay && handleDependencyClick(dependency)}
        onMouseEnter={() => !isOverlay && drawLineFromDep(dependency)}
        onMouseLeave={() => {
          // !isOverlay && closeSnackbar();
          !isOverlay && !selectedMilestone && !showAllDependencies && !showTeamDependencies && deleteLine();
        }}
        variant="outlined"
        style={{ borderWidth: '2px', borderColor: dependencyColor, color: dependencyColor }}
        className={classes.dependency}
      />
    );
  };

  const renderSprintForTeam = (team, pi, index, hasUnplannedFeatures) => {
    const teamField = pi.status !== 'planning' ? (isCurrentPlan ? 'teamId' : 'originalState.teamId') : 'teamId';

    const teamFeatures = filteredFeatures.filter(
      (feature) => get(feature, teamField, null) === team.id && feature.programIncrement === pi.id,
    );

    const teamDeferredFeatures = deferredFeatures.filter((feature) => get(feature, 'teamId', null) === team.id);

    const teamObjectives = objectives.filter((objective) => objective.teamId === team.id);
    const outboundDeps = filteredDependencies.filter((dependency) => dependency.teamId === team.id);
    const inboundDeps = filteredDependencies.filter((dependency) =>
      some(
        dependency.targetFeatures.map(
          (feature) => find([...teamFeatures, ...teamDeferredFeatures], { id: feature.id }) || {},
        ),
        (feature) => feature.id && (!backlog || feature.backlogId === backlog),
      ),
    );

    if (pi.status !== 'planning' && isEmpty(teamFeatures) && isEmpty(teamObjectives) && isEmpty(outboundDeps)) {
      return null;
    }

    const plannedItems = pi?.sprints?.map((sprint) => {
      return (
        <SummaryTeamSprint key={sprint.id} team={team} sprint={sprint}>
          {getItemsForSprint(teamObjectives, teamFeatures, teamDeferredFeatures, outboundDeps, sprint)}
        </SummaryTeamSprint>
      );
    });

    const unassignedFeatures = teamFeatures
      .filter((feature) => !feature.committedSprint)
      .map((feature) => renderFeature(feature));

    const unassignedObjectives = teamObjectives
      .filter((objective) => !objective.sprintId)
      .map((objective) => renderObjective(objective));

    return (
      <TableRow key={team.id} className={classes.row}>
        <TableCell key="team" className={classes.tableHeadCol}>
          <TeamCard
            team={team}
            increment={selectedPiFull}
            featureCount={teamFeatures.length}
            showTeamDependencies={showTeamDependencies}
            changeShowTeamDependencies={changeShowTeamDependencies}
            outboundDeps={outboundDeps.length}
            inboundDeps={inboundDeps.length}
          />
        </TableCell>
        {hasUnplannedFeatures && (
          <TableCell key="unassigned" className={classes.tableCell}>
            {unassignedFeatures}
            {unassignedObjectives}
          </TableCell>
        )}
        {plannedItems}
      </TableRow>
    );
  };

  const renderExternalTeamsHeader = () => {
    const plannedItems = pi?.sprints?.map((sprint) => {
      const isCurrentSprint =
        DateTime.fromISO(sprint.startDate).startOf('day') <= DateTime.local().startOf('day') &&
        DateTime.local().startOf('day') <= DateTime.fromISO(sprint.endDate).startOf('day');

      return (
        <TableCell
          key={sprint.id}
          className={classes.tableCell}
          style={{ borderLeft: 'none' }}
          sx={{ backgroundColor: theme.palette.color.paper }}></TableCell>
      );
    });

    return (
      <TableRow key="externalTeams" className={classes.row}>
        <TableCell key="externalTeams" className={classes.tableHeadCol}>
          <Text size="sm" fw={700} c={'bigagilePink.4'}>
            Other Programmes
          </Text>
        </TableCell>
        {hasUnplannedFeatures && (
          <TableCell
            key="unassigned"
            className={classes.tableCell}
            sx={{ backgroundColor: theme.palette.color.paper }}
          />
        )}
        {plannedItems}
      </TableRow>
    );
  };

  const renderCrossProgrammeDepTeams = () => {
    const externalDeps = flatten(
      filteredFeatures.map((feature) =>
        feature.dependencies.map((dep) => allDependencies.find((dependency) => dependency.id === dep.dependencyId)),
      ),
    ).filter(
      (dependency) =>
        dependency &&
        dependency.neededBySprint &&
        dependency.programIncrement &&
        dependency.programmeId !== selectedProgramme &&
        dependency.programmeId !== pi.programme,
    );

    const externalFeatures = flatten(
      filteredDependencies.map((dependency) =>
        dependency.targetFeatures.map((targetFeature) =>
          allFeatures.find((feature) => feature.id === targetFeature.id),
        ),
      ),
    ).filter(
      (feature) =>
        feature &&
        feature.committedSprint &&
        feature.programIncrement &&
        feature.programmeId !== selectedProgramme &&
        feature.programmeId !== pi.programme,
    );

    const externalDepsByTeams = groupBy(externalDeps, 'teamId');
    const externalFeatureByTeams = groupBy(externalFeatures, 'teamId');

    const externalTeams = teams
      .map((team) => {
        const teamDeps = externalDepsByTeams[team.id];
        const teamFeatures = externalFeatureByTeams[team.id];

        if (!teamDeps && !teamFeatures) return null;

        return renderExternalTeam(team, teamFeatures, teamDeps);
      })
      .filter((team) => !!team);

    return (
      <>
        {!!externalTeams.length && renderExternalTeamsHeader()}
        {externalTeams}
      </>
    );
  };

  const renderExternalTeam = (team, teamFeatures = [], teamDeps = []) => {
    const featureDisplayed = [];
    const depDisplayed = [];

    const featuresWithDate = uniqBy(teamFeatures, 'id').map((feature) => {
      const pi = programIncrements.find((pi) => pi.id === feature.programIncrement);
      return {
        date: pi.sprints?.find((sprint) => sprint.id === feature.committedSprint)?.endDate,
        feature,
      };
    });

    const dependenciesWithDate = uniqBy(teamDeps, 'id').map((dependency) => {
      const pi = programIncrements.find((pi) => pi.id === dependency.programIncrement);
      return {
        date: pi.sprints?.find((sprint) => sprint.id === dependency.neededBySprint)?.endDate,
        dependency,
      };
    });

    const unplannedFeatures = featuresWithDate
      .filter((data) => !data.feature.committedSprint)
      .map((data) => renderFeature(data.feature, false));
    const unplannedDeps = dependenciesWithDate
      .filter((data) => !data.dependency.neededBySprint)
      .map((data) => renderDependency(data.dependency));

    const plannedItems = pi.sprints?.map((sprint) => {
      const plannedFeatures = featuresWithDate
        .filter((data) => date(sprint.startDate) <= date(data.date) && date(data.date) <= date(sprint.endDate))
        .map((data) => {
          featureDisplayed.push(data.feature);
          return renderFeature(data.feature, false);
        });

      const plannedDeps = dependenciesWithDate
        .filter((data) => date(sprint.startDate) <= date(data.date) && date(data.date) <= date(sprint.endDate))
        .map((data) => {
          depDisplayed.push(data.dependency);
          return renderDependency(data.dependency);
        });

      return (
        <TableCell key={sprint.id} className={classes.tableCell} style={{ borderLeft: 'none' }}>
          {plannedFeatures}
          {plannedDeps}
        </TableCell>
      );
    });

    if (!depDisplayed.length && !featureDisplayed.length) return null;

    const piToRedirect = featureDisplayed[0]?.programIncrement || depDisplayed[0]?.programIncrement;

    const teamProgrammes = uniq(
      depDisplayed.map((feature) => feature.programmeId).concat(featureDisplayed.map((dep) => dep.programmeId)),
    )
      .map((programmeId) => programmes.find((programme) => programme.id === programmeId))
      .filter((programme) => !!programme);

    return (
      <TableRow key={team.id} className={classes.row}>
        <TableCell key="team" className={classes.tableHeadCol}>
          <TeamCard
            team={team}
            featureCount={teamFeatures.length}
            showTeamDependencies={showTeamDependencies}
            external={true}
            changeShowTeamDependencies={changeShowTeamDependencies}
            programmes={teamProgrammes}
            onProgramRedirect={onProgramRedirect}
            piToRedirect={piToRedirect}
          />
        </TableCell>
        {hasUnplannedFeatures && (
          <TableCell key="unassigned" className={classes.tableCell}>
            {unplannedFeatures}
            {unplannedDeps}
          </TableCell>
        )}
        {plannedItems}
      </TableRow>
    );
  };

  const filterEmptyTeams = (team) => {
    if (selectedProgramme && !team.programmes?.includes(selectedProgramme)) return false;
    if (pi.status === 'planning') return true;

    const teamField = pi.status !== 'planning' ? (isCurrentPlan ? 'teamId' : 'originalState.teamId') : 'teamId';

    const teamFeatures = filteredFeatures.filter(
      (feature) => get(feature, teamField, null) === team.id && feature.programIncrement === pi.id,
    );

    const teamObjectives = objectives.filter((objective) => objective.teamId === team.id);
    const teamDependencies = filteredDependencies.filter((dependency) => dependency.teamId === team.id);

    return !(isEmpty(teamFeatures) && isEmpty(teamObjectives) && isEmpty(teamDependencies));
  };

  const renderOverlay = (element) => {
    if (element.__typename === 'features') {
      return renderChip(element, true);
    } else if (element.__typename === 'dependencies') {
      return renderDependencyChip(element, true);
    }
  };

  return (
    <div className={classes.root}>
      <DndContext sensors={sensors} onDragStart={handleDragStart} onDragEnd={handleDragEnd} autoScroll={{ canScroll }}>
        <ToolBarSummary
          title="Summary View"
          backlogs={backlogs}
          backlog={backlog}
          selectedPlan={selectedPlan}
          showAllDependencies={showAllDependencies}
          showCurrentState={isCurrentPlan}
          selectedItem={selectedItem}
          changeShowAllDependencies={changeShowAllDependencies}
          onBacklogChange={onBacklogChange}
          handleSwitch={handleSwitch}
          handlePlanSwitch={handlePlanSwitch}
          createMilestone={createMilestone}
          isHiddenPlan={true}
        />
        <FeatureTable
          pi={pi}
          milestones={milestones}
          selectedMilestone={selectedMilestone}
          teams={teams.filter(filterEmptyTeams)}
          hasUnplannedFeatures={hasUnplannedFeatures}
          unSelectMilestone={unSelectMilestone}
          selectMilestone={selectMilestone}
          handleMilestoneClick={handleMilestoneClick}
          isVisibleUnassignedColumn={isUnassignedFeatures}
          anchorMilestone={anchorMilestone}
          showAllDependencies={showAllDependencies}
          showTeamDependencies={showTeamDependencies}
          showObjectives={showObjectives}
          isHoverActive={isHoverActive}>
          {hasUnassignedFeatures &&
            renderSprintForTeam(
              {
                id: null,
                name: 'Unassigned',
              },
              pi,
              1,
              hasUnplannedFeatures,
            )}
          {orderBy([...teams], [sortTeams, 'name'], 'desc')
            .filter(filterEmptyTeams)
            .map((team, index) => {
              return renderSprintForTeam(team, pi, index, hasUnplannedFeatures);
            })}
          {renderCrossProgrammeDepTeams()}
        </FeatureTable>
        <DragOverlay>{elementDragged ? renderOverlay(elementDragged) : null}</DragOverlay>
      </DndContext>
      <InfoDialog />
    </div>
  );
}

export default DeliverySummary;
