import React from 'react';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Tooltip } from '@mui/material';

import { getFeatureBackgroundColor } from '../../../../utils/piColors';
import { getMappedValues } from '../../../../utils/tableUtils';
import { Display2 } from '../../../ValueStream/InitiativesDashboard/InitiativeDashboard.style';

function createData(item, teams, programIncrements, theme) {
  const { id, name, teamId, sprintId = '', status, ragStatus, plannedValue, deliveredValue, commitment } = item;
  const teamNames = getMappedValues(teams)[teamId];

  const sprintValue =
    (
      Array.isArray(programIncrements) &&
      programIncrements.length &&
      programIncrements
        .filter((pi) => pi.id === item.programIncrement)
        .map((pi) => pi?.sprints.find((item) => item.id === sprintId))
        .find((item) => item?.name)
    )?.name || '';

  return {
    id,
    name,
    team: teamNames,
    sprint: sprintValue.replace(/^Sprint\s*/, ''),
    status,
    plannedValue,
    deliveredValue,
    ragStatus: (
      <Display2
        data-value={ragStatus}
        color={getFeatureBackgroundColor({ ragStatus }, theme)}
        bgColor={getFeatureBackgroundColor({ ragStatus }, theme)}
        variant="light"
        style={{ width: '65px' }}>
        {ragStatus.toUpperCase()}
      </Display2>
    ),
    uncommitted:
      commitment === 'Committed' ? (
        <Tooltip withArrow placement="top" title={'Committed'}>
          <TaskAltIcon data-value={'Committed'} style={{ width: '20px' }} />
        </Tooltip>
      ) : (
        <Tooltip withArrow placement="top" title={'Uncommitted'}>
          <RadioButtonUncheckedIcon data-value={'Uncommitted'} style={{ width: '20px' }} />
        </Tooltip>
      ),
  };
}

export function getRowsData(data, teams, programIncrements, theme) {
  let rows = [];
  if (Array.isArray(data) && data.length) {
    rows = data.map((item) => createData(item, teams, programIncrements, theme));
  }
  return rows;
}
