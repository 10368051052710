import React, { useMemo, useEffect, useState } from 'react';

import { useQuery, useReactiveVar, useApolloClient } from '@apollo/client';
import { useTheme } from '@emotion/react';
import BugReportIcon from '@mui/icons-material/BugReport';
import { CircularProgress, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { GET_IN_PROGRESS_STORIES_FOR_FEATURE } from 'containers/Product/Features/graphql';
import { styles } from 'containers/Reports/Roadmap/styles';
import { find, get } from 'lodash';
import ReactApexChart from 'react-apexcharts';

import SvgFeatures from '../../../../../../assets/images/Features';
import SvgStories from '../../../../../../assets/images/Stories';
import { Metric } from '../../../../../../components/Dashboard/ChartElements';
import { selectedInitiativeVar } from '../../../../../../reactiveVariables';
import { GET_FEATURES_FOR_PROGRAMME } from '../../../../../Product/Features/graphql';
import { GET_TEAMS } from '../../../../../Settings/GraphQL/teams';
import { GET_SELECTED_PI_FULL } from '../../../../../Settings/ProgramIncrement/graphql';

const useStyles = makeStyles(styles);

const FeatureProgression = ({ backlog, selectedTeams, scope }) => {
  const classes = useStyles();
  const { data: { teams = [] } = {}, loading: teamLoading } = useQuery(GET_TEAMS);
  const { data: { features = [] } = {}, loading: featureLoading } = useQuery(GET_FEATURES_FOR_PROGRAMME);
  const { data: { selectedPiFull = {} || {} } = {} } = useQuery(GET_SELECTED_PI_FULL);

  const theme = useTheme();

  const loading = teamLoading || featureLoading;
  const selectedInitiative = useReactiveVar(selectedInitiativeVar);

  function hasStoriesInPI(feature) {
    return !!feature.metrics?.metricsPerIncrement?.[selectedPiFull?.id];
  }

  const filteredFeatures = useMemo(
    () =>
      features.filter(
        (feature) =>
          (feature.programIncrement === selectedPiFull?.id || hasStoriesInPI(feature)) &&
          (!backlog || feature.backlogId === backlog) &&
          (!selectedTeams.length || selectedTeams.includes(feature.teamId)) &&
          (!selectedInitiative || feature.initiativeId === selectedInitiative),
      ),
    [features, backlog, selectedTeams, selectedInitiative, selectedPiFull],
  );

  const updatedFeatures = useMemo(
    () =>
      filteredFeatures
        .filter((feature) => feature.teamId && feature.metrics)
        .sort((a, b) => a.teamId > b.teamId)
        .map((feature) => {
          return {
            ...feature,
            id: feature.id,
            teamName: find(teams, { id: feature.teamId }).name,
            status: feature.status,
            name: feature.name,
            inProgressCount: get(feature.metrics?.metricsPerIncrement?.[selectedPiFull?.id], `${scope}.inProgress`, 0),
            toDoCount: get(feature.metrics?.metricsPerIncrement?.[selectedPiFull?.id], `${scope}.toDo`, 0),
            doneCount: get(feature.metrics?.metricsPerIncrement?.[selectedPiFull?.id], `${scope}.done`, 0),
          };
        }),
    [teams, backlog, selectedTeams, filteredFeatures, scope],
  );

  const featuresDone = filteredFeatures.filter((feature) => feature.status === 'Done');
  const storiesToDo = filteredFeatures.reduce(
    (total, feature) => total + get(feature, 'metrics.storyCount.toDo', 0),
    0,
  );
  const storiesInProgress = filteredFeatures.reduce(
    (total, feature) => total + get(feature, 'metrics.storyCount.inProgress', 0),
    0,
  );
  const storiesDone = filteredFeatures.reduce(
    (total, feature) => total + get(feature, 'metrics.storyCount.done', 0),
    0,
  );
  const storyPointsToDo = filteredFeatures.reduce(
    (total, feature) => total + get(feature, 'metrics.storyPoints.toDo', 0),
    0,
  );
  const storyPointsInProgress = filteredFeatures.reduce(
    (total, feature) => total + get(feature, 'metrics.storyPoints.inProgress', 0),
    0,
  );
  const storyPointsDone = filteredFeatures.reduce(
    (total, feature) => total + get(feature, 'metrics.storyPoints.done', 0),
    0,
  );
  const defects = filteredFeatures.reduce((total, feature) => total + get(feature, 'metrics.defectsCount.total', 0), 0);
  const defectsDone = filteredFeatures.reduce(
    (total, feature) => total + get(feature, 'metrics.defectsCount.done', 0),
    0,
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Metric
          title="Features"
          icon={<SvgFeatures color={theme.palette.color.buttonIcon} />}
          classes={classes}
          done={featuresDone.length}
          total={filteredFeatures.length}
        />
        <Metric
          title="Bugs"
          icon={<BugReportIcon color="primary" />}
          classes={classes}
          done={defectsDone}
          total={defects}
        />
        <Metric
          title="Stories"
          classes={classes}
          done={storiesDone}
          total={storiesToDo + storiesInProgress + storiesDone}
          icon={<SvgStories color={theme.palette.color.buttonIcon} />}
        />
        <Metric
          title="Story Points"
          classes={classes}
          done={storyPointsDone}
          total={storyPointsInProgress + storyPointsToDo + storyPointsDone}
          icon={<SvgFeatures color={theme.palette.color.buttonIcon} />}
        />
        <Grid item xs={12}>
          {loading ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <CircularProgress className={classes.progress} size={50} />
            </div>
          ) : (
            <div style={{ height: `${updatedFeatures.length * 40 + 100}px` }}>
              <Progression features={updatedFeatures} scope={scope} />
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const Progression = ({ features, scope }) => {
  const theme = useTheme();
  const client = useApolloClient();
  const [storiesData, setStoriesData] = useState({});
  const [loading, setLoading] = useState(true);

  // Disable stories fetching because of performances
  // Fetch "In Progress" stories for all features at once
  // useEffect(() => {
  //   const fetchAllInProgressStories = async () => {
  //     setLoading(true);
  //     const updatedStoriesData = {};
  //
  //     try {
  //       const fetchPromises = features.map(async (feature) => {
  //         const { data } = await client.query({
  //           query: GET_IN_PROGRESS_STORIES_FOR_FEATURE,
  //           variables: {
  //             featureId: feature.id,
  //             connectorId: feature.team?.connectorId,
  //             epicId: feature.epicId,
  //             project: feature.team?.projectId,
  //           },
  //           fetchPolicy: 'network-only',
  //           context: { clientName: 'rest' },
  //         });
  //
  //         updatedStoriesData[feature.id] = (data.stories || []).filter(
  //           (story) => story?.fields?.status?.statusCategory?.name === 'In Progress',
  //         );
  //       });
  //
  //       await Promise.all(fetchPromises);
  //       setStoriesData(updatedStoriesData);
  //     } catch (error) {
  //       console.error('Error fetching In Progress stories:', error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //
  //   if (features.length > 0) {
  //     fetchAllInProgressStories();
  //   }
  // }, [features, client]);

  const toDo = [];
  const inProgress = [];
  const done = [];

  const categories = features.map((feature) => {
    toDo.push(feature.toDoCount);
    inProgress.push(feature.inProgressCount);
    done.push(feature.doneCount);
    return feature.name;
  });

  const config = {
    series: [
      {
        name: scope === 'storyCount' ? 'To Do Story Count' : 'To Do Story Points',
        data: toDo,
        color: theme.palette.color.toDo,
      },
      {
        name: scope === 'storyCount' ? 'In Progress Story Count' : 'In Progress Story Points',
        data: inProgress,
        color: theme.palette.color.inProgress,
      },
      {
        name: scope === 'storyCount' ? 'Done Story Count' : 'Done Story Points',
        data: done,
        color: theme.palette.color.done,
      },
    ],
    options: {
      chart: { type: 'bar', height: 350, stacked: true, background: theme.palette.color.background },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: { fontSize: '13px', fontWeight: 900, color: theme.palette.text.secondary },
            },
          },
        },
      },
      tooltip: {
        theme: theme.palette.mode,
        custom: ({ seriesIndex, dataPointIndex }) => {
          const feature = features[dataPointIndex];

          if (seriesIndex !== 1) {
            return `
              <div>
                <div style="background: #f0f0f0; font-weight: bold; padding: 8px">
                  ${feature.name}
                </div>
                <div style="padding: 8px;">
                  ${
                    seriesIndex === 0
                      ? `To Do ${
                          scope === 'storyCount'
                            ? 'Story Count: <strong>' + feature.toDoCount + '</strong>'
                            : 'Story Points: <strong>' + feature?.metrics?.storyPoints?.toDo + '</strong>'
                        }`
                      : `Done ${
                          scope === 'storyCount'
                            ? 'Story Count: <strong>' + feature.doneCount + '</strong>'
                            : 'Story Points: <strong>' + feature?.metrics?.storyPoints?.done + '</strong>'
                        }`
                  }
                </div>
              </div>
            `;
          }

          if (loading) return `<div style="font-weight: bold; padding: 8px;">Loading...</div>`;

          const stories = storiesData[feature.id] || [];
          const totalStoryPoints = stories.reduce((sum, story) => sum + (story.fields.customfield_10023 || 0), 0);

          const storyList = stories
            .map(
              (story) =>
                `<div style="margin-bottom: 4px;"><strong>${story.key}</strong> - ${story.fields.summary} <strong style="color: #d156a1">(${story.storyPoints})</strong></div>`,
            )
            .join('');

          return `
            <div>
              <div style="background: #f0f0f0; padding: 8px; font-weight: bold;">
                In Progress (${totalStoryPoints} Points)
              </div>
              <div style="margin-top: 5px; padding: 8px;">
                ${storyList || '<div>No stories data available</div>'}
              </div>
            </div>
          `;
        },
      },

      xaxis: {
        axisBorder: { show: false },
        categories,
        labels: {
          style: {
            colors: theme.palette.text.secondary,
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
        labels: {
          style: {
            colors: theme.palette.text.secondary,
            fontSize: '12px',
          },
        },
      },
      theme: {
        mode: theme.palette.mode,
      },
      grid: {
        yaxis: { lines: { show: false } },
        xaxis: { lines: { show: true } },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40,
        labels: { colors: theme.palette.text.secondary },
      },
    },
  };

  return <ReactApexChart options={config.options} series={config.series} type="bar" height="100%" />;
};

export default FeatureProgression;
